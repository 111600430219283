import React, {Component} from 'react';
import './App.css';
import {Redirect, Route, Switch} from 'react-router';
import {RoutingPass} from './constant/routing-pass';
import {PageTopComponent} from './component/page/top/page-top-component';
import {PageNoticeComponent} from './component/page/notice/page-notice-component';
import {PageHowtoComponent} from './component/page/howto/page-howto-component';
import {PageInfoComponent} from './component/page/info/page-info-component';
import {PageSimulatorComponent} from './component/page/simulator/page-simulator-component';
import {ModalComponent} from './component/modal/modal-component';
import imgLogoNichibei from './assets/img/common/logo_back.png';
import imgLogoNichibeiRet from './assets/img/common/logo_back@2x.png';
import imgLogoWindowSimulator from './assets/img/common/header_logo_window_simulator.png';
import imgLogoWindowSimulatorRet from './assets/img/common/header_logo_window_simulator@2x.png';
import imgTitleheadHowto from './assets/img/common/icon_titlehead_howto.png';
import imgTitleheadHowtoRet from './assets/img/common/icon_titlehead_howto@2x.png';
import imgIconHowto1 from './assets/img/common/icon_howto_1.png';
import imgIconHowto1Ret from './assets/img/common/icon_howto_1@2x.png';
import {DummyImgGetter} from './util/dummy-img-getter';
import imgIconHowto2 from './assets/img/common/icon_howto_2.png';
import imgIconHowto2Ret from './assets/img/common/icon_howto_2@2x.png';
import imgIconHowto3 from './assets/img/common/icon_howto_3.png';
import imgIconHowto3Ret from './assets/img/common/icon_howto_3@2x.png';
import imgIconHowto4 from './assets/img/common/icon_howto_4.png';
import imgIconHowto4Ret from './assets/img/common/icon_howto_4@2x.png';
import imgIconHowto5 from './assets/img/common/icon_howto_5.png';
import imgIconHowto5Ret from './assets/img/common/icon_howto_5@2x.png';
import about1 from './assets/img/common/about_pc_1.png';
import about2 from './assets/img/common/about_pc_2.png';
import about3 from './assets/img/common/about_pc_3.png';
import about4 from './assets/img/common/about_pc_4.png';
import about5 from './assets/img/common/about_pc_5.png';
import {CanvasConfService} from './service/canvas-conf-service';
import {ModalDialogComponent} from './component/modal/dialog/modal-dialog-component';
import {SaveImageComponent} from './component/modal/save-image/save-image-component';
import {ConnectionService} from './service/connection-service';
import {PostCreateImage} from './model/api/image/post-create-image';
import {string} from 'prop-types';
import Axios, {AxiosResponse} from 'axios';
import {ColorChangeConfirmComponent} from "./component/modal/color-change-confirm/color-change-confirm-component";

interface appState {
  isLoading: boolean;
  isHowTo: boolean;
  isSave: boolean;
  isColorChangeConfirm: boolean,
  callbackChangeColor: () => void,
}

interface appProps {
}

class App extends React.Component<appProps, appState> {

  static VERSION: string = '7.0.0';

  constructor(props: appProps) {
    super(props);
    this.state = {
      isLoading: false,
      isHowTo: false,
      isSave: false,
      isColorChangeConfirm: false,
      callbackChangeColor: () => {},
    };
  }

  componentDidMount(): void {

    // const arr:{id: string, type: string, code: string, subCode: string, seriese: string, group: string, name: string, color: string, texture: string, base: string, alpha: string, accentSide: string, accentBottom: string, headbox: string, wideradder: string}[] = [
    //
    // ];
    // const convert: {color: string, texture: string, color2: string, texture2: string}[] =[
    //
    // ];
    // arr.forEach((data) => {
    //   const rep = convert.find((c) => data.color === c.color && data.texture === data.texture);
    //   if (rep){
    //     data.color = rep.color2;
    //     data.texture = rep.texture2;
    //   }
    // });
    // let str: string = '';
    // arr.forEach((v) => {
    //   const {id, type, color, subCode, seriese, group, name, code, texture, base, alpha, accentSide, accentBottom, headbox, wideradder} = v;
    //   str += `${id}\t${type}\t${code}\t${subCode}\t${seriese}\t${group}\t${name}\t${color}\t${texture}\t${base}\t${alpha}\t${accentSide}\t${accentBottom}\t${headbox}\t${wideradder}\n`;
    // });
    // console.log(str);

    CanvasConfService.isSave = (flag: boolean) => this.setState({isSave: flag});
    CanvasConfService.loading = (flag: boolean) => {
      CanvasConfService.isLoading = flag;
      this.setState({isLoading: flag});
    };
    // setInterval(() => console.log('race: ', CanvasConfService.ins.centerRace), 3000);
    CanvasConfService.isHowTo = () => {
      this.setState({isHowTo: true});
      return true;
    };
    CanvasConfService.isColorChangeConfirm = (flag: boolean, callback: () => void) => this.setState({isColorChangeConfirm: flag, callbackChangeColor: callback});
    ////////////////////////////////////////////////////////////////////////////////////////////
    const handlerRotateScreen = () => {
      if (Math.abs(Number(window.orientation || 0)) !== 90) {
        setTimeout(() => {
          const {
            clientWidth,
            clientHeight,
          } = window.document.body;
          if (clientWidth < clientHeight) {
            CanvasConfService.ins.isVertical = true;
            ModalComponent.open(<ModalDialogComponent
              title={'※ 注意'}
              value={['端末が横向きであることを確認してお使いください']}
              displayCloseButton={true}
              callbackClose={() => {
                // window.removeEventListener('orientationchange', handlerRotateScreen);
                ModalComponent.closeAll();
              }}
            />);
          } else {
            ModalComponent.closeAll();
          }
        }, 1000);
      } else {
        ModalComponent.closeAll();
        if(CanvasConfService.ins.rotateListener){
          CanvasConfService.ins.rotateListener();
        }
      }
    };
    const ua = navigator.userAgent;
    const isMobile = ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 || ua.indexOf('Mobile') > 0;
    if (isMobile && Math.abs(Number(window.orientation || 0)) !== 90) {
      const {
        clientWidth,
        clientHeight,
      } = window.document.body;
      if (clientWidth < clientHeight) {
        CanvasConfService.ins.isVertical = true;
        ModalComponent.open(<ModalDialogComponent
          title={'※ 注意'}
          value={['端末が横向きであることを確認してお使いください']}
          displayCloseButton={true}
          callbackClose={() => {
            // window.removeEventListener('orientationchange', handlerRotateScreen);
            ModalComponent.closeAll();
            // setTimeout(() => {
            //   if (!CanvasConfService.isHowTo()) {
            //     if (!PageSimulatorComponent.isLaunch && ModalComponent.ins.state.bodyList.length === 0) {
            //       PageSimulatorComponent.isLaunch = true;
            //     }
            //   }
            // });
          }}
        />);
      }
    }
    // 画面回転イベントリスナー登録
    window.addEventListener('orientationchange', handlerRotateScreen);
    // スマホ対応用
    if (isMobile) {
      window.document.body.setAttribute('id', 'isSP');
    }
    ////////////////////////////////////////////////////////////////////////////////////////////

    if(ua.indexOf('iPhone') !== -1 || ua.indexOf('Android') !== -1 && ua.indexOf('Mobile') !== -1 ){
      // window.document.body.setAttribute('id', 'isSP');
    }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
      window.document.body.setAttribute('id', 'isTablet');
    }
  }

  render() {
    const {isLoading, isHowTo, isSave, isColorChangeConfirm, callbackChangeColor} = this.state;
    const agent = window.navigator.userAgent.toLowerCase();
    const isEnableSaveImage = ((agent.toLowerCase().indexOf('chrome') === -1) || (agent.toLowerCase().indexOf('edge') === -1) || (agent.toLowerCase().indexOf('firefox') === -1) && agent.toLowerCase().indexOf('safari') !== -1);
    return (
      <div>

        <Switch>
          {/* トップ */}
          <Route
            path={RoutingPass.TOP}
            component={PageTopComponent}
          />
          {/* お知らせ */}
          <Route
            path={RoutingPass.NOTICE}
            component={PageNoticeComponent}
          />
          {/* 使い方 */}
          <Route
            path={RoutingPass.HOWTO}
            component={PageHowtoComponent}
          />
          {/* インフォメーション */}
          <Route
            path={RoutingPass.INFO}
            component={PageInfoComponent}
          />
          {/* シミュレータ */}
          <Route
            path={RoutingPass.SIMULATOR}
            component={PageSimulatorComponent}
          />
          <Redirect to={RoutingPass.SIMULATOR}/>
        </Switch>

        <ModalComponent/>

        {isSave ? (
          <div className={'dialog_wrap dialog_saveImage'}>
            <div className={'dialog_bg'}/>
            <div className={'dialog_body'}>
              <SaveImageComponent isEnableSaveImage={isEnableSaveImage}/>
            </div>
          </div>
        ) : (<></>)}

        {/* ローディング */}
        {/*/!**/}
        {isLoading ? (
          <div className={'loading_wrap'}>
            <div className={'loading_bg'}/>
            <div className={'loading_body flex_box flex_align_center flex_content_center'}>
              <div className={'loader'}/>
            </div>
          </div>
        ) : (<></>)}
        {/*// */}

        {isColorChangeConfirm ? (
          <div className={'dialog_wrap dialog_saveImage'}>
            <div className={'dialog_bg'}/>
            <div className={'dialog_body'}>
              <ColorChangeConfirmComponent callback={callbackChangeColor} />
            </div>
          </div>
        ) : (<></>)}

        {/* 操作案内 */}
        {/* page-simulator-component.tsx と同じものを表示してます */}
        {/*{isHowTo ? (*/}
        {/*  <div className={'guide_modal'}>*/}
        {/*    <div className={'guide_modal_bg'}/>*/}
        {/*    <div className={'guide_modal_body'}>*/}
        {/*      <div className="changeBlindModal_title flex_box flex_align_center flex_space_between">*/}
        {/*        <div className="modalTitleInner">操作方法</div>*/}
        {/*        <div className="right_btn_wrap">*/}
        {/*          <button*/}
        {/*            type="button"*/}
        {/*            className="reset"*/}
        {/*            onClick={() => {*/}
        {/*              this.setState({isHowTo: false});*/}
        {/*              setTimeout(() => {*/}
        {/*                if(!PageSimulatorComponent.isLaunch  && ModalComponent.ins.state.bodyList.length === 0){*/}
        {/*                  PageSimulatorComponent.isLaunch = true;*/}
        {/*                  // ModalComponent.open(<ModalDialogComponent*/}
        {/*                  //   title={''}*/}
        {/*                  //   value={[*/}
        {/*                  //     '収録商品の一部の色柄が選択できない場合がありますので、あらかじめご了承ください。',*/}
        {/*                  //     'それらの色柄については、今後のバージョンアップで順次対応していく予定です。'*/}
        {/*                  //   ]}*/}
        {/*                  //   displayCloseButton={true}*/}
        {/*                  //   callbackClose={() => {*/}
        {/*                  //     // window.removeEventListener('orientationchange', handlerRotateScreen);*/}
        {/*                  //     ModalComponent.closeAll();*/}
        {/*                  //   }}*/}
        {/*                  //   fontColor={'#FF0000'}*/}
        {/*                  // />);*/}
        {/*                }*/}
        {/*              })*/}
        {/*            }}*/}
        {/*          >✕*/}
        {/*          </button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className={'body_howto'}>*/}
        {/*        <div className={'common_body'}>*/}
        {/*          <div className={'common_title flex_box flex_align_center'}>*/}
        {/*            <div>*/}
        {/*              <img*/}
        {/*                src={imgTitleheadHowto}*/}
        {/*                srcSet={`${imgTitleheadHowto} 1x, ${imgTitleheadHowtoRet} 2x`}*/}
        {/*                alt={'本サイトの使い方'}*/}
        {/*              />*/}
        {/*            </div>*/}
        {/*            <div><h3>本サイトの使い方</h3></div>*/}
        {/*          </div>*/}

        {/*          <div className={'common_contentArea'}>*/}

        {/*            <div className={'common_cont_wrap'}>*/}
        {/*              <div className={'common_cont_title'}>*/}
        {/*                <div className={'common_cont_titleInner'}>*/}
        {/*                  <h4>本サイトについて</h4>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*              <div className={'common_cont_body'}>*/}
        {/*                ニチベイの豊富な商品をお好きなだけ試して、あなたのお部屋にぴったりのブラインドが探せる‼<br/><br/>*/}
        {/*                ブラインドシミュレーションサイト<br/>*/}
        {/*                「ニチベイ ウィンドウシミュレーター」<br/><br/>*/}
        {/*                お客様の実際のお部屋の写真にブラインドのイメージを合成して、設置した際のイメージを手軽に確認することができるサイトです。*/}
        {/*              </div>*/}
        {/*              <hr className={'commonContBorder'}/>*/}

        {/*              <div className={'common_cont_body howto_cont_body'}>*/}

        {/*                <div className={'howto_cont_item_wrap'}>*/}
        {/*                  <div className={'howto_cont_item_head flex_box'}>*/}
        {/*                    <div>*/}
        {/*                      <img*/}
        {/*                        src={imgIconHowto1}*/}
        {/*                        srcSet={`${imgIconHowto1} 1x, ${imgIconHowto1Ret} 2x`}*/}
        {/*                        alt={'デジカメでお部屋の窓を撮影'}*/}
        {/*                      />*/}
        {/*                    </div>*/}
        {/*                    <div><h5>デジカメでお部屋の窓を撮影</h5></div>*/}
        {/*                  </div>*/}
        {/*                  <div className={'howto_cont_item_body'}>*/}
        {/*                    <img src={about1}/>*/}
        {/*                  </div>*/}
        {/*                </div>*/}

        {/*                <div className={'howto_cont_item_wrap'}>*/}
        {/*                  <div className={'howto_cont_item_head flex_box'}>*/}
        {/*                    <div>*/}
        {/*                      <img*/}
        {/*                        src={imgIconHowto2}*/}
        {/*                        srcSet={`${imgIconHowto2} 1x, ${imgIconHowto2Ret} 2x`}*/}
        {/*                        alt={'撮った写真をパソコンに取り込みます'}*/}
        {/*                      />*/}
        {/*                    </div>*/}
        {/*                    <div><h5>撮った写真をパソコンに取り込みます</h5></div>*/}
        {/*                  </div>*/}
        {/*                  <div className={'howto_cont_item_body'}>*/}
        {/*                    <img src={about2}/>*/}
        {/*                  </div>*/}
        {/*                </div>*/}

        {/*                <div className={'howto_cont_item_wrap'}>*/}
        {/*                  <div className={'howto_cont_item_head flex_box'}>*/}
        {/*                    <div>*/}
        {/*                      <img*/}
        {/*                        src={imgIconHowto3}*/}
        {/*                        srcSet={`${imgIconHowto3} 1x, ${imgIconHowto3Ret} 2x`}*/}
        {/*                        alt={'シミュレーションしたい商品やタイプを選んだ後に、表示されたブラインドの四隅を窓にぴったり合わせます'}*/}
        {/*                      />*/}
        {/*                    </div>*/}
        {/*                    <div><h5>シミュレーションしたい商品やタイプを選んだ後に、表示されたブラインドの四隅を窓にぴったり合わせます</h5></div>*/}
        {/*                  </div>*/}
        {/*                  <div className={'howto_cont_item_body'}>*/}
        {/*                    <img src={about3}/>*/}
        {/*                  </div>*/}
        {/*                </div>*/}

        {/*                <div className={'howto_cont_item_wrap'}>*/}
        {/*                  <div className={'howto_cont_item_head flex_box'}>*/}
        {/*                    <div>*/}
        {/*                      <img*/}
        {/*                        src={imgIconHowto4}*/}
        {/*                        srcSet={`${imgIconHowto4} 1x, ${imgIconHowto4Ret} 2x`}*/}
        {/*                        alt={'色柄や商品、タイプを変えてお気に入りのブラインドを探せます'}*/}
        {/*                      />*/}
        {/*                    </div>*/}
        {/*                    <div><h5>色柄や商品、タイプを変えてお気に入りのブラインドを探せます</h5></div>*/}
        {/*                  </div>*/}
        {/*                  <div className={'howto_cont_item_body'}>*/}
        {/*                    <img src={about4}/>*/}
        {/*                  </div>*/}
        {/*                </div>*/}

        {/*                <div className={'howto_cont_item_wrap'}>*/}
        {/*                  <div className={'howto_cont_item_head flex_box'}>*/}
        {/*                    <div>*/}
        {/*                      <img*/}
        {/*                        src={imgIconHowto5}*/}
        {/*                        srcSet={`${imgIconHowto5} 1x, ${imgIconHowto5Ret} 2x`}*/}
        {/*                        alt={'気に入ったブラインドが見つかったら、イメージシートが作成できるので、ニチベイ商品取扱店でスムーズにご注文が可能です'}*/}
        {/*                      />*/}
        {/*                    </div>*/}
        {/*                    <div><h5>気に入ったブラインドが見つかったら、イメージシートが作成できるので、ニチベイ商品取扱店でスムーズにご注文が可能です</h5></div>*/}
        {/*                  </div>*/}
        {/*                  <div className={'howto_cont_item_body'}>*/}
        {/*                    <img src={about5}/>*/}
        {/*                  </div>*/}
        {/*                </div>*/}

        {/*              </div>*/}
        {/*            </div>*/}

        {/*          </div>*/}
        {/*        </div>*/}

              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*) : (<></>)}*/}

      </div>
    );
  }

}

export default App;

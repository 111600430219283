export class ColorExceptionManager {

  private static list: string[] = [
    'M8098',
    'M8099',
    'M8100',
    'M8101',
    'N7018',
    'N7019',
    'N7020',
    'N7048',
    'N7186',
    'N7187',
    'N7188',
    'N7189',
    'N7190',
    'N7191',
    'N7192',
    'N7238',
    'N7239',
    'N7240',
    'N7270',
    'N7271',
    'N7272',
    'N7273',
    'N7418',
    'N7419',
    'N7420',
    'N7586',
    'N7587',
    'N7588',
    'N7589',
    'N7590',
    'N7591',
    'N7592',
    'N7672',
  ];

  static exception(value: string): boolean {
    return Boolean(this.list.find((data) => data === value));
  }
}

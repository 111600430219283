import * as React from 'react';
import './menu-size-component.scss';
import imgSizeSelect from '../../../../../../assets/img/simulator/sizeSelect_img.png';
// import imgSizeSelectRet from '../../../../../../assets/img/simulator/sizeSelect_img@2x.png';
import {CanvasConfService} from '../../../../../../service/canvas-conf-service';
import {SlatModalService} from '../../../../../../service/slat-modal-service';

interface MenuSizeComponentProps {
}

interface MenuSizeComponentState {
  width: number;
  height: number;
}

export class MenuSizeComponent extends React.Component<MenuSizeComponentProps, MenuSizeComponentState> {

  constructor(props: MenuSizeComponentProps) {
    super(props);
    this.state = {
      width: CanvasConfService.ins.blindData.windowSizeWidth,
      height: CanvasConfService.ins.blindData.windowSizeHeight,
    }
  }

  render() {
    const {width, height} = this.state;
    return (
      <div className={'content_area_wrap cont_sizeSelectArea_inner'}>

        <div className={'inputArea_wrap flex_box'}>

          <div className={'inputArea_left'}>
            {/* <img src={DummyImgGetter.getRandomURL()}/> */}
            <h4>
              <img
                src={imgSizeSelect}
                // srcSet={`${imgSizeSelect} 1x, ${imgSizeSelectRet} 2x`}
                alt={'商品サイズ'}
              />
            </h4>
          </div>
          <div className={'inputArea_right'}>
            <div>
              <div className={'inputTitle'}>幅 (W)</div>
              <div className={'flex_box'}>
                <input
                  type={'number'}
                  value={width}
                  onChange={(e) => this.handlerInputWidth(+e.target.value)}
                  onBlur={() => CanvasConfService.ins.updateBlindColor(
                    SlatModalService.ins.slatColor.length > 0 ?
                      SlatModalService.ins.slatColor :
                      CanvasConfService.ins.blindData.blindColorCode)}
                />
                <span className={'minText'}>mm</span>
              </div>
            </div>
            <div>
              <div className={'inputTitle'}>高さ (H)</div>
              <div className={'flex_box'}>
                <input
                  type={'number'}
                  value={height}
                  // onChange={(e) => this.setState({height: +e.target.value})}
                  onChange={(e) => this.handlerInputHeight(+e.target.value)}
                  onBlur={() => CanvasConfService.ins.updateBlindColor(
                    SlatModalService.ins.slatColor.length > 0 ?
                      SlatModalService.ins.slatColor :
                    CanvasConfService.ins.blindData.blindColorCode)}
                />
                <span className={'minText'}>mm</span>
              </div>
            </div>
          </div>

        </div>
        {/* //.inputArea_wrap */}

        <ul className={'attentionMessage'}>
          {/*<li>※半角数字で入力してください。</li>*/}
          {/*<li>※窓の大きさは、幅(W)高さ(H)共に、300mm以上、5000mm以下で入力してください。</li>*/}
          {/*<li>※各商品への制御可能寸法はニチベイオフィシャルサイト、販売店でご確認ください。</li>*/}
          {/*<li>※レールジョイントのルーバー枚数は、窓幅の中心で分割する想定で算出されます。</li>*/}
          <li>※半角数字で入力してください。</li>
          <li>※商品の寸法は、幅(W)高さ(H)共に、300mm以上、5000mm以下で入力してください。</li>
          <li>※各商品の製作可能寸法はデジタルブック、またはニチベイ商品販売店にてご確認ください。</li>
          <li>※たて型ブラインド「レールジョイントタイプ」のルーバー枚数は、商品幅の中心で分割する想定で算出されます。</li>
        </ul>

      </div>
    );
  }

  private handlerInputWidth(num: number){
    if(num >= 300 && num <= 5000){
      CanvasConfService.ins.blindData.windowSizeWidth = num;
      CanvasConfService.ins.updateBlindSpec();
    }
    this.setState({
      width: num,
    });
  }

  private handlerInputHeight(num: number){
    if(num >= 300 && num <= 5000){
      CanvasConfService.ins.blindData.windowSizeHeight = num;
      CanvasConfService.ins.updateBlindSpec();
    }
    this.setState({
      height: num,
    })
  }
}

import { Buhin, CanvasConfService, Colors } from '../service/canvas-conf-service';
import * as d3 from 'd3';
import { NumericSolveManager } from './numeric-solve-manager';
import { CreateSlatManager } from './create-slat-manager';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { SlatModalService } from '../service/slat-modal-service';
import TA271 from '../assets/img/slat/TA271.png';
import TA272_0 from '../assets/img/slat/TA272_0.png';
import TA272_1 from '../assets/img/slat/TA272_1.png';
import TA272_2 from '../assets/img/slat/TA272_2.png';
import TA272_3 from '../assets/img/slat/TA272_3.png';
import TA273_0 from '../assets/img/slat/TA273_0.png';
import TA273_1 from '../assets/img/slat/TA273_1.png';
import TA273_2 from '../assets/img/slat/TA273_2.png';
import TA274_0 from '../assets/img/slat/TA274_0.png';
import TA274_1 from '../assets/img/slat/TA274_1.png';
import TA274_2 from '../assets/img/slat/TA274_2.png';
import TA274_3 from '../assets/img/slat/TA274_3.png';
import TA274_4 from '../assets/img/slat/TA274_4.png';
import TA274_5 from '../assets/img/slat/TA274_5.png';
import B_TA271 from '../assets/img/slat/B_TA271.png';
import B_TA272_0 from '../assets/img/slat/B_TA272_0.png';
import B_TA272_1 from '../assets/img/slat/B_TA272_1.png';
import B_TA272_2 from '../assets/img/slat/B_TA272_2.png';
import B_TA272_3 from '../assets/img/slat/B_TA272_3.png';
import B_TA273_0 from '../assets/img/slat/B_TA273_0.png';
import B_TA273_1 from '../assets/img/slat/B_TA273_1.png';
import B_TA273_2 from '../assets/img/slat/B_TA273_2.png';
import B_TA274_0 from '../assets/img/slat/B_TA274_0.png';
import B_TA274_1 from '../assets/img/slat/B_TA274_1.png';
import B_TA274_2 from '../assets/img/slat/B_TA274_2.png';
import B_TA274_3 from '../assets/img/slat/B_TA274_3.png';
import B_TA274_4 from '../assets/img/slat/B_TA274_4.png';
import B_TA274_5 from '../assets/img/slat/B_TA274_5.png';
import { ExportImgManager } from './export-img-manager';
import { Pos } from '../model/pos';
import { Margin } from '../model/margin';
import { PostMakePdf } from '../model/api/pdf/post-make-pdf';
import { ConnectionService } from '../service/connection-service';
import { DateFormat, DateFormatter } from '../util/date-formatter';
import { HtmlCreator } from '../service/html-creator';
import { PostCreateImage } from '../model/api/image/post-create-image';
import { LogManager } from './log-manager';
import { GetColorsByCode } from '../model/api/color/get-colors-by-code';
import { GetOptionColors } from '../model/api/option/get-option-colors';
import { ImgRotater } from '../util/img-rotater';

export class DrawManager {
  /**
   * スラットを宣言
   * DB で管理予定だったが元々の画像を流用することができず自作する必要があったためローカルで管理
   */
  private slatVertical: string = 'vertical.svg';
  private slatSide: string = 'side.svg';
  private topVa: string = 'Top_va100.svg';
  private bottomVertical: string = 'bottom_vertical.svg';
  private middleBar: string = 'MiddleBar.png';
  private balance: string = 'balance.svg';
  private longBottom: string = 'bottom.svg';
  private raceSlat: string = '';
  private raceBottom: string = '';

  /**
   * 特殊パターン
   * DB の設計上サーバー側で管理できないためローカルで保持
   */
  private TA271: string = '';
  private TA272_0: string = '';
  private TA272_1: string = '';
  private TA272_2: string = '';
  private TA272_3: string = '';
  private TA273_0: string = '';
  private TA273_1: string = '';
  private TA273_2: string = '';
  private TA274_0: string = '';
  private TA274_1: string = '';
  private TA274_2: string = '';
  private TA274_3: string = '';
  private TA274_4: string = '';
  private TA274_5: string = '';
  private B_TA271: string = '';
  private B_TA272_0: string = '';
  private B_TA272_1: string = '';
  private B_TA272_2: string = '';
  private B_TA272_3: string = '';
  private B_TA273_0: string = '';
  private B_TA273_1: string = '';
  private B_TA273_2: string = '';
  private B_TA274_0: string = '';
  private B_TA274_1: string = '';
  private B_TA274_2: string = '';
  private B_TA274_3: string = '';
  private B_TA274_4: string = '';
  private B_TA274_5: string = '';
  private V310: string = '';

  // iPhone対応は[ navigator.userAgent.indexOf('iPhone') ] を条件に指定して対応
  private isMobile: boolean = navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0 || navigator.userAgent.indexOf('Mobile') > 0;
  public static transformSVGIdName: string = 'transform';
  private static controlsSVGIdName: string = 'control';
  private transformSVG: any;
  private controlSVG: any;
  static controlHandlerObj: any;
  private blindLine1: any;
  private blindLine2: any;
  private blindLine3: any;
  private blindLine4: any;
  private margin: Margin = new Margin(150, 150, 150, 150);
  private width: number = 700 - this.margin.left - this.margin.right;
  private height: number = 700 - this.margin.top - this.margin.bottom;
  private defaultSVGStyle: string =
    `position: absolute; top: 0; left: 0;` +
    `transform-origin: ${this.margin.left}px ${this.margin.top}px 0px ;`;
  private sourcePoints: Pos[] = [
    new Pos(0, 0),
    new Pos(this.width, 0),
    new Pos(this.width, this.height),
    new Pos(0, this.height),
  ];
  static targetPoints: Pos[] = [
    new Pos(-350, -350),
    new Pos(400 - 350, -350),
    new Pos(400 - 350, 400 - 350),
    new Pos(-350, 400 - 350),
  ];
  private isDrag: boolean = false;
  private inBlindRange: boolean = true;
  private static isVertical: boolean = false;
  public static currentPos: Pos = { x: 0, y: 0 };
  private static touchPos: Pos = { x: 0, y: 0 };
  private static moveLen = { width: 0, height: 0 };

  private slopeList: number[] = [0, 0, 0, 0];
  private displayObjArr: any[] = [];
  private dragPointNum: number = -1;

  private headClip!: SVGPathElement;
  private bottomClip!: SVGPathElement;

  private tempImage: string = '';
  static blindSelected: boolean = false;
  static rotation: number = 0;

  public constructor() {
    /**
     * constructor で特殊ケースの画像を読み込み
     * constructor で実行してるのは画像読み込みとモバイル判定のみ
     */
    DrawManager.getFileFromUrl(TA271)
      .then((res: string) => {
        this.TA271 = res;
      });
    DrawManager.getFileFromUrl(TA272_0)
      .then((res: string) => {
        this.TA272_0 = res;
      });
    DrawManager.getFileFromUrl(TA272_1)
      .then((res: string) => {
        this.TA272_1 = res;
      });
    DrawManager.getFileFromUrl(TA272_2)
      .then((res: string) => {
        this.TA272_2 = res;
      });
    DrawManager.getFileFromUrl(TA272_3)
      .then((res: string) => {
        this.TA272_3 = res;
      });
    DrawManager.getFileFromUrl(TA273_0)
      .then((res: string) => {
        this.TA273_0 = res;
      });
    DrawManager.getFileFromUrl(TA273_1)
      .then((res: string) => {
        this.TA273_1 = res;
      });
    DrawManager.getFileFromUrl(TA273_2)
      .then((res: string) => {
        this.TA273_2 = res;
      });
    DrawManager.getFileFromUrl(TA274_0)
      .then((res: string) => {
        this.TA274_0 = res;
      });
    DrawManager.getFileFromUrl(TA274_1)
      .then((res: string) => {
        this.TA274_1 = res;
      });
    DrawManager.getFileFromUrl(TA274_2)
      .then((res: string) => {
        this.TA274_2 = res;
      });
    DrawManager.getFileFromUrl(TA274_3)
      .then((res: string) => {
        this.TA274_3 = res;
      });
    DrawManager.getFileFromUrl(TA274_4)
      .then((res: string) => {
        this.TA274_4 = res;
      });
    DrawManager.getFileFromUrl(TA274_5)
      .then((res: string) => {
        this.TA274_5 = res;
      });
    DrawManager.getFileFromUrl(B_TA271)
      .then((res: string) => {
        this.B_TA271 = res;
      });
    DrawManager.getFileFromUrl(B_TA272_0)
      .then((res: string) => {
        this.B_TA272_0 = res;
      });
    DrawManager.getFileFromUrl(B_TA272_1)
      .then((res: string) => {
        this.B_TA272_1 = res;
      });
    DrawManager.getFileFromUrl(B_TA272_2)
      .then((res: string) => {
        this.B_TA272_2 = res;
      });
    DrawManager.getFileFromUrl(B_TA272_3)
      .then((res: string) => {
        this.B_TA272_3 = res;
      });
    DrawManager.getFileFromUrl(B_TA273_0)
      .then((res: string) => {
        this.B_TA273_0 = res;
      });
    DrawManager.getFileFromUrl(B_TA273_1)
      .then((res: string) => {
        this.B_TA273_1 = res;
      });
    DrawManager.getFileFromUrl(B_TA273_2)
      .then((res: string) => {
        this.B_TA273_2 = res;
      });
    DrawManager.getFileFromUrl(B_TA274_0)
      .then((res: string) => {
        this.B_TA274_0 = res;
      });
    DrawManager.getFileFromUrl(B_TA274_1)
      .then((res: string) => {
        this.B_TA274_1 = res;
      });
    DrawManager.getFileFromUrl(B_TA274_2)
      .then((res: string) => {
        this.B_TA274_2 = res;
      });
    DrawManager.getFileFromUrl(B_TA274_3)
      .then((res: string) => {
        this.B_TA274_3 = res;
      });
    DrawManager.getFileFromUrl(B_TA274_4)
      .then((res: string) => {
        this.B_TA274_4 = res;
      });
    DrawManager.getFileFromUrl(B_TA274_5)
      .then((res: string) => {
        this.B_TA274_5 = res;
      });
    /** 記述方法が変わるがこの辺も画像の読み込み */
    DrawManager.getSlatElementFromUrl(
      this.slatSide,
      '#ABA9A3',
      '#D6D4CC',
    )
      .then((svgRes) => {
        const svgData = new XMLSerializer().serializeToString(svgRes);
        const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
        DrawManager.getFileFromUrl(data)
          .then((res: string) => {
            this.V310 = res;
          });
      });
    DrawManager.getFileFromUrl(this.middleBar)
      .then((res: string) => {
        this.middleBar = res;
      });
    DrawManager.getSlatElementFromUrl(
      this.bottomVertical,
      '#F5F5F5',
      '#C4C4C4',
    ).then((bottomSvg) => {
      const bottomSvgData = new XMLSerializer().serializeToString(bottomSvg);
      const bottomData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(bottomSvgData)));
      DrawManager.getFileFromUrl(bottomData)
        .then((bottomRes: string) => {
          this.raceBottom = bottomRes;
        });
    });
    DrawManager.getSlatElementFromUrl(
      this.slatVertical,
      '#F5F5F5',
      '#C4C4C4',
    ).then((slat) => {
      const raceSlat = new XMLSerializer().serializeToString(slat);
      const slatData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(raceSlat)));
      DrawManager.getFileFromUrl(slatData)
        .then((res: string) => {
          this.raceSlat = res;
        });
    });
    if (this.isMobile) {
      /** モバイル対応（再三の要件変更で実質不要） */
      for (let i: number = 0; i < DrawManager.targetPoints.length; i++) {
        const size = (navigator.userAgent.indexOf('iPhone') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1)) ? 100 : 50;
        DrawManager.targetPoints[i].x += DrawManager.targetPoints[i].x > 0 ? -size : size;
        DrawManager.targetPoints[i].y += DrawManager.targetPoints[i].y > 0 ? -size : size;
      }
    }
  }

  private static backgroundImg: any;

  /**
   * 背景とsvgを描画
   * 表示はお互い独立してるが背景移動でsvgも一緒に移動させなきゃいけない
   * 背景画像は縦横比見て移動可能な方向を判断しなければならない
   */
  public static drawSVG(): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
        const backgroundImg = this.backgroundImg = new Image();
        backgroundImg.crossOrigin = 'anonymous';
        backgroundImg.src = CanvasConfService.ins.backgroundImg;
        backgroundImg.onload = () => {
          const rectSize = 700 - ((navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0 || navigator.userAgent.indexOf('Mobile') > 0) ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0);
          const canvas = document.getElementById(CanvasConfService.ins.canvasId) as HTMLCanvasElement;
          const context = canvas.getContext('2d') as CanvasRenderingContext2D;
          const { width, height } = backgroundImg;
          const widthLen = width > height ? (rectSize * (width / height)) : rectSize;
          const heightLen = width > height ? rectSize : (rectSize * (height / width));
          const scrollLimitX = width > height ? (widthLen - rectSize) / 2 : 0;
          const scrollLimitY = width > height ? 0 : (heightLen - rectSize) / 2;
          if (width > height && (this.currentPos.x + this.moveLen.width > -scrollLimitX && this.currentPos.x + this.moveLen.width < scrollLimitX)) {
            this.currentPos.x += this.moveLen.width;
            this.targetPoints.map((pos) => {
              pos.x += this.moveLen.width;
            });
          }
          if (height > width && (this.currentPos.y + this.moveLen.height > -scrollLimitY && this.currentPos.y + this.moveLen.height < scrollLimitY)) {
            this.currentPos.y += this.moveLen.height;
            this.targetPoints.map((pos) => {
              pos.y += this.moveLen.height;
            });
          }
          this.isVertical = height > width;
          if (this.controlHandlerObj) {
            this.controlHandlerObj._groups[0].map((sel: any, i: number) => {
              d3.select(sel)
                .attr('transform', 'translate(' + (sel.x = this.targetPoints[i].x) + ',' + (sel.y = this.targetPoints[i].y) + ')')
                .attr('cursor', i % 2 === 0 ? 'nwse-resize' : 'nesw-resize');
            });
          }
          this.moveLen.width = this.moveLen.height = 0;
          context.clearRect(0, 0, rectSize, rectSize);
          context.drawImage(backgroundImg, -scrollLimitX + this.currentPos.x, -scrollLimitY + this.currentPos.y, widthLen, heightLen);
          if (CanvasConfService.isLoading) {
            setTimeout(() => CanvasConfService.loading(false), 250);
          }
          resolve();
        };
        backgroundImg.onerror = reject;
      },
    );
  }

  /**
   * 描画を更新するためのクリア処理
   * やってること自体はdrawSVGとあまり変わらない
   * svg操作時はこちらを実行（使い分けは挙動で判断）
   */
  private static clearSVG(): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      const backgroundImg = this.backgroundImg;
      const canvas = document.getElementById(CanvasConfService.ins.canvasId) as HTMLCanvasElement;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      const rectSize = 700 - ((navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0 || navigator.userAgent.indexOf('Mobile') > 0) ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0);
      const { width, height } = backgroundImg;
      const widthLen = width > height ? (rectSize * (width / height)) : rectSize;
      const heightLen = width > height ? rectSize : (rectSize * (height / width));
      const scrollLimitX = width > height ? (widthLen - rectSize) / 2 : 0;
      const scrollLimitY = width > height ? 0 : (heightLen - rectSize) / 2;
      if (width > height && (this.currentPos.x + this.moveLen.width > -scrollLimitX && this.currentPos.x + this.moveLen.width < scrollLimitX)) {
        this.currentPos.x += this.moveLen.width;
        this.targetPoints.map((pos) => {
          pos.x += this.moveLen.width;
        });
      }
      if (height > width && (this.currentPos.y + this.moveLen.height > -scrollLimitY && this.currentPos.y + this.moveLen.height < scrollLimitY)) {
        this.currentPos.y += this.moveLen.height;
        this.targetPoints.map((pos) => {
          pos.y += this.moveLen.height;
        });
      }
      this.controlHandlerObj._groups[0].map((sel: any, i: number) => {
        d3.select(sel)
          .attr('transform', 'translate(' + this.targetPoints[i].x + ',' + this.targetPoints[i].y + ')')
          .attr('cursor', i % 2 === 0 ? 'nwse-resize' : 'nesw-resize');
      });
      this.moveLen.width = this.moveLen.height = 0;
      context.clearRect(0, 0, rectSize, rectSize);
      context.drawImage(backgroundImg, -scrollLimitX + this.currentPos.x, -scrollLimitY + this.currentPos.y, widthLen, heightLen);
      resolve();
    });
  }

  /**
   * リセット機能
   * 初期状態に戻す
   */
  static reset(): void {
    this.targetPoints = [
      new Pos(-350, -350),
      new Pos(400 - 350, -350),
      new Pos(400 - 350, 400 - 350),
      new Pos(-350, 400 - 350),
    ];
    const ua = navigator.userAgent;
    if (ua.indexOf('iPhone') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) || ua.indexOf('iPad') !== -1 || ua.indexOf('Android') !== -1 || ua.indexOf('Mobile') !== -1) {
      for (let i: number = 0; i < DrawManager.targetPoints.length; i++) {
        const size = (navigator.userAgent.indexOf('iPhone') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1)) ? 100 : 50;
        DrawManager.targetPoints[i].x += DrawManager.targetPoints[i].x > 0 ? -size : size;
        DrawManager.targetPoints[i].y += DrawManager.targetPoints[i].y > 0 ? -size : size;
      }
    }
    this.controlHandlerObj._groups[0].map((sel: any, i: number) => {
      if (sel.parentElement) {
        sel.parentElement.removeChild(sel);
      }
    });
    this.controlHandlerObj = null;
    this.currentPos = { x: 0, y: 0 };
    CanvasConfService.ins.updateBlindColor(SlatModalService.ins.slatColor);
  }

  /**
   * svgの宣言
   *
   * ・画像変形用：transform
   *
   * ・操作のための円、枠線用：controls
   *
   * 大まかにブラインド本体と変形させるためのコントローラー2種類のsvgが存在する
   * やっているのはその宣言、設定、イベント付与
   * イベント付与の流れでブラウザサイズ変更時のイベントもここに記載
   */
  public async init(): Promise<void> {
    const container = document.getElementById(CanvasConfService.ins.svgAreaId) as HTMLElement;
    const areaWidth: number = container.clientWidth;
    const areaHeight: number = container.clientHeight;
    const elementGroup1 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    elementGroup1.setAttributeNS(null, 'transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    const elementGroup2 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    elementGroup2.setAttributeNS(null, 'transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    const elementTransformSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    elementTransformSVG.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    elementTransformSVG.id = DrawManager.transformSVGIdName;
    elementTransformSVG.setAttributeNS(null, 'x', '0');
    elementTransformSVG.setAttributeNS(null, 'y', '0');
    elementTransformSVG.setAttributeNS(null, 'width', this.width + this.margin.left + this.margin.right + '');
    elementTransformSVG.setAttributeNS(null, 'height', this.height + this.margin.top + this.margin.bottom + '');
    elementTransformSVG.setAttributeNS(null, 'viewBox', '0 0 ' + (this.width + this.margin.left + this.margin.right) + ' ' + (this.height + this.margin.top + this.margin.bottom));
    elementTransformSVG.setAttributeNS(null, 'style', this.defaultSVGStyle + ';');
    elementTransformSVG.append(elementGroup1);
    const elementControlSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    elementControlSVG.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    elementControlSVG.id = DrawManager.controlsSVGIdName;
    elementControlSVG.setAttributeNS(null, 'x', '0');
    elementControlSVG.setAttributeNS(null, 'y', '0');
    elementControlSVG.setAttributeNS(null, 'width', '' + areaWidth);
    elementControlSVG.setAttributeNS(null, 'height', '' + areaHeight);
    elementControlSVG.setAttributeNS(null, 'viewBox', '-' + (areaWidth / 2) + ' -' + (areaHeight / 2) + ' ' + areaWidth + ' ' + areaHeight);
    elementControlSVG.setAttributeNS(null, 'style', `transform-origin: ${this.margin.left + 'px ' + this.margin.top + 'px 0px'};`);
    elementControlSVG.append(elementGroup2);
    container.append(elementTransformSVG);
    (document.getElementById('control-area') as HTMLElement).append(elementControlSVG);
    this.transformSVG = d3.select(elementTransformSVG);
    this.controlSVG = d3.select(elementControlSVG);
    this.blindLine1 = this.controlSVG.select('g').append('line');
    this.blindLine2 = this.controlSVG.select('g').append('line');
    this.blindLine3 = this.controlSVG.select('g').append('line');
    this.blindLine4 = this.controlSVG.select('g').append('line');
    this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
    const { clientWidth, clientHeight } = container;
    const bounds = container.getBoundingClientRect();
    const {
      left,
      top,
    } = (document.getElementById(CanvasConfService.ins.canvasId) as HTMLCanvasElement).getBoundingClientRect();
    const wid = (clientWidth < (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 250 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 300 : 450 : 700)) ? clientWidth + (left / 2) : (clientWidth - (this.isMobile ? navigator.userAgent.indexOf('iPhone') ? 300 : 450 : 700)) / 2 + bounds.left + 500;
    const hei = (clientHeight < (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 250 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 300 : 450 : 700)) ? clientHeight - bounds.top + (top - bounds.top) : (clientHeight - (this.isMobile ? navigator.userAgent.indexOf('iPhone') ? 300 : 450 : 700)) / 2 + bounds.top + 500;
    (document.getElementById('control-area') as HTMLElement).addEventListener('touchstart', (e: any) => {
      this.isDrag = true;
      this.inBlindRange = true;
      DrawManager.touchPos.x = e.touches[0].clientX;
      DrawManager.touchPos.y = e.touches[0].clientY;
      const { clientWidth, clientHeight } = container;
      const bounds = container.getBoundingClientRect();
      // const size = this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 250 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 300 : 450 : 700;
      const size = 700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0);
      const wid = (clientWidth < size) ? 0 : (clientWidth - size) / 2 + bounds.left + (size / 2 + (this.isMobile ? 150 : 150));
      const hei = (clientHeight < size) ? 0 : (clientHeight - size) / 2 + bounds.top + (size / 2 + (this.isMobile ? 150 : 150));
      const touchPos: Pos = new Pos(DrawManager.touchPos.x - wid, DrawManager.touchPos.y - hei);
      for (let i: number = 0; i < DrawManager.targetPoints.length; i++) {
        this.inBlindRange = this.inBlindRange && this.calcRad(
            DrawManager.targetPoints[i],
            touchPos,
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
          ) < this.calcRad(
            DrawManager.targetPoints[i],
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          ) &&
          this.calcRad(
            DrawManager.targetPoints[i],
            touchPos,
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          ) < this.calcRad(
            DrawManager.targetPoints[i],
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          );
      }
    });
    (document.getElementById('control-area') as HTMLElement).addEventListener('touchmove', (e: any) => {
      if (!this.isDrag) {
        return;
      }
      DrawManager.moveLen.width = e.touches[0].clientX - DrawManager.touchPos.x;
      DrawManager.moveLen.height = e.touches[0].clientY - DrawManager.touchPos.y;
      if (this.inBlindRange) {
        DrawManager.targetPoints.map((data) => {
          data.x += DrawManager.moveLen.width;
          data.y += DrawManager.moveLen.height;
        });
        DrawManager.controlHandlerObj._groups[0].map((sel: any, i: number) => {
          d3.select(sel)
            .attr('transform', 'translate(' + DrawManager.targetPoints[i].x + ',' + DrawManager.targetPoints[i].y + ')')
            .attr('cursor', i % 2 === 0 ? 'nwse-resize' : 'nesw-resize');
        });
        this.transformed();
      } else {
        DrawManager.clearSVG();
        this.transformSVG.attr('display', 'none');
        this.drawLine('0.6');
      }
      DrawManager.touchPos.x = e.touches[0].clientX;
      DrawManager.touchPos.y = e.touches[0].clientY;
    });
    (document.getElementById('control-area') as HTMLElement).addEventListener('touchend', (e: any) => {
      this.isDrag = false;
      if (this.inBlindRange) {
        //
      } else {
        DrawManager.drawSVG();
        this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
        this.transformSVG.attr('display', 'flex');
        this.transformed();
        this.inBlindRange = true;
      }
    });
    // } else {
    (document.getElementById('control-area') as HTMLElement).addEventListener('mousedown', (e: MouseEvent) => {
      this.isDrag = true;
      this.inBlindRange = true;
      DrawManager.touchPos.x = e.clientX;
      DrawManager.touchPos.y = e.clientY;
      const touchPos: Pos = new Pos(DrawManager.touchPos.x - wid, DrawManager.touchPos.y - hei);
      for (let i: number = 0; i < DrawManager.targetPoints.length; i++) {
        this.inBlindRange = this.inBlindRange && this.calcRad(
            DrawManager.targetPoints[i],
            touchPos,
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
          ) < this.calcRad(
            DrawManager.targetPoints[i],
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          ) &&
          this.calcRad(
            DrawManager.targetPoints[i],
            touchPos,
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          ) < this.calcRad(
            DrawManager.targetPoints[i],
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          );
      }
    });
    (document.getElementById('control-area') as HTMLElement).addEventListener('mousemove', (e: MouseEvent) => {
      let flag: boolean = true;
      const pos = { x: e.clientX - wid, y: e.clientY - hei };
      for (let i: number = 0; i < DrawManager.targetPoints.length; i++) {
        if (!flag) {
          continue;
        }
        flag = this.calcRad(
            DrawManager.targetPoints[i],
            pos,
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
          ) < this.calcRad(
            DrawManager.targetPoints[i],
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          ) &&
          this.calcRad(
            DrawManager.targetPoints[i],
            pos,
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          ) < this.calcRad(
            DrawManager.targetPoints[i],
            DrawManager.targetPoints[i > 0 ? i - 1 : 3],
            DrawManager.targetPoints[i < 3 ? i + 1 : 0],
          );
      }
      if (flag) {
        (document.getElementById('control-area') as HTMLElement).style.cursor = 'all-scroll';
      } else {
        (document.getElementById('control-area') as HTMLElement).style.cursor = DrawManager.isVertical ? 'ns-resize' : 'ew-resize';
      }
      if (!this.isDrag) {
        return;
      }
      DrawManager.moveLen.width = e.clientX - DrawManager.touchPos.x;
      DrawManager.moveLen.height = e.clientY - DrawManager.touchPos.y;
      if (this.inBlindRange) {
        DrawManager.targetPoints.map((data) => {
          data.x += DrawManager.moveLen.width;
          data.y += DrawManager.moveLen.height;
        });
        DrawManager.controlHandlerObj._groups[0].map((sel: any, i: number) => {
          d3.select(sel)
            .attr('transform', 'translate(' + DrawManager.targetPoints[i].x + ',' + DrawManager.targetPoints[i].y + ')')
            .attr('cursor', i % 2 === 0 ? 'nwse-resize' : 'nesw-resize');
        });
        this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
        this.transformed();
      } else {
        DrawManager.clearSVG();
        this.transformSVG.attr('display', 'none');
        this.drawLine('0.6');
      }
      DrawManager.touchPos.x = e.clientX;
      DrawManager.touchPos.y = e.clientY;
    });
    (document.getElementById('control-area') as HTMLElement).addEventListener('mouseup', (e: MouseEvent) => {
      this.isDrag = false;
      if (this.inBlindRange) {
        //
      } else {
        DrawManager.drawSVG();
        this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
        this.transformSVG.attr('display', 'flex');
        this.transformed();
      }
    });
    (document.getElementById('control-area') as HTMLElement).addEventListener('mouseout', () => {
      (document.getElementById('control-area') as HTMLElement).style.cursor = 'default';
      this.isDrag = false;
      if (this.inBlindRange) {
        //
      } else {
        if (this.dragPointNum === -1) {
          DrawManager.drawSVG();
          this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
          this.transformSVG.attr('display', 'flex');
          this.transformed();
        }
      }
    });
    window.onresize = () => {
      if (window.navigator.userAgent.toLowerCase().indexOf('chrome') === -1 && window.navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        return;
      }
      const canvas = document.getElementById(CanvasConfService.ins.canvasId) as HTMLCanvasElement;
      const { left, top } = canvas.getBoundingClientRect();
      if (left < 0 && top < 0) {
        // canvas.setAttributeNS(null, 'style', `width: ${700 + left - 10}px; height: ${700 + top - 10}px;`);
        canvas.setAttributeNS(null, 'width', `${700 + left}px`);
        canvas.setAttributeNS(null, 'height', `${700 + top}px`);
      } else if (left < 0) {
        canvas.setAttributeNS(null, 'width', `${700 + left}px`);
        canvas.setAttributeNS(null, 'height', `${700 + left}px`);
        // canvas.setAttributeNS(null, 'style', `width: ${700 + left - 10}px; height: 700px;`);
      } else if (top < 0) {
        canvas.setAttributeNS(null, 'width', `${700 + top}px`);
        canvas.setAttributeNS(null, 'height', `${700 + top}px`);
        // canvas.setAttributeNS(null, 'style', `width: 700px; height: ${700 + top - 10}px;`);
      }
      const { clientWidth, clientHeight } = container;
      elementControlSVG.setAttributeNS(null, 'width', '' + clientWidth);
      elementControlSVG.setAttributeNS(null, 'height', '' + clientHeight);
      elementControlSVG.setAttributeNS(null, 'viewBox', '-' + (clientWidth / 2) + ' -' + (clientHeight / 2) + ' ' + clientWidth + ' ' + clientHeight);
      elementControlSVG.setAttributeNS(null, 'display', 'flex');
      DrawManager.drawSVG();
    };
    /**
     * クリッピング
     *
     * ブラインドに画像が含まれる場合に画像をサイズ内に収めるためクリッピングをしている
     * 3次元変換をしている関係でマスキングだとうまくいかない
     */
    const clip = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
    clip.setAttributeNS(null, 'id', 'clip-path');
    const clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    clipPath.setAttributeNS(null, 'd', `M${this.margin.left},${this.margin.top}L${this.margin.left + this.width},${this.margin.top}L${this.margin.left + this.width},${this.margin.top + this.height}L${this.margin.left},${this.margin.top + this.height}Z`);
    clip.appendChild(clipPath);
    this.transformSVG.node().appendChild(clip);
    const clipTop = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
    clipTop.setAttributeNS(null, 'id', 'clip-path-top');
    const clipPathTop = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    clipPathTop.setAttributeNS(null, 'd', `M${this.margin.left},${this.margin.top}L${this.margin.left + this.width},${this.margin.top}L${this.margin.left + this.width},${this.margin.top + this.height / 2}L${this.margin.left},${this.margin.top + this.height / 2}Z`);
    clipTop.appendChild(clipPathTop);
    this.transformSVG.node().appendChild(clipTop);
    const clipUnder = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
    clipUnder.setAttributeNS(null, 'id', 'clip-path-under');
    const clipPathUnder = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    clipPathUnder.setAttributeNS(null, 'd', `M${this.margin.left},${this.margin.top + this.height / 2}L${this.margin.left + this.width},${this.margin.top + this.height / 2}L${this.margin.left + this.width},${this.margin.top + this.height / 2}L${this.margin.left},${this.margin.top + this.height / 2}Z`);
    clipUnder.appendChild(clipPathUnder);
    this.transformSVG.node().appendChild(clipUnder);
    const clipHead = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
    clipHead.setAttributeNS(null, 'id', 'clip-path-head');
    this.headClip = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    clipHead.appendChild(this.headClip);
    this.transformSVG.node().appendChild(clipHead);
    const clipBottom = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
    clipBottom.setAttributeNS(null, 'id', 'clip-path-bottom');
    this.bottomClip = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    clipBottom.appendChild(this.bottomClip);
    this.transformSVG.node().appendChild(clipBottom);
    this.transformed();
  }

  /** オプションパーツ設定用 */
  private setOption(path: string, x: number, y: number, width: number, height: number): void {
    this.transformSVG.append('image')
      .attr('xlink:href', path)
      .attr('x', '' + x)
      .attr('y', '' + y)
      .attr('width', '' + width)
      .attr('height', '' + height)
      .attr('preserveAspectRatio', 'none')
      .attr('opacity', '1');
  }

  /**
   * スラットの色生成と画像描画によりブラインド作成
   */
  public async setSlatImg(colorCodeList?: string | string[]): Promise<void> {
    await new Promise((resolve) => {
      /** スラット画像読み込み（2枚）判定用に cnt を宣言 */
      let cnt = 0;
      const isCenter = CanvasConfService.ins.blindData.blindType.indexOf('センターレース') !== -1;
      /** スラットの読み込み */
      DrawManager.getSlatElementFromUrl(
        this.bottomVertical,
        isCenter ? CanvasConfService.ins.centerRace.color.replace('0x', '#') : '#F5F5F5',
        '#C4C4C4',
      ).then((bottomSvg) => {
        const bottomSvgData = new XMLSerializer().serializeToString(bottomSvg);
        const bottomData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(bottomSvgData)));
        DrawManager.getFileFromUrl(bottomData)
          .then((bottomRes: string) => {
            cnt++;
            this.raceBottom = bottomRes;
            if (cnt === 2) {
              resolve();
            }
          });
      });
      DrawManager.getSlatElementFromUrl(
        this.slatVertical,
        isCenter ? CanvasConfService.ins.centerRace.color.replace('0x', '#') : '#F5F5F5',
        '#C4C4C4',
      ).then((slat) => {
        const raceSlat = new XMLSerializer().serializeToString(slat);
        const slatData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(raceSlat)));
        DrawManager.getFileFromUrl(slatData)
          .then((res: string) => {
            cnt++;
            this.raceSlat = res;
            if (cnt === 2) {
              resolve();
            }
          });
      });
    });
    /** 名称になぜか改行が入っているので削除 */
    const name = CanvasConfService.ins.blindData.blindName.replace('\n', '');
    /** スラット本数の計算式（一部）が文字列で入っている */
    const calc: string = CanvasConfService.ins.detail_blind.calc;
    /** スラットの本数を計算 */
    let len = this.calcAfter(CanvasConfService.ins.blindData.blindName === '調光ロールスクリーン' ?
      CanvasConfService.ins.blindData.blindType.indexOf('ハナリ') !== -1 ?
        this.height / CanvasConfService.ins.blindData.windowSizeHeight * 8.8 : (CanvasConfService.ins.blindData.windowSizeHeight / 125) :
      calc.split(',').length === 2 ?
        Math.ceil(
          Math.ceil(eval(calc.split(',')[0]
            .replace('W', '' + CanvasConfService.ins.blindData.windowSizeWidth)
            .replace('H', '' + CanvasConfService.ins.blindData.windowSizeHeight))) +
          Math.ceil(eval(calc.split(',')[1]
            .replace('W', '' + CanvasConfService.ins.blindData.windowSizeWidth)
            .replace('H', '' + CanvasConfService.ins.blindData.windowSizeHeight)),
          ))
        : Math.ceil(eval(calc
            .replace('W', '' + CanvasConfService.ins.blindData.windowSizeWidth)
            .replace('H', '' + this.calcBefore('height')),
          // .replace('H', '' + CanvasConfService.ins.blindData.windowSizeHeight),
        )));
    /** 開閉タイプのブラインド判定用 */
    SlatModalService.ins.isOpenType = false;
    /** 各オプション */
    const {
      head_box,
      bottom_rail,
      wood_balance,
      middle,
    } = CanvasConfService.ins.isSetOption;
    let isVertical: boolean = false;
    /** 色味の調整 */
    const baseColor = name === 'たて型ブラインドトレンドコレクション' ? '#FFFFFF' : CanvasConfService.ins.responseDataColorInfo.color.replace('0x', '#');
    const textureColor = name === 'たて型ブラインドトレンドコレクション' ? '#CCCCCC' : CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#');
    const img = new Image();
    /** 描画処理を配列に格納して最後に配列内の処理を実行 */
    this.displayObjArr.splice(0, this.displayObjArr.length);
    /** 初期表示状態 */
    if (!DrawManager.blindSelected) {
      this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
      this.displayBlind();
      return;
    }
    this.drawLine('0');
    /** ブラインド生成処理 */
    await ((): Promise<void> => {
      return new Promise((resolve: () => void) => {
        if (!CanvasConfService.ins.blindData.isStandardBlind && name === 'ミックスルーバー' || name === 'カラーアラカルト') {
          const arrLen = SlatModalService.ins.slatSvgList.length;
          const dummyList: Colors[] = [];
          if (name === 'ミックスルーバー') {
            SlatModalService.ins.isVertical = true;
            SlatModalService.ins.isOpenType = CanvasConfService.ins.blindData.blindType.indexOf('センターレース') !== -1;
            this.displayObjArr.push(() => {
              const slatWidth: number = this.width / len;
              if (SlatModalService.ins.isOpenType) {
                const raceOrigin = CanvasConfService.ins.centerRaceColorArr;
                if (raceOrigin.length === 0) {
                  let race = CanvasConfService.ins.centerRace || {
                    id: 467,
                    option_type: 'patAC',
                    color_code: 'PA356',
                    color_name: 'ホワイト',
                    color: '0xF7F6F2',
                    image: '',
                    image_file: '',
                    series_name: 'センターレース',
                  };
                  const centerRace = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'センターレース');
                  if (centerRace) {
                    const {
                      id,
                      option_type,
                      color_code,
                      color_name,
                      color,
                      image_file,
                      image,
                      series_name,
                    } = centerRace;
                    race = {
                      id: id,
                      option_type: option_type,
                      color_code: color_code,
                      color_name: color_name,
                      color: color,
                      image: image,
                      image_file: image_file,
                      series_name: series_name,
                    };
                  } else {
                    const centerRaceArr = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'ミラーセンターレース');
                    if (centerRaceArr) {
                      const {
                        id,
                        option_type,
                        color_code,
                        color_name,
                        color,
                        image_file,
                        image,
                        series_name,
                      } = centerRaceArr;
                      race = {
                        id: id,
                        option_type: option_type,
                        color_code: color_code,
                        color_name: color_name,
                        color: color,
                        image: image,
                        image_file: image_file,
                        series_name: series_name,
                      };
                    } else {
                      const centerRaceArr = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'ミラーレース遮熱');
                      if (centerRaceArr) {
                        const {
                          id,
                          option_type,
                          color_code,
                          color_name,
                          color,
                          image_file,
                          image,
                          series_name,
                        } = centerRaceArr;
                        race = {
                          id: id,
                          option_type: option_type,
                          color_code: color_code,
                          color_name: color_name,
                          color: color,
                          image: image,
                          image_file: image_file,
                          series_name: series_name,
                        };
                      }
                    }
                  }
                  for (let i: number = 0; i < len - 1; i++) {
                    raceOrigin.push(race);
                  }
                } else if (raceOrigin.length < len - 1) {
                  const currentLen = raceOrigin.length;
                  for (let i: number = currentLen; i < len - 1; i++) {
                    const race = { ...raceOrigin[raceOrigin.length - 1] };
                    raceOrigin.push(race);
                  }
                } else if (raceOrigin.length > len - 1) {
                  raceOrigin.splice(len - 2, raceOrigin.length - (len - 2));
                }
              }
              if (SlatModalService.ins.isOpenType) {
                // レース部分の座標調整
                const margin = 60 / CanvasConfService.ins.centerRaceColorArr.length;
                for (let i: number = 0; i < CanvasConfService.ins.centerRaceColorArr.length; i++) {
                  const back = CreateSlatManager.createSlat(slatWidth, this.height - 1, CanvasConfService.ins.centerRaceColorArr[i].color.replace('0x', '#'), SlatModalService.ins.isOpen ? 0.4 : 1);
                  back.setAttributeNS(null, 'x', '' + (this.margin.left + 2 + margin + (slatWidth * i)));
                  back.setAttributeNS(null, 'y', '' + this.margin.top);
                  this.transformSVG.node().append(back);
                }
              }
              for (let i: number = 0; i < len; i++) {
                if (colorCodeList) {
                  const colorCodeName: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[i] ? colorCodeList[i] : colorCodeList[0];
                  const colorName: string = SlatModalService.ins.slatColor.length <= i ? CanvasConfService.ins.responseDataColorInfo.color_name : SlatModalService.ins.slatColor[i].name;
                  const colorCode: string = SlatModalService.ins.slatColor.length <= i ? CanvasConfService.ins.responseDataColorInfo.color_code : SlatModalService.ins.slatColor[i].color_code;
                  // SlatModalService.ins.slatColor.push({
                  //   name: colorName,
                  //   color: colorCode,
                  // });
                  if (!colorCodeList[i]) {
                    dummyList.push({
                      name: colorName,
                      color: typeof colorCodeName === 'string' ? colorCodeName : colorCodeName ? (colorCodeName as any).color : dummyList[i - 1].color,
                      color_code: typeof colorCodeName === 'string' ? colorCode : colorCodeName ? (colorCodeName as any).color_code : dummyList[i - 1].color_code,
                    });
                  } else {
                    dummyList.push({
                      name: colorName,
                      color: typeof colorCodeName === 'string' ? colorCodeName : colorCodeName ? (colorCodeName as any).color : dummyList[0].color,
                      color_code: typeof colorCodeName === 'string' ? colorCode : colorCodeName ? (colorCodeName as any).color_code : dummyList[0].color_code,
                    });
                  }
                }
                if (!SlatModalService.ins.slatSvgList[i]) {
                  SlatModalService.ins.slatSvgList[i] = SlatModalService.ins.slatSvgList[i - 1];
                  SlatModalService.ins.bottomSvgList.push(SlatModalService.ins.bottomSvgList[i - 1]);
                  if (!SlatModalService.ins.slatSvgList[i]) {
                    SlatModalService.ins.slatSvgList[i] = SlatModalService.ins.slatSvgList[0];
                    SlatModalService.ins.bottomSvgList.push(SlatModalService.ins.bottomSvgList[0]);
                  }
                }
                if (SlatModalService.ins.slatSvgList.length < i) {
                  SlatModalService.ins.slatSvgList.push(SlatModalService.ins.slatSvgList[0]);
                  SlatModalService.ins.bottomSvgList.push(SlatModalService.ins.bottomSvgList[0]);
                }
                if (SlatModalService.ins.isOpen) {
                  if (i === 0) {
                    // const back = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.centerRace.color.replace('0x', '#'), 0.4);
                    // back.setAttributeNS(null, 'x', '' + (this.margin.left + 2));
                    // back.setAttributeNS(null, 'y', '' + this.margin.top);
                    // this.transformSVG.node().append(back);
                  }
                  if (i !== len - 1) {
                    // this.transformSVG.append('image')
                    //   .attr('xlink:href', this.raceBottom)
                    //   .attr('x', '' + (this.margin.left + (slatWidth * i)))
                    //   .attr('y', '' + (this.margin.top + this.height - 14))
                    //   .attr('width', '' + (slatWidth * 1.1))
                    //   .attr('height', '' + 15)
                    //   .attr('preserveAspectRatio', 'none')
                    //   .attr('opacity', 0.4);
                    // this.transformSVG.append('image')
                    //   .attr('xlink:href', this.raceSlat)
                    //   .attr('x', '' + (this.margin.left + (slatWidth * i)))
                    //   .attr('y', '' + (this.margin.top ))
                    //   .attr('width', '' + (slatWidth *  1.1 ))
                    //   .attr('height', '' + (this.height / len  - 12))
                    //   .attr('preserveAspectRatio', 'none')
                    //   .attr('opacity', 0.4);
                  }
                  const openG = this.transformSVG
                    .append('g')
                    .attr('transform', `translate(${(this.margin.left + ((slatWidth * i)) + (slatWidth * 0.1) + slatWidth * 0.1)}, ${(this.margin.top)})`);
                  openG
                    .append('image')
                    .attr('xlink:href', SlatModalService.ins.slatSvgList[i])
                    .attr('width', '' + (slatWidth * 0.4))
                    .attr('height', '' + (this.height - (12) + 11))
                    .attr('preserveAspectRatio', 'none')
                    .attr('opacity', 1)
                    .attr('transform', 'skewY(20)');
                  openG.append('image')
                    .attr('xlink:href', SlatModalService.ins.bottomSvgList[i])
                    .attr('y', '' + (this.height - 14))
                    .attr('width', '' + (slatWidth * 0.4))
                    .attr('height', '' + 15)
                    .attr('preserveAspectRatio', 'none')
                    .attr('opacity', 0.3)
                    .attr('transform', 'skewY(20)');
                } else if (SlatModalService.ins.isOpenType) {
                  // TODO
                  if (i === 0) {
                    // const back = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.centerRace.color.replace('0x', '#'), 1);
                    // back.setAttributeNS(null, 'x', '' + (this.margin.left + 2));
                    // back.setAttributeNS(null, 'y', '' + this.margin.top);
                    // this.transformSVG.node().append(back);
                  }
                  const openG = this.transformSVG
                    .append('g')
                    .attr('transform', `translate(${(this.margin.left + ((slatWidth * i)) + (slatWidth * 0.1))}, ${(this.margin.top)})`);
                  openG
                    .append('image')
                    .attr('xlink:href', SlatModalService.ins.slatSvgList[i])
                    .attr('width', '' + (slatWidth * 0.9))
                    .attr('height', '' + (this.height - (12) + 11))
                    .attr('preserveAspectRatio', 'none')
                    .attr('opacity', 1)
                    .attr('transform', 'skewY(10)');
                  openG.append('image')
                    .attr('xlink:href', SlatModalService.ins.bottomSvgList[i])
                    .attr('y', '' + (this.height - 14))
                    .attr('width', '' + (slatWidth * 0.9))
                    .attr('height', '' + 15)
                    .attr('preserveAspectRatio', 'none')
                    .attr('opacity', 0.3)
                    .attr('transform', 'skewY(10)');
                } else {
                  this.transformSVG.append('image')
                    .attr('xlink:href', SlatModalService.ins.bottomSvgList[i])
                    .attr('x', '' + (this.margin.left + (slatWidth * i)))
                    .attr('y', '' + (this.margin.top + this.height - 14))
                    .attr('width', '' + (slatWidth * 1.1))
                    .attr('height', '' + 15)
                    .attr('preserveAspectRatio', 'none')
                    .attr('opacity', CanvasConfService.ins.LouverAlpha());
                  this.transformSVG.append('image')
                    .attr('xlink:href', SlatModalService.ins.slatSvgList[i])
                    .attr('x', '' + (this.margin.left + (slatWidth * i)))
                    .attr('y', '' + (this.margin.top))
                    .attr('width', '' + (slatWidth * 1.1))
                    .attr('height', '' + (this.height - 12))
                    .attr('preserveAspectRatio', 'none')
                    .attr('opacity', CanvasConfService.ins.LouverAlpha());
                }
              }
            });
          } else {
            SlatModalService.ins.isVertical = false;
            this.displayObjArr.push(() => {
              const slatHeight: number = this.height / len;
              for (let i: number = 0; i < len; i++) {
                if (colorCodeList) {
                  const colorCodeName: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[i] ? colorCodeList[i] : colorCodeList[0];
                  const colorName: string = SlatModalService.ins.slatColor.length <= i ? CanvasConfService.ins.responseDataColorInfo.color_name : SlatModalService.ins.slatColor[i].name;
                  const colorCode: string = SlatModalService.ins.slatColor.length <= i ? CanvasConfService.ins.responseDataColorInfo.color_code : SlatModalService.ins.slatColor[i].color_code;
                  // SlatModalService.ins.slatColor.push({
                  //   name: colorName,
                  //   color: colorCode,
                  // });
                  if (!colorCodeList[i]) {
                    dummyList.push({
                      name: colorName,
                      color: typeof colorCodeName === 'string' ? colorCodeName : colorCodeName ? (colorCodeName as any).color : dummyList[i - 1].color,
                      color_code: typeof colorCodeName === 'string' ? colorCode : colorCodeName ? (colorCodeName as any).color_code : dummyList[i - 1].color_code,
                    });
                  } else {
                    dummyList.push({
                      name: colorName,
                      color: typeof colorCodeName === 'string' ? colorCodeName : colorCodeName ? (colorCodeName as any).color : dummyList[0].color,
                      color_code: typeof colorCodeName === 'string' ? colorCode : colorCodeName ? (colorCodeName as any).color_code : dummyList[0].color_code,
                    });
                  }
                  if (!SlatModalService.ins.slatSvgList[i]) {
                    SlatModalService.ins.slatSvgList[i] = SlatModalService.ins.slatSvgList[i - 1];
                    if (!SlatModalService.ins.slatSvgList[i]) {
                      SlatModalService.ins.slatSvgList[i] = SlatModalService.ins.slatSvgList[0];
                    }
                  }
                  // const rect = CreateSlatManager.createSlat(this.width - 1, (slatHeight * 1.2), typeof colorCodeName === 'string' ? colorCodeName : colorCodeName ? (colorCodeName as any).color : dummyList[0].color, 1);
                  const rect = CreateSlatManager.createSlat(this.width - 1, (slatHeight * 1.25), dummyList[i].color, 1);
                  rect.setAttributeNS(null, 'x', '' + this.margin.left);
                  rect.setAttributeNS(null, 'y', '' + (this.margin.top + (slatHeight * i)));
                  this.transformSVG.node().append(rect);
                  const dummyRect = CreateSlatManager.createSlat(this.width - 1, (slatHeight * 1.25), dummyList[i].color, 1);
                  dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                  dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + (slatHeight * i)));
                  this.transformSVG.node().append(rect);
                }
                this.transformSVG.append('image')
                  // .attr('xlink:href', SlatModalService.ins.slatSvgList[arrLen < len ? i : 0])
                  .attr('xlink:href', SlatModalService.ins.slatSvgList[i])
                  .attr('x', '' + this.margin.left)
                  .attr('y', '' + (this.margin.top + (slatHeight * i)))
                  .attr('width', '' + this.width)
                  .attr('height', '' + (slatHeight * 1.2))
                  .attr('preserveAspectRatio', 'none')
                  .attr('opacity', '1');
                if (SlatModalService.ins.slatSvgList.length < i) {
                  SlatModalService.ins.slatSvgList.push(SlatModalService.ins.slatSvgList[0]);
                }
              }
              if (SlatModalService.ins.slatSvgList.length > SlatModalService.ins.slatColor.length) {
                SlatModalService.ins.slatSvgList.splice(SlatModalService.ins.slatColor.length, SlatModalService.ins.slatSvgList.length - SlatModalService.ins.slatColor.length);
              }
            });
          }
          SlatModalService.ins.slatColor = dummyList;
          resolve();
        } else if (CanvasConfService.ins.slatImg) {
          /**
           * 画像からスラットを生成する場合、スラットの色はここで宣言
           */
          // const baseColor = name === 'たて型ブラインドトレンドコレクション' ? '#FFFFFF' : CanvasConfService.ins.responseDataColorInfo.color.replace('0x', '#');
          // const textureColor = name === 'たて型ブラインドトレンドコレクション' ? '#CCCCCC' : CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#');
          DrawManager.getSlatElementFromUrl(
            CanvasConfService.ins.slatImg.indexOf('va') === -1 ? this.slatSide : this.slatVertical,
            baseColor,
            textureColor,
          )
            .then((svgRes) => {
              const svgData = new XMLSerializer().serializeToString(svgRes);
              const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
              img.src = CanvasConfService.ins.slatImg.indexOf('va') === -1 ? this.slatSide : this.slatVertical;
              img.onerror = (e) => console.log('err: ', e);
              img.onload = () => {
                const width: number = img.naturalWidth;
                const height: number = img.naturalHeight;
                // const isVertical: boolean = height > width;
                isVertical = (height > width) || name.indexOf('たて') > -1;
                const slatWidth: number = isVertical ? (this.width / len) : (this.width);
                const slatHeight: number = isVertical ? (this.height) : (this.height / len);
                SlatModalService.ins.isVertical = isVertical;
                DrawManager.getFileFromUrl(data)
                  .then((res: string) => {
                      /**
                       * 縦型ブラインドトレンドコレクションのみ別途宣言
                       */
                      // if (name === 'たて型ブラインドトレンドコレクション') {
                      if (name === 'たて型ブラインドトレンドコレクション') {
                        this.displayObjArr.push(() => {
                          const color_name = CanvasConfService.ins.responseDataColorInfo.color_name;
                          if (color_name === 'ブルーモーメント') {
                            for (let i: number = 0; i < len; i++) {
                              // .attr('mask', 'url(#blind_mask)');
                              this.transformSVG.append('image')
                                .attr('xlink:href', this.B_TA271)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - 13))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '15')
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', this.TA271)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight)))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '' + (this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight) - 12))
                                .attr('preserveAspectRatio', 'none')
                                .attr('clip-path', 'url(#clip-path)')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', res)
                                .attr('x', '' + (this.margin.left + (slatWidth * i) - 5))
                                .attr('y', '' + (this.margin.top))
                                .attr('width', '' + (slatWidth * 1.2 + 2))
                                .attr('height', '' + (slatHeight - 10))
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', '0.2');
                            }
                            // this.imageMaskRect.appendChild(imageMask);
                            // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#image-mask-rect)');
                            // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#blind_mask)');
                            // this.transformSVG.node().append(maskPath);
                            // resolve();
                          } else if (color_name === 'グラビエ') {
                            for (let i: number = 0; i < len; i++) {
                              const imgName = ((i >= len - 2) ? this.TA272_2 : ((i >= len - 4) ? this.TA272_1 : ((i >= len - 6) ? this.TA272_0 : this.TA272_3)));
                              const imgNameBottom = ((i >= len - 2) ? this.B_TA272_2 : ((i >= len - 4) ? this.B_TA272_1 : ((i >= len - 6) ? this.B_TA272_0 : this.B_TA272_3)));
                              this.transformSVG.append('image')
                                .attr('xlink:href', imgNameBottom)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - 13))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '15')
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', imgName)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight)))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '' + (this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight) - 12))
                                .attr('preserveAspectRatio', 'none')
                                .attr('clip-path', 'url(#clip-path)')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', res)
                                .attr('x', '' + (this.margin.left + (slatWidth * i) - 5))
                                .attr('y', '' + (this.margin.top))
                                .attr('width', '' + (slatWidth * 1.2 + 2))
                                .attr('height', '' + (slatHeight - 10))
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', '0.2');
                            }
                            // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#blind_mask)');
                            // resolve();
                          } else if (color_name === 'エクラ') {
                            for (let i: number = 0; i < len; i++) {
                              const imgName = (i % 3 === 0 ? this.TA273_0 : (i % 3 === 1 ? this.TA273_1 : this.TA273_2));
                              const imgNameBottom = (i % 3 === 0 ? this.B_TA273_0 : (i % 3 === 1 ? this.B_TA273_1 : this.B_TA273_2));
                              this.transformSVG.append('image')
                                .attr('xlink:href', imgNameBottom)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - 13))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '15')
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', imgName)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight)))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '' + (this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight) - 12))
                                .attr('preserveAspectRatio', 'none')
                                .attr('clip-path', 'url(#clip-path)')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', res)
                                .attr('x', '' + (this.margin.left + (slatWidth * i) - 5))
                                .attr('y', '' + (this.margin.top))
                                .attr('width', '' + (slatWidth * 1.2 + 2))
                                .attr('height', '' + (slatHeight - 10))
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', '0.2');
                            }
                            // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#blind_mask');
                            // resolve();
                          } else if (color_name === 'アンティークウッド(V)') {
                            for (let i: number = 0; i < len; i++) {
                              const imgName = (i % 6 === 0 ? this.TA274_0 : (i % 6 === 1 ? this.TA274_1 : (i % 6 === 2 ? this.TA274_2 : (i % 6 === 3 ? this.TA274_3 : (i % 6 === 4 ? this.TA274_4 : this.TA274_5)))));
                              const imgNameBottom = (i % 6 === 0 ? this.B_TA274_0 : (i % 6 === 1 ? this.B_TA274_1 : (i % 6 === 2 ? this.B_TA274_2 : (i % 6 === 3 ? this.B_TA274_3 : (i % 6 === 4 ? this.B_TA274_4 : this.B_TA274_5)))));
                              this.transformSVG.append('image')
                                .attr('xlink:href', imgNameBottom)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - 13))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '15')
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', imgName)
                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                .attr('y', '' + (this.margin.top + this.height - this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight)))
                                .attr('width', '' + (slatWidth + 1))
                                .attr('height', '' + (this.height * (5000 / CanvasConfService.ins.blindData.windowSizeHeight) - 12))
                                .attr('preserveAspectRatio', 'none')
                                .attr('clip-path', 'url(#clip-path)')
                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                              this.transformSVG.append('image')
                                .attr('xlink:href', res)
                                .attr('x', '' + (this.margin.left + (slatWidth * i) - 5))
                                .attr('y', '' + (this.margin.top))
                                .attr('width', '' + (slatWidth * 1.2 + 2))
                                .attr('height', '' + (slatHeight - 10))
                                .attr('preserveAspectRatio', 'none')
                                .attr('opacity', '0.2');
                            }
                            // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#blind_mask');
                            // resolve();
                          }
                        });
                        resolve();
                      } else {
                        DrawManager.getSlatElementFromUrl(
                          this.bottomVertical,
                          // CanvasConfService.ins.responseDataColorInfo.color.replace('0x', '#'),
                          // CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'),
                          baseColor,
                          textureColor,
                        ).then((bottomSvg) => {
                          const bottomSvgData = new XMLSerializer().serializeToString(bottomSvg);
                          const bottomData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(bottomSvgData)));
                          DrawManager.getFileFromUrl(bottomData)
                            .then((bottomRes: string) => {
                                // if (CanvasConfService.ins.responseDataColorInfo.color_code === 'V310') {
                                //   colorCodeList = '#D6D4CC';
                                //   CanvasConfService.ins.responseDataColorInfo.texture_color = '#ABA9A3';
                                // }
                                if (!isVertical && name !== 'よこ型ブラインドデザインタイプ') {
                                  this.displayObjArr.push(() => {
                                    const back = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.responseDataColorInfo.texture_color, CanvasConfService.ins.LouverAlpha());
                                    back.setAttributeNS(null, 'x', '' + this.margin.left);
                                    back.setAttributeNS(null, 'y', '' + this.margin.top);
                                    this.transformSVG.node().append(back);
                                  });
                                } else {
                                  if (CanvasConfService.ins.blindData.blindType.indexOf('センターレース') === -1) {
                                    this.displayObjArr.push(() => {
                                      const back = CreateSlatManager.createSlat(this.width, this.height, '#FFFFFF', CanvasConfService.ins.LouverAlpha());
                                      back.setAttributeNS(null, 'x', '' + this.margin.left);
                                      back.setAttributeNS(null, 'y', '' + this.margin.top);
                                      this.transformSVG.node().append(back);
                                    });
                                  }
                                }
                                if (CanvasConfService.ins.blindData.displayImgPath) {
                                  const image = new Image();
                                  image.onload = () => {
                                    this.displayObjArr.push(() => {
                                      const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                                      const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / image.height);
                                      const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                                      const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                                      if (name !== 'よこ型ブラインドデザインタイプ') {
                                        for (let i: number = 0; i <= sideNum; i++) {
                                          for (let j: number = 0; j <= verticalNum; j++) {
                                            this.transformSVG.append('image')
                                              .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                                              .attr('x', `${this.margin.left + (sizeWid * i)}`)
                                              .attr('y', `${this.margin.top + (sizeHei * j)}`)
                                              // .attr('width', `${this.width / (sideNum || 1)}`)
                                              // .attr('height', `${this.height / (verticalNum || 1)}`)
                                              .attr('width', `${sizeWid}`)
                                              .attr('height', `${sizeHei}`)
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('clip-path', 'url(#clip-path)')
                                              .attr('opacity', '1');
                                          }
                                        }
                                      }
                                      for (let i: number = 0; i < len; i++) {
                                        if (colorCodeList) {
                                          // const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[i];
                                          // // SlatModalService.ins.slatColor.push(colorCode);
                                          SlatModalService.ins.slatSvgList.push(res);
                                        }
                                        const dummyAlpha = this.calcWideNum() ? '1' : CanvasConfService.ins.LouverAlpha();
                                        // const dummyAlpha = this.calcWideNum() && CanvasConfService.ins.isSetOption.wide_ladder_tape ? '1' : CanvasConfService.ins.LouverAlpha();
                                        // 開閉機能を考慮
                                        if (isVertical) {
                                          if (CanvasConfService.ins.blindData.blindType.indexOf('センターレース') !== -1) {
                                            SlatModalService.ins.isOpenType = true;
                                            if (SlatModalService.ins.isOpen) {
                                              if (i !== len - 1) {
                                                this.transformSVG.append('image')
                                                  .attr('xlink:href', this.raceBottom)
                                                  .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                                  .attr('y', '' + (this.margin.top + this.height - 14))
                                                  .attr('width', '' + (slatWidth * 1.1))
                                                  .attr('height', '' + 15)
                                                  .attr('preserveAspectRatio', 'none')
                                                  .attr('opacity', 0.4);
                                                this.transformSVG.append('image')
                                                  .attr('xlink:href', this.raceSlat)
                                                  .attr('x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)))
                                                  .attr('y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))))
                                                  .attr('width', '' + (slatWidth * (isVertical ? 1.1 : 1)))
                                                  .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                                  .attr('preserveAspectRatio', 'none')
                                                  .attr('opacity', 0.4);
                                              }
                                              const openG = this.transformSVG
                                                .append('g')
                                                .attr('transform', `translate(${(this.margin.left + (isVertical ? (slatWidth * i) : 0) + (slatWidth * 0.1) + slatWidth * 0.1)}, ${(this.margin.top + (isVertical ? 0 : (slatHeight * i)))})`);
                                              openG
                                                .append('image')
                                                .attr('xlink:href', res)
                                                .attr('width', '' + (slatWidth * 0.4))
                                                .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0) + 11))
                                                .attr('preserveAspectRatio', 'none')
                                                .attr('opacity', dummyAlpha)
                                                .attr('transform', 'skewY(20)');
                                              openG.append('image')
                                                .attr('xlink:href', bottomRes)
                                                .attr('y', '' + (this.height - 14))
                                                .attr('width', '' + (slatWidth * 0.4))
                                                .attr('height', '' + 15)
                                                .attr('preserveAspectRatio', 'none')
                                                .attr('opacity', 0.3)
                                                .attr('transform', 'skewY(20)');
                                            } else {
                                              this.transformSVG.append('image')
                                                .attr('xlink:href', bottomRes)
                                                .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                                .attr('y', '' + (this.margin.top + this.height - 14))
                                                .attr('width', '' + (slatWidth * 1.1))
                                                .attr('height', '' + 15)
                                                .attr('preserveAspectRatio', 'none')
                                                .attr('opacity', CanvasConfService.ins.LouverAlpha());
                                              this.transformSVG.append('image')
                                                .attr('xlink:href', res)
                                                .attr('x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)))
                                                .attr('y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))))
                                                .attr('width', '' + (slatWidth * (isVertical ? 1.1 : 1)))
                                                .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                                .attr('preserveAspectRatio', 'none')
                                                .attr('opacity', dummyAlpha);
                                            }
                                          } else {
                                            this.transformSVG.append('image')
                                              .attr('xlink:href', bottomRes)
                                              .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                              .attr('y', '' + (this.margin.top + this.height - 14))
                                              .attr('width', '' + (slatWidth * 1.1))
                                              .attr('height', '' + 15)
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('opacity', CanvasConfService.ins.LouverAlpha());
                                            this.transformSVG.append('image')
                                              .attr('xlink:href', res)
                                              .attr('x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)))
                                              .attr('y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))))
                                              .attr('width', '' + (slatWidth * (isVertical ? 1.1 : 1)))
                                              .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('opacity', dummyAlpha);
                                          }
                                        } else {
                                          // this.transformSVG.append('image')
                                          //   .attr('xlink:href', bottomRes)
                                          //   .attr('x', '' + (this.margin.left))
                                          //   .attr('y', '' + (this.margin.top + (slatHeight * i)))
                                          //   .attr('width', '' + (slatWidth * 1.1))
                                          //   .attr('height', '' + 15)
                                          //   .attr('preserveAspectRatio', 'none')
                                          //   .attr('opacity', CanvasConfService.ins.LouverAlpha());
                                          if (name === 'よこ型ブラインドデザインタイプ') {
                                            // if (CanvasConfService.ins.responseDataColorInfo.color_code === 'V310') {
                                            this.transformSVG.append('image')
                                              .attr('xlink:href', CanvasConfService.ins.responseDataColorInfo.color_code === 'V310' ? (i % 6) < 3 ? res : this.V310 : res)
                                              .attr('x', '' + (this.margin.left - 30))
                                              .attr('y', '' + (this.margin.top + ((slatHeight * i))))
                                              .attr('width', '' + (this.width + 60))
                                              .attr('height', '' + (slatHeight * 1.1 + 1))
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('clip-path', 'url(#clip-path)')
                                              .attr('opacity', CanvasConfService.ins.responseDataColorInfo.color_code.indexOf('V') !== -1 ? '0.35' : '1');
                                          } else {
                                            this.transformSVG.append('image')
                                              .attr('xlink:href', res)
                                              .attr('x', '' + (this.margin.left - 30))
                                              .attr('y', '' + (this.margin.top + ((slatHeight * i))))
                                              .attr('width', '' + (this.width + 60))
                                              .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('clip-path', 'url(#clip-path)')
                                              .attr('opacity', CanvasConfService.ins.blindData.displayImgPath ? '0.35' : dummyAlpha);
                                          }
                                        }
                                      }
                                    });
                                  };
                                  image.src = CanvasConfService.ins.blindData.displayImgPath || '';
                                  // this.displayObjArr.push(() => {
                                  //   this.transformSVG.append('image')
                                  //     .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                                  //     .attr('x', '' + this.margin.left)
                                  //     .attr('y', '' + this.margin.top)
                                  //     .attr('width', '' + this.width)
                                  //     .attr('height', '' + this.height)
                                  //     .attr('preserveAspectRatio', 'none')
                                  //     .attr('opacity', '0.94');
                                  // });
                                } else {
                                  this.displayObjArr.push(() => {
                                    for (let i: number = 0; i < len; i++) {
                                      if (colorCodeList) {
                                        // const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[i];
                                        // // SlatModalService.ins.slatColor.push(colorCode);
                                        SlatModalService.ins.slatSvgList.push(res);
                                      }
                                      const dummyAlpha = this.calcWideNum() ? '1' : CanvasConfService.ins.LouverAlpha();
                                      // const dummyAlpha = this.calcWideNum() && CanvasConfService.ins.isSetOption.wide_ladder_tape ? '1' : CanvasConfService.ins.LouverAlpha();
                                      // 開閉機能を考慮
                                      if (isVertical) {
                                        // TODO 開閉
                                        const margin = 80 / len;
                                        /* センターレース ミニマルウエイトの時、センターレースに色が入っていないのでここで入れる */
                                        if (CanvasConfService.ins.blindData.blindType.indexOf('センターレーススタイル ミニマルウエイト') !== -1) {
                                          CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[0];
                                        }
                                        if (CanvasConfService.ins.blindData.blindType.indexOf('センターレース') !== -1) {
                                          SlatModalService.ins.isOpenType = true;
                                          if (SlatModalService.ins.isOpen) {
                                            if (i !== len - 1) {
                                              this.transformSVG.append('image')
                                                .attr('xlink:href', this.raceBottom)
                                                .attr('x', '' + (this.margin.left + margin + (slatWidth * i)))
                                                .attr('y', '' + (this.margin.top + this.height - 14))
                                                .attr('width', '' + (slatWidth * 1.1))
                                                .attr('height', '' + 15)
                                                .attr('preserveAspectRatio', 'none')
                                                .attr('opacity', 0.4);
                                              this.transformSVG.append('image')
                                                .attr('xlink:href', this.raceSlat)
                                                .attr('x', '' + (this.margin.left + margin + (isVertical ? (slatWidth * i) : 0)))
                                                .attr('y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))))
                                                .attr('width', '' + (slatWidth * (isVertical ? 1.1 : 1)))
                                                .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                                .attr('preserveAspectRatio', 'none')
                                                .attr('opacity', 0.4);
                                            }
                                            const openG = this.transformSVG
                                              .append('g')
                                              .attr('transform', `translate(${(this.margin.left + (isVertical ? (slatWidth * i) : 0) + (slatWidth * 0.1) + slatWidth * 0.1)}, ${(this.margin.top + (isVertical ? 0 : (slatHeight * i)))})`);
                                            openG
                                              .append('image')
                                              .attr('xlink:href', res)
                                              .attr('width', '' + (slatWidth * 0.4))
                                              .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0) + 11))
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('opacity', dummyAlpha)
                                              .attr('transform', 'skewY(20)');
                                            openG.append('image')
                                              .attr('xlink:href', bottomRes)
                                              .attr('y', '' + (this.height - 14))
                                              .attr('width', '' + (slatWidth * 0.4))
                                              .attr('height', '' + 15)
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('opacity', 0.3)
                                              .attr('transform', 'skewY(20)');
                                          } else {
                                            if (i === 0) {
                                              const margin = 80 / len;
                                              const back = CreateSlatManager.createSlat(this.width - slatWidth, this.height, CanvasConfService.ins.centerRace.color.replace('0x', '#'), 1);
                                              back.setAttributeNS(null, 'x', '' + (this.margin.left + 2 + margin));
                                              back.setAttributeNS(null, 'y', '' + this.margin.top);
                                              this.transformSVG.node().append(back);
                                            }
                                            const openG = this.transformSVG
                                              .append('g')
                                              .attr('transform', `translate(${(this.margin.left + (isVertical ? (slatWidth * i) : 0) + (slatWidth * 0.1) + slatWidth * 0.1)}, ${(this.margin.top + (isVertical ? 0 : (slatHeight * i)))})`);
                                            openG
                                              .append('image')
                                              .attr('xlink:href', res)
                                              .attr('width', '' + (slatWidth * 0.9))
                                              .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0) + 11))
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('opacity', dummyAlpha)
                                              .attr('transform', 'skewY(10)');
                                            openG.append('image')
                                              .attr('xlink:href', bottomRes)
                                              .attr('y', '' + (this.height - 14))
                                              .attr('width', '' + (slatWidth * 0.9))
                                              .attr('height', '' + 15)
                                              .attr('preserveAspectRatio', 'none')
                                              .attr('opacity', 0.3)
                                              .attr('transform', 'skewY(10)');
                                          }
                                        } else {
                                          this.transformSVG.append('image')
                                            .attr('xlink:href', bottomRes)
                                            .attr('x', '' + (this.margin.left + (slatWidth * i)))
                                            .attr('y', '' + (this.margin.top + this.height - 14))
                                            .attr('width', '' + (slatWidth * 1.1))
                                            .attr('height', '' + 15)
                                            .attr('preserveAspectRatio', 'none')
                                            .attr('opacity', CanvasConfService.ins.LouverAlpha());
                                          this.transformSVG.append('image')
                                            .attr('xlink:href', res)
                                            .attr('x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)))
                                            .attr('y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))))
                                            .attr('width', '' + (slatWidth * (isVertical ? 1.1 : 1)))
                                            .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                            .attr('preserveAspectRatio', 'none')
                                            .attr('opacity', dummyAlpha);
                                        }
                                      } else {
                                        // this.transformSVG.append('image')
                                        //   .attr('xlink:href', bottomRes)
                                        //   .attr('x', '' + (this.margin.left))
                                        //   .attr('y', '' + (this.margin.top + (slatHeight * i)))
                                        //   .attr('width', '' + (slatWidth * 1.1))
                                        //   .attr('height', '' + 15)
                                        //   .attr('preserveAspectRatio', 'none')
                                        //   .attr('opacity', CanvasConfService.ins.LouverAlpha());
                                        if (name === 'よこ型ブラインドデザインタイプ') {
                                          // if (CanvasConfService.ins.responseDataColorInfo.color_code === 'V310') {
                                          this.transformSVG.append('image')
                                            .attr('xlink:href', CanvasConfService.ins.responseDataColorInfo.color_code === 'V310' ? (i % 6) < 3 ? res : this.V310 : res)
                                            .attr('x', '' + (this.margin.left - 30))
                                            .attr('y', '' + (this.margin.top + ((slatHeight * i))))
                                            .attr('width', '' + (this.width + 60))
                                            .attr('height', '' + (slatHeight * 1.1 + 1))
                                            .attr('preserveAspectRatio', 'none')
                                            .attr('clip-path', 'url(#clip-path)')
                                            .attr('opacity', CanvasConfService.ins.responseDataColorInfo.color_code.indexOf('V') !== -1 ? '0.35' : '1');
                                        } else {
                                          this.transformSVG.append('image')
                                            .attr('xlink:href', res)
                                            .attr('x', '' + (this.margin.left - 30))
                                            .attr('y', '' + (this.margin.top + ((slatHeight * i))))
                                            .attr('width', '' + (this.width + 60))
                                            .attr('height', '' + (slatHeight * (isVertical ? 1 : 1.1) - (isVertical ? 12 : 0)))
                                            .attr('preserveAspectRatio', 'none')
                                            .attr('clip-path', 'url(#clip-path)')
                                            .attr('opacity', CanvasConfService.ins.blindData.displayImgPath ? '0.35' : dummyAlpha);
                                        }
                                      }
                                    }
                                  });
                                  resolve();
                                }
                                /**
                                 * よこ型ブラインドデザインタイプ
                                 */
                                if (CanvasConfService.ins.blindData.displayImgPath) {
                                  if (name === 'よこ型ブラインドデザインタイプ') {
                                    const headHei = ((32 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20)));
                                    const pattern = (CanvasConfService.ins.blindData.blindType.indexOf('セレーノオアシス25') > -1);
                                    const widRate = CanvasConfService.ins.blindData.windowSizeWidth / 5000;
                                    const heiRate = CanvasConfService.ins.blindData.windowSizeHeight / 5000;
                                    // const widScale = pattern ? 1.38 : (1.5 + (1.7 - 1.5) * widRate); // V301
                                    // const heiScale = pattern ? 1.4 : 1.3; //V301
                                    let widScale = pattern ? 1.7 : (1.5 + (1.7 - 1.5) * widRate);
                                    let heiScale = pattern ? 1.42 : 1.3;
                                    switch (+CanvasConfService.ins.responseDataColorInfo.image) {
                                      case 2:
                                        break;
                                      case 3:
                                        widScale = pattern ? 1.75 : 1.3;
                                        heiScale = pattern ? 1.42 : 1.3;
                                        break;
                                      case 4:
                                        widScale = pattern ? 1.62 : 1.3;
                                        heiScale = pattern ? 1.20 : 1.3;
                                        break;
                                      case 5:
                                        break;
                                      case 6:
                                        widScale = pattern ? 1.85 : (1.5 + (1.7 - 1.5) * widRate);
                                        heiScale = pattern ? 2.2 : 1.3;
                                        break;
                                    }
                                    const image = new Image();
                                    image.onload = () => {
                                      // const wid = (this.width / (CanvasConfService.ins.blindData.windowSizeWidth) * widScale);
                                      // const hei = ((this.height - (pattern ? headHei : 0)) / (CanvasConfService.ins.blindData.windowSizeHeight) * heiScale);
                                      const wid = image.width * 2 / CanvasConfService.ins.blindData.windowSizeWidth;
                                      const hei = image.height * 2 / CanvasConfService.ins.blindData.windowSizeHeight;
                                      let pos: Pos = new Pos(0, 0);
                                      switch (+CanvasConfService.ins.responseDataColorInfo.image) {
                                        case 2:
                                          pos.x = this.width - (this.width * wid);
                                          pos.y = (pattern ? headHei : 1);
                                          break;
                                        case 3:
                                          pos.y = this.height - (this.height * hei) - (slatHeight / 3);
                                          break;
                                        case 4:
                                          pos.x = this.width - (this.width * wid);
                                          pos.y = this.height - (this.height * hei);
                                          break;
                                        case 5:
                                          pos.x = (this.width / 2) - (this.width * wid / 2);
                                          pos.y = this.height - (this.height * hei);
                                          break;
                                        case 6:
                                          pos.x = (this.width / 2) - (this.width * wid / 2);
                                          pos.y = (this.height / 2) - (this.height * hei / 2);
                                          break;
                                      }
                                      this.displayObjArr.push(() => {
                                        this.transformSVG.append('image')
                                          .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                                          .attr('x', '' + (this.margin.left + pos.x))
                                          .attr('y', '' + (this.margin.top + pos.y))
                                          .attr('width', '' + (this.width * wid))
                                          .attr('height', '' + (this.height * hei))
                                          .attr('preserveAspectRatio', 'none')
                                          .attr('clip-path', 'url(#clip-path)')
                                          .attr('opacity', '0.6');
                                      });
                                    };
                                    image.src = CanvasConfService.ins.blindData.displayImgPath;
                                  } else {
                                    const wid = this.width;
                                    const hei = this.height;
                                    let pos: Pos = new Pos(0, 0);
                                    switch (+CanvasConfService.ins.responseDataColorInfo.image) {
                                      case 2:
                                        pos.x = this.width - (this.width * wid);
                                        break;
                                      case 3:
                                        pos.y = this.height - (this.height * hei);
                                        break;
                                      case 4:
                                        pos.x = this.width - (this.width * wid);
                                        pos.y = this.height - (this.height * hei);
                                        break;
                                      case 5:
                                        pos.x = (this.width / 2) - (this.width * wid / 2);
                                        pos.y = this.height - (this.height * hei);
                                        break;
                                      case 6:
                                        pos.x = (this.width / 2) - (this.width * wid / 2);
                                        pos.y = (this.height / 2) - (this.height * hei / 2);
                                        break;
                                    }
                                    this.displayObjArr.push(() => {
                                      this.transformSVG.append('image')
                                        .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                                        .attr('x', '' + (this.margin.left + pos.x))
                                        .attr('y', '' + (this.margin.top + pos.y))
                                        .attr('width', '' + (this.width * wid))
                                        .attr('height', '' + (this.height * hei))
                                        .attr('preserveAspectRatio', 'none')
                                        .attr('clip-path', 'url(#clip-path)')
                                        .attr('opacity', '0.6');
                                      // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#blind_mask)');
                                    });
                                  }
                                }
                                resolve();
                              },
                            );
                        })
                        ;
                      }
                    },
                  )
                  .catch((err) => console.log(err));
              };
            });
        } else {
          // TODO
          /**
           * プログラムでブラインドを生成するものは全てここに直接書いています
           */
          SlatModalService.ins.isVertical = false;
          // this.displayObjArr.push(() => {
          if (name === 'ロールスクリーン') {
            if (CanvasConfService.ins.blindData.displayImgPath) {
              this.displayObjArr.push(() => {
                isVertical = true;
                const image = new Image();
                let margin =
                  CanvasConfService.ins.blindData.color === 'N9147' ||
                  CanvasConfService.ins.blindData.color === 'N9148' ||
                  CanvasConfService.ins.blindData.color === 'N9149' ? 1.03 :
                    CanvasConfService.ins.blindData.color === 'N9224' ||
                    CanvasConfService.ins.blindData.color === 'N9225' ||
                    CanvasConfService.ins.blindData.color === 'N9226' ? 1.0026 : 1.01;
                image.onload = () => {
                  if (
                    CanvasConfService.ins.blindData.color === 'N9147' ||
                    CanvasConfService.ins.blindData.color === 'N9148' ||
                    CanvasConfService.ins.blindData.color === 'N9149'
                    // CanvasConfService.ins.blindData.color === 'N9224' ||
                    // CanvasConfService.ins.blindData.color === 'N9225' ||
                    // CanvasConfService.ins.blindData.color === 'N9226'
                  ) {
                    const back = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.blindData.blindColorCode, 0.2);
                    back.setAttributeNS(null, 'x', '' + this.margin.left);
                    back.setAttributeNS(null, 'y', '' + this.margin.top);
                    this.transformSVG.node().append(back);
                  }
                  const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                  const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / image.height);
                  const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                  const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                  for (let i: number = 0; i <= sideNum; i++) {
                    for (let j: number = 0; j <= verticalNum; j++) {
                      this.transformSVG.append('image')
                        .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                        .attr('x', `${this.margin.left + (sizeWid * i)}`)
                        .attr('y', `${this.margin.top + (sizeHei * j)}`)
                        // .attr('width', `${this.width / (sideNum || 1)}`)
                        // .attr('height', `${this.height / (verticalNum || 1)}`)
                        .attr('width', `${sizeWid * margin}`)
                        .attr('height', `${sizeHei * margin}`)
                        .attr('preserveAspectRatio', 'none')
                        .attr('clip-path', 'url(#clip-path)')
                        .attr('opacity', CanvasConfService.ins.blindData.color === 'N7272' ? '0.96' : CanvasConfService.ins.LouverAlpha());
                    }
                  }
                };
                image.src = CanvasConfService.ins.blindData.displayImgPath || '';
                // this.transformSVG.append('image')
                //   .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                //   .attr('x', '' + this.margin.left)
                //   .attr('y', '' + this.margin.top)
                //   .attr('width', '' + this.width)
                //   .attr('height', '' + this.height)
                //   .attr('preserveAspectRatio', 'none')
                //   .attr('clip-path', 'url(#clip-path)')
                //   .attr('opacity', '1');
              });
            } else {
              this.displayObjArr.push(() => {
                isVertical = true;
                const rect = CreateSlatManager.createSlat(
                  this.width,
                  this.height,
                  CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'),
                  CanvasConfService.ins.LouverAlpha(),
                );
                rect.setAttributeNS(null, 'x', '' + this.margin.left);
                rect.setAttributeNS(null, 'y', '' + this.margin.top);
                this.transformSVG.node().append(rect);
              });
            }
            // resolve();
          } else if (name === 'プリーツスクリーンシングルスタイル') {
            // this.displayObjArr.push(() => {
            if (CanvasConfService.ins.blindData.displayImgPath) {
              const image = new Image();
              image.onload = () => {
                this.displayObjArr.push(() => {
                  const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                  const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / image.height);
                  const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                  const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                  for (let i: number = 0; i <= sideNum; i++) {
                    for (let j: number = 0; j <= verticalNum; j++) {
                      this.transformSVG.append('image')
                        .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                        .attr('x', `${this.margin.left + (sizeWid * i)}`)
                        .attr('y', `${this.margin.top + (sizeHei * j)}`)
                        // .attr('width', `${this.width / (sideNum || 1)}`)
                        // .attr('height', `${this.height / (verticalNum || 1)}`)
                        .attr('width', `${sizeWid * 1.01}`)
                        .attr('height', `${sizeHei * 1.01}`)
                        .attr('preserveAspectRatio', 'none')
                        .attr('clip-path', 'url(#clip-path)')
                        // .attr('opacity', '0.94');
                        .attr('opacity', '1');
                    }
                  }
                  const width: number = img.naturalWidth;
                  const height: number = img.naturalHeight;
                  const isVertical: boolean = height > width;
                  const slatWidth: number = isVertical ? (this.width / len) : (this.width);
                  const slatHeight: number = isVertical ? (this.height) : (this.height / len);
                  const {
                    accent_bottom,
                    accent_side,
                  } = CanvasConfService.ins.responseDataColorInfo;
                  for (let i: number = 0; i < len; i++) {
                    if (CanvasConfService.ins.responseDataColorInfo.texture_color) {
                      const rect = CreateSlatManager.createSlat(slatWidth, slatHeight / 2, '#000000', 0.3);
                      rect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                      rect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                      this.transformSVG.node().append(rect);
                    }
                  }
                  for (let i: number = 0; i <= sideNum; i++) {
                    for (let j: number = 0; j <= verticalNum; j++) {
                      this.transformSVG.append('image')
                        .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                        .attr('x', `${this.margin.left + (sizeWid * i)}`)
                        .attr('y', `${this.margin.top + (sizeHei * j)}`)
                        // .attr('width', `${this.width / (sideNum || 1)}`)
                        // .attr('height', `${this.height / (verticalNum || 1)}`)
                        .attr('width', `${sizeWid}`)
                        .attr('height', `${sizeHei}`)
                        .attr('preserveAspectRatio', 'none')
                        .attr('clip-path', 'url(#clip-path)')
                        // .attr('opacity', '0.94');
                        .attr('opacity', '0.1');
                    }
                  }
                  if (Number(accent_bottom) === 1 && Number(accent_side) === 1 && CanvasConfService.ins.accentColor.acctentType !== 'none') {
                    const accentWidth = (300 / CanvasConfService.ins.blindData.windowSizeWidth) * slatWidth / 2;
                    if (CanvasConfService.ins.accentColor.acctentType === 'left' || CanvasConfService.ins.accentColor.acctentType === 'both') {
                      const accentBackRect = CreateSlatManager.createSlat(accentWidth, this.height, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 0.97);
                      accentBackRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      accentBackRect.setAttributeNS(null, 'y', '' + this.margin.top);
                      this.transformSVG.node().append(accentBackRect);
                      for (let i: number = 0; i < len; i++) {
                        const accentTextureRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 1);
                        accentTextureRect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                        accentTextureRect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                        this.transformSVG.node().append(accentTextureRect);
                        const accentRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.color.replace('0x', '#'), 0.3);
                        accentRect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                        accentRect.setAttributeNS(null, 'y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                        this.transformSVG.node().append(accentRect);
                      }
                    }
                    if (CanvasConfService.ins.accentColor.acctentType === 'right' || CanvasConfService.ins.accentColor.acctentType === 'both') {
                      const accentBackRect = CreateSlatManager.createSlat(accentWidth, this.height, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 0.97);
                      accentBackRect.setAttributeNS(null, 'x', '' + (this.margin.left + this.width - accentWidth));
                      accentBackRect.setAttributeNS(null, 'y', '' + this.margin.top);
                      this.transformSVG.node().append(accentBackRect);
                      for (let i: number = 0; i < len; i++) {
                        const accentTextureRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 1);
                        accentTextureRect.setAttributeNS(null, 'x', '' + (this.margin.left + this.width - accentWidth));
                        accentTextureRect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                        this.transformSVG.node().append(accentTextureRect);
                        const accentRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.color.replace('0x', '#'), 0.3);
                        accentRect.setAttributeNS(null, 'x', '' + (this.margin.left + this.width - accentWidth));
                        accentRect.setAttributeNS(null, 'y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                        this.transformSVG.node().append(accentRect);
                      }
                    }
                  }
                });
              };
              image.src = CanvasConfService.ins.blindData.displayImgPath;
            } else {
              this.displayObjArr.push(() => {
                // const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 0.97);
                if (colorCodeList) {
                  // const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[0] ? colorCodeList[0] : CanvasConfService.ins.responseDataColorInfo.texture_color;
                  const colorCode: string = CanvasConfService.ins.responseDataColorInfo.texture_color;
                  // const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 1);
                  const rect = CreateSlatManager.createSlat(this.width, this.height, colorCode.replace('0x', '#'), 1);
                  rect.setAttributeNS(null, 'x', '' + this.margin.left);
                  rect.setAttributeNS(null, 'y', '' + this.margin.top);
                  this.transformSVG.node().append(rect);
                }
                const width: number = img.naturalWidth;
                const height: number = img.naturalHeight;
                const isVertical: boolean = height > width;
                const slatWidth: number = isVertical ? (this.width / len) : (this.width);
                const slatHeight: number = isVertical ? (this.height) : (this.height / len);
                const {
                  accent_bottom,
                  accent_side,
                } = CanvasConfService.ins.responseDataColorInfo;
                for (let i: number = 0; i < len; i++) {
                  if (CanvasConfService.ins.responseDataColorInfo.texture_color) {
                    const rect = CreateSlatManager.createSlat(slatWidth, slatHeight / 2, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 0.6);
                    rect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                    rect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                    this.transformSVG.node().append(rect);
                  }
                  if (colorCodeList) {
                    const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[i] ? colorCodeList[i] : colorCodeList[0];
                    const rect = CreateSlatManager.createSlat(slatWidth, slatHeight / 2, colorCode, 0.3);
                    rect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                    rect.setAttributeNS(null, 'y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                    this.transformSVG.node().append(rect);
                    // SlatModalService.ins.slatColor.push(colorCode);
                  }
                }
                if (Number(accent_bottom) === 1 && Number(accent_side) === 1 && CanvasConfService.ins.accentColor.acctentType !== 'none') {
                  const accentWidth = (300 / CanvasConfService.ins.blindData.windowSizeWidth) * slatWidth / 2;
                  if (CanvasConfService.ins.accentColor.acctentType === 'left' || CanvasConfService.ins.accentColor.acctentType === 'both') {
                    const accentBackRect = CreateSlatManager.createSlat(accentWidth, this.height, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 0.97);
                    accentBackRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    accentBackRect.setAttributeNS(null, 'y', '' + this.margin.top);
                    this.transformSVG.node().append(accentBackRect);
                    for (let i: number = 0; i < len; i++) {
                      const accentTextureRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 1);
                      accentTextureRect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                      accentTextureRect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                      this.transformSVG.node().append(accentTextureRect);
                      const accentRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.color.replace('0x', '#'), 0.3);
                      accentRect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                      accentRect.setAttributeNS(null, 'y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                      this.transformSVG.node().append(accentRect);
                    }
                  }
                  if (CanvasConfService.ins.accentColor.acctentType === 'right' || CanvasConfService.ins.accentColor.acctentType === 'both') {
                    const accentBackRect = CreateSlatManager.createSlat(accentWidth, this.height, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 0.97);
                    accentBackRect.setAttributeNS(null, 'x', '' + (this.margin.left + this.width - accentWidth));
                    accentBackRect.setAttributeNS(null, 'y', '' + this.margin.top);
                    this.transformSVG.node().append(accentBackRect);
                    for (let i: number = 0; i < len; i++) {
                      const accentTextureRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.texture_color.replace('0x', '#'), 1);
                      accentTextureRect.setAttributeNS(null, 'x', '' + (this.margin.left + this.width - accentWidth));
                      accentTextureRect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                      this.transformSVG.node().append(accentTextureRect);
                      const accentRect = CreateSlatManager.createSlat(accentWidth, slatHeight / 2, CanvasConfService.ins.accentColor.color.color.replace('0x', '#'), 0.3);
                      accentRect.setAttributeNS(null, 'x', '' + (this.margin.left + this.width - accentWidth));
                      accentRect.setAttributeNS(null, 'y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                      this.transformSVG.node().append(accentRect);
                    }
                  }
                }
              });
            }
            // });
          } else if (name === 'プリーツスクリーンツインスタイル') {
            // this.displayObjArr.push(() => {
            if (CanvasConfService.ins.blindData.isStandardBlind) {
              if (CanvasConfService.ins.responseDataColorInfo.image_file) {
                const image = new Image();
                image.onload = () => {
                  this.displayObjArr.push(() => {
                    const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                    const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / 2 / image.height);
                    const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                    const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                    for (let i: number = 0; i <= sideNum; i++) {
                      for (let j: number = 0; j <= verticalNum; j++) {
                        this.transformSVG.append('image')
                          .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                          .attr('x', `${this.margin.left + (sizeWid * i)}`)
                          .attr('y', `${this.margin.top + (sizeHei * j)}`)
                          // .attr('width', `${this.width / (sideNum || 1)}`)
                          // .attr('height', `${this.height / (verticalNum || 1)}`)
                          .attr('width', `${sizeWid * 1.01}`)
                          .attr('height', `${sizeHei * 1.01}`)
                          .attr('preserveAspectRatio', 'none')
                          .attr('clip-path', 'url(#clip-path-top)')
                          .attr('opacity', '1');
                      }
                    }
                    if (!CanvasConfService.ins.responseDataColorInfo.image_file && colorCodeList) {
                      const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[0] ? colorCodeList[0] : CanvasConfService.ins.responseDataColorInfo.texture_color;
                      // const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 1);
                      const rect = CreateSlatManager.createSlat(this.width, this.height / 2, colorCode.replace('0x', '#'), 1);
                      rect.setAttributeNS(null, 'x', '' + this.margin.left);
                      rect.setAttributeNS(null, 'y', '' + this.margin.top);
                      this.transformSVG.node().append(rect);
                    }
                    if (!CanvasConfService.ins.underSlatData.image_file) {
                      const rect = CreateSlatManager.createSlat(this.width, this.height / 2, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 1);
                      rect.setAttributeNS(null, 'x', '' + this.margin.left);
                      rect.setAttributeNS(null, 'y', '' + this.margin.top + this.height / 2);
                      this.transformSVG.node().append(rect);
                    }
                    for (let i: number = 0; i < len; i++) {
                      if (CanvasConfService.ins.responseDataColorInfo.image_file && i < len / 2) {
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, '#000000', '0.3');
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                        continue;
                      }
                      if (CanvasConfService.ins.underSlatData.image_file && i > (len / 2 - 1)) {
                        continue;
                      }
                      const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                      if (i < Math.floor(len / 2)) {
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha());
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      } else {
                        if (i === Math.floor(len / 2) + 1 && len % 2 === 1) {
                          const dummyRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                          dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                          dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * (i - 1)) + this.height / len / 2));
                          this.transformSVG.node().append(dummyRect);
                        }
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      }
                    }

                    const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                    middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                    middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                    // middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - ((8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len)) / 2) + this.height / len / 2));
                    this.transformSVG.node().append(middleBar);
                  });
                };
                image.src = CanvasConfService.ins.blindData.displayImgPath || '';
                // this.transformSVG.append('image')
                //   .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                //   .attr('x', '' + this.margin.left)
                //   .attr('y', '' + this.margin.top)
                //   .attr('width', '' + this.width)
                //   .attr('height', '' + (this.height / 2))
                //   .attr('preserveAspectRatio', 'none')
                //   .attr('opacity', CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.responseDataColorInfo.alpha));
              } else if (CanvasConfService.ins.underSlatData.image_file) {
                const image = new Image();
                image.onload = () => {
                  this.displayObjArr.push(() => {
                    const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                    const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / 2 / image.height);
                    const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                    const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                    for (let i: number = 0; i <= sideNum; i++) {
                      for (let j: number = 0; j <= verticalNum; j++) {
                        this.transformSVG.append('image')
                          .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                          .attr('x', `${this.margin.left + (sizeWid * i)}`)
                          .attr('y', `${this.margin.top + (this.height / 2) + (sizeHei * j)}`)
                          // .attr('width', `${this.width / (sideNum || 1)}`)
                          // .attr('height', `${this.height / (verticalNum || 1)}`)
                          .attr('width', `${sizeWid * 1.01}`)
                          .attr('height', `${sizeHei * 1.01}`)
                          .attr('preserveAspectRatio', 'none')
                          .attr('clip-path', 'url(#clip-path)')
                          .attr('opacity', '1');
                      }
                    }
                    for (let i: number = 0; i < len; i++) {
                      if (CanvasConfService.ins.responseDataColorInfo.image_file && i < len / 2) {
                        continue;
                      }
                      if (CanvasConfService.ins.underSlatData.image_file && i > (len / 2 - 1)) {
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, '#000000', '0.3');
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                        continue;
                      }
                      const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                      const textureColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.texture_color : CanvasConfService.ins.underSlatData.texture_color).replace('0x', '#');
                      if (i < Math.floor(len / 2)) {
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha());
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      } else {
                        if (i === Math.floor(len / 2) + 1 && len % 2 === 1) {
                          const dummyRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                          dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                          dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * (i - 1)) + this.height / len / 2));
                          this.transformSVG.node().append(dummyRect);
                        }
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      }
                    }
                    const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                    middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                    middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                    // middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - ((8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len)) / 2) + this.height / len / 2));
                    this.transformSVG.node().append(middleBar);
                  });
                };
                image.src = CanvasConfService.ins.blindData.displayImgPath || '';
                // this.transformSVG.append('image')
                //   .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                //   .attr('x', '' + this.margin.left)
                //   .attr('y', '' + (this.margin.top + this.height / 2))
                //   .attr('width', '' + this.width)
                //   .attr('height', '' + (this.height / 2))
                //   .attr('preserveAspectRatio', 'none')
                //   .attr('opacity', CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
              } else {
                this.displayObjArr.push(() => {
                  for (let i: number = 0; i < len; i++) {
                    if (CanvasConfService.ins.responseDataColorInfo.image_file && i < len / 2) {
                      continue;
                    }
                    if (CanvasConfService.ins.underSlatData.image_file && i > (len / 2 - 1)) {
                      continue;
                    }
                    const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                    const textureColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.texture_color : CanvasConfService.ins.underSlatData.texture_color).replace('0x', '#');
                    if (i < Math.floor(len / 2)) {
                      const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha());
                      textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                      this.transformSVG.node().append(textureRect);
                      const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                      baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                      this.transformSVG.node().append(baseRect);
                    } else {
                      if (i === Math.floor(len / 2) + 1 && len % 2 === 1) {
                        const dummyRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * (i - 1)) + this.height / len / 2));
                        this.transformSVG.node().append(dummyRect);
                      }
                      const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                      textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                      this.transformSVG.node().append(textureRect);
                      const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                      baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                      this.transformSVG.node().append(baseRect);
                    }
                  }
                  const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                  middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                  middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                  // middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - ((8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len)) / 2) + this.height / len / 2));
                  this.transformSVG.node().append(middleBar);
                });
              }
            } else {
              if (CanvasConfService.ins.responseDataColorInfo.image_file && CanvasConfService.ins.underSlatData.image_file) {
                Promise.all([
                  new Promise((resolve: (image: HTMLImageElement) => void, reject: () => void) => {
                    const image = new Image();
                    image.onload = () => resolve(image);
                    image.onerror = () => reject();
                    image.src = CanvasConfService.ins.blindData.displayImgPath || '';
                  }),
                  new Promise((resolve: (image: HTMLImageElement) => void, reject: () => void) => {
                    const image = new Image();
                    image.onload = () => resolve(image);
                    image.onerror = () => reject();
                    image.src = CanvasConfService.ins.blindData.displayImgPathUnder || '';
                  }),
                ])
                  .then((res) => {
                    this.displayObjArr.push(() => {
                      const sideNumTop = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / res[0].width);
                      const verticalNumTop = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / 2 / res[0].height);
                      const sizeWidTop = this.width * res[0].width / CanvasConfService.ins.blindData.windowSizeWidth;
                      const sizeHeiTop = this.height * res[0].height / CanvasConfService.ins.blindData.windowSizeHeight;
                      for (let i: number = 0; i <= sideNumTop; i++) {
                        for (let j: number = 0; j <= verticalNumTop; j++) {
                          this.transformSVG.append('image')
                            .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                            .attr('x', `${this.margin.left + (sizeWidTop * i)}`)
                            .attr('y', `${this.margin.top + (sizeHeiTop * j)}`)
                            // .attr('width', `${this.width / (sideNum || 1)}`)
                            // .attr('height', `${this.height / (verticalNum || 1)}`)
                            .attr('width', `${sizeWidTop * 1.01}`)
                            .attr('height', `${sizeHeiTop * 1.01}`)
                            .attr('preserveAspectRatio', 'none')
                            .attr('clip-path', 'url(#clip-path-top)')
                            .attr('opacity', '1');
                        }
                      }
                      const sideNumUnder = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / res[1].width);
                      const verticalNumUnder = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / 2 / res[1].height);
                      const sizeWidUnder = this.width * res[1].width / CanvasConfService.ins.blindData.windowSizeWidth;
                      const sizeHeiUnder = this.height * res[1].height / CanvasConfService.ins.blindData.windowSizeHeight;
                      for (let i: number = 0; i <= sideNumUnder; i++) {
                        for (let j: number = 0; j <= verticalNumUnder; j++) {
                          this.transformSVG.append('image')
                            .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPathUnder)
                            .attr('x', `${this.margin.left + (sizeWidUnder * i)}`)
                            .attr('y', `${this.margin.top + (this.height / 2) + (sizeHeiUnder * j)}`)
                            // .attr('width', `${this.width / (sideNum || 1)}`)
                            // .attr('height', `${this.height / (verticalNum || 1)}`)
                            .attr('width', `${sizeWidUnder * 1.01}`)
                            .attr('height', `${sizeHeiUnder * 1.01}`)
                            .attr('preserveAspectRatio', 'none')
                            .attr('clip-path', 'url(#clip-path)')
                            .attr('opacity', '1');
                        }
                      }
                      if (!CanvasConfService.ins.responseDataColorInfo.image_file && colorCodeList) {
                        const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[0] ? colorCodeList[0] : CanvasConfService.ins.responseDataColorInfo.texture_color;
                        // const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 1);
                        const rect = CreateSlatManager.createSlat(this.width, this.height / 2, colorCode.replace('0x', '#'), 1);
                        rect.setAttributeNS(null, 'x', '' + this.margin.left);
                        rect.setAttributeNS(null, 'y', '' + this.margin.top);
                        this.transformSVG.node().append(rect);
                      }
                      if (!CanvasConfService.ins.underSlatData.image_file) {
                        const rect = CreateSlatManager.createSlat(this.width, this.height / 2, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 1);
                        rect.setAttributeNS(null, 'x', '' + this.margin.left);
                        rect.setAttributeNS(null, 'y', '' + this.margin.top + this.height / 2);
                        this.transformSVG.node().append(rect);
                      }
                      for (let i: number = 0; i < len; i++) {
                        if (CanvasConfService.ins.responseDataColorInfo.image_file && i < len / 2) {
                          const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, '#000000', '0.3');
                          baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                          baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                          this.transformSVG.node().append(baseRect);
                          continue;
                        }
                        if (CanvasConfService.ins.underSlatData.image_file && i > (len / 2 - 1)) {
                          const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, '#000000', '0.3');
                          baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                          baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                          this.transformSVG.node().append(baseRect);
                          continue;
                        }
                      }
                      const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                      middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                      middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                      // middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - ((8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len)) / 2) + this.height / len / 2));
                      this.transformSVG.node().append(middleBar);
                    });
                  });
              } else if (CanvasConfService.ins.responseDataColorInfo.image_file) {
                const image = new Image();
                image.onload = () => {
                  this.displayObjArr.push(() => {
                    const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                    const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / 2 / image.height);
                    const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                    const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                    for (let i: number = 0; i <= sideNum; i++) {
                      for (let j: number = 0; j <= verticalNum; j++) {
                        this.transformSVG.append('image')
                          .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                          .attr('x', `${this.margin.left + (sizeWid * i)}`)
                          .attr('y', `${this.margin.top + (sizeHei * j)}`)
                          // .attr('width', `${this.width / (sideNum || 1)}`)
                          // .attr('height', `${this.height / (verticalNum || 1)}`)
                          .attr('width', `${sizeWid * 1.01}`)
                          .attr('height', `${sizeHei * 1.01}`)
                          .attr('preserveAspectRatio', 'none')
                          .attr('clip-path', 'url(#clip-path-top)')
                          .attr('opacity', '1');
                      }
                    }
                    for (let i: number = 0; i < len; i++) {
                      if (CanvasConfService.ins.responseDataColorInfo.image_file && i < len / 2) {
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, '#000000', '0.3');
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                        continue;
                      }
                      const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                      const textureColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.texture_color : CanvasConfService.ins.underSlatData.texture_color).replace('0x', '#');
                      if (i < Math.floor(len / 2)) {
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha());
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      } else {
                        if (i === Math.floor(len / 2) + 1 && len % 2 === 1) {
                          const dummyRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                          dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                          dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * (i - 1)) + this.height / len / 2));
                          this.transformSVG.node().append(dummyRect);
                        }
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      }
                    }
                    const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                    middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                    middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                    this.transformSVG.node().append(middleBar);
                  });
                };
                image.src = CanvasConfService.ins.blindData.displayImgPath || '';
              } else if (CanvasConfService.ins.underSlatData.image_file) {
                const image = new Image();
                image.onload = () => {
                  this.displayObjArr.push(() => {
                    const sideNum = Math.floor(CanvasConfService.ins.blindData.windowSizeWidth / image.width);
                    const verticalNum = Math.floor(CanvasConfService.ins.blindData.windowSizeHeight / 2 / image.height);
                    const sizeWid = this.width * image.width / CanvasConfService.ins.blindData.windowSizeWidth;
                    const sizeHei = this.height * image.height / CanvasConfService.ins.blindData.windowSizeHeight;
                    for (let i: number = 0; i <= sideNum; i++) {
                      for (let j: number = 0; j <= verticalNum; j++) {
                        this.transformSVG.append('image')
                          .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPathUnder)
                          .attr('x', `${this.margin.left + (sizeWid * i)}`)
                          .attr('y', `${this.margin.top + (this.height / 2) + (sizeHei * j)}`)
                          // .attr('width', `${this.width / (sideNum || 1)}`)
                          // .attr('height', `${this.height / (verticalNum || 1)}`)
                          .attr('width', `${sizeWid * 1.01}`)
                          .attr('height', `${sizeHei * 1.01}`)
                          .attr('preserveAspectRatio', 'none')
                          .attr('clip-path', 'url(#clip-path)')
                          .attr('opacity', '1');
                      }
                    }
                    for (let i: number = 0; i < len; i++) {
                      if (CanvasConfService.ins.underSlatData.image_file && i > (len / 2 - 1)) {
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, '#000000', '0.3');
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                        continue;
                      }
                      const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                      const textureColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.texture_color : CanvasConfService.ins.underSlatData.texture_color).replace('0x', '#');
                      if (i < Math.floor(len / 2)) {
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha());
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      } else {
                        if (i === Math.floor(len / 2) + 1 && len % 2 === 1) {
                          const dummyRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                          dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                          dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * (i - 1)) + this.height / len / 2));
                          this.transformSVG.node().append(dummyRect);
                        }
                        const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                        this.transformSVG.node().append(textureRect);
                        const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                        this.transformSVG.node().append(baseRect);
                      }
                    }
                    const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                    middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                    middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                    this.transformSVG.node().append(middleBar);
                  });
                };
                image.src = CanvasConfService.ins.blindData.displayImgPathUnder || '';
              } else {
                this.displayObjArr.push(() => {
                  for (let i: number = 0; i < len; i++) {
                    const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                    const textureColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.texture_color : CanvasConfService.ins.underSlatData.texture_color).replace('0x', '#');
                    if (i < Math.floor(len / 2)) {
                      const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha());
                      textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                      this.transformSVG.node().append(textureRect);
                      const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                      baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                      this.transformSVG.node().append(baseRect);
                    } else {
                      if (i === Math.floor(len / 2) + 1 && len % 2 === 1) {
                        const dummyRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                        dummyRect.setAttributeNS(null, 'x', '' + this.margin.left);
                        dummyRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * (i - 1)) + this.height / len / 2));
                        this.transformSVG.node().append(dummyRect);
                      }
                      const textureRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                      textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i) + this.height / len / 2));
                      this.transformSVG.node().append(textureRect);
                      const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                      baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                      baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                      this.transformSVG.node().append(baseRect);
                    }
                  }
                  const middleBar = CreateSlatManager.createSlat(this.width, ((10 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len))) * (this.height / (20 * len))), middle.replace('0x', '#'), '1');
                  middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
                  middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2) - (8 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / (20 * len)) * (this.height / (20 * len)))));
                  this.transformSVG.node().append(middleBar);
                });
              }
            }
          } else if (CanvasConfService.ins.blindData.blindName === '調光ロールスクリーン') {
            this.displayObjArr.push(() => {
              if (CanvasConfService.ins.blindData.blindType.indexOf('ハナリ') !== -1) {
                isVertical = false;
                SlatModalService.ins.isOpenType = true;
                if (SlatModalService.ins.isOpen) {
                  const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), 0.4);
                  rect.setAttributeNS(null, 'x', '' + this.margin.left);
                  rect.setAttributeNS(null, 'y', '' + this.margin.top);
                  this.transformSVG.node().append(rect);
                  const num = CanvasConfService.ins.blindData.windowSizeHeight < 310 ? 4 :
                    4 + Math.ceil((CanvasConfService.ins.blindData.windowSizeHeight - 309) / 55);
                  len = num;
                  const height = (CanvasConfService.ins.blindData.windowSizeHeight / num / 2) * this.height / CanvasConfService.ins.blindData.windowSizeHeight;
                  for (let i: number = 0; i < num; i++) {
                    const rect = CreateSlatManager.createSlat(this.width, height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), 1);
                    rect.setAttributeNS(null, 'x', '' + this.margin.left);
                    rect.setAttributeNS(null, 'y', '' + (this.margin.top + (height * (i * 2 + 1))));
                    this.transformSVG.node().append(rect);
                    const underRect = CreateSlatManager.createSlat(this.width, height / 3 * this.height / CanvasConfService.ins.blindData.windowSizeHeight, '#000000', 0.03);
                    underRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    underRect.setAttributeNS(null, 'y', '' + (this.margin.top + (height * (i * 2 + 1 + 1)) - height / 3 * this.height / CanvasConfService.ins.blindData.windowSizeHeight));
                    this.transformSVG.node().append(underRect);
                  }
                } else {
                  const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), CanvasConfService.ins.LouverAlpha());
                  rect.setAttributeNS(null, 'x', '' + this.margin.left);
                  rect.setAttributeNS(null, 'y', '' + this.margin.top);
                  this.transformSVG.node().append(rect);
                  const num = Math.ceil(CanvasConfService.ins.blindData.windowSizeHeight / 125);
                  len = num;
                  for (let i: number = 0; i < num; i++) {
                    const height = (i === num - 1 ? (
                      (((125 * i) + 55) * this.height / CanvasConfService.ins.blindData.windowSizeHeight) > this.height ?
                        (CanvasConfService.ins.blindData.windowSizeHeight - (125 * i)) * (this.height / CanvasConfService.ins.blindData.windowSizeHeight)
                        : (55 * (420 / CanvasConfService.ins.blindData.windowSizeHeight))
                    ) : (55 * (420 / CanvasConfService.ins.blindData.windowSizeHeight)));
                    const rect = CreateSlatManager.createSlat(this.width, height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), 0.25);
                    rect.setAttributeNS(null, 'x', '' + this.margin.left);
                    rect.setAttributeNS(null, 'y', '' + (this.margin.top + (125 * i) * this.height / CanvasConfService.ins.blindData.windowSizeHeight));
                    this.transformSVG.node().append(rect);
                  }
                }
              } else {
                isVertical = false;
                SlatModalService.ins.isOpenType = true;
                if (SlatModalService.ins.isOpen) {
                  const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), 0.4);
                  rect.setAttributeNS(null, 'x', '' + this.margin.left);
                  rect.setAttributeNS(null, 'y', '' + this.margin.top);
                  this.transformSVG.node().append(rect);
                  const num = CanvasConfService.ins.blindData.windowSizeHeight < 375 ? 3 :
                    3 + Math.ceil((CanvasConfService.ins.blindData.windowSizeHeight - 375) / 130);
                  len = num;
                  const height = (CanvasConfService.ins.blindData.windowSizeHeight / num / 2) * this.height / CanvasConfService.ins.blindData.windowSizeHeight;
                  for (let i: number = 0; i < num; i++) {
                    const rect = CreateSlatManager.createSlat(this.width, height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), 1);
                    rect.setAttributeNS(null, 'x', '' + this.margin.left);
                    rect.setAttributeNS(null, 'y', '' + (this.margin.top + (height * (i * 2 + 1))));
                    this.transformSVG.node().append(rect);
                    const underRect = CreateSlatManager.createSlat(this.width, height / 3 * this.height / CanvasConfService.ins.blindData.windowSizeHeight, '#000000', 0.03);
                    underRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    underRect.setAttributeNS(null, 'y', '' + (this.margin.top + (height * (i * 2 + 1 + 1)) - height / 3 * this.height / CanvasConfService.ins.blindData.windowSizeHeight));
                    this.transformSVG.node().append(underRect);
                  }
                } else {
                  const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), CanvasConfService.ins.LouverAlpha());
                  rect.setAttributeNS(null, 'x', '' + this.margin.left);
                  rect.setAttributeNS(null, 'y', '' + this.margin.top);
                  this.transformSVG.node().append(rect);
                  const num = Math.ceil(CanvasConfService.ins.blindData.windowSizeHeight / 125);
                  len = num;
                  for (let i: number = 0; i < num; i++) {
                    const height = (i === num - 1 ? (
                      (((125 * i) + 55) * this.height / CanvasConfService.ins.blindData.windowSizeHeight) > this.height ?
                        (CanvasConfService.ins.blindData.windowSizeHeight - (125 * i)) * (this.height / CanvasConfService.ins.blindData.windowSizeHeight)
                        : (55 * (420 / CanvasConfService.ins.blindData.windowSizeHeight))
                    ) : (55 * (420 / CanvasConfService.ins.blindData.windowSizeHeight)));
                    const rect = CreateSlatManager.createSlat(this.width, height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), 0.25);
                    rect.setAttributeNS(null, 'x', '' + this.margin.left);
                    rect.setAttributeNS(null, 'y', '' + (this.margin.top + (125 * i) * this.height / CanvasConfService.ins.blindData.windowSizeHeight));
                    this.transformSVG.node().append(rect);
                  }
                }
              }
            });
          } else if (name === 'ハニカムスクリーンシングルスタイル') {
            this.displayObjArr.push(() => {
              const width: number = img.naturalWidth;
              const height: number = img.naturalHeight;
              const isVertical: boolean = height > width;
              const slatWidth: number = isVertical ? (this.width / len) : (this.width);
              const slatHeight: number = isVertical ? (this.height) : (this.height / len);
              const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), CanvasConfService.ins.LouverAlpha());
              rect.setAttributeNS(null, 'x', '' + this.margin.left);
              rect.setAttributeNS(null, 'y', '' + this.margin.top);
              this.transformSVG.node().append(rect);
              for (let i: number = 0; i < len; i++) {
                // if (CanvasConfService.ins.responseDataColorInfo.texture_color) {
                //   const rect = CreateSlatManager.createSlat(slatWidth, slatHeight / 2, CanvasConfService.ins.responseDataColorInfo.texture_color.replace('0x', '#'), 0.6);
                //   rect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                //   rect.setAttributeNS(null, 'y', '' + (slatHeight / 2 + this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                //   this.transformSVG.node().append(rect);
                // }
                if (colorCodeList) {
                  const colorCode: string = typeof colorCodeList === 'string' ? colorCodeList : colorCodeList[i] ? colorCodeList[i] : colorCodeList[0];
                  const rect = CreateSlatManager.createSlat(slatWidth, slatHeight / 2, typeof colorCode === 'string' ? colorCode : (colorCode as any).color, 0.3);
                  rect.setAttributeNS(null, 'x', '' + (this.margin.left + (isVertical ? (slatWidth * i) : 0)));
                  rect.setAttributeNS(null, 'y', '' + (this.margin.top + (isVertical ? 0 : (slatHeight * i))));
                  this.transformSVG.node().append(rect);
                  // SlatModalService.ins.slatColor.push(colorCode);
                }
              }
            });
          } else if (name === 'ハニカムスクリーンツインスタイル') {
            this.displayObjArr.push(() => {
              if (colorCodeList) {
                for (let i: number = 0; i < len; i++) {
                  const baseColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.color : CanvasConfService.ins.underSlatData.color).replace('0x', '#');
                  const textureColor = (i < len / 2 ? CanvasConfService.ins.responseDataColorInfo.texture_color : CanvasConfService.ins.underSlatData.texture_color).replace('0x', '#');
                  if (i < len / 2) {
                    const textureRect = CreateSlatManager.createSlat(this.width, this.height / len, baseColor, CanvasConfService.ins.LouverAlpha());
                    textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                    this.transformSVG.node().append(textureRect);
                    const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, textureColor, CanvasConfService.ins.LouverAlpha());
                    baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                    this.transformSVG.node().append(baseRect);
                  } else {
                    const textureRect = CreateSlatManager.createSlat(this.width, this.height / len, textureColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                    textureRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    textureRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                    this.transformSVG.node().append(textureRect);
                    const baseRect = CreateSlatManager.createSlat(this.width, this.height / len / 2, baseColor, CanvasConfService.ins.LouverAlpha(CanvasConfService.ins.underSlatData.alpha));
                    baseRect.setAttributeNS(null, 'x', '' + this.margin.left);
                    baseRect.setAttributeNS(null, 'y', '' + (this.margin.top + ((this.height / len) * i)));
                    this.transformSVG.node().append(baseRect);
                  }
                }
              }
              const middleBar = CreateSlatManager.createSlat(this.width, this.height / len * (8 / 15), head_box.replace('0x', '#'), '1');
              middleBar.setAttributeNS(null, 'x', '' + this.margin.left);
              middleBar.setAttributeNS(null, 'y', '' + (this.margin.top + (this.height / 2)));
              this.transformSVG.node().append(middleBar);
            });
          } else if (name === '外付けロールスクリーン') {
            this.displayObjArr.push(() => {
              isVertical = true;
              const rect = CreateSlatManager.createSlat(this.width, this.height, CanvasConfService.ins.blindData.blindColorCode.replace('0x', '#'), CanvasConfService.ins.LouverAlpha());
              rect.setAttributeNS(null, 'x', '' + this.margin.left);
              rect.setAttributeNS(null, 'y', '' + this.margin.top);
              this.transformSVG.node().append(rect);
            });
            /**
             * ロールスクリーン トレンドコレクション
             */
          } else {
            this.displayObjArr.push(() => {
              if (CanvasConfService.ins.blindData.displayImgPath) {
                const colorImage = new Image();
                colorImage.onload = () => {
                  const { naturalWidth, naturalHeight } = colorImage;
                  const {
                    windowSizeWidth,
                    windowSizeHeight,
                    imgSizeWidth,
                    imgSizeHeight,
                  } = CanvasConfService.ins.blindData;
                  const patternNum: number = +CanvasConfService.ins.responseDataColorInfo.image;
                  isVertical = (imgSizeHeight > imgSizeWidth) || (name.indexOf('たて') > -1);
                  const widthNum = Math.ceil(windowSizeWidth / naturalWidth);
                  const heightNum = Math.ceil(windowSizeHeight / naturalHeight);
                  if (
                    CanvasConfService.ins.blindData.color !== 'TN204' &&
                    CanvasConfService.ins.blindData.color !== 'TN244' &&
                    CanvasConfService.ins.blindData.color !== 'TN213' &&
                    CanvasConfService.ins.blindData.color !== 'TN215' &&
                    CanvasConfService.ins.blindData.color !== 'TN220' &&
                    CanvasConfService.ins.blindData.color !== 'TN221' &&
                    CanvasConfService.ins.blindData.color !== 'TN225' &&
                    CanvasConfService.ins.blindData.color !== 'TN265'
                  ) {
                    // const rect = CreateSlatManager.createSlat(this.width, this.height, '#FFFFFF', CanvasConfService.ins.LouverAlpha() ** 2);
                    const rect = CreateSlatManager.createSlat(this.width, this.height, '#FFFFFF', CanvasConfService.ins.LouverAlpha() ** 3);
                    rect.setAttributeNS(null, 'x', '' + this.margin.left);
                    rect.setAttributeNS(null, 'y', '' + this.margin.top);
                    this.transformSVG.node().append(rect);
                  }
                  if (patternNum === 2) {
                    for (let w: number = 0; w < widthNum; w++) {
                      this.transformSVG.append('image')
                        .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                        .attr('x', '' + (this.margin.left + w * (this.width * (widthNum * naturalWidth / windowSizeWidth) / widthNum)))
                        .attr('y', '' + (this.margin.top + this.height - this.height * (naturalHeight / CanvasConfService.ins.blindData.windowSizeHeight)))
                        .attr('width', '' + (this.width * (widthNum * naturalWidth / windowSizeWidth) / widthNum))
                        .attr('height', '' + this.height * (naturalHeight / CanvasConfService.ins.blindData.windowSizeHeight))
                        .attr('clip-path', 'url(#clip-path)')
                        .attr('preserveAspectRatio', 'none')
                        .attr('opacity', CanvasConfService.ins.LouverAlpha() ** 3);
                    }
                  } else if (patternNum === 3) {
                    for (let h: number = 0; h < heightNum; h++) {
                      this.transformSVG.append('image')
                        .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                        .attr('x', '' + (this.margin.left + (this.width / 2) - (this.width * (2000 / CanvasConfService.ins.blindData.windowSizeWidth) / 2)))
                        .attr('y', '' + (this.margin.top + h * (this.height * (1500 / CanvasConfService.ins.blindData.windowSizeHeight))))
                        .attr('width', '' + (this.width * (2000 / CanvasConfService.ins.blindData.windowSizeWidth)))
                        .attr('height', '' + (this.height * (1500 / CanvasConfService.ins.blindData.windowSizeHeight)))
                        .attr('preserveAspectRatio', 'none')
                        .attr('clip-path', 'url(#clip-path)')
                        .attr('opacity', CanvasConfService.ins.LouverAlpha() ** 3);
                    }
                    // }
                  } else {
                    for (let w: number = 0; w < widthNum + 1; w++) {
                      for (let h: number = 0; h < heightNum + 1; h++) {
                        this.transformSVG.append('image')
                          .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
                          // .attr('x', '' + (this.margin.left + w * (this.width * (widthNum * naturalWidth / windowSizeWidth) / widthNum) - widthMargin))
                          // .attr('y', '' + (this.margin.top + h * (this.height * (heightNum * naturalHeight / windowSizeHeight) / heightNum) - heightMargin))
                          .attr('x', '' + (this.margin.left + w * (this.width * (widthNum * naturalWidth / windowSizeWidth) / widthNum)))
                          .attr('y', '' + (this.margin.top + h * (this.height * (heightNum * naturalHeight / windowSizeHeight) / heightNum)))
                          .attr('width', '' + (this.width * (widthNum * naturalWidth / windowSizeWidth) / widthNum))
                          .attr('height', '' + (this.height * (heightNum * naturalHeight / windowSizeHeight) / heightNum))
                          .attr('preserveAspectRatio', 'none')
                          .attr('clip-path', 'url(#clip-path)')
                          .attr('opacity', CanvasConfService.ins.LouverAlpha() ** 3);
                      }
                    }
                  }
                  // this.transformSVG.node().setAttributeNS(null, 'mask', 'url(#blind_mask)');
                  // resolve();
                };
                colorImage.src = CanvasConfService.ins.blindData.displayImgPath;
              }
              isVertical = true;
            });
          }
          // });
          resolve();
        }
      });
    })();
    /**
     * ↑ここまでがブラインド生成処理
     *
     * ここからはオプションパーツ表示処理
     *
     * ブラインド生成の際に記載してるものもあり
     */
    const num: number = this.calcWideNum();
    if (num && !CanvasConfService.ins.isSetOption.wide_ladder_tape) {
      await new Promise((resolve: () => void) => {
        DrawManager.getFileFromUrl(CanvasConfService.ins.isSetOption.wide_ladder_tape)
          .then((res) => {
            this.displayObjArr.push(() => {
              const span = this.width / num;
              for (let i: number = 0; i < num; i++) {
                const rect = CreateSlatManager.createSlat(1, this.height, CanvasConfService.ins.responseDataColorInfo.color.replace('0x', '#'), 0.8);
                rect.setAttributeNS(null, 'x', '' + (this.margin.left + span / 2 + span * i));
                rect.setAttributeNS(null, 'y', '' + this.margin.top);
                this.transformSVG.node().append(rect);
              }
            });
            resolve();
          });
      });
    }
    const bottomHeight = name === '調光ロールスクリーン' ? 5 : isVertical ? 13 * (this.height / 460) : isNaN(len) ? 10 : this.height / len * 0.4;
    if (bottom_rail && CanvasConfService.ins.isSetOption.bottom_rail) {
      await new Promise((resolve: () => void) => {
        if (name === 'よこ型ブラインド' && CanvasConfService.ins.blindData.displayImgPath) {
          this.displayObjArr.push(() => {
            const left = this.margin.left - 1;
            const top = this.margin.top + this.height - (bottomHeight / 2);
            const width = this.width + 2;
            const height = ((30 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20)));
            this.bottomClip.setAttributeNS(null, 'd', `M${left},${top}L${left + width},${top}L${left + width},${top + bottomHeight}L${left},${top + bottomHeight}Z`);
            this.transformSVG.append('image')
              .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
              .attr('x', `${left}`)
              .attr('y', `${top}`)
              // .attr('width', `${this.width / (sideNum || 1)}`)
              // .attr('height', `${this.height / (verticalNum || 1)}`)
              .attr('width', `${1000}`)
              .attr('height', `${100 * height * bottomHeight / height}`)
              .attr('preserveAspectRatio', 'none')
              .attr('clip-path', 'url(#clip-path-bottom)')
              .attr('opacity', '1');
          });
          resolve();
        } else {
          DrawManager.getBottomElementFromUrl(
            this.longBottom,
            CanvasConfService.ins.isSetOption.bottom_rail,
          )
            .then((svgRes) => {
              const svgData = new XMLSerializer().serializeToString(svgRes);
              const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
              DrawManager.getFileFromUrl(data)
                .then((res: string) => {
                  this.displayObjArr.push(() => {
                    this.setOption(
                      res,
                      this.margin.left - 1,
                      // this.margin.top + this.height - (isVertical ? 13 * (this.height / 460 / 2) : ((13 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20)) * 0.7)),
                      this.margin.top + this.height - (bottomHeight / 2),
                      this.width + 2,
                      // isVertical ? 13 * (this.height / 460) : ((13 * 333.15 / 439 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20))),
                      // isVertical ? 13 * (this.height / 460) : isNaN(len) ? 10 : this.height / len * 0.4,
                      bottomHeight,
                      // bottomHeight,
                    );
                  });
                  resolve();
                });
            });
        }
      });
    }
    if (num && CanvasConfService.ins.isSetOption.wide_ladder_tape) {
      await new Promise((resolve: () => void) => {
        DrawManager.getFileFromUrl(CanvasConfService.ins.isSetOption.wide_ladder_tape)
          .then((res) => {
            this.displayObjArr.push(() => {
              const span = this.width / num;
              // const height = isNaN(len) ? 10 : this.height / len * 0.4;
              for (let i: number = 0; i < num; i++) {
                for (let j: number = 0; j < (this.height) / 6; j++) {
                  this.transformSVG.append('image')
                    .attr('xlink:href', res)
                    .attr('x', '' + (this.margin.left + (span / 2) + (span * i)))
                    .attr('y', '' + (this.margin.top + (j * 6)))
                    .attr('width', '6')
                    .attr('height', '6')
                    .attr('opacity', '1');
                }
                this.transformSVG.append('image')
                  .attr('xlink:href', res)
                  .attr('x', '' + (this.margin.left + (span / 2) + (span * i)))
                  .attr('y', '' + (this.margin.top + (Math.floor((this.height) / 6) * 6)))
                  .attr('width', '6')
                  .attr('height', '' + (bottomHeight / 1.6) * (1 - 0.1 * CanvasConfService.ins.blindData.windowSizeHeight / 5000))
                  .attr('opacity', '1');
              }
            });
            resolve();
          });
      });
    }
    if (head_box && CanvasConfService.ins.isSetOption.head_box) {
      await new Promise((resolve: () => void) => {
        if (name === 'よこ型ブラインド' && CanvasConfService.ins.blindData.displayImgPath) {
          this.displayObjArr.push(() => {
            const left = this.margin.left;
            const top = this.margin.top - 2;
            const width = this.width + 1;
            const height = ((30 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20)));
            this.headClip.setAttributeNS(null, 'd', `M${left},${top}L${left + width},${top}L${left + width},${top + height}L${left},${top + height}Z`);
            this.transformSVG.append('image')
              .attr('xlink:href', CanvasConfService.ins.blindData.displayImgPath)
              .attr('x', `${left}`)
              .attr('y', `${top}`)
              // .attr('width', `${this.width / (sideNum || 1)}`)
              // .attr('height', `${this.height / (verticalNum || 1)}`)
              .attr('width', `${1000}`)
              .attr('height', `${100 * height}`)
              .attr('preserveAspectRatio', 'none')
              .attr('clip-path', 'url(#clip-path-head)')
              .attr('opacity', '1');
          });
          resolve();
        } else {
          DrawManager.getHeaderElementFromUrl(
            this.topVa,
            CanvasConfService.ins.isSetOption.head_box,
          )
            .then((svgRes) => {
              const svgData = new XMLSerializer().serializeToString(svgRes);
              const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
              DrawManager.getFileFromUrl(data)
                .then((res: string) => {
                  this.displayObjArr.push(() => {
                    const response = res;
                    const left = this.margin.left;
                    const top = this.margin.top - 2;
                    const width = this.width + 1;
                    const height = CanvasConfService.ins.blindData.blindName === '調光ロールスクリーン' ? (
                        CanvasConfService.ins.detail_blind.color_table !== 'hanari_default'
                        && CanvasConfService.ins.detail_blind.unique_id !== '245'
                        && CanvasConfService.ins.detail_blind.unique_id !== '247'
                      ) ? 5 :
                        ((40 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20))) :
                      isVertical ? 8.8 * (this.height / 460) : ((30 / (CanvasConfService.ins.blindData.windowSizeHeight / ((len + 1) * 20))) * (this.height / ((len + 1) * 20)));
                    if (name.indexOf('ロールスクリーン') !== -1 && name.indexOf('トレンドコレクション') !== -1) {
                      setTimeout(() => {
                        this.setOption(
                          response,
                          left,
                          top,
                          width,
                          height / 2,
                        );
                      });
                    } else {
                      this.setOption(
                        response,
                        left,
                        top,
                        width,
                        // name === '調光ロールスクリーン' ? 4 : height,
                        height,
                      );
                    }
                  });
                  resolve();
                });
            });
        }
      });
    }
    if (CanvasConfService.ins.detail_blind.wood_table && wood_balance && CanvasConfService.ins.isSetOption.wood_balance) {
      await new Promise((resolve: () => void) => {
        DrawManager.getWoodBalanceElementFromUrl(
          this.balance,
          CanvasConfService.ins.isSetOption.wood_balance,
        )
          .then((svgRes) => {
            const svgData = new XMLSerializer().serializeToString(svgRes);
            const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
            DrawManager.getFileFromUrl(data)
              .then((res: string) => {
                this.displayObjArr.push(() => {
                  this.setOption(
                    res,
                    this.margin.left - 1.5,
                    this.margin.top - 3,
                    this.width + 3,
                    CanvasConfService.ins.detail_blind.color_table === 'dimming_roll' ? 13 :
                      isVertical ? 9.6 * (this.height / 460) : (this.height * (34 / ((len + 1) * 20)) * (CanvasConfService.ins.detail_blind.cover === '1' ? 0.4 : CanvasConfService.ins.detail_blind.cover === '2' ? 0.8 : 1) + 3),
                    // isVertical ? 9.6 * (this.height / 460) : this.height * (24 / ((len + 1) * 20)) * (CanvasConfService.ins.detail_blind.cover === '1' ? 0.4 : CanvasConfService.ins.detail_blind.cover === '2' ? 0.8 : 1),
                  );
                });
                resolve();
              });
          });
      });
    }
    setTimeout(() => {
      this.displayBlind();
    });
  }

  /** サイズが必要な場合、ブラインド固有のIDからサイズの調整が必要なものは調整後のサイズを返す */
  private calcBefore(type: 'width' | 'height' = 'width'): number {
    const blindId = LogManager.uniqueId();
    const size = type === 'width' ? CanvasConfService.ins.blindData.windowSizeWidth : CanvasConfService.ins.blindData.windowSizeHeight;
    switch (blindId) {
      case '1':
      case '2':
      case '198':
      case '199':
      case '202':
      case '118':
      case '119':
      case '200':
      case '201':
        return size % 10 ? size + (10 - (size % 10)) : size;
      default:
        return size;
    }
  }

  /** スラットの本数が必要な場合、ブラインド固有のIDから本数の調整が必要なものは調整後の本数を返す */
  private calcAfter(value: number): number {
    const blindId = LogManager.uniqueId();
    switch (blindId) {
      case '146':
      case '145':
      case '142':
      case '141':
      case '109':
      case '110':
      case '111':
      case '112':
      case '143':
      case '144':
      case '103':
      case '105':
        return Math.ceil(value) + (Math.ceil(value) % 2);
      case '34':
      case '37':
      case '40':
      case '216':
      case '188':
      case '190':
      case '194':
      case '196':
      case '61':
      case '65':
      case '47':
      case '205':
      case '206':
      case '51':
      case '207':
      case '208':
      case '55':
      case '209':
      case '210':
      case '77':
      case '81':
      case '224':
      case '228':
      case '230':
      case '234':
      case '235':
      case '237':
      case '239':
      case '242':
        return Math.ceil(value) * 2;
      case '161':
      case '162':
        return Math.round(value);
      default:
        return value;
    }
  }

  /**
   * 描画完了後の表示（？）処理
   * コントローラーの表示とローディングの完了
   */
  private displayBlind(): void {
    DrawManager.drawSVG()
      .then(() => {
        this.transformSVG.selectAll('rect').remove();
        this.transformSVG.selectAll('image').remove();
        this.transformSVG.selectAll('svg').remove();
        this.transformSVG.select('def').selectAll('mask').remove();
        this.displayObjArr.forEach((data) => data());
        this.transformed();
        if (!DrawManager.controlHandlerObj || !DrawManager.controlHandlerObj._groups[0]) {
          DrawManager.controlHandlerObj = this.controlSVG.select('g').selectAll('handle')
            .data(DrawManager.targetPoints)
            .enter().append('circle')
            .attr('class', 'handle')
            .attr('transform', (d: any) => {
              return 'translate(' + d.x + ',' + d.y + ')';
            })
            .attr('r', (navigator.userAgent.indexOf('iPhone') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1)) ? 10 : 18)
            .attr('fill', '#CAEAFF')
            .attr('fill-opacity', '0.4')
            .call(
              d3.drag()
                .on('start', (data, index, arr) => {
                  if (this.dragPointNum === -1) {
                    this.dragPointNum = index;
                  } else {
                    return;
                  }
                  DrawManager.clearSVG();
                  this.transformSVG.attr('display', 'none');
                  this.drawLine('0.6');
                })
                .on('drag', ((data: any, index: number, arr: SVGElement[]) => this.handlerDrag(data, index, arr)) as any)
                .on('end', () => {
                  this.dragPointNum = -1;
                  this.transformed();
                  DrawManager.drawSVG();
                  this.drawLine(DrawManager.blindSelected ? '0' : '0.6');
                  this.transformSVG.attr('display', 'flex');
                }),
            );
          DrawManager.controlHandlerObj._groups[0].map((sel: any, i: number) => {
            d3.select(sel)
              .attr('transform', 'translate(' + (sel.x = DrawManager.targetPoints[i].x) + ',' + (sel.y = DrawManager.targetPoints[i].y) + ')')
              .attr('cursor', i % 2 === 0 ? 'nwse-resize' : 'nesw-resize');
          });
        }
        setTimeout(() => {
          this.transformSVG.attr('display', 'flex');
          CanvasConfService.loading(false);
        }, CanvasConfService.isLoading ? 500 : 0);
      });
  }

  /**
   * 引数に渡したurlを元に画像ファイルを生成し、base64エンコードしたurlを返す
   *
   * @param url
   */
  public static getFileFromUrl(url: string, orientation?: number): Promise<string> {
    // const fileName: string = url.split('/')[url.split('/').length - 1];
    return new Promise((resolve, reject) => {
      Axios.get(url, { responseType: 'blob' })
        .then((v: AxiosResponse) => {
          const date = new Date();
          const blob = new Blob([v.data], { type: v.data.type });
          ((): Promise<File> => {
            return new Promise((resolve) => {
              if (orientation) {
                ImgRotater.rotate(blob as File, orientation)
                  .then(resolve);
              } else {
                resolve(blob as File);
              }
            });
          })().then((file) => {
            const fr = new FileReader();
            fr.onload = (e: any) => {
              resolve(e.target.result);
            };
            fr.readAsDataURL(file);
          });
        })
        .catch((e: AxiosError) => {
          reject();
        });
    });
  }

  /**
   * 色を変更したスラットのSVG要素を返す
   *
   * @param url
   * @param color
   * @param textureColor
   */
  public static getSlatElementFromUrl(url: string, slatColor: string, textureColor: string): Promise<any> {
    return new Promise<SVGElement>((resolve: (el: any) => void, reject: () => void) => {
      const https = require('https');
      https.get(url, (res: any) => {
        let data = '';
        res.on('data', (chunk: any) => {
          data += chunk;
        });
        res.on('end', () => {
          const temp = document.createElement('div');
          temp.innerHTML = data.replace(/SLAT-COLOR/g, slatColor).replace(/TEXTURE-COLOR/g, textureColor).replace(/##/g, '#');
          resolve(temp.firstElementChild);
        });
      }).on('error', (err: any) => {
        console.log(err);
        reject();
      });
    });
  }

  /**
   * オプションパーツ（ヘッドボックス）のSVG要素を返す
   *
   * @param url
   * @param baseColor
   */
  public static getHeaderElementFromUrl(url: string, baseColor: string): Promise<any> {
    return new Promise<SVGElement>((resolve: (el: any) => void, reject: () => void) => {
      const https = require('https');
      https.get(url, (res: any) => {
        let data = '';
        res.on('data', (chunk: any) => {
          data += chunk;
        });
        res.on('end', () => {
          const temp = document.createElement('div');
          baseColor = baseColor.replace('#', '');
          temp.innerHTML = data.replace(/BASE-COLOR/g, baseColor).replace(/##/g, '#');
          resolve(temp.firstElementChild);
        });
      }).on('error', (err: any) => {
        console.log(err);
        reject();
      });
    });
  }

  /**
   *
   * @param url
   * @param baseColor
   */
  public static getWoodBalanceElementFromUrl(url: string, baseColor: string): Promise<any> {
    return new Promise<SVGElement>((resolve: (el: any) => void, reject: () => void) => {
      const https = require('https');
      https.get(url, (res: any) => {
        let data = '';
        res.on('data', (chunk: any) => {
          data += chunk;
        });
        res.on('end', () => {
          const temp = document.createElement('div');
          baseColor = baseColor.replace('#', '');
          temp.innerHTML = data.replace(/OPTION-COLOR/g, baseColor).replace(/##/g, '#');
          resolve(temp.firstElementChild);
        });
      }).on('error', (err: any) => {
        console.log(err);
        reject();
      });
    });
  }

  /**
   *
   * @param url
   * @param baseColor
   */
  public static getBottomElementFromUrl(url: string, baseColor: string): Promise<any> {
    return new Promise<SVGElement>((resolve: (el: any) => void, reject: () => void) => {
      const https = require('https');
      https.get(url, (res: any) => {
        let data = '';
        res.on('data', (chunk: any) => {
          data += chunk;
        });
        res.on('end', () => {
          const temp = document.createElement('div');
          baseColor = baseColor.replace('#', '');
          temp.innerHTML = data.replace(/OPTION-COLOR/g, baseColor).replace(/##/g, '#');
          resolve(temp.firstElementChild);
        });
      }).on('error', (err: any) => {
        console.log(err);
        reject();
      });
    });
  }

  /** svg画像を生成するための置換処理 */
  private powerSvgImg(str: string): string {
    let cnt: number = 1;
    while (str.indexOf(`NS${cnt}:href`) !== -1) {
      str = str.replace(`NS${cnt}:href`, 'href');
      if (str.indexOf(`NS${cnt}:href`) === -1) {
        cnt++;
      }
    }
    return str;
  }

  /**
   * 画像保存（生成）処理
   */
  public saveImg(): Promise<void> {
    return new Promise((resolve: () => void) => {
      /** 背景とsvgで座標を合わせる */
      const dummyPoints: Pos[] = DrawManager.targetPoints;
      DrawManager.targetPoints = [
        new Pos(dummyPoints[0].x + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2, dummyPoints[0].y + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2),
        new Pos(dummyPoints[1].x + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2, dummyPoints[1].y + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2),
        new Pos(dummyPoints[2].x + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2, dummyPoints[2].y + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2),
        new Pos(dummyPoints[3].x + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2, dummyPoints[3].y + (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)) / 2),
      ];
      /** 画像作成用に変形 */
      this.transformed();
      /** 背景とsvgを表示するhtmlファイルを作成 */
      const canvas = document.getElementById(CanvasConfService.ins.canvasId) as HTMLCanvasElement;
      const svg: HTMLElement = this.transformSVG.node();
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      const date = new Date();
      const str = HtmlCreator.create(ExportImgManager.getEncodedImage(canvas, 'png'), svg, 1);
      const blob = new Blob([this.powerSvgImg(str)], { type: 'text/html' });
      const file: File = Object.assign(blob, {
        name: 'temp.html',
        lastModifiedDate: date,
        webkitRelativePath: '',
      }) as any as File;
      /** 変形したのを元に戻す */
      DrawManager.targetPoints = dummyPoints;
      this.transformed();
      /** htmlファイルを画像に変換 */
      ConnectionService.ins.connect(new PostCreateImage(file as File))
        .then((res) => {
          /** 画像ダウンロード用にcanvas宣言 */
          const canvas = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas') as HTMLCanvasElement;
          canvas.setAttributeNS(null, 'width', `${700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)}`);
          canvas.setAttributeNS(null, 'height', `${700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)}`);
          const context: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
          const image = new Image();
          image.onload = () => {
            /** html生成のためのsvg作成 */
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
            svg.setAttributeNS(null, 'x', '0');
            svg.setAttributeNS(null, 'y', '0');
            svg.setAttributeNS(null, 'width', `700`);
            svg.setAttributeNS(null, 'height', `700`);
            svg.setAttributeNS(null, 'viewBox', `0 0 700 700`);
            const svgImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            svgImage.setAttributeNS(null, 'x', '0');
            svgImage.setAttributeNS(null, 'y', '0');
            svgImage.setAttributeNS(null, 'width', `${image.width}`);
            svgImage.setAttributeNS(null, 'height', `${image.height}`);
            svgImage.setAttributeNS(null, 'transform', `scale(${700 / (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0))})`);
            svgImage.setAttributeNS(null, 'preserveAspectRatio', 'none');
            svgImage.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `data:image/png;base64,${res.data}`);
            svg.appendChild(svgImage);
            const svgData = new XMLSerializer().serializeToString(svg);
            const svgImg = document.createElement('img');
            svgImg.onerror = (e) => console.log(e);
            svgImg.onload = () => {
              /** 以下は一般的な画像のダウンロード */
              context.drawImage(svgImg, 0, 0, 700, 700);
              const img = document.createElement('img');
              img.onload = () => {
                this.tempImage = img.src;
                const anchorEle: HTMLAnchorElement = document.createElement('a');
                const clickListener = () => {
                  if (window.navigator.msSaveBlob) {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', img.src);
                    xhr.responseType = 'blob';
                    xhr.onloadend = () => {
                      window.navigator.msSaveOrOpenBlob(xhr.response, 'blind-img-' + DateFormatter.date2str(new Date(), DateFormat.YYYYMMDD_HHmm, '').replace(/\s+/g, '').replace(':', '') + '.png');
                      resolve();
                    };
                    xhr.onerror = (e) => console.log('e: ', e);
                    xhr.send();
                  } else {
                    document.body.appendChild(anchorEle);
                    anchorEle.href = img.src;
                    anchorEle.target = '_brank';
                    anchorEle.download = 'blind-img-' + DateFormatter.date2str(new Date(), DateFormat.YYYYMMDD_HHmm, '').replace(/\s+/g, '').replace(':', '') + '.png';
                    anchorEle.removeEventListener('click', clickListener);
                    document.body.removeChild(anchorEle);
                  }
                  resolve();
                };
                const error = (e: any) => {
                  console.log('error: ', e);
                  anchorEle.removeEventListener('click', clickListener);
                  anchorEle.removeEventListener('error', error);
                };
                anchorEle.addEventListener('click', clickListener);
                anchorEle.addEventListener('error', error);
                setTimeout(() => {
                  anchorEle.click();
                });
              };
              const date = new Date();
              const str = HtmlCreator.create('', svg, 700 / (700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)));
              const blob = new Blob([this.powerSvgImg(str)], { type: 'text/html' });
              const file: File = Object.assign(blob, {
                name: 'temp.html',
                lastModifiedDate: date,
                webkitRelativePath: '',
              }) as any as File;
              ConnectionService.ins.connect(new PostCreateImage(file as File))
                .then((res) => {
                  img.src = 'data:image/png;base64,' + res.data;
                });
            };
            const resizeCanvas = document.createElement('canvas');
            const resizeCtx = canvas.getContext('2d');
            const resizeImage = new Image();
            resizeImage.onload = () => {
              var dstWidth, dstHeight;
              if (resizeImage.width > resizeImage.height) {
                dstWidth = 700;
                dstHeight = resizeImage.height * 700 / resizeImage.width;
              } else {
                dstHeight = 700;
                dstWidth = resizeImage.width * 700 / resizeImage.height;
              }
              resizeCanvas.width = dstWidth;
              resizeCanvas.height = dstHeight;
              (resizeCtx as CanvasRenderingContext2D).drawImage(resizeImage, 0, 0, this.width, this.height, 0, 0, dstWidth, dstHeight);
              /** canvas で svg を画像化 */
              svgImg.src = resizeCanvas.toDataURL();
            };
            /** svg画像の文字列を読み込み */
            resizeImage.src = 'data:image/svg+xml;charset=utf-8;base64,' + btoa(svgData);
          };
          /** htmlから生成した画像の読み込み */
          image.src = 'data:image/png;base64,' + res.data;
        })
        .catch((e) => console.log('e: ', e));
    });
  }

  /**
   * pdf出力
   * 基本的には強引にパラメータを持ってくる
   * API定義書はあるが内容正しくないので基本的には頑張るかサーバー側に確認してもらう
   */
  public outputPdf(orderSheet: 0 | 1): void {
    const canvas = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas') as HTMLCanvasElement;
    canvas.setAttributeNS(null, 'width', `${700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)}`);
    canvas.setAttributeNS(null, 'height', `${700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)}`);
    const context: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
    const image = new Image();
    const {
      head_box,
      head_box_value1,
      head_box_value2,
      middle,
      middle_value1,
      middle_value2,
      bottom_rail,
      bottom_rail_value1,
      bottom_rail_value2,
      wide_ladder_tape,
      wide_ladder_tape_value1,
      wide_ladder_tape_value2,
      wood_balance,
      wood_balance_value1,
      wood_balance_value2,
    } = CanvasConfService.ins.isSetOption;
    const option: Buhin[] = [];
    if (head_box || head_box_value1 || head_box_value2) {
      option.push({
        name: 'ヘッドボックス',
        color: head_box_value1,
        color_name: head_box_value2,
      });
    }
    if (wood_balance || wood_balance_value1 || wood_balance_value2) {
      option.push({
        name: 'ウッドバランス',
        color: wood_balance_value1,
        color_name: wood_balance_value2,
      });
    }
    if (wide_ladder_tape || wide_ladder_tape_value1 || wide_ladder_tape_value2) {
      option.push({
        name: 'ワイドラダーテープ',
        color: wide_ladder_tape_value1,
        color_name: wide_ladder_tape_value2,
      });
    }
    if (middle || middle_value1 || middle_value2) {
      option.push({
        name: 'ミドルバー',
        color: middle_value1,
        color_name: middle_value2,
      });
    }
    if (bottom_rail || bottom_rail_value1 || bottom_rail_value2) {
      option.push({
        name: 'ボトムレール',
        color: bottom_rail_value1,
        color_name: bottom_rail_value2,
      });
    }
    image.onload = () => {
      context.drawImage(image, 0, 0, 700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0), 700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0));
      const img = document.createElement('img');
      img.src = ExportImgManager.getEncodedImage(canvas, 'jpeg');
      img.onload = () => {
        const open =
          CanvasConfService.ins.detail_blind.open === 'CENTER' ? 2 :
            CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'MIX' ? 3 : 1;
        const color: { name: string, color: string }[] = [];
        SlatModalService.ins.slatColor.forEach((data) => {
          color.push({
            name: data.color_code,
            color: (typeof data.color === 'string' ? data.color : (data as any).color.color).replace('#', '0x'),
          });
        });
        const centerColor: { name: string, color: string }[] = [];
        if (SlatModalService.ins.isOpenType) {
          if (CanvasConfService.ins.blindData.isStandardBlind) {
            color.forEach(() => {
              if (centerColor.length < color.length - 1) {
                centerColor.push({
                  name: CanvasConfService.ins.centerRace.color_code,
                  color: CanvasConfService.ins.centerRace.color,
                });
              }
            });
          } else {
            CanvasConfService.ins.centerRaceColorArr.forEach((data) => {
              centerColor.push({
                name: data.color_code,
                color: data.color,
              });
            });
          }
        } else if (CanvasConfService.ins.blindData.blindType.indexOf('バックレース') !== -1) {
          if (CanvasConfService.ins.blindData.isStandardBlind || CanvasConfService.ins.centerRaceColorArr.length === 0) {
            color.forEach(() => {
              if (centerColor.length < color.length) {
                centerColor.push({
                  name: CanvasConfService.ins.centerRace.color_code,
                  color: CanvasConfService.ins.centerRace.color,
                });
              }
            });
          } else {
            CanvasConfService.ins.centerRaceColorArr.forEach((data) => {
              centerColor.push({
                name: data.color_code,
                color: data.color,
              });
            });
          }
        }
        const request = new PostMakePdf(
          img.src,
          [
            CanvasConfService.ins.blindData.blindName.replace('\n', '').replace('\t', ' '),
            CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN' ? CanvasConfService.ins.responseDataColorInfo.color_code : CanvasConfService.ins.blindData.color,
            '' + (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN' ? CanvasConfService.ins.underSlatData.color_code :
                (SlatModalService.ins.isOpenType || CanvasConfService.ins.blindData.blindType.indexOf('バックレース') !== -1) ? CanvasConfService.ins.centerRace.color_code : ''
            ),
            '' + CanvasConfService.ins.blindData.windowSizeWidth,
            '' + CanvasConfService.ins.blindData.windowSizeHeight,
            CanvasConfService.ins.blindData.blindOpenType,
            '',
          ],
          option,
          open,
          (Number(CanvasConfService.ins.detail_blind.cover) + 1) === 1 ? 1 :
            (SlatModalService.ins.isOpenType || CanvasConfService.ins.blindData.blindType.indexOf('バックレース') !== -1) ? 2 : 3,
          color,
          centerColor,
          orderSheet,
          CanvasConfService.ins.blindData.id,
          CanvasConfService.ins.blindData.sub_id,
          String(CanvasConfService.ins.responseDataBlindDetail.id),
        );
        ConnectionService.ins.connect(request)
          .then((response: AxiosResponse<any>) => {
            CanvasConfService.loading(false);
            const hostName: string = request.getHost();
            const anchorEle: HTMLAnchorElement = document.createElement('a');
            anchorEle.href = `${hostName}/${response.data.file_name}`;
            anchorEle.target = 'blank';
            anchorEle.click();
          })
          .catch(console.log);
      };
    };
    image.src = this.tempImage;
  }

  /**
   * ドラッグ中の処理
   *
   * ドラッグ対象の移動とトランスフォームを実行
   *
   * @param d
   * @param index
   * @param elementList
   */
  private handlerDrag(d: any, index: number, elementList: SVGElement[]): void {
    if (this.dragPointNum !== index) {
      return;
    }
    const pos = this.calcPos(d3.event.x, d3.event.y, index);
    const posx = pos.x;
    const posy = pos.y;
    this.drawLine('0.6');
    d3.select(elementList[index])
      .attr('transform', 'translate(' + (d.x = posx) + ',' + (d.y = posy) + ')');
  }

  /**
   * 座標を見て変形可能な形を制限
   * 角度が180°を超えると描画できなくなるので許容する角度を決める
   */
  private calcPos(posx: number, posy: number, index: number): { x: number, y: number } {
    const pos: { x: number, y: number } = { x: posx, y: posy };
    const container = document.getElementById('control-area') as HTMLElement;
    const { clientWidth, clientHeight } = container;
    if (pos.x < -(clientWidth / 2 + this.margin.left - 18)) {
      pos.x = -(clientWidth / 2 + this.margin.left - 18);
    } else if (pos.x > (clientWidth / 2 - this.margin.left - 18)) {
      pos.x = (clientWidth / 2 - this.margin.left - 18);
    }
    if (pos.y < -(clientHeight / 2 + this.margin.top - 18)) {
      pos.y = -(clientHeight / 2 + this.margin.top - 18);
    } else if (pos.y > (clientHeight / 2 - this.margin.top - 18)) {
      pos.y = (clientHeight / 2 - this.margin.top - 18);
    }
    const angleMargin: number = 5;
    const angle1 = this.calcRad(
      DrawManager.targetPoints[index > 1 ? index - 2 : index + 2],
      pos,
      DrawManager.targetPoints[index > 0 ? index - 1 : 3],
    );
    const angle2 = this.calcRad(
      DrawManager.targetPoints[index > 1 ? index - 2 : index + 2],
      DrawManager.targetPoints[index > 0 ? index - 1 : 3],
      DrawManager.targetPoints[index < 3 ? index + 1 : 0],
    ) - angleMargin;
    const angle3 = this.calcRad(
      pos,
      DrawManager.targetPoints[index > 0 ? index - 1 : 3],
      DrawManager.targetPoints[index < 3 ? index + 1 : 0],
    );
    const angle5 = this.calcRad(
      DrawManager.targetPoints[index > 0 ? index - 1 : 3],
      pos,
      DrawManager.targetPoints[index > 1 ? index - 2 : index + 2],
    );
    const angle6 = this.calcRad(
      DrawManager.targetPoints[index < 3 ? index + 1 : 0],
      pos,
      DrawManager.targetPoints[index > 1 ? index - 2 : index + 2],
    );
    const angle7 = this.calcRad(
      DrawManager.targetPoints[index > 0 ? index - 1 : 3],
      DrawManager.targetPoints[index < 3 ? index + 1 : 0],
      DrawManager.targetPoints[index > 1 ? index - 2 : index + 2],
    ) + angleMargin;
    const angle8 = this.calcRad(
      DrawManager.targetPoints[index < 3 ? index + 1 : 0],
      DrawManager.targetPoints[index > 0 ? index - 1 : 3],
      DrawManager.targetPoints[index > 1 ? index - 2 : index + 2],
    ) + angleMargin;
    const sumAngle = (angle2 + angleMargin + angle3 + angle5 + angle6);
    if (angle1 > 5 && angle1 < angle2 && angle5 > angle7 && angle6 > angle8 && (sumAngle < 364 && sumAngle > 356)) {
      if (pos.x < -(clientWidth / 2 + this.margin.left - 18)) {
        pos.x = -(clientWidth / 2 + this.margin.left - 18);
      } else if (pos.x > (clientWidth / 2 - this.margin.left - 18)) {
        pos.x = (clientWidth / 2 - this.margin.left - 18);
      }
      if (pos.y < -(clientHeight / 2 + this.margin.top - 18)) {
        pos.y = -(clientHeight / 2 + this.margin.top - 18);
      } else if (pos.y > (clientHeight / 2 - this.margin.top - 18)) {
        pos.y = (clientHeight / 2 - this.margin.top - 18);
      }
      return pos;
    } else {
      return DrawManager.targetPoints[index];
    }
  }

  /**
   * p1を中央に置いたp1, p2, p3 の角度
   *
   * @param p1
   * @param p2
   * @param p3
   */
  private calcRad(p1: Pos, p2: Pos, p3: Pos): number {
    const ba = new Array(2);
    ba[0] = p2.x - p1.x;
    ba[1] = p2.y - p1.y;
    const bc = new Array(2);
    bc[0] = p3.x - p1.x;
    bc[1] = p3.y - p1.y;
    const babc = ba[0] * bc[0] + ba[1] * bc[1];
    const ban = (ba[0] * ba[0]) + (ba[1] * ba[1]);
    const bcn = (bc[0] * bc[0]) + (bc[1] * bc[1]);
    const radian = Math.acos(babc / (Math.sqrt(ban * bcn)));
    return radian * 180 / Math.PI;
  }

  /**
   * ブラインド変形処理
   */
  private transformed(): void {
    const A: number[][] = [];
    const b: number[] = [];
    for (let i: number = 0; i < this.sourcePoints.length; ++i) {
      const s = this.sourcePoints[i];
      const t = DrawManager.targetPoints[i];
      A.push([s.x, s.y, 1, 0, 0, 0, -s.x * t.x, -s.y * t.x]);
      b.push(t.x);
      A.push([0, 0, 0, s.x, s.y, 1, -s.x * t.y, -s.y * t.y]);
      b.push(t.y);
    }
    const X = NumericSolveManager.solve(A, b, true);
    const matrix = [
      X[0], X[3], 0, X[6],
      X[1], X[4], 0, X[7],
      0, 0, 1, 0,
      X[2], X[5], 0, 1,
    ].map((x) => {
      return x.toFixed(6);
    });
    this.transformSVG.node().setAttributeNS(null, 'style', `${this.defaultSVGStyle}` + `transform: matrix3d(${matrix});`);
  }

  /**
   * ドラッグ点をつなぐ線を描画
   *
   * 引数にはアルファを文字列で渡す
   *
   * @param opacity
   */
  private drawLine(opacity: string): void {
    this.blindLine1
      .attr('x1', '' + (DrawManager.targetPoints[0].x + 2))
      .attr('y1', '' + DrawManager.targetPoints[0].y)
      .attr('x2', '' + (DrawManager.targetPoints[1].x - 2))
      .attr('y2', '' + DrawManager.targetPoints[1].y)
      .attr('stroke', '#CCCCCC')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('opacity', opacity);
    this.blindLine2
      .attr('x1', '' + DrawManager.targetPoints[1].x)
      .attr('y1', '' + DrawManager.targetPoints[1].y)
      .attr('x2', '' + DrawManager.targetPoints[2].x)
      .attr('y2', '' + DrawManager.targetPoints[2].y)
      .attr('stroke', '#CCCCCC')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('opacity', opacity);
    this.blindLine3
      .attr('x1', '' + (DrawManager.targetPoints[2].x - 2))
      .attr('y1', '' + DrawManager.targetPoints[2].y)
      .attr('x2', '' + (DrawManager.targetPoints[3].x + 2))
      .attr('y2', '' + DrawManager.targetPoints[3].y)
      .attr('stroke', '#CCCCCC')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('opacity', opacity);
    this.blindLine4
      .attr('x1', '' + DrawManager.targetPoints[3].x)
      .attr('y1', '' + DrawManager.targetPoints[3].y)
      .attr('x2', '' + DrawManager.targetPoints[0].x)
      .attr('y2', '' + DrawManager.targetPoints[0].y)
      .attr('stroke', '#CCCCCC')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('opacity', opacity);
  }

  /**
   * ワイドラダーの本数計算
   */
  public calcWideNum() {
    const type: String = CanvasConfService.ins.detail_blind.type_code;
    const width: number = CanvasConfService.ins.blindData.windowSizeWidth;
    const height: number = CanvasConfService.ins.blindData.windowSizeHeight;
    let num: number = 0;
    switch (true) {
      case type == 'SL15':
      case type == 'SL25':
      case type == 'UN15':
      case type == 'UN25':
      case type == 'SG25':
      case type == 'SG35':
      case type == 'PVB':
      case type == 'PBB':
        num = 2;
        break;
      case type == 'Q50F':
      case type == 'Q50':
      case type == 'QD50S':
        num = (width >= 250 && width <= 1000 ? 2 : (width >= 1001 && width <= 2000 ? 3 : (width >= 2001 ? 5 : 0)));
        break;
      case type == 'Q35F':
      case type == 'Q35':
        num = (
          width >= 250 && width <= 1100 ? 2 : (
            width >= 1101 && width <= 1800 ? 3 : (
              width >= 1801 ? 5 : 0
            )
          )
        );
        break;
      case type == 'QG':
        num = (width >= 550 && width <= 1500 ? 3 : (width >= 1500 ? 5 : 0));
        break;
      case type == 'KL50F':
      case type == 'KL50':
      case type == 'KL35F':
      case type == 'KL35':
        num = (
          height <= 2500 ? (
            width <= 800 ? 2 : (
              width >= 801 && width <= 1000 ? 2 : (
                width >= 1001 && width <= 1200 ? 3 : (
                  width >= 1201 && width <= 1400 ? 3 : (
                    width >= 1401 && width <= 1600 ? 3 : (
                      width >= 1601 && width <= 1700 ? 4 : (
                        width >= 1701 && width <= 2000 ? 5 : (
                          width >= 2001 ? 5 : 0
                        )
                      )
                    )
                  )
                )
              )
            )
          ) : (height >= 2501 && height <= 3000 ? (
                width <= 800 ? 2 : (
                  width >= 801 && width <= 1000 ? 3 : (
                    width >= 1001 && width <= 1200 ? 3 : (
                      width >= 1201 && width <= 1400 ? 4 : (
                        width >= 1401 && width <= 1600 ? 5 : (
                          width >= 1601 && width <= 1700 ? 5 : (
                            width >= 1701 && width <= 2000 ? 5 : (
                              width >= 2001 ? 7 : 0
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
              : (
                width <= 800 ? 2 : (
                  width >= 801 && width <= 1000 ? 3 : (
                    width >= 1001 && width <= 1200 ? 3 : (
                      width >= 1201 && width <= 1400 ? 5 : (
                        width >= 1401 && width <= 1600 ? 5 : (
                          width >= 1601 && width <= 1700 ? 5 : (
                            width >= 1701 && width <= 2000 ? 7 : (
                              width >= 2001 ? 7 : 0
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
          )
        );
        break;
      case type == 'KLG50':
        num = (
          height <= 2500 ? (
            width <= 800 ? 3 : (
              width >= 801 && width <= 1000 ? 3 : (
                width >= 1001 && width <= 1200 ? 3 : (
                  width >= 1201 && width <= 1400 ? 3 : (
                    width >= 1401 && width <= 1600 ? 3 : (
                      width >= 1601 && width <= 1700 ? 5 : (
                        width >= 1701 && width <= 2000 ? 5 : (
                          width >= 2001 ? 5 : 0
                        )
                      )
                    )
                  )
                )
              )
            )
          ) : (
            width <= 800 ? 3 : (
              width >= 801 && width <= 1000 ? 3 : (
                width >= 1001 && width <= 1200 ? 3 : (
                  width >= 1201 && width <= 1400 ? 5 : (
                    width >= 1401 && width <= 1600 ? 5 : (
                      width >= 1601 && width <= 1700 ? 5 : (
                        width >= 1701 && width <= 2000 ? 5 : (
                          width >= 2001 ? 7 : 0
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        );
        break;
      case type == 'KL50DFW':
        num = (
          height <= 2500 ? (
            width <= 540 ? 2 : (
              width >= 541 && width <= 760 ? 3 : (
                width >= 761 && width <= 840 ? 3 : (
                  width >= 841 && width <= 960 ? 4 : (
                    width >= 961 && width <= 1440 ? 5 : (
                      width >= 1441 && width <= 2040 ? 7 : (
                        width >= 2041 ? 9 : 0
                      )
                    )
                  )
                )
              )
            )
          ) : (height > 2501 && height < 3000 ? (
                width <= 540 ? 2 : (
                  width >= 541 && width <= 760 ? 3 : (
                    width >= 761 && width <= 840 ? 4 : (
                      width >= 841 && width <= 960 ? 4 : (
                        width >= 961 && width <= 1440 ? 5 : (
                          width >= 1441 && width <= 2040 ? 7 : (
                            width >= 2041 ? 9 : 0
                          )
                        )
                      )
                    )
                  )
                )
              )
              : (
                width <= 540 ? 2 : (
                  width >= 541 && width <= 760 ? 3 : (
                    width >= 761 && width <= 840 ? 4 : (
                      width >= 841 && width <= 960 ? 4 : (
                        width >= 961 && width <= 1440 ? 5 : (
                          width >= 1441 && width <= 2040 ? 5 : (
                            width >= 2041 ? 9 : 0
                          )
                        )
                      )
                    )
                  )
                )
              )
          )
        );
        break;
      case type == 'KLG50DFW':
        num = (
          height <= 2500 ? (
            width <= 760 ? 3 : (
              width >= 761 && width <= 840 ? 3 : (
                width >= 841 && width <= 960 ? 5 : (
                  width >= 961 && width <= 1440 ? 5 : (
                    width >= 1441 && width <= 2040 ? 7 : (
                      width >= 2041 ? 9 : 0
                    )
                  )
                )
              )
            )
          ) : (
            width <= 760 ? 3 : (
              width >= 761 && width <= 840 ? 5 : (
                width >= 841 && width <= 960 ? 5 : (
                  width >= 961 && width <= 1440 ? 5 : (
                    width >= 1441 && width <= 2040 ? 5 : (
                      width >= 2041 ? 5 : 0
                    )
                  )
                )
              )
            )
          )
        );
        break;
      case (type.indexOf('BF') > -1):
        num = (width >= 300 && width <= 1100 ? 2 : (width >= 1101 && width < 2000 ? 3 : (width >= 2001 ? 5 : 0)));
        break;
    }
    return num;
  }

}

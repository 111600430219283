import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {CanvasConfService} from "../../../service/canvas-conf-service";

/**
 * ブラインド開閉タイプ取得
 */
export class GetOpenTypeList extends RequestBase {

  param: {
    id: number;
  };

  constructor(
    id: number = CanvasConfService.ins.blindId,
  ) {
    super(HTTPMethod.GET, '/api/subblind/open_type_list');
    this.param = {
      id: id,
    };
  }

}

export class OpenTypeDetail {
  id: number = -1;
  type_name: string = '';
  type_img: string = '';
}
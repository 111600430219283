import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {RoutingPass} from '../../../constant/routing-pass';
import {DummyImgGetter} from '../../../util/dummy-img-getter';
import './page-howto-component.scss';
import imgLogoNichibei from '../../../assets/img/common/logo_back.png';
import imgLogoNichibeiRet from '../../../assets/img/common/logo_back@2x.png';
import imgLogoWindowSimulator from '../../../assets/img/common/header_logo_window_simulator.png';
import imgLogoWindowSimulatorRet from '../../../assets/img/common/header_logo_window_simulator@2x.png';
import imgTitleheadHowto from '../../../assets/img/common/icon_titlehead_howto.png';
import imgTitleheadHowtoRet from '../../../assets/img/common/icon_titlehead_howto@2x.png';
import imgIconHowto1 from '../../../assets/img/common/icon_howto_1.png';
import imgIconHowto1Ret from '../../../assets/img/common/icon_howto_1@2x.png';
import imgIconHowto2 from '../../../assets/img/common/icon_howto_2.png';
import imgIconHowto2Ret from '../../../assets/img/common/icon_howto_2@2x.png';
import imgIconHowto3 from '../../../assets/img/common/icon_howto_3.png';
import imgIconHowto3Ret from '../../../assets/img/common/icon_howto_3@2x.png';
import imgIconHowto4 from '../../../assets/img/common/icon_howto_4.png';
import imgIconHowto4Ret from '../../../assets/img/common/icon_howto_4@2x.png';
import imgIconHowto5 from '../../../assets/img/common/icon_howto_5.png';
import imgIconHowto5Ret from '../../../assets/img/common/icon_howto_5@2x.png';
import about1 from '../../../assets/img/common/about_pc_1.png';
import about2 from '../../../assets/img/common/about_pc_2.png';
import about3 from '../../../assets/img/common/about_pc_3.png';
import about4 from '../../../assets/img/common/about_pc_4.png';
import about5 from '../../../assets/img/common/about_pc_5.png';


interface PageHowtoComponentProps extends RouteComponentProps {
}

interface PageHowtoComponentState {
}

export class PageHowtoComponent extends React.Component<PageHowtoComponentProps, PageHowtoComponentState> {

  constructor(props: PageHowtoComponentProps) {
    super(props);
  }

  render() {
    return (
      <div className={'body_wrap'}>
        <div className={'body_howto'}>

          <div className={'header_common flex_box flex_align_center'}>
            <div>
              <h1>
                <button
                  onClick={() => this.props.history.push(RoutingPass.TOP)}
                  className={'reset'}
                >
                  <img
                    src={imgLogoNichibei}
                    srcSet={`${imgLogoNichibei} 1x, ${imgLogoNichibeiRet} 2x`}
                    alt={'トップへ'}
                  />
                </button>
              </h1>
            </div>
            <div>
              <h2>
                <img
                  src={imgLogoWindowSimulator}
                  srcSet={`${imgLogoWindowSimulator} 1x, ${imgLogoWindowSimulatorRet} 2x`}
                  alt={'Window Simulator'}
                />
              </h2>
            </div>
          </div>
          {/* //.header_common end */}

          <div className={'common_body'}>
            <div className={'common_title flex_box flex_align_center'}>
              <div>
                <img
                  src={imgTitleheadHowto}
                  srcSet={`${imgTitleheadHowto} 1x, ${imgTitleheadHowtoRet} 2x`}
                  alt={'本アプリの使い方'}
                />
              </div>
              <div><h3>本アプリの使い方</h3></div>
            </div>

            <div className={'common_contentArea'}>

              <div className={'common_cont_wrap'}>
                <div className={'common_cont_title'}>
                  <div className={'common_cont_titleInner'}>
                    <h4>本コンテンツについて</h4>
                  </div>
                </div>
                <div className={'common_cont_body'}>
                  ニチベイの豊富な商品をお好きなだけ試して、あなたのお部屋にぴったりのブラインドが探せる‼<br/><br/>
                  ブラインドシミュレーションコンテンツ<br/>
                  「ニチベイ ウィンドウシミュレーター」<br/><br/>
                  お客様の実際のお部屋の写真にブラインドのイメージを合成して、設置した際のイメージを手軽に確認することができるコンテンツです。
                </div>
                <hr className={'commonContBorder'} />

                <div className={'common_cont_body howto_cont_body'}>

                  <div className={'howto_cont_item_wrap'}>
                    <div className={'howto_cont_item_head flex_box'}>
                      <div>
                        <img
                          src={imgIconHowto1}
                          srcSet={`${imgIconHowto1} 1x, ${imgIconHowto1Ret} 2x`}
                          alt={'デジカメでお部屋の窓を撮影'}
                        />
                      </div>
                      <div><h5>デジカメでお部屋の窓を撮影</h5></div>
                    </div>
                    <div className={'howto_cont_item_body'}>
                      <img src={about1} />
                    </div>
                  </div>
                  {/* //.howto_cont_item_wrap */}

                  <div className={'howto_cont_item_wrap'}>
                    <div className={'howto_cont_item_head flex_box'}>
                      <div>
                        <img
                          src={imgIconHowto2}
                          srcSet={`${imgIconHowto2} 1x, ${imgIconHowto2Ret} 2x`}
                          alt={'撮った写真をパソコンに取り込みます'}
                        />
                      </div>
                      <div><h5>撮った写真をパソコンに取り込みます</h5></div>
                    </div>
                    <div className={'howto_cont_item_body'}>
                      <img src={about2} />
                    </div>
                  </div>
                  {/* //.howto_cont_item_wrap */}

                  <div className={'howto_cont_item_wrap'}>
                    <div className={'howto_cont_item_head flex_box'}>
                      <div>
                        <img
                          src={imgIconHowto3}
                          srcSet={`${imgIconHowto3} 1x, ${imgIconHowto3Ret} 2x`}
                          alt={'シミュレーションしたい商品やタイプを選んだ後に、表示されたブラインドの四隅を窓にぴったり合わせます'}
                        />
                      </div>
                      <div><h5>シミュレーションしたい商品やタイプを選んだ後に、表示されたブラインドの四隅を窓にぴったり合わせます</h5></div>
                    </div>
                    <div className={'howto_cont_item_body'}>
                      <img src={about3} />
                    </div>
                  </div>
                  {/* //.howto_cont_item_wrap */}

                  <div className={'howto_cont_item_wrap'}>
                    <div className={'howto_cont_item_head flex_box'}>
                      <div>
                        <img
                          src={imgIconHowto4}
                          srcSet={`${imgIconHowto4} 1x, ${imgIconHowto4Ret} 2x`}
                          alt={'色柄や商品、タイプを変えてお気に入りのブラインドを探せます'}
                        />
                      </div>
                      <div><h5>色柄や商品、タイプを変えてお気に入りのブラインドを探せます</h5></div>
                    </div>
                    <div className={'howto_cont_item_body'}>
                      <img src={about4} />
                    </div>
                  </div>
                  {/* //.howto_cont_item_wrap */}

                  <div className={'howto_cont_item_wrap'}>
                    <div className={'howto_cont_item_head flex_box'}>
                      <div>
                        <img
                          src={imgIconHowto5}
                          srcSet={`${imgIconHowto5} 1x, ${imgIconHowto5Ret} 2x`}
                          alt={'気に入ったブラインドが見つかったら、イメージシートが作成できるので、ニチベイ商品取扱店でスムーズにご注文が可能です'}
                        />
                      </div>
                      <div><h5>気に入ったブラインドが見つかったら、イメージシートが作成できるので、ニチベイ商品取扱店でスムーズにご注文が可能です</h5></div>
                    </div>
                    <div className={'howto_cont_item_body'}>
                      <img src={about5} />
                    </div>
                  </div>
                  {/* //.howto_cont_item_wrap */}

                </div>
                {/* //.howTo_cont_wrap */}
              </div>
              {/* //.common_cont_wrap */}

            </div>
            {/* //.common_contentArea */}
          </div>
          {/* //.common_body */}

        </div>
      </div>
    );
  }

}

// @ts-ignore
import * as loadImg from 'blueimp-load-image';

export class ImgRotater {
  static rotate(file : File, orientation: number = 0): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      loadImg.parseMetaData(file, (data: any) => {
        const options = {
          canvas: true,
          orientation: null,
        };
        if (orientation){
          options.orientation = orientation as any;
        } else if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        loadImg(file, (canvas: any) => {
          try {
            const base64Image: string = canvas.toDataURL(file.type);
            const bin = atob(base64Image.replace(/^.*,/, ''));
            const buffer = new Uint8Array(bin.length);
            for (let i: number = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }
            resolve(new File([buffer.buffer], file.name, {type: file.type}));
          }catch (e) {
            resolve(file);
          }
        }, options);
      });
    });
  }
}

import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';

/**
 *  サンプル背景詳細取得
 */
export class GetSampleBackgroundDetail extends RequestBase {
  param: {
    id: number;
  };

  constructor(
    id: number,
  ) {
    super(HTTPMethod.GET, '/api/sample/sample_detail');
    this.param = {
      id: id,
    };
  }
}

export class SampleBackgroundDetail {
  id: number = -1;
  name: string = '';
  image_file: string = '';
}
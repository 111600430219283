import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {CanvasConfService} from "../../../service/canvas-conf-service";
import {ColorInfo} from "./get-colors-by-id";

/**
 * 色カテゴリ取得
 */
export class GetColorGroup extends RequestBase {

  param: {
    id: number
  };

  constructor(
    id: number = CanvasConfService.ins.blindId,
  ) {
    super(HTTPMethod.GET, '/api/colordata/color_group');
    this.param = {
      id: id,
    };
  }

}

export class ColorList {
  group_name: string = '';
  colors: ColorInfo[] = [];
}

import * as React from 'react';
import './menu-option-component.scss';
import img1 from '../../../../../../assets/img/simulator/dummy/option/01.jpg';
import img2 from '../../../../../../assets/img/simulator/dummy/option/02.jpg';
import img3 from '../../../../../../assets/img/simulator/dummy/option/03.jpg';
import img5 from '../../../../../../assets/img/simulator/dummy/option/05.jpg';
import { GetOptionColors, OptionDetail } from '../../../../../../model/api/option/get-option-colors';
import { ConnectionService } from '../../../../../../service/connection-service';
import { AxiosResponse } from 'axios';
import { CanvasConfService } from '../../../../../../service/canvas-conf-service';
import { RequestBase } from '../../../../../../model/api/request-base';
import { SlatModalService } from '../../../../../../service/slat-modal-service';
import { ColorList, GetColorGroup } from '../../../../../../model/api/color/get-color-group';
import { ColorInfo } from '../../../../../../model/api/color/get-colors-by-id';
import imgLogoNichibei from '../../../../../../assets/img/common/logo_back.png';
import { detail_blind } from '../../../../../../model/api/blind/get-sub-blind-list';

interface MenuOptionComponentProps {
  default: boolean;
  list: OptionDetail[];
}

interface MenuOptionComponentState {
  list: OptionDetail[];
  status: number;
}

const hideWoodBalanceBlindType = [
  {
    id: 57,
    type: 'レフィーナ25シングルスタイル',
  },
  {
    id: 58,
    type: 'レフィーナ45シングルスタイル',
  },
  {
    id: 59,
    type: 'レフィーナ25(ハニカム+ハニカム)',
  },
  {
    id: 60,
    type: 'レフィーナ45(ハニカム+ハニカム)',
  },
  {
    id: 61,
    type: 'レフィーナ25(ハニカム+プリーツ)',
  },
  {
    id: 62,
    type: 'レフィーナ45(ハニカム+プリーツ)',
  },
  {
    id: 104,
    type: 'レフィーナ25(ハニカム+ハニカム)',
  },
  {
    id: 105,
    type: 'レフィーナ45(ハニカム+ハニカム)',
  },
  {
    id: 106,
    type: 'レフィーナ25(ハニカム+プリーツ)',
  },
  {
    id: 107,
    type: 'レフィーナ45(ハニカム+プリーツ)',
  },
  {
    id: 24,
    type: 'クレール35F',
  },
  {
    id: 25,
    type: 'クレール35',
  },
];


export class MenuOptionComponent extends React.Component<MenuOptionComponentProps, MenuOptionComponentState> {
  private optionName: string = '';

  constructor(props: MenuOptionComponentProps) {
    super(props);
    this.state = {
      list: props.list,
      status: -1,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<MenuOptionComponentProps>, nextContext: any): void {
    if (nextProps.default) {
      this.setState({
        list: nextProps.list,
        status: -1,
      });
    }
  }

  render() {
    const {
      list,
      status,
    } = this.state;
    const {
      headbox,
      bottom,
      wide,
      wood,
      headbox_table,
      bottom_table,
      wide_table,
      wood_table,
    } = CanvasConfService.ins.detail_blind;
    const {
      accent_bottom,
      accent_side,
    } = CanvasConfService.ins.responseDataColorInfo;
    const isHeadbox: boolean = this.checkIsDisplay(+headbox);
    const isBottom: boolean = this.checkIsDisplay(+bottom);
    const isWood: boolean = this.checkIsDisplay(+wood);
    const isExceptionWood = hideWoodBalanceBlindType.find((v) => v.id === +CanvasConfService.ins.detail_blind.parent_id);
    const host = RequestBase.HOST_URL;
    return (
      <>
        {status !== -1 ? (
          <>
            <div className={'content_area_wrap cont_colorSelectArea_inner' + (SlatModalService.ins.isOpenType ? ' isDisplayBtn_openClose' : '')}>
              <div className={'scrollArea'}>
                <div className={'flex_box flex_container_wrap'}>
                  {status === 2 || status === 4 || status === 6 || this.optionName === 'ワイドラダーテープ' ? (
                    <>
                      <div
                        className={'img_wrap'}
                      >
                        <div
                          // className={'imgInner_wrap'}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x={0}
                            y={0}
                            width={50}
                            height={50}
                            viewBox="0 0 50 50"
                            onClick={() => this.setState({
                              list: [],
                              status: -1,
                            })}
                          >
                            {/*<rect*/}
                            {/*  x={0}*/}
                            {/*  y={0}*/}
                            {/*  width={48}*/}
                            {/*  height={48}*/}
                            {/*  fill={'#FFFFFF'}*/}
                            {/*/>*/}
                            {/*<line*/}
                            {/*  x1={0}*/}
                            {/*  y1={0}*/}
                            {/*  x2={49}*/}
                            {/*  y2={49}*/}
                            {/*  stroke={'#FF0000'}*/}
                            {/*/>*/}
                            <image
                              x={3}
                              y={-3}
                              width={55}
                              height={55}
                              href={imgLogoNichibei}
                              preserveAspectRatio={'none'}
                            />
                          </svg>
                        </div>
                        <div className={'align_c colorCode'} />
                      </div>
                      {
                        list.map((data, i) => (
                          <div
                            key={data.color_code + '_' + i}
                            className={'img_wrap'}
                          >
                            <div
                              className={'imgInner_wrap'}
                            >
                              {data.image_file ? (
                                <img
                                  src={host + data.image_file}
                                  onClick={() => this.handlerClickOptionColor(host + data.image_file, data)}
                                />
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x={0}
                                  y={0}
                                  width={50}
                                  height={50}
                                  viewBox="0 0 50 50"
                                  onClick={() => this.handlerClickOptionColor(data.color, data)}
                                >
                                  <rect
                                    x={0}
                                    y={0}
                                    width={48}
                                    height={48}
                                    fill={data.color.replace('0x', '#')}
                                  />
                                </svg>
                              )}
                            </div>
                            <div className={'align_c colorCode'}>{data.color_name}</div>
                          </div>
                        ))
                      }
                    </>
                  ) : (
                    <div
                      className={'img_wrap'}
                    >
                      <div
                        className={'imgInner_wrap'}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x={0}
                          y={0}
                          width={50}
                          height={50}
                          viewBox="0 0 50 50"
                          onClick={() => this.handlerClickOptionColor(CanvasConfService.ins.responseDataColorInfo.color.replace('0x', '#'), new OptionDetail())}
                        >
                          <rect
                            x={0}
                            y={0}
                            width={48}
                            height={48}
                            fill={CanvasConfService.ins.responseDataColorInfo.color.replace('0x', '#')}
                          />
                        </svg>
                      </div>
                      <div className={'align_c colorCode'}>{CanvasConfService.ins.responseDataColorInfo.color_name}</div>
                    </div>
                  )}

                  {this.optionName === 'ワイドラダーテープ' || status === 2 ? (<></>) : (
                    <div
                      className={'img_wrap'}
                    >
                      <div
                        className={'imgInner_wrap'}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x={0}
                          y={0}
                          width={50}
                          height={50}
                          viewBox="0 0 50 50"
                          onClick={() => this.handlerClickOptionColor('OFF', new OptionDetail())}
                        >
                          <rect
                            x={0}
                            y={0}
                            width={48}
                            height={48}
                            fill={'#FFFFFF'}
                          />
                          <line
                            x1={0}
                            y1={0}
                            x2={49}
                            y2={49}
                            stroke={'#FF0000'}
                          />
                        </svg>
                      </div>
                      <div className={'align_c colorCode'}>表示OFF</div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={'content_area_wrap cont_optionSelectArea_inner'}>
              <div className={'scrollArea'}>
                <div className={'imgList_col4_wrap'}>
                  <div className={'flex_box flex_container_wrap'}>

                    {isHeadbox ? (
                      <div
                        // onClick={() => this.handlerClickOption('ヘッドボックス', head_box)}
                        onClick={() => this.handlerClickOption('ヘッドボックス', headbox_table, +headbox)}
                        className={'img_wrap'}
                      >
                        <img src={img1} />
                        <div className={'optionName'}>{this.headName()}</div>
                      </div>
                    ) : (<></>)}

                    {isBottom ? (
                      <div
                        onClick={() => this.handlerClickOption('ボトムレール', bottom_table, +bottom)}
                        className={'img_wrap'}
                      >
                        <img src={img2} />
                        <div className={'optionName'}>{this.bottomName()}</div>
                      </div>
                    ) : (<></>)}

                    {wide_table ? (
                      <div
                        onClick={() => this.handlerClickOption('ワイドラダーテープ', wide_table, +wide)}
                        className={'img_wrap'}
                      >
                        <img src={img3} />
                        <div className={'optionName'}>ワイドラダーテープ</div>
                      </div>
                    ) : (<></>)}
                    {isWood && !isExceptionWood ? (
                      <div
                        onClick={() => this.handlerClickOption(CanvasConfService.ins.detail_blind.cover ? 'カバー' : 'ウッドバランス', wood_table, +wood)}
                        className={'img_wrap'}
                      >
                        <img src={img1} />
                        <div className={'optionName'}>{Number(CanvasConfService.ins.detail_blind.cover) !== 0 || (CanvasConfService.ins.blindData.blindName === '' && CanvasConfService.ins.blindData.blindType) ? 'カバー' : 'ウッドバランス'}</div>
                      </div>
                    ) : (<></>)}
                    {Number(accent_bottom) === 1 && Number(accent_side) === 1 ? (
                      <div
                        onClick={() => this.handlerClickOption('アクセントカラー', '1', 1)}
                        className={'img_wrap'}
                      >
                        <img src={img5} />
                        <div className={'optionName'}>アクセントカラー</div>
                      </div>
                    ) : (<></>)}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  private handlerClickOpenButton(flag: boolean): void {
    SlatModalService.ins.isOpen = flag;
    CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
  }

  private checkIsDisplay(id: number): boolean {
    return id === 2 || id === 3 || id === 4 || id === 5 || id === 6 || id === 9;
  }

  private handlerClickOption(name: string, value: string, status: number): void {
    this.optionName = name;
    if (value && status === 2 || status === 4 || status === 6 || name === 'ワイドラダーテープ') {
      const request = new GetOptionColors(value);
      ConnectionService.ins.connect(request)
        .then((responce: AxiosResponse<OptionDetail[]>) => {
          // 色柄追加で途中に入ってきたので暫定対応
          if (value === 'patYP01' || value === 'patYP02') {
            const colorList: string[] = [
              'V1244',
              'V1245',
              'V1246',
              'V1247',
              'V1248',
              'V1249',
              'V1911',
              'V1912',
              'V1913',
              'V1914',
              'V1915',
            ];
            const arr: OptionDetail[] = [];
            colorList.forEach((str) => {
              const index = responce.data.findIndex((v) => v.color_code === str);
              if (index !== -1) {
                arr.push({ ...responce.data[index] });
                responce.data.splice(index, 1);
              }
            });
            const v1243 = responce.data.findIndex((v) => v.color_code === 'V1243');
            if (v1243 !== -1) {
              responce.data.splice(v1243, 0, ...arr);
            }
          }
          this.setState({
            list: responce.data,
            status: status,
          });
        })
        .catch((err) => console.log(err));
    } else if (name === 'アクセントカラー') {
      const reqest = new GetColorGroup();
      ConnectionService.ins.connect(reqest)
        .then((res: AxiosResponse<ColorInfo[]>) => {
          const arr: ColorList[] = [];
          res.data.forEach((resData) => {
            const index = arr.findIndex((data) => data.group_name === resData.group_name);
            if (index !== -1) {
              arr[index].colors.push(resData);
            } else {
              arr.push({
                group_name: resData.group_name,
                colors: [resData],
              });
            }
          });
          const list = arr.find((data: {
            group_name: string,
            colors: any
          }) => data.group_name === CanvasConfService.ins.responseDataColorInfo.group_name);
          if (list) {
            SlatModalService.ins.accentDialog(list.colors);
          }
        });
    } else {
      this.setState({
        status: status,
      });
    }
  }

  private handlerClickOptionColor(colorCode: string, option: OptionDetail): void {
    const value: string = colorCode === 'OFF' ? '' : colorCode.replace('0x', '#');
    if (this.state.status === 3 && colorCode !== 'OFF') {
      CanvasConfService.ins.isSetOption.head_box = value;
      CanvasConfService.ins.isSetOption.head_box_value1 = CanvasConfService.ins.blindData.color;
      CanvasConfService.ins.isSetOption.head_box_value2 = CanvasConfService.ins.blindData.color;
      CanvasConfService.ins.updateBlindColor(SlatModalService.ins.slatColor);
      return;
    }
    if ((this.state.status === 2 || this.state.status === 4) && (CanvasConfService.ins.detail_blind.headbox || CanvasConfService.ins.detail_blind.bottom)) {
      if (value) {
        CanvasConfService.ins.isSetOption.middle = value;
        CanvasConfService.ins.isSetOption.middle_value1 = option.color_code;
        CanvasConfService.ins.isSetOption.middle_value2 = option.color_name;
        if (this.optionName === 'ヘッドボックス' && CanvasConfService.ins.isSetOption.bottom_rail) {
          CanvasConfService.ins.isSetOption.bottom_rail = value;
          CanvasConfService.ins.isSetOption.bottom_rail_value1 = option.color_code;
          CanvasConfService.ins.isSetOption.bottom_rail_value2 = option.color_name;
        } else if (this.optionName === 'ボトムレール' && CanvasConfService.ins.isSetOption.head_box) {
          CanvasConfService.ins.isSetOption.head_box = value;
          CanvasConfService.ins.isSetOption.head_box_value1 = option.color_code;
          CanvasConfService.ins.isSetOption.head_box_value2 = option.color_name;
        }
      }
    }
    if ((this.state.status === 2 || this.state.status === 4) && (!Number(CanvasConfService.ins.detail_blind.headbox) && Number(CanvasConfService.ins.detail_blind.wood) && Number(CanvasConfService.ins.detail_blind.bottom))) {
      // if ((this.state.status === 2 || this.state.status === 4) && (!CanvasConfService.ins.detail_blind.headbox && CanvasConfService.ins.detail_blind.wood && CanvasConfService.ins.detail_blind.bottom)){
      if (value) {
        if (this.optionName === 'カバー' && CanvasConfService.ins.isSetOption.bottom_rail) {
          CanvasConfService.ins.isSetOption.bottom_rail = value;
          CanvasConfService.ins.isSetOption.bottom_rail_value1 = option.color_code;
          CanvasConfService.ins.isSetOption.bottom_rail_value2 = option.color_name;
        } else if (this.optionName === 'ボトムレール' && CanvasConfService.ins.isSetOption.wood_balance) {
          CanvasConfService.ins.isSetOption.wood_balance = value;
          CanvasConfService.ins.isSetOption.wood_balance_value1 = option.color_code;
          CanvasConfService.ins.isSetOption.wood_balance_value2 = option.color_name;
        }
      }
    }
    switch (this.optionName) {
      case 'ヘッドボックス':
        CanvasConfService.ins.isSetOption.head_box = value;
        CanvasConfService.ins.isSetOption.head_box_value1 = option.color_code;
        CanvasConfService.ins.isSetOption.head_box_value2 = option.color_name;
        if (value) {
          CanvasConfService.ins.isSetOption.middle = value;
          CanvasConfService.ins.isSetOption.middle_value1 = option.color_code;
          CanvasConfService.ins.isSetOption.middle_value2 = option.color_name;
        }
        break;
      case 'ボトムレール':
        CanvasConfService.ins.isSetOption.bottom_rail = value;
        CanvasConfService.ins.isSetOption.bottom_rail_value1 = option.color_code;
        CanvasConfService.ins.isSetOption.bottom_rail_value2 = option.color_name;
        if (value) {
          CanvasConfService.ins.isSetOption.middle = value;
          CanvasConfService.ins.isSetOption.middle_value1 = option.color_code;
          CanvasConfService.ins.isSetOption.middle_value2 = option.color_name;
        }
        break;
      case 'ワイドラダーテープ':
        CanvasConfService.ins.isSetOption.wide_ladder_tape = value;
        CanvasConfService.ins.isSetOption.wide_ladder_tape_value1 = option.color_code;
        CanvasConfService.ins.isSetOption.wide_ladder_tape_value2 = option.color_name;
        break;
      case 'ウッドバランス':
      case 'カバー':
        CanvasConfService.ins.isSetOption.wood_balance = value;
        CanvasConfService.ins.isSetOption.wood_balance_value1 = option.color_code;
        CanvasConfService.ins.isSetOption.wood_balance_value2 = option.color_name;
        break;
      case 'アクセントカラー':
        CanvasConfService.ins.isSetOption.accent = value;
        CanvasConfService.ins.isSetOption.accent_value1 = option.color_code;
        CanvasConfService.ins.isSetOption.accent_value2 = option.color_name;
        break;
    }
    CanvasConfService.ins.updateBlindColor(SlatModalService.ins.slatColor);
  }

  private headName(): string {
    switch (CanvasConfService.ins.detail_blind.unique_id) {
      case '1':
        return 'ヘッドボックス';
      case '2':
        return 'ヘッドボックス';
      case '198':
        return 'ヘッドボックス';
      case '199':
        return 'ヘッドボックス';
      case '3':
        return 'ヘッドボックス';
      case '4':
        return 'ヘッドボックス';
      case '170':
        return 'ヘッドボックス';
      case '171':
        return 'ヘッドボックス';
      case '147':
        return 'ヘッドボックス';
      case '164':
        return 'ヘッドボックス';
      case '148':
        return 'ヘッドボックス';
      case '165':
        return 'ヘッドボックス';
      case '149':
        return 'ヘッドボックス';
      case '150':
        return 'ヘッドボックス';
      case '151':
        return 'ヘッドボックス';
      case '166':
        return 'ヘッドボックス';
      case '152':
        return 'ヘッドボックス';
      case '153':
        return 'ヘッドボックス';
      case '172':
        return 'ヘッドボックス';
      case '173':
        return 'ヘッドボックス';
      case '174':
        return 'ヘッドボックス';
      case '175':
        return 'ヘッドボックス';
      case '176':
        return 'ヘッドボックス';
      case '59':
        return 'ヘッドボックス';
      case '24':
        return 'ヘッドボックス';
      case '177':
        return 'ヘッドボックス';
      case '202':
        return 'ヘッドボックス';
      case '25':
        return 'ヘッドレール';
      case '34':
        return 'ヘッドレール';
      case '85':
        return 'ヘッドレール';
      case '35':
        return 'ヘッドレール';
      case '36':
        return 'ヘッドレール';
      case '26':
        return 'ヘッドレール';
      case '37':
        return 'ヘッドレール';
      case '86':
        return 'ヘッドレール';
      case '38':
        return 'ヘッドレール';
      case '39':
        return 'ヘッドレール';
      case '228':
        return 'ヘッドレール';
      case '229':
        return 'ヘッドレール';
      case '230':
        return 'ヘッドレール';
      case '231':
        return 'ヘッドレール';
      case '232':
        return 'ヘッドレール';
      case '233':
        return 'ヘッドレール';
      case '234':
        return 'ヘッドレール';
      case '27':
        return 'ヘッドレール';
      case '40':
        return 'ヘッドレール';
      case '87':
        return 'ヘッドレール';
      case '41':
        return 'ヘッドレール';
      case '42':
        return 'ヘッドレール';
      case '213':
        return 'ヘッドレール';
      case '214':
        return 'ヘッドレール';
      case '28':
        return 'ヘッドレール';
      case '43':
        return 'ヘッドレール';
      case '88':
        return 'ヘッドレール';
      case '44':
        return 'ヘッドレール';
      case '45':
        return 'ヘッドレール';
      case '215':
        return 'ヘッドレール';
      case '216':
        return 'ヘッドレール';
      case '217':
        return 'ヘッドレール';
      case '218':
        return 'ヘッドレール';
      case '219':
        return 'ヘッドレール';
      case '187':
        return 'ヘッドレール';
      case '188':
        return 'ヘッドレール';
      case '189':
        return 'ヘッドレール';
      case '190':
        return 'ヘッドレール';
      case '193':
        return 'ヘッドレール';
      case '194':
        return 'ヘッドレール';
      case '195':
        return 'ヘッドレール';
      case '196':
        return 'ヘッドレール';
      case '60':
        return 'ヘッドレール';
      case '61':
        return 'ヘッドレール';
      case '89':
        return 'ヘッドレール';
      case '62':
        return 'ヘッドレール';
      case '63':
        return 'ヘッドレール';
      case '64':
        return 'ヘッドレール';
      case '65':
        return 'ヘッドレール';
      case '90':
        return 'ヘッドレール';
      case '66':
        return 'ヘッドレール';
      case '67':
        return 'ヘッドレール';
      case '68':
        return 'ヘッドレール';
      case '69':
        return 'ヘッドレール';
      case '91':
        return 'ヘッドレール';
      case '70':
        return 'ヘッドレール';
      case '71':
        return 'ヘッドレール';
      case '29':
        return 'セットフレーム';
      case '30':
        return 'セットフレーム';
      case '183':
        return 'セットフレーム';
      case '220':
        return 'セットフレーム';
      case '31':
        return 'セットフレーム';
      case '72':
        return 'セットフレーム';
      case '73':
        return 'セットフレーム';
      case '32':
        return 'ヘッドボックス';
      case '103':
        return 'ヘッドボックス';
      case '74':
        return 'ヘッドボックス';
      case '33':
        return 'ヘッドボックス';
      case '105':
        return 'ヘッドボックス';
      case '75':
        return 'ヘッドボックス';
      case '161':
        return 'カバー';
      case '162':
        return 'カバー';
      case '108':
        return 'カバー';
      case '109':
        return 'ヘッドボックス';
      case '110':
        return 'ヘッドボックス';
      case '111':
        return 'ヘッドボックス';
      case '112':
        return 'ヘッドボックス';
      case '143':
        return 'ヘッドボックス';
      case '144':
        return 'ヘッドボックス';
      case '197':
        return 'ケース';
      case '46':
        return 'ヘッドレール';
      case '47':
        return 'ヘッドレール';
      case '92':
        return 'ヘッドレール';
      case '205':
        return 'ヘッドレール';
      case '48':
        return 'ヘッドレール';
      case '49':
        return 'ヘッドレール';
      case '50':
        return 'ヘッドレール';
      case '51':
        return 'ヘッドレール';
      case '93':
        return 'ヘッドレール';
      case '207':
        return 'ヘッドレール';
      case '52':
        return 'ヘッドレール';
      case '53':
        return 'ヘッドレール';
      case '235':
        return 'ヘッドレール';
      case '236':
        return 'ヘッドレール';
      case '237':
        return 'ヘッドレール';
      case '238':
        return 'ヘッドレール';
      case '239':
        return 'ヘッドレール';
      case '240':
        return 'ヘッドレール';
      case '241':
        return 'ヘッドレール';
      case '242':
        return 'ヘッドレール';
      case '54':
        return 'ヘッドレール';
      case '55':
        return 'ヘッドレール';
      case '94':
        return 'ヘッドレール';
      case '209':
        return 'ヘッドレール';
      case '56':
        return 'ヘッドレール';
      case '57':
        return 'ヘッドレール';
      case '76':
        return 'ヘッドレール';
      case '77':
        return 'ヘッドレール';
      case '95':
        return 'ヘッドレール';
      case '78':
        return 'ヘッドレール';
      case '79':
        return 'ヘッドレール';
      case '80':
        return 'ヘッドレール';
      case '81':
        return 'ヘッドレール';
      case '96':
        return 'ヘッドレール';
      case '82':
        return 'ヘッドレール';
      case '83':
        return 'ヘッドレール';
      case '221':
        return 'ヘッドレール';
      case '222':
        return 'ヘッドレール';
      case '113':
        return 'ヘッドレール';
      case '114':
        return 'ヘッドレール';
      case '115':
        return 'ヘッドレール';
      case '211':
        return 'ヘッドレール';
      case '116':
        return 'ヘッドレール';
      case '117':
        return 'ヘッドレール';
      case '223':
        return 'ヘッドレール';
      case '224':
        return 'ヘッドレール';
      case '225':
        return 'ヘッドレール';
      case '226':
        return 'ヘッドレール';
      case '227':
        return 'ヘッドレール';
      case '97':
        return 'ヘッドレール';
      case '98':
        return 'ヘッドレール';
      case '99':
        return 'ヘッドレール';
      case '100':
        return 'ヘッドレール';
      case '101':
        return 'ヘッドレール';
      case '118':
        return 'ヘッドボックス';
      case '119':
        return 'ヘッドボックス';
      case '200':
        return 'ヘッドボックス';
      case '201':
        return 'ヘッドボックス';
      case '120':
        return 'ヘッドボックス';
      case '121':
        return 'ヘッドボックス';
      case '203':
        return 'ヘッドボックス';
      case '204':
        return 'ヘッドボックス';
      case '243':
        return 'ヘッドボックス';
      case '244':
        return 'ヘッドボックス';
      case '58':
        return 'ヘッドボックス';
      case '107':
        return 'ヘッドボックス';
      case '84':
        return 'ヘッドボックス';
      case '141':
        return 'ヘッドボックス';
      case '142':
        return 'ヘッドボックス';
      case '145':
        return 'ヘッドボックス';
      case '146':
        return 'ヘッドボックス';
      case '245':
        return 'カバー';
      case '246':
        return 'セットフレーム';
      case '247':
        return 'カバー';
      case '248':
        return 'セットフレーム';
      default:
        return 'ヘッドボックス';
    }
  }

  private bottomName(): string {
    switch (CanvasConfService.ins.detail_blind.unique_id) {
      case '1':
        return 'ボトムレール';
      case '2':
        return 'ボトムレール';
      case '198':
        return 'ボトムレール';
      case '199':
        return 'ボトムレール';
      case '3':
        return 'ボトムレール';
      case '4':
        return 'ボトムレール';
      case '170':
        return 'ボトムレール';
      case '171':
        return 'ボトムレール';
      case '147':
        return 'ボトムレール';
      case '164':
        return 'ボトムレール';
      case '148':
        return 'ボトムレール';
      case '165':
        return 'ボトムレール';
      case '149':
        return 'ボトムレール';
      case '150':
        return 'ボトムレール';
      case '151':
        return 'ボトムレール';
      case '166':
        return 'ボトムレール';
      case '152':
        return 'ボトムレール';
      case '153':
        return 'ボトムレール';
      case '172':
        return 'ボトムレール';
      case '173':
        return 'ボトムレール';
      case '174':
        return 'ボトムレール';
      case '175':
        return 'ボトムレール';
      case '176':
        return 'ボトムレール';
      case '59':
        return 'ボトムレール';
      case '24':
        return 'ボトムレール';
      case '177':
        return 'ボトムレール';
      case '202':
        return 'ボトムレール';
      case '29':
        return 'ウエイトバー';
      case '30':
        return 'ウエイトバー';
      case '183':
        return 'ウエイトバー';
      case '220':
        return 'ウエイトバー';
      case '31':
        return 'ウエイトバー';
      case '72':
        return 'ウエイトバー';
      case '73':
        return 'ウエイトバー';
      case '32':
        return 'ボトムレール';
      case '103':
        return 'ボトムレール';
      case '74':
        return 'ボトムレール';
      case '33':
        return 'ボトムレール';
      case '105':
        return 'ボトムレール';
      case '75':
        return 'ボトムレール';
      case '161':
        return 'ウエイトバー';
      case '162':
        return 'ウエイトバー';
      case '108':
        return 'ウエイトバー';
      case '109':
        return 'ボトムレール';
      case '110':
        return 'ボトムレール';
      case '111':
        return 'ボトムレール';
      case '112':
        return 'ボトムレール';
      case '143':
        return 'ボトムレール';
      case '144':
        return 'ボトムレール';
      case '197':
        return 'ボトムレール';
      case '118':
        return 'ボトムレール';
      case '119':
        return 'ボトムレール';
      case '200':
        return 'ボトムレール';
      case '201':
        return 'ボトムレール';
      case '120':
        return 'ボトムレール';
      case '121':
        return 'ボトムレール';
      case '203':
        return 'ボトムレール';
      case '204':
        return 'ボトムレール';
      case '243':
        return 'ボトムレール';
      case '244':
        return 'ボトムレール';
      case '58':
        return 'ボトムレール';
      case '107':
        return 'ボトムレール';
      case '84':
        return 'ボトムレール';
      case '141':
        return 'ボトムレール';
      case '142':
        return 'ボトムレール';
      case '145':
        return 'ボトムレール';
      case '146':
        return 'ボトムレール';
      case '245':
        return 'ウエイトバー';
      case '246':
        return 'ウエイトバー';
      case '247':
        return 'ウエイトバー';
      case '248':
        return 'ウエイトバー';
      default:
        return 'ボトムレール';
    }
  }
}

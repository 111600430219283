
export enum DateFormat {
  YYYY,
  YYYYMMDD,
  YYYYMMDD_HHmm,
  YYYYMMDD_HHmmSS,
  MMDD,
  MMDD_HHmm,
  MMDD_HHmmSS,
  HHmm,
  HHmmSS,
  JPN_YYYYMD,
  JPN_YYYYMMDD,
}

export class DateFormatter {

  /**
   * date -> str
   * @param {Date} date
   * @param {DateFormat} format
   * @param {string} dateSplitStr
   * @return {string}
   */
  static date2str(date: Date, format: DateFormat = DateFormat.YYYYMMDD, dateSplitStr: string = '/'): string {
    if (!date) {
      //console.log('date is null : ', date);
      return '';
    }
    const YYYY: string = date.getFullYear() + '';
    const MM: string = ('0' + String(date.getMonth() + 1)).slice(-2);
    const DD: string = ('0' + String(date.getDate())).slice(-2);
    const HH: string = ('0' + String(date.getHours())).slice(-2);
    const mm: string = ('0' + String(date.getMinutes())).slice(-2);
    const SS: string = ('0' + String(date.getSeconds())).slice(-2);
    switch (format) {
      case DateFormat.YYYY:
        return YYYY;
      case DateFormat.YYYYMMDD:
        return `${YYYY}${dateSplitStr}${MM}${dateSplitStr}${DD}`;
      case DateFormat.YYYYMMDD_HHmm:
        return `${YYYY}${dateSplitStr}${MM}${dateSplitStr}${DD} ${HH}:${mm}`;
      case DateFormat.YYYYMMDD_HHmmSS:
        return `${YYYY}${dateSplitStr}${MM}${dateSplitStr}${DD} ${HH}:${mm}:${SS}`;
      case DateFormat.MMDD:
        return `${MM}${dateSplitStr}${DD}`;
      case DateFormat.MMDD_HHmm:
        return `${MM}${dateSplitStr}${DD} ${HH}:${mm}`;
      case DateFormat.MMDD_HHmmSS:
        return `${MM}${dateSplitStr}${DD} ${HH}:${mm}:${SS}`;
      case DateFormat.HHmm:
        return `${HH}:${mm}`;
      case DateFormat.HHmmSS:
        return `${HH}:${mm}:${SS}`;
      case DateFormat.JPN_YYYYMD:
        return `${YYYY}年${MM[0]==='0'?MM[1]:MM}月${DD[0]==='0'?DD[1]:DD}日`;
      case DateFormat.JPN_YYYYMMDD:
        return `${YYYY}年${MM}月${DD}日`;
      default:
        console.error('this format is undefined !! : ', format);
        return 'error';
    }
  }

  /**
   * str -> date
   * 対応フォーマット
   *  2019/01/23
   *  2019-01-23
   *  2019年1月23日
   * @param str
   * @return {Date | null}
   */
  static str2date(str: string): Date | null {
    const replacedStr: string = str
      .replace(/\//g, '-')
      .replace(/年/g, '-')
      .replace(/月/g, '-')
      .replace(/日/g, '')
      .replace(/\s/g, 'T');
    const yearStr: string = ('00' + replacedStr.split('-')[0]).slice(-2);
    const monthStr: string = ('00' + replacedStr.split('-')[1]).slice(-2);
    const dateStr: string = ('00' + replacedStr.split('-')[2]).slice(-2);
    const date: Date = new Date(`${yearStr}-${monthStr}-${dateStr}`);
    /*console.group('str2date()');
    console.log('str : ', str);
    console.log('str : ', replacedStr);
    console.log('y m d : ', yearStr, monthStr, dateStr);
    console.log('date : ', date);
    console.groupEnd();*/
    return (str ? (
      date
    ) : (
      null
    ));
  }

}

import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {CanvasConfService} from "../../../service/canvas-conf-service";

/**
 * ブラインド詳細取得
 */
export class GetBlindDetail extends RequestBase {

  param: {
    id: number;
  };

  constructor(
    id: number = CanvasConfService.ins.blindId,
  ) {
    super(HTTPMethod.GET, '/api/blind/blind_detail');
    this.param = {
      id: id,
    };
  }
}

export class BlindDetail {
  id: number = -1;
  sub_id: number = 1;
  type: string = '';
  type_code: string = '';
  type_image: string = '';
  order_type: string = '';
  color_change_type: string = '';
  open_flag: string = '';
  type_image_file: string = '';
  des_image: string | null = null;
  cover: string = '';
}

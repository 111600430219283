import {ColorInfo} from '../model/api/color/get-colors-by-id';
import {BlindDetail} from '../model/api/blind/get-blind-detail';
import {detail_blind} from '../model/api/blind/get-sub-blind-list';
import {OptionDetail} from '../model/api/option/get-option-colors';

/**
 * 必要な情報を保持
 */
export class CanvasConfService {
  private static isReset: boolean = false;
  private static _ins: CanvasConfService;
  static get ins(): CanvasConfService {
    if (!this._ins) {
      this._ins = new CanvasConfService();
    }
    return this._ins;
  }

  private constructor() {
    CanvasConfService.isReset = true;
  }

  static reset(): void {
    if(this.isReset){
      this._ins = new CanvasConfService();
    }
  }

  /**
   * パーツ色（アルファ？）
   */
  get HEADBOX_COLOR(): Number {
    return 0.7
  };

  get BALANCE_COLOR(): Number {
    return 0.7;
  }

  get MIDDLEBAR_COLOR(): Number {
    return 0.7;
  };

  get WIDETAPE_COLOR(): Number {
    return 0.6;
  };

  get BOTTOM_TREND_COLOR(): Number {
    return 0.8;
  };

  get BOTTOMRAIL_COLOR(): Number {
    return 0.7;
  };

  get SLAT_COLOR(): Number {
    return 0.6;
  };

  //** 変数
  /**
   * svgを操作するdivのid
   */
  private _svgAreaId: string = 'svg-area';
  get svgAreaId(): string {
    return this._svgAreaId;
  }

  /**
   * ブラインドと背景を描画するcanvasのid
   */
  private _canvasId: string = 'main-canvas';
  get canvasId(): string {
    return this._canvasId;
  }

  /**
   * 背景画像のパス
   */
  private _backgroundImg: string = '';
  set backgroundImg(value: string) {
    this._backgroundImg = value;
  }

  get backgroundImg(): string {
    return this._backgroundImg;
  }

  /**
   * スラットの画像
   */
  private _slatImg: string = '';
  set slatImg(value: string) {
    this._slatImg = value;
  }

  get slatImg(): string {
    return this._slatImg;
  }

  /**
   * 選択中のブラインドid
   */
  private _blindId: number = 1;
  get blindId(): number {
    return this._blindId;
  }

  set blindId(value: number) {
    this._blindId = value;
  }

  /**
   * 内部的に保持する必要があるブラインド情報
   *
   * ブラインドスペックとpdf生成に必要な情報を保持
   */
  private _blindData: BlindData = new BlindData();
  get blindData(): BlindData {
    return this._blindData;
  }

  /**
   * オプションパーツの画像パス
   *
   * オプションパーツのみ予めbase64エンコードしたパス
   */
  private _optionImg: OptionImg = new OptionImg();
  get optionImg(): OptionImg {
    return this._optionImg;
  }

  set optionImg(value: OptionImg) {
    this._optionImg = value;
  }

  /**
   * オプションパーツを表示するかのフラグ
   */
  private _isSetOption: IsSetOption = new IsSetOption();
  get isSetOption(): IsSetOption {
    return this._isSetOption;
  }

  set isSetOption(value: IsSetOption) {
    this._isSetOption = value;
  }

  /**
   * センターレース保持
   */
  private _centerRaceArr: OptionDetail[] = [];
  get centerRaceArr(): OptionDetail[] {
    return this._centerRaceArr;
  }
  set centerRaceArr(value: OptionDetail[]) {
    this._centerRaceArr = value;
  }
  private _centerRace: OptionDetail = new OptionDetail();
  get centerRace(): OptionDetail {
    return this._centerRace;
  }
  set centerRace(value: OptionDetail) {
    this._centerRace = value;
  }
  private _centerRaceBase: OptionDetail = new OptionDetail();
  get centerRaceBase(): OptionDetail {
    return this._centerRaceBase;
  }
  set centerRaceBase(value: OptionDetail) {
    this._centerRaceBase = value;
  }

  /**
   * 取得した色情報保持
   *
   * 破綻するのでまるまる持たせる
   */
  private _responseDataColorInfo: ColorInfo = new ColorInfo();
  get responseDataColorInfo(): ColorInfo {
    return this._responseDataColorInfo;
  }

  set responseDataColorInfo(value: ColorInfo) {
    this._responseDataColorInfo = value;
  }

  /**
   * 取得したブラインド情報保持
   *
   * 上に同じ
   */
  private _responseDataBlindDetail: BlindDetail = new BlindDetail();
  get responseDataBlindDetail(): BlindDetail {
    return this._responseDataBlindDetail;
  }

  set responseDataBlindDetail(value: BlindDetail) {
    this._responseDataBlindDetail = value;
  }

  /**
   * 色変更が上下で分割されているブラインドの下半分の情報保持用
   */
  private _underSlatData: ColorInfo = new ColorInfo();
  get underSlatData(): ColorInfo {
    return this._underSlatData;
  }

  set underSlatData(value: ColorInfo) {
    this._underSlatData = value;
  }

  /**
   * ブラインドタイプリストから取得したdetail_blindを直接保持
   *
   * 連携取れないのでdetail_blindごと保持
   */
  private _detail_blindArr: detail_blind[] = [];
  get detail_blindArr(): detail_blind[] {
    return this._detail_blindArr;
  }

  set detail_blindArr(value: detail_blind[]) {
    this._detail_blindArr = value;
  }

  /**
   * 開閉タイプから決定したdetail_blindを保持
   */
  private _detail_blind: detail_blind = new detail_blind();
  get detail_blind(): detail_blind {
    return this._detail_blind;
  }

  set detail_blind(value: detail_blind) {
    this._detail_blind = value;
  }

  private _centerRaceColorArr: OptionDetail[] = [];

  get centerRaceColorArr(): OptionDetail[] {
    return this._centerRaceColorArr;
  }

  set centerRaceColorArr(value: OptionDetail[]) {
    this._centerRaceColorArr = value;
  }

//**

  //**関数
  /**
   * 画面左のブラインド部分更新処理をセット
   *
   * update関数で他処理と同時に一括で実行
   */
  private _updateDrawContainer: any;
  set updateDrawContainer(value: any) {
    this._updateDrawContainer = value;
  }

  /**
   * 画面右上のブラインドスペック表記情報更新処理をセット
   *
   * 必要に応じて呼び出し
   */
  private _updateBlindSpec: any = () => {
    return;
  };
  set updateBlindSpec(value: any) {
    this._updateBlindSpec = value;
  }

  get updateBlindSpec(): any {
    return this._updateBlindSpec;
  }

  /**
   * ブラインドの色更新処理をセット
   *
   * 必要に応じて呼び出し
   */
  private _updateBlindColor: any;
  set updateBlindColor(value: any) {
    this._updateBlindColor = value;
  }

  get updateBlindColor(): any {
    return this._updateBlindColor;
  }

  /**
   * ブラインドの画像情報を更新（色柄項目）
   *
   * 必要に応じて呼び出し
   */
  private _updateBlindImg: any;
  get updateBlindImg(): any {
    return this._updateBlindImg;
  }

  set updateBlindImg(value: any) {
    this._updateBlindImg = value;
  }

  /**
   * ブラインド更新処理をセット
   *
   * 必要に応じて呼び出し
   */
  private _updateBlind: any;
  set updateBlind(value: any) {
    this._updateBlind = value;
  }

  get updateBlind(): any {
    return this._updateBlind;
  }

  /**
   * 更新処理を一括で実'施予定
   */
  public update(): void {
    this._updateDrawContainer();
    // this._updateBlindSpec();
  }

  /**
   * DBから取得した文字列を元にアルファを返す
   */
  public LouverAlpha(alpha: string = CanvasConfService.ins.responseDataColorInfo.alpha): number {
    switch (alpha) {
      case '遮光':
        return 1;
      case 'シークレット':
        return 0.99;
      case 'ミディアムシークレット':
        return 0.98;
      case 'ミディアム':
        return 0.97;
      case 'シースルー':
        return 0.82;
      default:
        return 0.4;
    }
  }

  /**
   * loading表示用
   */
  public static isLoading: boolean = false;
  private static _loading: (flag: boolean) => void = (flag) => {
    return;
  };
  public static get loading(): (flag: boolean) => void {
    return this._loading;
  }
  public static set loading(value: (flag: boolean) => void) {
    this._loading = value;
  }

  private static _isSave: (flag: boolean) => void;
  static get isSave(): (flag: boolean) => void {
    return this._isSave;
  }
  static set isSave(value: (flag: boolean) => void) {
    this._isSave = value;
  }

  private _outputPdf: (orderSheet: 0 | 1) => void = (orderSheet: 0 | 1) => {
    return;
  };
  get outputPdf(): (orderSheet: 0 | 1) => void {
    return this._outputPdf;
  }
  set outputPdf(value: (orderSheet: 0 | 1) => void) {
    this._outputPdf = value;
  }

  private _saveImg: () => Promise<void> = () => new Promise((resolve) => resolve);
  get saveImg(): () => Promise<void> {
    return this._saveImg;
  }
  set saveImg(value: () => Promise<void>) {
    this._saveImg = value;
  }

  private static _isColorChangeConfirm: (flag: boolean, callback: () => void) => void = () => {};
  static get isColorChangeConfirm(): (flag: boolean, callback: () => void) => void {
    return this._isColorChangeConfirm;
  }
  static set isColorChangeConfirm(value: (flag: boolean, callback: () => void) => void) {
    this._isColorChangeConfirm = value;
  }

  accentColor: AccentColorModel = new AccentColorModel();

  /**
   * 使い方初回表示用
   */
  public static _isHowTo: () => boolean = () => {
    return true;
  };
  public static get isHowTo(): () => boolean {
    return () => {return false};
    // if (localStorage.getItem('window-simulator')) {
    //   return () => {
    //     return false;
    //   };
    // }
    // localStorage.setItem('window-simulator', JSON.stringify(new Object()));
    // return this._isHowTo;
  }

  public static set isHowTo(value: () => boolean) {
    this._isHowTo = value;
  }

  public isVertical: boolean = false;
  public rotateListener: any = null;

//**
}

export class BlindData {
  public blindName: string = '';
  public isStandardBlind: boolean = true;
  public blindType: string = '';
  public blindColorCode: string = '';
  public displayImgPath: string | null = null;
  public displayImgPathUnder: string | null = null;
  public blindWidth: number = 0;
  public blindHeight: number = 0;
  public blindOpenType: string = '';
  public buhin: Buhin[] = [];
  public Colors: Colors[] = [];
  public centerColor: CenterColor[] = [];
  public color: string = '';
  public windowSizeWidth: number = 1800;
  public windowSizeHeight: number = 1800;
  public imgSizeWidth: number = 1;
  public imgSizeHeight: number = 1;
  public id: string = '';
  public sub_id: string = '';
}

export interface Buhin {
  name: string;
  color: string;
  color_name: string;
}

export interface Colors {
  name: string;
  color: string;
  color_code: string;
}

export interface CenterColor {
  name: string;
  color: string;
}

export class OptionImg {
  public constructor(
    public head_box: string = '',
    public bottom_rail: string = '',
    public wide_ladder_tape: string = '',
    public wood_balance: string = '',
    public accent: string = '',
    public cover: string = '',
  ) {
  }
}

export class IsSetOption {
  public constructor(
    public head_box: string = '',
    public head_box_value1: string = '',
    public head_box_value2: string = '',
    public bottom_rail: string = '',
    public bottom_rail_value1: string = '',
    public bottom_rail_value2: string = '',
    public wide_ladder_tape: string = '',
    public wide_ladder_tape_value1: string = '',
    public wide_ladder_tape_value2: string = '',
    public wood_balance: string = '',
    public wood_balance_value1: string = '',
    public wood_balance_value2: string = '',
    public accent: string = '',
    public accent_value1: string = '',
    public accent_value2: string = '',
    public cover: string = '',
    public cover_value1: string = '',
    public cover_value2: string = '',
    public middle: string = '',
    public middle_value1: string = '',
    public middle_value2: string = '',
  ) {
  }

}

export class AccentColorModel {
  constructor(
    public color: ColorInfo = new ColorInfo(),
    public acctentType: 'left' | 'right' | 'both' | 'none' = 'none',
  ){}
}

import * as React from 'react';
import {ColorGenerator} from '../../../../../../util/color-generator';
import {CanvasConfService} from '../../../../../../service/canvas-conf-service';
import {DummyImgGetter} from '../../../../../../util/dummy-img-getter';
import {SampleBackgroundComponent} from './sample-background-component/sample-background-component';
import './menu-background-component.scss';
import btnBackGround1 from '../../../../../../assets/img/simulator/background_btn_1.png';
import btnBackGround1Ret from '../../../../../../assets/img/simulator/background_btn_1@2x.png';
import btnBackGround2 from '../../../../../../assets/img/simulator/background_btn_2.png';
import btnBackGround2Ret from '../../../../../../assets/img/simulator/background_btn_2@2x.png';
import bgControllBtnLeft from '../../../../../../assets/img/simulator/bg_controll_btn_left.svg';
import bgControllBtnRight from '../../../../../../assets/img/simulator/bg_controll_btn_right.svg';
import {ReactElement} from 'react';
import {DrawManager} from '../../../../../../manager/draw-manager';
import {ImgRotater} from '../../../../../../util/img-rotater';

interface MenuBackgroundComponentProps {
  sampleBackgroundComponent: ReactElement | null;
}

interface MenuBackgroundComponentState {
  sampleBackgroundComponent: ReactElement | null;
  isAnnounce: boolean;
}

export class MenuBackgroundComponent extends React.Component<MenuBackgroundComponentProps, MenuBackgroundComponentState> {

  constructor(props: MenuBackgroundComponentProps) {
    super(props);
    this.state = {
      sampleBackgroundComponent: props.sampleBackgroundComponent,
      isAnnounce: false,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<MenuBackgroundComponentProps>, nextContext: any): void {
    this.setState({
      sampleBackgroundComponent: nextProps.sampleBackgroundComponent,
    });
  }

  render() {
    const {sampleBackgroundComponent, isAnnounce} = this.state;
    /*
      サンプルから選択ボタン押下時は
      SampleBackgroundComponentを表示です。
    */
    if (sampleBackgroundComponent) {
      return sampleBackgroundComponent;
    } else {
      return (
        <div className={'content_area_wrap cont_backGroundArea_inner'}>
          <div className={'main_btn_wrap'}>
            <button
              onClick={() => this.handlerClickAlbumButton()}
              className={'reset marg_auto'}
            >
              <img
                src={btnBackGround1}
                srcSet={`${btnBackGround1} 1x, ${btnBackGround1Ret} 2x`}
                alt={'アルバムから選択'}
              />
            </button>

            <div className={'divide40'}/>

            <button
              onClick={() => this.handlerClickSampleButton()}
              className={'reset marg_auto'}
            >
              <img
                src={btnBackGround2}
                srcSet={`${btnBackGround2} 1x, ${btnBackGround2Ret} 2x`}
                alt={'サンプルから選択'}
              />
            </button>
          </div>

          <div className="bgControllBtn_wrap bgControllBtn_toTablet_wrap  flex_box flex_align_center flex_content_center">
            <div>
              <button
                type={'button'}
                className={'reset'}
                onClick={() => {
                  // DrawManager.rotation -= 90;
                  // DrawManager.rotation %= 360;
                  this.rotate(DrawManager.rotation - 90);
                }}
              ><img src={bgControllBtnLeft} alt={'左回転'} /></button></div>
            <div>
              <button
                type={'button'}
                className={'reset'}
                onClick={() => {
                  // DrawManager.rotation += 90;
                  // DrawManager.rotation %= 360;
                  this.rotate(DrawManager.rotation + 90);
                }}
              ><img src={bgControllBtnRight} alt={'右回転'} /></button></div>
          </div>

          <div className={'divide30'}/>

          <ul className={'bottomText'}>
            <li>※写真を撮影またはアルバムから写真を選択して、ブラインドの画像と合成してシミュレートします。</li>
            <li>※本アプリにて表示される色柄の模様や、印刷位置、オプション、透過度などは実物と異なる場合があります。</li>
            <li>※実際の商品仕様についてはニチベイ商品販売店、デジタルブック等でご確認ください。</li>
            {/*{!isAnnounce ? (<><li className={'announceText'}>※特定の色柄は現在選択できません。</li></>) : (<></>)}*/}
          </ul>

          <div className="bgControllBtn_wrap flex_box flex_align_center flex_content_center">
            <div>
              <button
                type={'button'}
                className={'reset'}
                onClick={() => {
                  // DrawManager.rotation -= 90;
                  // DrawManager.rotation %= 360;
                  this.rotate(DrawManager.rotation - 90);
                }}
              ><img src={bgControllBtnLeft} alt={'左回転'} /></button></div>
            <div>
              <button
                type={'button'}
                className={'reset'}
                onClick={() => {
                  // DrawManager.rotation += 90;
                  // DrawManager.rotation %= 360;
                  this.rotate(DrawManager.rotation + 90);
                }}
              ><img src={bgControllBtnRight} alt={'右回転'} /></button></div>
          </div>
        </div>
      );
    }
  }

  private handlerClickAlbumButton(): void {
    this.handlerClickTakeImg();
  }

  private handlerClickSampleButton(): void {
    this.setState({
      sampleBackgroundComponent: <SampleBackgroundComponent/>,
    });
  }

  private handlerClickTakeImg(): void {
    const inputElement: HTMLInputElement = document.createElement('input');
    inputElement.type = 'file';
    // inputElement.accept = 'image/png, image/jpeg, image/jpg';
    inputElement.accept = 'image/png, image/jpeg, image/jpg';
    inputElement.className = 'hideEle';
    inputElement.setAttributeNS(null, 'style', 'display: none;');
    inputElement.onchange = (e: any) => {
      document.body.removeChild(inputElement);
      this.readImgFile(e.target.files[0]);
    };
    document.body.appendChild(inputElement);
    inputElement.value = '';
    inputElement.click();
  }

  private async readImgFile(file: File): Promise<void> {
    const reader: FileReader = new FileReader();
    // reader.readAsDataURL(file);
    // if(navigator.userAgent.toLowerCase().indexOf('edge') !== -1 && navigator.userAgent.toLowerCase().indexOf('chrome') === -1){
    // }
    // const rotateFile = navigator.userAgent.toLowerCase().indexOf('edge') !== -1 && navigator.userAgent.toLowerCase().indexOf('chrome') === -1 ? await ImgRotater.rotate(file) : file;

    // const rotateFile = await ((): Promise<File> => {
    //   return new Promise(async (resolve: (res: File) => void): Promise<void> => {
    //     try {
    //       const rotateFile = await ImgRotater.rotate(file);
    //       resolve(rotateFile);
    //     }catch (e) {
    //       resolve(file);
    //     }
    //   });
    // })();
    try {
      const rotateFile = await ImgRotater.rotate(file);
      reader.readAsDataURL(rotateFile);
      reader.onload = () => {
        if (reader.error) {
          console.error(reader.error);
          return;
        }
        CanvasConfService.loading(true);
        DrawManager.currentPos = {x: 0, y: 0};
        CanvasConfService.ins.backgroundImg = reader.result as string;
        DrawManager.rotation = 0;
        CanvasConfService.ins.update();
        // this.setState({
        //   value: reader.result as string,
        // });
      };
    } catch (e) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.error) {
          console.error(reader.error);
          return;
        }
        CanvasConfService.loading(true);
        DrawManager.currentPos = {x: 0, y: 0};
        CanvasConfService.ins.backgroundImg = reader.result as string;
        CanvasConfService.ins.update();
        // this.setState({
        //   value: reader.result as string,
        // });
      };
    }
    // reader.readAsDataURL(rotateFile);
    // reader.onload = () => {
    //   if (reader.error) {
    //     console.error(reader.error);
    //     return;
    //   }
    //   CanvasConfService.loading(true);
    //   DrawManager.currentPos = {x: 0, y: 0};
    //   CanvasConfService.ins.backgroundImg = reader.result as string;
    //   CanvasConfService.ins.update();
    //   // this.setState({
    //   //   value: reader.result as string,
    //   // });
    // };
  }

  private rotate(rad: number): void {
    rad = (rad + 360) % 360;
    let orient = 0;
    switch (rad) {
      case 0:
        orient = 1;
        break;
      case 90:
        orient = 6;
        break;
      case 180:
        orient = 3;
        break;
      case 270:
        orient = 8;
        break;
    }
    DrawManager.getFileFromUrl(CanvasConfService.ins.backgroundImg, orient)
      .then((res) => {
        DrawManager.currentPos = {x: 0, y: 0};
        CanvasConfService.ins.backgroundImg = res;
        CanvasConfService.ins.update();
      });
  }
}

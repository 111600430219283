import * as React from 'react';
import {ReactElement} from 'react';
import './save-image-component.scss';
import btnAddSheet from '../../../assets/img/modal/btn_saveImage_addSheet.png';
import btnAddSheetRet from '../../../assets/img/modal/btn_saveImage_addSheet@2x.png';
import btnSaveImage from '../../../assets/img/modal/btn_saveImage_saveImage.png';
import btnSaveImageRet from '../../../assets/img/modal/btn_saveImage_saveImage@2x.png';
import iconCheckBox from '../../../assets/img/modal/icon_checkbox.png';
import iconCheckBoxRet from '../../../assets/img/modal/icon_checkbox@2x.png';
import iconCheckBoxChecked from '../../../assets/img/modal/icon_checkbox_checked.png';
import iconCheckBoxCheckedRet from '../../../assets/img/modal/icon_checkbox_checked@2x.png';
import btnCancel from '../../../assets/img/modal/btn_saveImage_cancel.png';
import btnCancelRet from '../../../assets/img/modal/btn_saveImage_cancel@2x.png';
import saveImageThumb from '../../../assets/img/modal/saveImageDialogThumb.jpg';
import saveImageThumbRet from '../../../assets/img/modal/saveImageDialogThumb@2x.jpg';
import {CanvasConfService} from '../../../service/canvas-conf-service';
import {ExportImgManager} from '../../../manager/export-img-manager';
import {Pos} from '../../../model/pos';
import {DrawManager} from '../../../manager/draw-manager';

interface SaveImageComponentProps {
  isEnableSaveImage: boolean,
}

interface SaveImageComponentState {
  step: 1 | 2 | 3 | 99,
  isCheck: boolean,
}

export class SaveImageComponent extends React.Component<SaveImageComponentProps, SaveImageComponentState> {

  private loading: boolean = false;

  constructor(props: SaveImageComponentProps) {
    super(props);
    const agent = window.navigator.userAgent.toLowerCase();
    this.state = {
      step: 1,
      // step: 1,
      isCheck: false,
    };
  }

  componentWillMount(): void {
    const agent = window.navigator.userAgent.toLowerCase();
    this.setState({
      step: 1,
      // step: 1,
      isCheck: false,
    });
    this.loading = false;
  }

  render() {
    const {
      step,
      isCheck,
    } = this.state;
    return (
      <div className={'dialog_bodyInner'}>
        <div className={'dialog_title flex_box flex_align_center flex_space_between'}>
          <div className={'dialog_titleInner'}>画像保存 / イメージシート</div>
          <div className={'right_btn_wrap'}>
            <button
              type={'button'}
              className={'reset'}
              onClick={() => this.handlerClickCancel()}
            >&#10005;</button>
          </div>
        </div>

        {step === 99 ? (
          <div className={'dialog_cont'}>
            <div className={'dialog_contInner'}>

              <div className={'dialog_message flex_box flex_align_center flex_content_center'}>
                <div>エラーが発生しました。<br />しばらく時間をあけて再度お試しください。</div>
              </div>
              <div className={'divide30'}/>

              <div className={'dialog_btn flex_box flex_align_center flex_content_center'}>
                {/*<div>*/}
                {/*  <button*/}
                {/*    type={'button'}*/}
                {/*    className={'reset'}*/}
                {/*    onClick={() => this.handlerClickCancel()}*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src={btnCancel}*/}
                {/*      srcSet={`${btnCancel} 1x, ${btnCancelRet} 2x`}*/}
                {/*      alt={'キャンセル'}*/}
                {/*    />*/}
                {/*  </button>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <button*/}
                {/*    type={'button'}*/}
                {/*    className={'reset'}*/}
                {/*    onClick={() => this.handlerClickStep2()}*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src={btnAddSheet}*/}
                {/*      srcSet={`${btnAddSheet} 1x, ${btnAddSheetRet} 2x`}*/}
                {/*      alt={'エラー'}*/}
                {/*    />*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
              {/* //.dialog_btn */}

            </div>
          </div>
        ) : (<></>)}

        {step === 1 ? (
          <div className={'dialog_cont'}>
            <div className={'dialog_contInner'}>

              {/* **************** 保存ダイアログ パターン1 **************** */}
              <div className={'dialog_message'}>
                <div className={'align_c'}>シミュレートを終了して画像を保存します。</div>

                <div className={'divide20 spMin_20'}/>

                <div className={'red_message'}>

                  <div className={'textItem_wrap flex_box'}>
                    <div>※</div>
                    <div>
                      ブラウザのポップアップブロック機能が有効になっているとイメージシートを作成できません。<br />
                      ポップアップブロック機能は解除してからご利用ください。<br />
                      (iOSの場合は、設定 ＞ safari ＞ ポップアップブロック が有効になっていないかご確認ください)<br />
                    </div>
                  </div>

                  <div className={'divide20 spMin_10'}/>

                  <div className={'textItem_wrap flex_box'}>
                    <div>※</div>
                    <div>
                      iPhoneの場合は新しいタブに画像が表示されますので、長押しして画像を保存してください。
                    </div>
                  </div>

                  <div className={'divide20 spMin_10'}/>

                  <div className={'textItem_wrap flex_box'}>
                    <div>※</div>
                    <div>
                      入力された寸法によっては、製作できない場合がございます。<br/>
                      製作可能寸法は商品webページでご確認ください。
                    </div>
                  </div>

                  <div className={'divide20 spMin_10'}/>

                  <div className={'textItem_wrap flex_box'}>
                    <div>※</div>
                    <div>
                      一部商品のパーツ色は同色系に置き換えとなる場合がございます。
                    </div>
                  </div>
                </div>

                <div className={'divide30 spMin_20'}/>

                <div className={'text_size16 align_c'}>保存後はオーダーや店頭での相談に便利なイメージシートの作成が可能です。</div>
              </div>

              <div className={'divide30 spMin_20'}/>

              <div className={'dialog_btn flex_box flex_align_center flex_content_center'}>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickCancel()}
                  >
                    <img
                      src={btnCancel}
                      srcSet={`${btnCancel} 1x, ${btnCancelRet} 2x`}
                      alt={'キャンセル'}
                    />
                  </button>
                </div>
                <div>
                  {/* **************** 保存ダイアログ パターン1 **************** */}
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickStep1()}
                  >
                    <img
                      src={btnSaveImage}
                      srcSet={`${btnSaveImage} 1x, ${btnSaveImageRet} 2x`}
                      alt={'画像を保存'}
                    />
                  </button>
                </div>
              </div>
              {/* //.dialog_btn */}

            </div>
          </div>

        ) : (<></>)}

        {step === 2 ? (
          <div className={'dialog_cont'}>
            <div className={'dialog_contInner'}>

              <div className={'dialog_message flex_box flex_align_center flex_content_center'}>
                <div>画像を保存しました。</div>
              </div>
              <div className={'divide30'}/>

              <div className={'dialog_btn flex_box flex_align_center flex_content_center'}>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickCancel()}
                  >
                    <img
                      src={btnCancel}
                      srcSet={`${btnCancel} 1x, ${btnCancelRet} 2x`}
                      alt={'キャンセル'}
                    />
                  </button>
                </div>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickStep2()}
                  >
                    <img
                      src={btnAddSheet}
                      srcSet={`${btnAddSheet} 1x, ${btnAddSheetRet} 2x`}
                      alt={'イメージシート作成'}
                    />
                  </button>
                </div>
              </div>
              {/* //.dialog_btn */}

            </div>
          </div>
        ) : (<></>)}

        {step === 3 ? (
          <div className={'dialog_cont'}>
            <div className={'dialog_contInner'}>

              <div className={'dialog_message pattern_3 flex_box flex_align_center'}>
                <div className={'dialog_messageInner_left'}>
                  <div className={'innerText_wrap'}>
                    お客様が
                    <span className={'innerRedText'}>シミュレートしたブラインド</span>
                    を実際に
                    <span className={'innerRedText'}>店頭でオーダーする</span>
                    際に役立つ注文票のようなものです。<br/>
                    PDFで作成されたイメージシートを
                    <span className={'innerRedText'}>店頭でご提示</span>
                    いただくだけで、
                    <span className={'innerRedText'}>「かんたんオーダー」</span>
                    ができます。
                  </div>

                  <div className={'divide20 spMin_10'}/>

                  <div className={'red_message'}>
                    <div className={'textItem_wrap flex_box'}>
                      <div>※</div>
                      <div>
                        選択された色柄番号やパーツ情報は保存されません。<br/>
                        シミュレーション結果をあとで参照する場合はイメージシート（PDF）を作成し、保存してください。
                      </div>
                    </div>

                    <div className={'divide20 spMin_10'}/>

                    <div className={'textItem_wrap flex_box'}>
                      <div>※</div>
                      <div>
                        表示されている色柄、オプション、透過度は実物と異なる場合がございますので、実物・デジタルブックでご確認ください。
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className={'checkBox_wrap flex_box flex_content_center' + (isCheck ? ' checked' : '') + ((CanvasConfService.ins.blindData.isStandardBlind || CanvasConfService.ins.blindData.blindName.indexOf('プリーツスクリーン') !== -1) || CanvasConfService.ins.blindData.blindName.indexOf('ハニカムスクリーン') !== -1 ? ' ele_hidden' : '')}>
                <div
                  onClick={() => this.setState({isCheck: !isCheck})}
                  className={'checkBoxInner_wrap cursor_p flex_box flex_align_center flex_content_center'}
                >
                  <div className={'icon_wrap'}>
                    <img
                      src={iconCheckBox}
                      srcSet={`${iconCheckBox} 1x, ${iconCheckBoxRet} 2x`}
                      alt={'チェックなし'}
                      className={'noCheck'}
                    />
                    <img
                      src={iconCheckBoxChecked}
                      srcSet={`${iconCheckBoxChecked} 1x, ${iconCheckBoxCheckedRet} 2x`}
                      alt={'チェックあり'}
                      className={'checked'}
                    />
                  </div>
                  <div className={'label_wrap'}>株式会社ニチベイ専用オーダーシート追加</div>
                </div>
              </div>

              <div className={'divide30 spMin_10'}/>

              <div className={'dialog_btn flex_box flex_align_center flex_content_center'}>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickCancel()}
                  >
                    <img
                      src={btnCancel}
                      srcSet={`${btnCancel} 1x, ${btnCancelRet} 2x`}
                      alt={'キャンセル'}
                    />
                  </button>
                </div>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickStep3()}
                  >
                    <img
                      src={btnAddSheet}
                      srcSet={`${btnAddSheet} 1x, ${btnAddSheetRet} 2x`}
                      alt={'イメージシート作成'}
                    />
                  </button>
                </div>
              </div>
              {/* //.dialog_btn */}

            </div>
          </div>
        ) : (<></>)}
      </div>
    );
  }

  private handlerClickStep1(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    CanvasConfService.ins.saveImg()
      .then(() => {
        this.loading = false;
        this.setState({
          step: 2,
        });
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
        this.setState({
          step: 99,
        });
      });
  }

  private handlerClickStep2(): void {
    this.setState({
      step: 3,
    });
  }

  private handlerClickStep3(): void {
    this.loading = true;
    CanvasConfService.ins.outputPdf(this.state.isCheck ? 1 : 0);
    CanvasConfService.isSave(false);
    this.setState({
      step: 1,
    });
  }

  private handlerClickCancel(): void {
    // CanvasConfService.ins.outputPdf();
    CanvasConfService.isSave(false);
    this.setState({
      step: 1,
    });
  }

}

export class CreateSlatManager {
  static createSlat(width: number, height: number, colorCode: string, opacity: number | string): SVGRectElement {
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    // rect.setAttributeNS('', 'x', '0');
    // rect.setAttributeNS('', 'y', '0');
    const color: string = typeof colorCode === 'string' ? colorCode[0] === '#' ? colorCode.slice(1) : colorCode : (colorCode as any).color;
    rect.setAttributeNS('', 'fill', '#' + color.replace('#', '').replace('0x', ''));
    // rect.setAttributeNS('', 'stroke', '#000000');
    rect.setAttributeNS('', 'fill-opacity', '' + opacity);
    rect.setAttributeNS('', 'width', '' + (width ));
    rect.setAttributeNS('', 'height', '' + (height ));
    return rect;
  }

  static createNotImgSlat(slat: slatName): SVGRectElement {
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    switch (slat) {
      case slatName.bar_pmdo_w:
        break;
      case slatName.bar_hanari:
        break;
        case slatName.screen_ris:
          break;
          case slatName.rope:
            break;
      default:
        break
    }
    return rect;
  }
}

export enum slatName {
  bar_pmdo_w,
  bar_hanari,
  screen_ris,
  rope,
}

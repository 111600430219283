import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Buhin, CanvasConfService, CenterColor, Colors} from '../../../service/canvas-conf-service';
import {string} from 'prop-types';

export class PostCreateImage extends RequestBase {

  param: {
    file: File,
  } | any;

  constructor(
    file: File,
  ) {
    super(HTTPMethod.POST, '/api/blind/html_to_ping');
    this.param = {
      // html: html,
      file: file as File,
    };
  }

  getParam(): FormData {
    const formData: FormData = new FormData();
    Object.keys(this.param).forEach((key: string) => {
      if(key === 'file'){
        formData.append(key, this.param[key], 'temp.html');
      }else {
        formData.append(key, this.param[key]);
      }
    });
    return formData;
  }

}

import * as React from 'react';
import { ColorGenerator } from '../../../../util/color-generator';
import './blind-spec-component.scss';
import imgSpecTitle from '../../../../assets/img/simulator/spec_title.png';
import imgSpecTitleRet from '../../../../assets/img/simulator/spec_title@2x.png';
import { CanvasConfService } from '../../../../service/canvas-conf-service';
import { DrawManager } from '../../../../manager/draw-manager';

interface BlindSpecComponentProps {
}

interface BlindSpecComponentState {
  type: string;
  classification: string;
  style: string;
  colorId: string;
  width: number;
  height: number;
}

export class BlindSpecComponent extends React.Component<BlindSpecComponentProps, BlindSpecComponentState> {

  constructor(props: BlindSpecComponentProps) {
    super(props);
    this.state = {
      // type: CanvasConfService.ins.blindData.blindName,
      // classification: CanvasConfService.ins.blindData.isStandardBlind ? 'スタンダード' : 'オリジナル',
      // style: CanvasConfService.ins.blindData.blindType,
      // colorId: CanvasConfService.ins.blindData.color,
      // width: CanvasConfService.ins.blindData.windowSizeWidth,
      // height: CanvasConfService.ins.blindData.windowSizeHeight,
      type: '-',
      classification: '',
      style: '-',
      colorId: '-',
      width: 0,
      height: 0,
    };
  }

  render() {
    const { type, classification, style, colorId, width, height } = this.state;
    return (
      <div className={'spec_inner_wrap position_r'}>
        <div className={'spcTitleImage'}>
          <h3>
            <img
              src={imgSpecTitle}
              srcSet={`${imgSpecTitle} 1x, ${imgSpecTitleRet} 2x`}
              alt={'ブラインドスペック'}
            />
          </h3>
        </div>
        <div className={'spec_body'}>
          <ul>
            <li className={'flex_box flex_align_center'}>
              {type}
              {classification ? (<span className={'typeLabel'}>{classification}</span>) : (<></>)}
            </li>
            <li>
              {style}
            </li>
            <li>
              色柄 : {colorId}
            </li>
            <li>
              商品サイズ : 幅 {width || '-'} mm | 高さ {height || '-'} mm
            </li>
          </ul>
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    CanvasConfService.ins.updateBlindSpec = () => {
      if (!DrawManager.blindSelected) {
        return;
      }

      this.setState({
        type: CanvasConfService.ins.blindData.blindName,
        classification: CanvasConfService.ins.blindData.isStandardBlind ? 'スタンダード' : 'オリジナル',
        style: CanvasConfService.ins.blindData.blindType,
        colorId: CanvasConfService.ins.blindData.blindName.indexOf('ツインスタイル') !== -1 ?
          CanvasConfService.ins.responseDataColorInfo.color_code + ' ' + CanvasConfService.ins.underSlatData.color_code :
          CanvasConfService.ins.blindData.isStandardBlind ? CanvasConfService.ins.blindData.color :
            CanvasConfService.ins.blindData.blindName.indexOf('ミックスルーバー') !== -1 ? 'ミックスルーバー' :
              CanvasConfService.ins.blindData.blindName.indexOf('カラーアラカルト') !== -1 ? 'カラーアラカルト' :
                CanvasConfService.ins.responseDataColorInfo.color_code + ' ' + CanvasConfService.ins.underSlatData.color_code,
        width: CanvasConfService.ins.blindData.windowSizeWidth,
        height: CanvasConfService.ins.blindData.windowSizeHeight,
      });
    };
    CanvasConfService.ins.updateBlindSpec();
  }

}

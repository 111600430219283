import * as React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { RoutingPass } from '../../../constant/routing-pass';
import { DrawContainerComponent } from './draw-container/draw-container-component';
import { BlindSpecComponent } from './blind-spec/blind-spec-component';
import { MenuComponent } from './menu/menu-component';
import './page-simulator-component.scss';
import imgLogoNichibei from '../../../assets/img/common/logo_back.png';
import imgLogoNichibeiRet from '../../../assets/img/common/logo_back@2x.png';
import imgLogoWindowSimulator from '../../../assets/img/common/header_logo_window_simulator.png';
import imgLogoWindowSimulatorRet from '../../../assets/img/common/header_logo_window_simulator@2x.png';
import imgBtnSaveImageSheet from '../../../assets/img/simulator/btn_save_imagesheet.png';
import imgBtnSaveImageSheetRet from '../../../assets/img/simulator/btn_save_imagesheet@2x.png';
import btnChangeBLindPermeability from '../../../assets/img/modal/btn_changeBlind_permeability.png';
import btnChangeBLindPermeabilityRet from '../../../assets/img/modal/btn_changeBlind_permeability@2x.png';
import btnAccentColorDescription from '../../../assets/img/modal/btn_accentColor_description.png';
import btnAccentColorDescriptionRet from '../../../assets/img/modal/btn_accentColor_description@2x.png';
import btnChangeBLindAttention from '../../../assets/img/modal/btn_changeBlind_attention.png';
import btnChangeBLindAttentionRet from '../../../assets/img/modal/btn_changeBlind_attention@2x.png';
import btnChangeBLindSelection from '../../../assets/img/modal/btn_changeBlind_reSelection.png';
import btnChangeBLindSelectionRet from '../../../assets/img/modal/btn_changeBlind_reSelection@2x.png';
import btnChangeBLindColorSelection from '../../../assets/img/modal/btn_changeBlindColor_reSelection.png';
import btnChangeBLindColorSelectionRet from '../../../assets/img/modal/btn_changeBlindColor_reSelection@2x.png';
import btnChangeBLindCancel from '../../../assets/img/modal/btn_changeBlind_cancel.png';
import btnChangeBLindCancelRet from '../../../assets/img/modal/btn_changeBlind_cancel@2x.png';
import btnChangeBLindDo from '../../../assets/img/modal/btn_changeBlind_do.png';
import btnChangeBLindDoRet from '../../../assets/img/modal/btn_changeBlind_do@2x.png';
import btnOparationRight from '../../../assets/img/modal/btn_oparation_right.png';
import btnOparationRightRet from '../../../assets/img/modal/btn_oparation_right@2x.png';
import btnOparationLeft from '../../../assets/img/modal/btn_oparation_left.png';
import btnOparationLeftRet from '../../../assets/img/modal/btn_oparation_left@2x.png';
import btnOparationUp from '../../../assets/img/modal/btn_oparation_up.png';
import btnOparationUpRet from '../../../assets/img/modal/btn_oparation_up@2x.png';
import btnOparationDown from '../../../assets/img/modal/btn_oparation_down.png';
import btnOparationDownRet from '../../../assets/img/modal/btn_oparation_down@2x.png';
import labelOparation from '../../../assets/img/modal/label_oparation.png';
import labelOparationRet from '../../../assets/img/modal/label_oparation@2x.png';
import labelLouver from '../../../assets/img/modal/label_louver.png';
import labelLouverRet from '../../../assets/img/modal/label_louver@2x.png';
import { PostMakePdf } from '../../../model/api/pdf/post-make-pdf';
import { ConnectionService } from '../../../service/connection-service';
import { DrawManager } from '../../../manager/draw-manager';
import { ExportImgManager } from '../../../manager/export-img-manager';
import { CanvasConfService, Colors } from '../../../service/canvas-conf-service';
import { AxiosResponse } from 'axios';
import { SlatModalService } from '../../../service/slat-modal-service';
import { ColorList } from '../../../model/api/color/get-color-group';
import { RequestBase } from '../../../model/api/request-base';
import { DateFormat, DateFormatter } from '../../../util/date-formatter';
import { ColorInfo } from '../../../model/api/color/get-colors-by-id';
import { GetColorsByCode } from '../../../model/api/color/get-colors-by-code';
import { group } from 'd3-array';
import { UserAgentService } from '../../../service/user-agent-service';
import { ModalComponent } from '../../modal/modal-component';
import { ModalDialogComponent } from '../../modal/dialog/modal-dialog-component';
import iconRadio from '../../../assets/img/common/icon_radio.png';
import iconRadioRet from '../../../assets/img/common/icon_radio@2x.png';
import iconRadioChecked from '../../../assets/img/common/icon_radio_checked.png';
import iconRadioCheckedRet from '../../../assets/img/common/icon_radio_checked@2x.png';
import imgBtnReset from '../../../assets/img/simulator/btn_reset.svg';
import imgBtnOpenClose from '../../../assets/img/simulator/btn_openclose.svg';
import { Pos } from '../../../model/pos';
import { ColorExceptionManager } from '../../../manager/color-exception-manager';
import App from '../../../App';
import { LogManager } from '../../../manager/log-manager';
import btnEditLeft from '../../../assets/img/modal/btn_edit_left.svg';
import btnEditLeftBack from '../../../assets/img/modal/btn_edit_left_back.svg';
import btnEditRight from '../../../assets/img/modal/btn_edit_right.svg';
import { OptionDetail } from '../../../model/api/option/get-option-colors';
import iconMenu2 from '../../../assets/img/modal/icon_modal_blind.png';
import iconMenu2Ret from '../../../assets/img/modal/icon_modal_blind@2x.png';

interface PageSimulatorComponentProps extends RouteComponentProps {
}

interface PageSimulatorComponentState {
  isDisplaySlatModal: boolean;
  slatColorList: { value: string, colorCode: string }[];
  currentGroupNum: number,
  targetPos: number;
  baseColorInfo: ColorInfo;
  underColorInfo: ColorInfo;
  isDisplayAccentColorModal: boolean;
  accentColorType: 'left' | 'right' | 'both' | 'none';
  accentList: ColorInfo[],
  accentColor: ColorInfo,
  topImage: { width: number, height: number },
  bottomImage: { width: number, height: number },
  isSelectRace: boolean,
  targetPosRace: number,
  selectGroupNumRace: number,
  currentGroupNumRace: number,
  isSelectBack: boolean,
  targetPosBack: number,
  selectGroupNumBack: number,
  currentGroupNumBack: number,
}

export class PageSimulatorComponent extends React.Component<PageSimulatorComponentProps, PageSimulatorComponentState> {

  static isLaunch: boolean = false;

  private testSlat: string = 'vertical.svg';
  private testSlatYoko: string = 'side.svg';
  private testBottom: string = 'bottom_vertical.svg';
  private dialogId: string = 'color-change-dialog';

  private selectedGroupNum: number = 0;
  private slatDataList: string[] = [];
  private bottomDataList: string[] = [];
  private dummyNameList: string[] = [];
  private accentSlatList: string[] = [
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
    '#888888', '#CCCCCC',
  ];

  private originRaceList: OptionDetail[] = [];

  private isPushing: boolean = false;
  private timeout!: any;

  constructor(props: PageSimulatorComponentProps) {
    super(props);
    this.state = {
      isDisplaySlatModal: false,
      slatColorList: [],
      currentGroupNum: 0,
      targetPos: 0,
      baseColorInfo: new ColorInfo(),
      underColorInfo: new ColorInfo(),
      isDisplayAccentColorModal: false,
      accentColorType: CanvasConfService.ins.accentColor.acctentType,
      accentList: [],
      accentColor: CanvasConfService.ins.accentColor.color,
      topImage: { width: 0, height: 0 },
      bottomImage: { width: 0, height: 0 },
      isSelectRace: false,
      targetPosRace: 0,
      currentGroupNumRace: 0,
      selectGroupNumRace: 0,
      isSelectBack: false,
      targetPosBack: 0,
      currentGroupNumBack: 0,
      selectGroupNumBack: 0,
    };
    SlatModalService.ins.displayModalFunc = (isDisplay: boolean) => {
      this.originRaceList = [...CanvasConfService.ins.centerRaceColorArr.map((data) => {
        return { ...data };
      })];
      let num: number = 0;
      if (!SlatModalService.ins.colorList.find((data, i) => {
        num = +i;
        return data.group_name === CanvasConfService.ins.responseDataColorInfo.group_name;
      })) {
        num = 0;
      }
      const dummyCodeList: { value: string, colorCode: string }[] = [];
      this.dummyNameList = [];
      SlatModalService.ins.slatColor.map((data) => {
        dummyCodeList.push({
          value: (typeof data.color === 'string' ? data.color : (data as any).color.color).replace('0x', '#'),
          colorCode: data.color_code,
        });
        this.dummyNameList.push(data.name);
      });
      this.selectedGroupNum = num;
      this.slatDataList = SlatModalService.ins.slatSvgList;
      this.bottomDataList = SlatModalService.ins.bottomSvgList;
      const twinList: { value: string, colorCode: string }[] = [];
      this.createColorList().forEach((data) => {
        twinList.push({ value: data, colorCode: CanvasConfService.ins.responseDataColorInfo.color_code });
      });
      this.getImageSize(CanvasConfService.ins.responseDataColorInfo.image_file, CanvasConfService.ins.underSlatData.image_file)
        .then((res: { width: number, height: number }[]) => {
          this.setState({
            isDisplaySlatModal: isDisplay,
            slatColorList: CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN' ? twinList : dummyCodeList,
            currentGroupNum: num,
            targetPos: CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN' ? -1 : 0,
            baseColorInfo: CanvasConfService.ins.responseDataColorInfo,
            underColorInfo: CanvasConfService.ins.underSlatData,
            topImage: res[0],
            bottomImage: res[1],
            targetPosRace: 0,
            isSelectRace: false,
            selectGroupNumRace: CanvasConfService.ins.centerRaceColorArr.find((data) => data.series_name === 'センターレース') ? 0 : 1, // ミラーセンターレースのみの場合がある
            isSelectBack: false,
            targetPosBack: 0,
            selectGroupNumBack: 0,
            currentGroupNumBack: 0,
          });
        });
    };
    SlatModalService.ins.accentDialog = (list: ColorInfo[]) => this.setState({
      accentList: list,
      isDisplayAccentColorModal: list.length > 0,
      accentColor: CanvasConfService.ins.accentColor.color || list[0],
      accentColorType: CanvasConfService.ins.accentColor.acctentType,
    });
    setTimeout(() => {
      if (!CanvasConfService.isHowTo()) {
        if (!PageSimulatorComponent.isLaunch && ModalComponent.ins.state.bodyList.length === 0) {
          PageSimulatorComponent.isLaunch = true;
        }
      }
    });
  }

  componentDidMount(): void {
    const ua = navigator.userAgent;
    const isMobile = ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 || ua.indexOf('Mobile') > 0;
    if (isMobile) {
      (window.document.body.parentElement as HTMLElement).setAttribute('class', 'bounceOff');
    }
  }

  componentWillUnmount(): void {
    ModalComponent.closeAll();
    (window.document.body.parentElement as HTMLElement).setAttribute('class', '');
  }

  componentDidUpdate(prevProps: Readonly<PageSimulatorComponentProps>, prevState: Readonly<PageSimulatorComponentState>, snapshot?: any): void {
    if (this.state.isDisplaySlatModal) {
      this.dialogScroll();
    }
  }

  render() {
    const {
      isDisplaySlatModal,
      slatColorList,
      targetPos,
      currentGroupNum,
      baseColorInfo,
      underColorInfo,
      isDisplayAccentColorModal,
      accentColorType,
      accentList,
      accentColor,
      topImage,
      bottomImage,
      isSelectRace,
      targetPosRace,
      currentGroupNumRace,
      selectGroupNumRace,
      currentGroupNumBack,
      isSelectBack,
      selectGroupNumBack,
      targetPosBack,
    } = this.state;

    // ハニカムスクリーンツインスタイル　レフィーナ４５（ハニカム＋プリーツ　スタンダード）の場合、
    // 上部分を編集を表示しないようにするため、編集部分を下部分に変更する。
    if (CanvasConfService.ins.detail_blind.unique_id === '144' && targetPos === -1) {
      this.setState({ targetPos: -2 });
    }

    const selectedGroupNum = this.selectedGroupNum;
    const isStandard = !CanvasConfService.ins.blindData.isStandardBlind;
    const modalType = CanvasConfService.ins.responseDataBlindDetail.color_change_type;
    const colorGroup: ColorList[] = SlatModalService.ins.colorList;
    const colorList = colorGroup[currentGroupNum];
    const slatNum: number = slatColorList.length;
    const slatSize: number = 420 / slatNum < 15 ? 15 : 420 / slatNum;
    const topLen: { side: number, vertical: number } = {
      side: topImage.width && CanvasConfService.ins.blindData.windowSizeWidth / topImage.width,
      vertical: topImage.height && CanvasConfService.ins.blindData.windowSizeHeight / 2 / topImage.height,
    };
    const bottomLen: { side: number, vertical: number } = {
      side: bottomImage.width && CanvasConfService.ins.blindData.windowSizeWidth / bottomImage.width,
      vertical: bottomImage.height && CanvasConfService.ins.blindData.windowSizeHeight / 2 / bottomImage.height,
    };
    const baseArr: string[] = [];
    for (let i: number = 0; i < ((Math.round(topLen.side) || 1) + 1) * ((Math.round(topLen.vertical) || 1) + 1); i++) {
      baseArr.push(baseColorInfo.image_file);
    }
    const underArr: string[] = [];
    for (let i: number = 0; i < ((Math.round(bottomLen.side) || 1) + 1) * ((Math.round(bottomLen.vertical) || 1) + 1); i++) {
      underArr.push(underColorInfo.image_file);
    }
    const isCenterTypeOrigin = !CanvasConfService.ins.blindData.isStandardBlind && CanvasConfService.ins.blindData.blindType.indexOf('センターレース') !== -1;
    const isBackTypeOrigin = !CanvasConfService.ins.blindData.isStandardBlind && CanvasConfService.ins.blindData.blindType.indexOf('バックレース') !== -1;
    const originRaceList = this.originRaceList;
    const centerColorList: OptionDetail[] = [];
    /* NOTE センターレースにミラーレース遮熱を表示する必要がある奴らを管理するフラグ */
    const exceptionFlg = (): boolean => {
      const id = CanvasConfService.ins.detail_blind.parent_id;
      return id === '158' || id === '162' || id === '66';
    };
    CanvasConfService.ins.centerRaceArr.forEach((data) => {
      if (isCenterTypeOrigin && data.series_name === (currentGroupNumRace === 0 ? 'センターレース' : 'ミラーセンターレース')) {
        if (!centerColorList.find((v) => v.id === data.id)) {
          centerColorList.push(data);
        }
      }
      if (isCenterTypeOrigin && data.series_name === 'ミラーレース遮熱' && exceptionFlg()) {
        if (!centerColorList.find((v) => v.id === data.id)) {
          centerColorList.push(data);
        }
      }
      /* NOTE バックレース グループごとのカラーの制御はここで、新しいグループ増えたら対応必須*/
      const seriesFilter = () => {
        if (currentGroupNumBack === 0) return 'ミラーレース遮熱';
        if (currentGroupNumBack === 1) return 'ベーネ';
        if (currentGroupNumBack === 2) return 'ルーテ遮熱';
        return ''
      }
      if (isBackTypeOrigin && data.series_name === seriesFilter()) {
        if (!centerColorList.find((v) => v.id === data.id)) {
          centerColorList.push(data);
        }
      }
    });
    const centerColorGroup: ColorList[] = [];

    if (CanvasConfService.ins.centerRaceArr.find((data) => isCenterTypeOrigin && data.series_name === 'センターレース')) {
      centerColorGroup.push({ group_name: 'センターレース', colors: [] });
    }
    if (CanvasConfService.ins.centerRaceArr.find((data) => isCenterTypeOrigin && data.series_name === 'ミラーセンターレース')) {
      centerColorGroup.push({ group_name: 'ミラーセンターレース', colors: [] });
    }
    if (CanvasConfService.ins.centerRaceArr.find((data) => isCenterTypeOrigin && data.series_name === 'ミラーレース遮熱' && exceptionFlg())
    ) {
      centerColorGroup.push({ group_name: 'ミラーレース遮熱', colors: [] });
    }
    if (CanvasConfService.ins.centerRaceArr.find((data) => isBackTypeOrigin && data.series_name === 'ミラーレース遮熱')) {
      centerColorGroup.push({ group_name: 'ミラーレース遮熱', colors: [] });
    }
    if (CanvasConfService.ins.centerRaceArr.find((data) => isBackTypeOrigin && data.series_name === 'ベーネ')) {
      centerColorGroup.push({ group_name: 'ベーネ', colors: [] });
    }
    if (CanvasConfService.ins.centerRaceArr.find((data) => isBackTypeOrigin && data.series_name === 'ルーテ遮熱')) {
      centerColorGroup.push({ group_name: 'ルーテ遮熱', colors: [] });
    }
    if (isBackTypeOrigin && !originRaceList.length) {
      for (let i: number = 0; i < slatNum; i++) {
        originRaceList.push(centerColorList[0]);
      }
    }
    centerColorList.sort((a, b) => a.id - b.id);
    return (
      <div className={'body_wrap inSimulator'}>
        <div className={'body_simu flex_box'}>

          {/* --------------------------------------- left --------------------------------------- */}
          <div className={'left_wrap'}>

            <div className={'header_common flex_box flex_align_center position_r'}>
              <div className={'innerLeft flex_box flex_align_center'}>
                <div>
                  <button
                    onClick={() => this.handlerClickBack()}
                    className={'reset'}
                  >
                    <img
                      src={imgLogoNichibei}
                      srcSet={`${imgLogoNichibeiRet} 1x, ${imgLogoNichibeiRet} 2x`}
                      alt={'トップへ'}
                    />
                  </button>
                </div>
                <div>
                  <img
                    src={imgLogoWindowSimulator}
                    srcSet={`${imgLogoWindowSimulator} 1x, ${imgLogoWindowSimulatorRet} 2x`}
                    alt={'Window Simulator'}
                  />
                </div>
              </div>
              {/* //.innerLeft */}

              <div className={'innerRight flex_box flex_align_center' + (SlatModalService.ins.isOpenType ? ('') : (' isSingle'))}>
                {SlatModalService.ins.isOpenType ? (
                  <div>
                    <button
                      type={'button'}
                      className={'reset'}
                      onClick={() => {
                        SlatModalService.ins.isOpen = !SlatModalService.ins.isOpen;
                        CanvasConfService.ins.updateBlindColor(SlatModalService.ins.slatColor);
                      }}
                    >
                      <img
                        src={imgBtnOpenClose}
                        alt={'開閉ボタン'}
                      />
                    </button>
                  </div>
                ) : (<></>)}
                <div className={'btn_reset_wrap'}>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => DrawManager.reset()}
                  >
                    <img
                      src={imgBtnReset}
                      alt={'reset'}
                    />
                  </button>
                </div>
              </div>
            </div>
            {/* //.header_common end */}

            {/* 画面左のSVGを操作するエリア */}
            <div className={'drawArea_wrap position_r'}>
              <DrawContainerComponent />

              {/* ↓↓↓ブラインド未選択状態のメッセージ表示ダイアログ↓↓↓ */}
              {/*{DrawManager.blindSelected ? (<></>) : (*/}
              {/*  <div className={'unselectedBlindModal_wrap'}>*/}
              {/*    <div className={'unselectedBlindModal_body'}>*/}
              {/*      <div className={'unselectedBlindModal_bodyInner'}>*/}
              {/*        <div className={'flex_box flex_align_center flex_content_center'}>*/}
              {/*          <img*/}
              {/*            src={iconMenu2}*/}
              {/*            srcSet={`${iconMenu2} 1x, ${iconMenu2Ret} 2x`}*/}
              {/*            alt={'ブラインド'}*/}
              {/*            className={'def_img'}*/}
              {/*          />*/}
              {/*          ブラインドを選択してください*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
              {/* //.unselectedBlindModal_wrap */}
              {/* ↑↑↑ブラインド未選択状態のメッセージ表示↑↑↑ */}

              {/* ↓↓↓ブラインド変更モーダル↓↓↓ */}
              {isDisplaySlatModal ? (
                <div className={'changeBlindModal_wrap'}>
                  <div className={'changeBlindModal_bg'} />

                  <div className={'changeBlindModal_body'}>

                    <div className={'changeBlindModal_title flex_box flex_align_center flex_space_between'}>
                      <div
                        className={'modalTitleInner'}
                      >{CanvasConfService.ins.blindData.isStandardBlind ? 'スタンダードブラインド' : 'オリジナルブラインド'}</div>
                      <div className={'right_btn_wrap'}>
                        <button
                          type={'button'}
                          className={'reset'}
                          onClick={() => this.handlerClickCancel()}
                        >
                          &#10005;
                        </button>
                      </div>
                    </div>

                    <div className={'changeBlindModal_cont'}>
                      <div className={'changeBlindModal_contTop'}>
                        <div className={'divide10'} />
                        <div className={'flex_box'}>

                          <div className={'left_box'}>
                            <div className={'blindNum_wrap align_c font_b'}>
                              {modalType === 'TWIN' ? (<></>) :
                                isSelectRace ? (<>{targetPosRace + 1}&nbsp;/&nbsp;{slatNum - 1}</>) : (<>{(isSelectBack ? targetPosBack : targetPos) + 1}&nbsp;/&nbsp;{slatNum}</>)}
                            </div>

                            <div className={'divide10'} />

                            {modalType === 'MIX' ? (
                              <>
                                {/*色変更モーダル：縦*/}
                                <div
                                  id={this.dialogId}
                                  className={'svg_wrap'}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x={0}
                                    y={-100}
                                    width={slatSize * slatNum + 10}
                                    height={360}
                                    viewBox={'-10 -30 ' + (slatSize * slatNum + 10) + ' ' + (485)}
                                  >
                                    <image
                                      xlinkHref={btnOparationDownRet}
                                      x={(slatSize * (isSelectRace ? targetPosRace : isSelectBack ? targetPosBack : targetPos)) + ((slatSize / 2) - 10 + (isSelectRace ? slatSize / 2 : 0))}
                                      y={-20}
                                      width={20}
                                      height={20}
                                      // src={btnOparationDown}
                                      // srcSet={`${btnOparationDown} 1x, ${btnOparationDownRet} 2x`}
                                    />
                                    {slatColorList.map((data, i) => (
                                      <rect
                                        key={'color_vertical' + i}
                                        x={slatSize * (+i)}
                                        y={10}
                                        width={slatSize}
                                        height={440}
                                        fill={data.value.replace('0x', '#')}
                                        stroke={'#000000'}
                                        strokeWidth={1}
                                        onClick={() => {
                                          if (isSelectRace) {
                                            this.setState({ targetPosRace: +i });
                                          } else if (isSelectBack) {
                                            this.setState({ targetPosBack: +i });
                                          } else {
                                            this.setState({ targetPos: +i });
                                          }
                                        }}
                                        opacity={isSelectBack ? 0 : 1}
                                      />
                                    ))}
                                    {isCenterTypeOrigin ? (<>
                                      {originRaceList.map((data, i) => (
                                        <rect
                                          key={'color_vertical' + i}
                                          x={slatSize * (+i) + slatSize * 0.8}
                                          y={10}
                                          width={slatSize / 2}
                                          height={440}
                                          fill={data.color.replace('0x', '#')}
                                          stroke={'#000000'}
                                          strokeWidth={1}
                                          onClick={() => {
                                            if (isSelectRace) {
                                              this.setState({ targetPosRace: +i });
                                            } else if (isSelectBack) {
                                              this.setState({ targetPosBack: +i });
                                            } else {
                                              this.setState({ targetPos: +i });
                                            }
                                          }}
                                        />
                                      ))}
                                    </>) : (<></>)}
                                    {isBackTypeOrigin ? (<>
                                      {originRaceList.map((data, i) => (
                                        <rect
                                          key={'color_vertical' + i}
                                          x={slatSize * (+i)}
                                          y={10}
                                          width={slatSize}
                                          height={440}
                                          fill={data.color.replace('0x', '#')}
                                          stroke={'#000000'}
                                          strokeWidth={1}
                                          onClick={() => {
                                            if (isSelectRace) {
                                              this.setState({ targetPosRace: +i });
                                            } else if (isSelectBack) {
                                              this.setState({ targetPosBack: +i });
                                            } else {
                                              this.setState({ targetPos: +i });
                                            }
                                          }}
                                          opacity={isSelectBack ? 1 : 0}
                                        />
                                      ))}
                                    </>) : (<></>)}
                                  </svg>

                                </div>

                                <div className={'divide10'} />

                                {(!isCenterTypeOrigin && !isBackTypeOrigin) ? (
                                  <div
                                    className={'oparation_wrap ver_1 flex_box flex_align_center flex_content_center'}
                                  >

                                    <div>
                                      <div className={'flex_box flex_align_center flex_content_center'}>
                                        <div>
                                          <button
                                            type={'button'}
                                            className={'reset'}
                                            onClick={() => this.handlerClickDown()}
                                          >
                                            <img
                                              src={btnOparationLeft}
                                              srcSet={`${btnOparationLeft} 1x, ${btnOparationLeftRet} 2x`}
                                              alt={'戻る'}
                                            />
                                          </button>
                                        </div>
                                        <div>
                                          <img
                                            src={labelLouver}
                                            srcSet={`${labelLouver} 1x, ${labelLouverRet} 2x`}
                                            alt={'スラット操作'}
                                            className={'marg_auto'}
                                          />
                                        </div>
                                        <div>
                                          <button
                                            type={'button'}
                                            className={'reset'}
                                            onClick={() => this.handlerClickUp()}
                                          >
                                            <img
                                              src={btnOparationRight}
                                              srcSet={`${btnOparationRight} 1x, ${btnOparationRightRet} 2x`}
                                              alt={'進む'}
                                            />
                                          </button>
                                        </div>
                                      </div>

                                      <div className={'divide10'}></div>

                                      <div className={'attentionMessage_wrap'}>
                                        商品サイズによっては、実際の商品のルーバー枚数と誤差が生じる場合があります。<br />
                                        正確なルーバー枚数はカタログなどでご確認ください。
                                      </div>
                                    </div>

                                  </div>
                                ) : (
                                  <div
                                    className={'oparation_wrap ver_1 ver_N_btns flex_box flex_align_center flex_content_center flex_container_wrap'}
                                  >
                                    <div className={'flex_box flex_align_center flex_content_center'}>
                                      <div>
                                        <button
                                          type={'button'}
                                          className={'reset'}
                                          onClick={() => this.handlerClickDown()}
                                        >
                                          <img
                                            src={btnOparationLeft}
                                            srcSet={`${btnOparationLeft} 1x, ${btnOparationLeftRet} 2x`}
                                            alt={'戻る'}
                                          />
                                        </button>
                                      </div>
                                      <div>
                                        <img
                                          src={labelLouver}
                                          srcSet={`${labelLouver} 1x, ${labelLouverRet} 2x`}
                                          alt={'スラット操作'}
                                          className={'marg_auto'}
                                        />
                                      </div>
                                      <div>
                                        <button
                                          type={'button'}
                                          className={'reset'}
                                          onClick={() => this.handlerClickUp()}
                                        >
                                          <img
                                            src={btnOparationRight}
                                            srcSet={`${btnOparationRight} 1x, ${btnOparationRightRet} 2x`}
                                            alt={'進む'}
                                          />
                                        </button>
                                      </div>
                                    </div>

                                    <div className={'divide10'} />

                                    <div className={'edit_btn_wrap flex_box flex_align_center flex_content_center'}>
                                      <div className={'btn_wrap'}>
                                        <button
                                          type={'button'}
                                          className={'reset' + (isSelectRace || isSelectBack ? ' disabled' : '')}
                                          onClick={() => {
                                            if (isCenterTypeOrigin) {
                                              this.setState({ isSelectRace: true });
                                            } else {
                                              this.setState({ isSelectBack: true });
                                            }
                                          }}
                                        >
                                          {isCenterTypeOrigin ? (
                                            <img
                                              src={btnEditLeft}
                                              className={'センターレースを編集する'}
                                            />
                                          ) : (
                                            <img
                                              src={btnEditLeftBack}
                                              className={'バックレースを編集する'}
                                            />
                                          )}
                                        </button>
                                      </div>
                                      <div className={'btn_wrap'}>
                                        <button
                                          type={'button'}
                                          className={'reset' + (isSelectRace || isSelectBack ? '' : ' disabled')}
                                          onClick={() => this.setState({ isSelectRace: false, isSelectBack: false })}
                                        >
                                          <img
                                            src={btnEditRight}
                                            className={'ルーバーを編集する'}
                                          />
                                        </button>
                                      </div>
                                    </div>

                                    <div className={'divide10'} />

                                    <div className={'attentionMessage_wrap'}>
                                      商品サイズによっては、実際の商品のルーバー枚数と誤差が生じる場合があります。<br />
                                      正確なルーバー枚数はカタログなどでご確認ください。
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : modalType === 'VMIX' ? (
                              <>
                                {/*色変更モーダル：横*/}
                                <div
                                  id={this.dialogId}
                                  className={'svg_wrap'}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x={0}
                                    y={0}
                                    width={418}
                                    height={slatSize * slatNum + 10}
                                    viewBox={'0 10 420 ' + (slatSize * slatNum + 10 - 30)}
                                  >
                                    <image
                                      xlinkHref={btnOparationRightRet}
                                      x={0}
                                      y={((slatSize * targetPos) + 10) + ((slatSize / 2) - 10)}
                                      width={20}
                                      height={20}
                                    />
                                    {slatColorList.map((data, i) => (
                                      <rect
                                        key={'color-side_' + i}
                                        x={30}
                                        y={slatSize * (+i) + 10}
                                        width={390}
                                        height={slatSize}
                                        fill={data.value.replace('0x', '#')}
                                        stroke={'#000000'}
                                        strokeWidth={1}
                                        onClick={() => this.setState({ targetPos: +i })}
                                      />
                                    ))}
                                  </svg>

                                </div>

                                <div className={'divide10'} />

                                <div className={'oparation_wrap ver_2 flex_box flex_align_center flex_content_center'}>
                                  <div>
                                    <div>
                                      <button
                                        type={'button'}
                                        className={'reset marg_auto'}
                                        onClick={() => this.handlerClickDown()}
                                      >
                                        <img
                                          src={btnOparationUp}
                                          srcSet={`${btnOparationUp} 1x, ${btnOparationUpRet} 2x`}
                                          alt={'アップ'}
                                        />
                                      </button>
                                    </div>
                                    <div className={'divide10 spMin_5'} />
                                    <div>
                                      <img
                                        src={labelOparation}
                                        srcSet={`${labelOparation} 1x, ${labelOparationRet} 2x`}
                                        alt={'スラット操作'}
                                        className={'marg_auto'}
                                      />
                                    </div>
                                    <div className={'divide10 spMin_5'} />
                                    <div>
                                      <button
                                        type={'button'}
                                        className={'reset marg_auto'}
                                        onClick={() => this.handlerClickUp()}
                                      >
                                        <img
                                          src={btnOparationDown}
                                          srcSet={`${btnOparationDown} 1x, ${btnOparationDownRet} 2x`}
                                          alt={'ダウン'}
                                        />
                                      </button>
                                    </div>
                                    <div className={'divide10'}></div>

                                    <div className={'attentionMessage_wrap'}>
                                      {/*商品サイズの数値によっては、実際の商品のルーバー枚数と誤差が発生する場合があります。<br />*/}
                                      {/*厳密なルーバー枚数指定が必要な場合はニチベイ商品取扱店のご相談ください。*/}
                                      商品サイズによっては、実際の商品のスラット枚数と誤差が生じる場合があります。<br />
                                      正確なスラット枚数はカタログなどでご確認ください。
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : modalType === 'TWIN' ? (
                              <>
                                <div className={'ver_3_wrap flex_box'}>
                                  {/* 色変更モーダル： 二分割 */}
                                  <div className={'svg_wrap flex_box flex_align_center ver_3'}>
                                    <div className={'svgInner_wrap'}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x={0}
                                        y={0}
                                        width={360}
                                        height={390}
                                        viewBox="0 0 420 470"
                                      >
                                        <defs>
                                          <clipPath
                                            id={'mordal-image-mask-top'}
                                          >
                                            <path
                                              d={`M${1},${38}L${1 + 360},${38}L${1 + 360},${38 + (380 / 2 + 4)}L${1},${38 + (380 / 2 + 4)}Z`}
                                            />
                                          </clipPath>
                                          <clipPath
                                            id={'mordal-image-mask-bottom'}
                                          >
                                            <path
                                              d={`M${1},${38 + ((380 / 2) + 24)}L${1 + 360},${38 + ((380 / 2) + 24)}L${1 + 360},${38 + ((380 / 2) + 24) + (380 / 2 + 4)}L${1},${38 + ((380 / 2) + 24) + (380 / 2 + 4)}Z`}
                                            />
                                          </clipPath>
                                        </defs>
                                        <rect
                                          x={-2}
                                          y={0}
                                          width={364}
                                          height={38}
                                          fill={'#555555'}
                                          stroke={'#000000'}
                                          strokeWidth={1}
                                        />
                                        <rect
                                          x={-2}
                                          y={(380 / slatColorList.length) * (slatColorList.length / 2) + 40 + 2}
                                          width={364}
                                          height={20}
                                          fill={'#555555'}
                                          stroke={'#000000'}
                                          strokeWidth={1}
                                        />
                                        <rect
                                          x={-2}
                                          y={380 + 40 + 24 + 2}
                                          width={364}
                                          height={20}
                                          fill={'#555555'}
                                          stroke={'#000000'}
                                          strokeWidth={1}
                                        />
                                        {baseColorInfo.image_file ? (
                                          <>
                                            {baseArr.map((data, i) => (
                                              <image
                                                key={'base-image_' + i}
                                                xlinkHref={RequestBase.HOST_URL + data}
                                                x={1 + ((360 / ((topLen.side) || Number.MAX_VALUE)) * Math.round(Number(i) % ((Math.round(topLen.side) || Number(i)) + 1)))}
                                                // y={38 + (((380 / 2 + 4) / ((topLen.vertical) || Number.MAX_VALUE)) * Math.floor(Number(i) / ((((baseArr.length / (Math.floor(topLen.vertical)) + 1) - 1) || Number.MAX_VALUE) )))}
                                                y={38 + (((380 / 2 + 4) / ((topLen.vertical) || Number.MAX_VALUE)) * Math.floor(Number(i) / (Math.round(topLen.side) + 1)))}
                                                width={360 / (topLen.side || 1)}
                                                height={(380 / 2 + 4) / (topLen.vertical || 1)}
                                                preserveAspectRatio={'none'}
                                                clipPath={'url(#mordal-image-mask-top)'}
                                              />
                                            ))}
                                          </>
                                        ) : (<></>)}
                                        {underColorInfo.image_file ? (
                                          <>
                                            {underArr.map((data, i) => (
                                              <image
                                                key={'under-image_' + i}
                                                xlinkHref={RequestBase.HOST_URL + data}
                                                x={1 + ((360 / (bottomLen.side || Number.MAX_VALUE)) * Math.round(Number(i) % ((Math.round(bottomLen.side) || Number(i)) + 1)))}
                                                y={38 + ((380 / 2) + 24) + (((380 / 2 + 4) / ((bottomLen.vertical) || Number.MAX_VALUE)) * Math.floor(Number(i) / (Math.round(bottomLen.side) + 1)))}
                                                // y={38 + ((380 / 2) + 24) + (((380 / 2 + 4) / (bottomLen.vertical || Number.MAX_VALUE)) * Math.round(Number(i) / (((underArr.length / bottomLen.vertical) || Number.MAX_VALUE) )))}
                                                width={360 / (bottomLen.side || 1)}
                                                height={(380 / 2 + 4) / (bottomLen.vertical || 1)}
                                                preserveAspectRatio={'none'}
                                                clipPath={'url(#mordal-image-mask-bottom)'}
                                              />
                                            ))}
                                          </>
                                        ) : (<></>)}

                                        {slatColorList.map((data, i) => (
                                          <rect
                                            key={'color-twin_' + i}
                                            x={1}
                                            y={(380 / slatColorList.length) * (+i) + 40 + (+i > Math.floor(slatColorList.length / 2 - 1) ? 24 : 0)}
                                            width={358}
                                            height={380 / slatColorList.length}
                                            fill={data.value}
                                            stroke={'#000000'}
                                            strokeWidth={1}
                                            opacity={(
                                              baseColorInfo.image_file ? (
                                                underColorInfo.image_file ? 0 : (
                                                  +i < slatColorList.length / 2 ? 0 : 1
                                                )
                                              ) : (
                                                underColorInfo.image_file ? (
                                                  +i > (Math.floor(slatColorList.length / 2) - 1) ? 0 : 1
                                                ) : (
                                                  1
                                                )
                                              )
                                            )}
                                          />
                                        ))}
                                      </svg>
                                    </div>
                                  </div>

                                  {/*<div className={'divide10'}/>*/}

                                  <div
                                    className={'oparation_wrap ver_3 flex_box flex_align_center flex_content_center'}
                                  >
                                    <div>
                                      <div className={'editBtn_wrap'}>
                                        {
                                          // スタンダード　ハニカムツイン　レフィーナ４５　上部分編集を表示しない
                                          CanvasConfService.ins.detail_blind.unique_id !== '144' ?
                                            <div className={(targetPos === -1 ? ' isSelected' : '')}>
                                              <div className={'highlightArea'}>
                                                <button
                                                  type={'button'}
                                                  className={'reset' + (targetPos === -1 ? ' selected' : '')}
                                                  onClick={() => {
                                                    this.setState({ targetPos: -1 });
                                                  }}
                                                >
                                                  上部分を<br />編集する
                                                </button>
                                              </div>
                                            </div> : <div></div>}
                                        <div className={(targetPos === -2 ? ' isSelected' : '')}>
                                          <div className={'highlightArea'}>
                                            <button
                                              type={'button'}
                                              className={'reset' + (targetPos === -2 ? ' selected' : '')}
                                              onClick={() => this.setState({ targetPos: -2 })}
                                            >
                                              下部分を<br />編集する
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (<></>)}

                          </div>
                          {/* //.left_box */}

                          <div className={'right_box'}>
                            <div className={'divide5'} />
                            <div className={'displayArea displayColorGroup'}>
                              {(isSelectRace || isSelectBack ? centerColorGroup : colorGroup).map((list, i) => {
                                if (this.honeycombConditions(list, targetPos)) {
                                  return <div key={'color-group_' + list.group_name + '_' + i} />;
                                }
                                return (
                                  <div
                                    className={'list_wrap'}
                                    key={'color-group_' + list.group_name + '_' + i}
                                  >
                                    <div
                                      className={'listTitle' + (+i === (isSelectRace ? currentGroupNumRace : isSelectBack ? currentGroupNumBack : currentGroupNum) ? ' selected' : '')}
                                      onClick={() => this.handlerClickColorGroup(+i)}
                                    >
                                      {list.group_name.replace('?', 'Ⅱ')}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className={'displayArea displayColorList'}>
                              <div className={'list_wrap'}>
                                {(modalType === 'TWIN' && isStandard) ? (<></>) : (
                                  <>
                                    {isSelectRace ? (<>
                                      {selectGroupNumRace === currentGroupNumRace ? (
                                        <div className={'colorTypeName'}>
                                          <h4>個別センターレースカラーを選択</h4>
                                        </div>
                                      ) : (
                                        <div className={'colorTypeName'}>
                                          <h4>ベースカラーを選択</h4>
                                        </div>
                                      )}
                                    </>) : isSelectBack ? (
                                      <>
                                        {selectGroupNumBack === currentGroupNumBack ? (
                                          <div className={'colorTypeName'}>
                                            <h4>個別バックレースカラーを選択</h4>
                                          </div>
                                        ) : (
                                          <div className={'colorTypeName'}>
                                            <h4>ベースカラーを選択</h4>
                                          </div>
                                        )}
                                      </>
                                    ) : (<>
                                        {selectedGroupNum === currentGroupNum && modalType !== 'TWIN' ? (
                                          <>
                                            <div className={'colorTypeName'}>
                                              {modalType === 'MIX' ? (
                                                <h4>個別ルーバーカラーを選択</h4>
                                              ) : (
                                                <h4>個別スラットカラーを選択</h4>
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {modalType !== 'TWIN' ? (
                                              <div className={'colorTypeName'}>
                                                {modalType === 'MIX' ? (
                                                  <h4>ベースカラーを選択</h4>
                                                ) : (
                                                  <h4>個別スラットカラーを選択</h4>
                                                )}
                                              </div>
                                            ) : (<></>)}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>)}
                                {isSelectRace || isSelectBack ? (
                                  <div className={'listBody flex_box flex_container_wrap'}>
                                    {/*<div className={'listBody flex_box flex_container_wrap'}>*/}
                                    {centerColorList.map((data, i) => (
                                      <div
                                        // className={'listItem_wrap'  + (ColorExceptionManager.exception(data.color_code) ? ' isDisabled' : '')}
                                        className={'listItem_wrap' + (ColorExceptionManager.exception(data.color_code) ? ' ' : '')}
                                        key={'color-code_' + data.color_code + '_' + i}
                                      >
                                        <div className={'listItemInner_wrap'}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x={0}
                                            y={0}
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            onClick={() => this.handlerClickColorOriginCenter(data)}
                                          >
                                            {data.image_file ? (
                                              <image
                                                xlinkHref={RequestBase.HOST_URL + data.image_file}
                                                x={0}
                                                y={0}
                                                width={38}
                                                height={38}
                                                preserveAspectRatio={'none'}
                                              />
                                            ) : (
                                              <rect
                                                x={0}
                                                y={0}
                                                width={38}
                                                height={38}
                                                fill={data.color.replace('0x', '#')}
                                              />
                                            )}
                                          </svg>
                                        </div>
                                        <div className={'listItemName'}>{data.color_code || ''}{data.color_code ? (
                                          <br />) : (<></>)}{data.color_name || ''}</div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className={'listBody flex_box flex_container_wrap'}>
                                    {colorList.colors.map((data, i) => {
                                      if (
                                        modalType === 'TWIN' &&
                                        (targetPos === -1 && data.blind_edit_position === 'down') ||
                                        (targetPos === -2 && data.blind_edit_position === 'up')
                                      ) {
                                        return <div key={'color-code_' + data.color_code + '_' + i} />;
                                      }
                                      return (
                                        <div
                                          className={'listItem_wrap' + (ColorExceptionManager.exception(data.color_code) ? ' ' : '')}
                                          key={'color-code_' + data.color_code + '_' + i}
                                        >
                                          <div className={'listItemInner_wrap'}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              x={0}
                                              y={0}
                                              width={40}
                                              height={40}
                                              viewBox="0 0 40 40"
                                              onClick={() => this.handlerClickColor(data)}
                                              onMouseDown={() => this.handlerPush(data)}
                                              onMouseOut={() => {
                                                this.isPushing = false;
                                                if (this.timeout) {
                                                  clearTimeout(this.timeout);
                                                }
                                              }}
                                              onMouseUp={() => {
                                                this.isPushing = false;
                                                if (this.timeout) {
                                                  clearTimeout(this.timeout);
                                                }
                                              }}

                                              onTouchStart={() => this.handlerPush(data)}
                                              onTouchEnd={() => {
                                                this.isPushing = false;
                                                if (this.timeout) {
                                                  clearTimeout(this.timeout);
                                                }
                                              }}
                                            >
                                              {data.image_file ? (
                                                <image
                                                  xlinkHref={RequestBase.HOST_URL + data.image_file}
                                                  x={0}
                                                  y={0}
                                                  width={38}
                                                  height={38}
                                                  preserveAspectRatio={'none'}
                                                />
                                              ) : (
                                                <rect
                                                  x={0}
                                                  y={0}
                                                  width={38}
                                                  height={38}
                                                  fill={data.color.replace('0x', '#')}
                                                />
                                              )}
                                            </svg>
                                          </div>
                                          <div className={'listItemName'}>{data.color_code || ''}{data.color_code ? (
                                            <br />) : (<></>)}{data.color_name || ''}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* //.right_box */}

                        </div>
                      </div>

                      <div className={'changeBlindModal_contBottom flex_box flex_align_center flex_content_center'}>
                        <div>
                          <button
                            type={'button'}
                            className={'reset'}
                            onClick={() => this.handlerClickLink(`https://www.nichi-bei.co.jp/jsp/nws/explanation/transparency.php?v=${App.VERSION}`)}
                          >
                            <img
                              src={btnChangeBLindPermeability}
                              srcSet={`${btnChangeBLindPermeability} 1x, ${btnChangeBLindPermeabilityRet} 2x`}
                              alt={'透過度'}
                            />
                          </button>
                        </div>
                        <div>
                          <button
                            type={'button'}
                            className={'reset'}
                          >
                            <img
                              src={btnChangeBLindCancel}
                              srcSet={`${btnChangeBLindCancel} 1x, ${btnChangeBLindCancelRet} 2x`}
                              alt={'キャンセル'}
                              onClick={() => this.handlerClickCancel()}
                            />
                          </button>
                        </div>
                        <div>
                          <button
                            type={'button'}
                            className={'reset'}
                            onClick={() => this.handlerClickDecide()}
                          >
                            <img
                              src={btnChangeBLindDo}
                              srcSet={`${btnChangeBLindDo} 1x, ${btnChangeBLindDoRet} 2x`}
                              alt={'決定'}
                            />
                          </button>
                        </div>
                        <div>
                          <button
                            type={'button'}
                            className={'reset'}
                            style={{ 'display': (modalType === 'TWIN' ? 'flex' : 'none') }}
                            onClick={() => this.handlerClickLink(`https://www.nichi-bei.co.jp/jsp/nws/explanation/pleatstwin_tex.php?v=${App.VERSION}`)}
                          >
                            <img
                              src={btnChangeBLindAttention}
                              srcSet={`${btnChangeBLindAttention} 1x, ${btnChangeBLindAttentionRet} 2x`}
                              alt={'注意'}
                            />
                          </button>
                          <button
                            type={'button'}
                            className={'reset' + (modalType === 'TWIN' ? '' : ' noButton')}
                            style={{ 'display': (modalType === 'TWIN' ? 'none' : 'flex') }}
                            onClick={() => this.handlerClickLink(`https://www.nichi-bei.co.jp/jsp/nws/explanation/pleatstwin_tex.php?v=${App.VERSION}`)}
                          >
                            <img
                              src={modalType === 'VMIX' ? btnChangeBLindColorSelection : btnChangeBLindSelection}
                              srcSet={`${modalType === 'VMIX' ? btnChangeBLindColorSelection : btnChangeBLindSelection} 1x, ${modalType === 'VMIX' ? btnChangeBLindColorSelectionRet : btnChangeBLindSelectionRet} 2x`}
                              alt={'注意'}
                            />
                            {/*  TODO */}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* //.changeBlindModal_cont */}

                  </div>
                  {/* //.changeBlindModal_body */}

                </div>
              ) : (<></>)}
              {/* //.changeBlindModal_wrap */}
              {/* ↑↑↑ブラインド変更モーダル↑↑↑ */}

              {/* ↓↓↓アクセントカラーモーダル↓↓↓ */}
              {isDisplayAccentColorModal ? (
                <>
                  <div className={'changeBlindModal_wrap verAccentColor'}>
                    <div className={'changeBlindModal_bg'} />

                    <div className={'changeBlindModal_body'}>

                      <div className={'changeBlindModal_title flex_box flex_align_center flex_space_between'}>
                        <div className={'modalTitleInner'}>アクセントカラー</div>
                        <div className={'right_btn_wrap'}>
                          <button
                            type={'button'}
                            className={'reset'}
                            onClick={() => this.handlerClickCancel()}
                          >
                            &#10005;
                          </button>
                        </div>
                      </div>

                      <div className={'changeBlindModal_cont'}>
                        <div className={'changeBlindModal_contTop'}>
                          <div className={'divide10'} />
                          <div className={'flex_box'}>

                            <div className={'left_box'}>

                              <div className={'svg_wrap'}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x={0}
                                  y={30}
                                  width={418}
                                  height={418}
                                  viewBox={'0 -30 420 420'}
                                >
                                  {this.accentSlatList.map((data, i) => (
                                    <rect
                                      key={'back-rect_' + i}
                                      x={30}
                                      y={10 * (+i) + 10}
                                      width={390}
                                      height={10}
                                      fill={data.replace('0x', '#')}
                                      stroke={'#000000'}
                                      strokeWidth={1}
                                    />
                                  ))}
                                  {accentColor.color ? (
                                    <>
                                      {accentColorType === 'left' ? (
                                        <>
                                          {this.accentSlatList.map((data, i) => (
                                            <rect
                                              key={'accent-rect-l_' + i}
                                              x={30}
                                              y={10 * (+i) + 10}
                                              width={80}
                                              height={10}
                                              fill={accentColor.color.replace('0x', '#')}
                                              stroke={'#000000'}
                                              strokeWidth={1}
                                              opacity={0.6}
                                            />
                                          ))}
                                        </>
                                      ) : (<></>)}
                                      {accentColorType === 'right' ? (
                                        <>
                                          {this.accentSlatList.map((data, i) => (
                                            <rect
                                              key={'accent-rect-r_' + i}
                                              x={420 - 80}
                                              y={10 * (+i) + 10}
                                              width={80}
                                              height={10}
                                              fill={accentColor.color.replace('0x', '#')}
                                              stroke={'#000000'}
                                              strokeWidth={1}
                                              opacity={0.6}
                                            />
                                          ))}
                                        </>
                                      ) : (<></>)}
                                      {accentColorType === 'both' ? (
                                        <>
                                          {this.accentSlatList.map((data, i) => (
                                            <g
                                              key={'accent-g_' + i}
                                            >
                                              <rect
                                                x={30}
                                                y={10 * (+i) + 10}
                                                width={80}
                                                height={10}
                                                fill={accentColor.color.replace('0x', '#')}
                                                stroke={'#000000'}
                                                strokeWidth={1}
                                                opacity={0.6}
                                              />
                                              <rect
                                                x={420 - 80}
                                                y={10 * (+i) + 10}
                                                width={80}
                                                height={10}
                                                fill={accentColor.color.replace('0x', '#')}
                                                stroke={'#000000'}
                                                strokeWidth={1}
                                                opacity={0.6}
                                              />
                                            </g>
                                          ))}
                                        </>
                                      ) : (<></>)}
                                    </>
                                  ) : (<></>)}
                                </svg>
                              </div>

                              <div className={'selectColorType_wrap'}>
                                <div className={'selectColorTypeInnerTitle'}>
                                  アクセントカラー種類選択
                                </div>
                                <div className={'divide10 spMin_5'} />
                                <div className={'radioBtn_wrap flex_box flex_align_center'}>
                                  <div
                                    onClick={() => this.setState({ accentColorType: 'left' })}
                                    className={'radioItem cursor_p flex_box flex_align_center' + (accentColorType === 'left' ? ' isChecked' : '')}
                                  >
                                    <div>
                                      <img
                                        src={iconRadio}
                                        srcSet={`${iconRadio} 1x, ${iconRadioRet} 2x`}
                                        alt={'チェック'}
                                        className={'noCheck'}
                                      />
                                      <img
                                        src={iconRadioChecked}
                                        srcSet={`${iconRadioChecked} 1x, ${iconRadioCheckedRet} 2x`}
                                        alt={'チェックなし'}
                                        className={'checked'}
                                      />
                                    </div>
                                    <div>片サイド:左</div>
                                  </div>
                                  <div
                                    onClick={() => this.setState({ accentColorType: 'right' })}
                                    className={'radioItem cursor_p flex_box flex_align_center' + (accentColorType === 'right' ? ' isChecked' : '')}
                                  >
                                    <div>
                                      <img
                                        src={iconRadio}
                                        srcSet={`${iconRadio} 1x, ${iconRadioRet} 2x`}
                                        alt={'チェック'}
                                        className={'noCheck'}
                                      />
                                      <img
                                        src={iconRadioChecked}
                                        srcSet={`${iconRadioChecked} 1x, ${iconRadioCheckedRet} 2x`}
                                        alt={'チェックなし'}
                                        className={'checked'}
                                      />
                                    </div>
                                    <div>片サイド:右</div>
                                  </div>

                                  <div
                                    onClick={() => this.setState({ accentColorType: 'both' })}
                                    className={'radioItem cursor_p flex_box flex_align_center' + (accentColorType === 'both' ? ' isChecked' : '')}
                                  >
                                    <div>
                                      <img
                                        src={iconRadio}
                                        srcSet={`${iconRadio} 1x, ${iconRadioRet} 2x`}
                                        alt={'チェック'}
                                        className={'noCheck'}
                                      />
                                      <img
                                        src={iconRadioChecked}
                                        srcSet={`${iconRadioChecked} 1x, ${iconRadioCheckedRet} 2x`}
                                        alt={'チェックなし'}
                                        className={'checked'}
                                      />
                                    </div>
                                    <div>両サイド</div>
                                  </div>

                                  <div
                                    onClick={() => this.setState({ accentColorType: 'none' })}
                                    className={'radioItem cursor_p flex_box flex_align_center' + (accentColorType === 'none' ? ' isChecked' : '')}
                                  >
                                    <div>
                                      <img
                                        src={iconRadio}
                                        srcSet={`${iconRadio} 1x, ${iconRadioRet} 2x`}
                                        alt={'チェック'}
                                        className={'noCheck'}
                                      />
                                      <img
                                        src={iconRadioChecked}
                                        srcSet={`${iconRadioChecked} 1x, ${iconRadioCheckedRet} 2x`}
                                        alt={'チェックなし'}
                                        className={'checked'}
                                      />
                                    </div>
                                    <div>なし</div>
                                  </div>

                                </div>
                              </div>

                            </div>
                            {/* //.left_box */}

                            <div className={'right_box'}>
                              <div className={'divide5'} />
                              <div className={'displayArea displayColorList'}>
                                <div className={'list_wrap'}>
                                  <div className={'colorTypeName'}>
                                    <h4>カラーを選択</h4>
                                  </div>
                                  <div className={'listBody flex_box flex_container_wrap'}>
                                    {accentList.map((data, i) => (
                                      <div
                                        className={'listItem_wrap'}
                                        key={'accent-color-code_' + data.color_code + '_' + i}
                                      >
                                        <div className={'listItemInner_wrap'}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x={0}
                                            y={0}
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            onClick={() => this.handlerClickAccentColorTip(data)}
                                          >
                                            {data.image_file ? (
                                              <image
                                                xlinkHref={RequestBase.HOST_URL + data.image_file}
                                                x={0}
                                                y={0}
                                                width={38}
                                                height={38}
                                                preserveAspectRatio={'none'}
                                              />
                                            ) : (
                                              <rect
                                                x={0}
                                                y={0}
                                                width={38}
                                                height={38}
                                                fill={data.color.replace('0x', '#')}
                                              />
                                            )}
                                          </svg>
                                        </div>
                                        <div className={'listItemName'}>{data.color_code || ''}{data.color_code ? (
                                          <br />) : (<></>)}{data.color_name || ''}</div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* //.right_box */}

                          </div>
                        </div>

                        <div className={'changeBlindModal_contBottom flex_box flex_align_center flex_content_center'}>
                          <div>
                            <button
                              type={'button'}
                              className={'reset'}
                              onClick={() => this.handlerClickLink(`https://www.nichi-bei.co.jp/jsp/nws/explanation/transparency.php?v=${App.VERSION}`)}
                            >
                              <img
                                src={btnChangeBLindPermeability}
                                srcSet={`${btnChangeBLindPermeability} 1x, ${btnChangeBLindPermeabilityRet} 2x`}
                                alt={'透過度についてはコチラ'}
                              />
                            </button>
                          </div>
                          <div>
                            <button
                              type={'button'}
                              className={'reset'}
                              onClick={() => this.handlerClickCancel()}
                            >
                              <img
                                src={btnChangeBLindCancel}
                                srcSet={`${btnChangeBLindCancel} 1x, ${btnChangeBLindCancelRet} 2x`}
                                alt={'キャンセル'}
                              />
                            </button>
                          </div>
                          <div>
                            <button
                              type={'button'}
                              className={'reset'}
                              onClick={() => this.handlerClickAccentOk()}
                            >
                              <img
                                src={btnChangeBLindDo}
                                srcSet={`${btnChangeBLindDo} 1x, ${btnChangeBLindDoRet} 2x`}
                                alt={'決定'}
                              />
                            </button>
                          </div>
                          <div>
                            <button
                              type={'button'}
                              className={'reset'}
                              onClick={() => this.handlerClickLink(`https://www.nichi-bei.co.jp/jsp/nws/explanation/accent.php?v=${App.VERSION}`)}
                            >
                              <img
                                src={btnAccentColorDescription}
                                srcSet={`${btnAccentColorDescription} 1x, ${btnAccentColorDescriptionRet} 2x`}
                                alt={'アクセントカラーについてはコチラ'}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* //.changeBlindModal_cont */}

                    </div>
                    {/* //.changeBlindModal_body */}

                  </div>
                  {/* //.accentColorModal_wrap */}
                </>
              ) : (<></>)}
              {/* ↑↑↑アクセントカラーモーダル↑↑↑ */}

            </div>
          </div>
          {/* //.left_wrap */}
          {/* --------------------------------------- left end --------------------------------------- */}

          {/* --------------------------------------- right --------------------------------------- */}
          <div
            id={'simulationRightArea'}
            className={'right_wrap'}
          >
            <div className={'right_header flex_box'}>
              <div className={'spec_wrap'}>
                <BlindSpecComponent />
              </div>

              <div className={'btn_wrap'}>
                <button
                  onClick={() => this.handlerClickSaveButton()}
                  className={'reset'}
                  disabled={!DrawManager.blindSelected}
                >
                  <img
                    src={imgBtnSaveImageSheet}
                    srcSet={`${imgBtnSaveImageSheet} 1x, ${imgBtnSaveImageSheetRet} 2x`}
                    alt={'イメージシート作成'}
                  />
                </button>
              </div>
            </div>
            {/* //.right_header */}


            {/* 画面右の操作エリア（ボタン込み） */}
            <div className={'right_body'}>
              <MenuComponent />
            </div>
          </div>
          {/* //.right_wrap */}
          {/* --------------------------------------- right end --------------------------------------- */}

        </div>

      </div>
    );
  }

  // オリジナルハニカム
  honeycombConditions(list: ColorList, targetPos: number): Boolean {
    if (CanvasConfService.ins.detail_blind.unique_id !== '146') {
      return false;
    }
    if (targetPos === -1) {
      return !(list.group_name === 'みなもⅡ<防炎>' || list.group_name === 'デリス遮熱<防炎>');
    } else {
      if (list.group_name === 'みなもⅡ<防炎>' || list.group_name === 'デリス遮熱<防炎>') {
        return true;
      }
    }
    return false;
  }

  private handlerPush(color: ColorInfo): void {
    if (CanvasConfService.ins.responseDataBlindDetail.color_change_type !== 'VMIX') {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.isPushing = true;
    this.timeout = setTimeout(() => {
      if (this.isPushing) {
        CanvasConfService.isColorChangeConfirm(true, () => {
          const dummyList: { value: string, colorCode: string }[] = [];
          const colorList: { value: string, colorCode: string }[] = [];
          const groupNum = this.state.currentGroupNum;
          const selectGroupNum = this.selectedGroupNum;
          const targetNum = this.state.targetPos;
          DrawManager.getSlatElementFromUrl(
            CanvasConfService.ins.slatImg.indexOf('va') === -1 ? this.testSlatYoko : this.testSlat,
            color.color.replace('0x', '#'),
            color.texture_color.replace('0x', '#'),
          ).then((svgRes) => {
            const svgData = new XMLSerializer().serializeToString(svgRes);
            const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
            DrawManager.getFileFromUrl(data)
              .then((res: string) => {
                for (let i: number = 0; i < this.state.slatColorList.length; i++) {
                  this.slatDataList[i] = res;
                }
              });
          });
          DrawManager.getSlatElementFromUrl(
            this.testBottom,
            color.color.replace('0x', '#'),
            color.texture_color.replace('0x', '#'),
          ).then((svgRes) => {
            const svgData = new XMLSerializer().serializeToString(svgRes);
            const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
            DrawManager.getFileFromUrl(data)
              .then((res: string) => {
                for (let i: number = 0; i < this.state.slatColorList.length; i++) {
                  this.bottomDataList[i] = res;
                }
              });
          });
          for (let i: number = 0; i < this.state.slatColorList.length; i++) {
            dummyList.push({
              value: color.color.replace('0x', '#'),
              colorCode: color.color_code,
            });
          }
          this.selectedGroupNum = this.state.currentGroupNum;
          this.setState({
            slatColorList: dummyList,
            // targetPos: 0,
          });
        });
      }
    }, 750);
  }

  private getImageSize(top: string, bottom: string): Promise<{ width: number, height: number }[]> {
    return new Promise((resolve: (value: { width: number, height: number }[]) => void) => {
      Promise.all([
        new Promise((resolve: (value: { width: number, height: number }) => void) => {
          if (top) {
            const image = new Image();
            image.onload = () => resolve({ width: image.width, height: image.height });
            // image.src = RequestBase.HOST_URL + CanvasConfService.ins.responseDataColorInfo.image_file;
            image.src = RequestBase.HOST_URL + top;
          } else {
            resolve({ width: 0, height: 0 });
          }
        }),
        new Promise((resolve: (value: { width: number, height: number }) => void) => {
          if (bottom) {
            const image = new Image();
            image.onload = () => resolve({ width: image.width, height: image.height });
            // image.src = RequestBase.HOST_URL + CanvasConfService.ins.underSlatData.image_file;
            image.src = RequestBase.HOST_URL + bottom;
          } else {
            resolve({ width: 0, height: 0 });
          }
        }),
      ]).then((res) => resolve(res));
    });
  }

  private handlerClickAccentOk(): void {
    CanvasConfService.ins.accentColor.color = this.state.accentColor;
    CanvasConfService.ins.accentColor.acctentType = this.state.accentColorType;
    CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
    this.setState({
      isDisplayAccentColorModal: false,
      isDisplaySlatModal: false,
    });
  }

  private handlerClickAccentColorTip(color: ColorInfo): void {
    this.setState({
      accentColor: color,
    });
  }

  private dialogScroll(): void {
    const div = document.getElementById(this.dialogId);
    if (!div) {
      return;
    }
    const slatNum: number = this.state.slatColorList.length;
    const slatSize: number = 420 / slatNum < 15 ? 15 : 420 / slatNum;
    const pos = ((slatSize * this.state.targetPos) + 10) + ((slatSize / 2) - 10);
    if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'MIX') {
      div.scrollLeft = pos - div.getBoundingClientRect().width / 2;
    } else if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'VMIX') {
      div.scrollTop = pos - div.getBoundingClientRect().height / 2;
    }
  }

  private handlerClickBack(): void {
    const anchorEle: HTMLAnchorElement = document.createElement('a');
    anchorEle.href = `https://www.nichi-bei.co.jp/jsp/nws/title.php?v=${App.VERSION}`;
    anchorEle.target = '_self';
    anchorEle.click();
  }

  private handlerClickLink(url: string): void {
    const anchorEle: HTMLAnchorElement = document.createElement('a');
    // anchorEle.download = 'export.pdf';
    anchorEle.href = url;
    anchorEle.target = '_blank';
    anchorEle.click();
  }

  private handlerClickSaveButton(): void {
    if (!DrawManager.blindSelected) {
      return;
    }
    CanvasConfService.isSave(true);
    const logReq = new XMLHttpRequest();
    logReq.open('GET', `https://nws.bitpark.co.jp/php/nlog2.php?${LogManager.createParam()}`);
    logReq.addEventListener('load', (e) => {
      console.group('');
      console.log(e);
      console.groupEnd();
    });
    logReq.addEventListener('error', (e) => {
      console.error(e);
    });
    logReq.send();
  }

  private handlerClickCancel(): void {
    this.setState({ isDisplaySlatModal: false, isDisplayAccentColorModal: false });
    if (!CanvasConfService.ins.blindData.isStandardBlind && CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN') {
      if (CanvasConfService.ins.responseDataColorInfo.image_file) {
        const image = new Image();
        image.onload = () => {
          CanvasConfService.ins.blindData.imgSizeWidth = image.width;
          CanvasConfService.ins.blindData.imgSizeHeight = image.height;
        };
        image.src = RequestBase.HOST_URL + CanvasConfService.ins.responseDataColorInfo.image_file;
        DrawManager.getFileFromUrl(RequestBase.HOST_URL + CanvasConfService.ins.responseDataColorInfo.image_file)
          .then((res: string) => {
            CanvasConfService.ins.blindData.displayImgPath = res;
          })
          .catch((err) => console.log(err));
      }
      if (CanvasConfService.ins.underSlatData.image_file) {
        DrawManager.getFileFromUrl(RequestBase.HOST_URL + CanvasConfService.ins.underSlatData.image_file)
          .then((res: string) => {
            CanvasConfService.ins.blindData.displayImgPathUnder = res;
          })
          .catch((err) => console.log(err));
      }
    }
  }

  private handlerClickDown(): void {
    if (this.state.isSelectRace) {
      if (this.state.targetPosRace > 0) {
        this.setState({
          targetPosRace: this.state.targetPosRace - 1,
        });
      } else {
        this.setState({
          targetPosRace: SlatModalService.ins.slatColor.length - 2,
        });
      }
    } else if (this.state.isSelectBack) {
      if (this.state.targetPosBack > 0) {
        this.setState({
          targetPosBack: this.state.targetPosBack - 1,
        });
      } else {
        this.setState({
          targetPosBack: SlatModalService.ins.slatColor.length - 1,
        });
      }
    } else {
      if (this.state.targetPos > 0) {
        this.setState({
          targetPos: this.state.targetPos - 1,
        });
      } else {
        this.setState({
          targetPos: SlatModalService.ins.slatColor.length - 1,
        });
      }
    }
  }

  private handlerClickUp(): void {
    if (this.state.isSelectRace) {
      if (this.state.targetPosRace < SlatModalService.ins.slatColor.length - 2) {
        this.setState({
          targetPosRace: this.state.targetPosRace + 1,
        });
      } else {
        this.setState({
          targetPosRace: 0,
        });
      }
    } else if (this.state.isSelectBack) {
      if (this.state.targetPosBack < this.state.slatColorList.length - 1) {
        this.setState({
          targetPosBack: this.state.targetPosBack + 1,
        });
      } else {
        this.setState({
          targetPosBack: 0,
        });
      }
    } else {
      if (this.state.targetPos < SlatModalService.ins.slatColor.length - 1) {
        this.setState({
          targetPos: this.state.targetPos + 1,
        });
      } else {
        this.setState({
          targetPos: 0,
        });
      }
    }
  }

  private handlerClickColorOriginCenter(option: OptionDetail): void {
    if (this.state.isSelectRace) {
      if (this.state.currentGroupNumRace === this.state.selectGroupNumRace) {
        this.originRaceList[this.state.targetPosRace] = { ...option };
        this.handlerClickUp();
      } else {
        const { color, series_name, color_code, color_name, id, image, image_file, option_type } = option;
        this.originRaceList.forEach((data) => {
          data.color = color;
          data.series_name = series_name;
          data.color_code = color_code;
          data.color_name = color_name;
          data.id = id;
          data.image = image;
          data.image_file = image_file;
          data.option_type = option_type;
        });
        this.setState({
          selectGroupNumRace: this.state.selectGroupNumRace ? 0 : 1,
          currentGroupNumRace: this.state.selectGroupNumRace ? 0 : 1,
          targetPosRace: 0,
        });
      }
    } else if (this.state.isSelectBack) {
      if (this.state.currentGroupNumBack === this.state.selectGroupNumBack) {
        this.originRaceList[this.state.targetPosBack] = { ...option };
        this.handlerClickUp();
      } else {
        const { color, series_name, color_code, color_name, id, image, image_file, option_type } = option;
        this.originRaceList.forEach((data) => {
          data.color = color;
          data.series_name = series_name;
          data.color_code = color_code;
          data.color_name = color_name;
          data.id = id;
          data.image = image;
          data.image_file = image_file;
          data.option_type = option_type;
        });
        this.setState({
          selectGroupNumBack: this.state.currentGroupNumBack,
          currentGroupNumBack: this.state.currentGroupNumBack,
          targetPosRace: 0,
        });
      }
    } else {
      if (this.state.currentGroupNumBack === this.state.selectGroupNumBack) {
        this.originRaceList[this.state.targetPosBack] = { ...option };
        this.handlerClickUp();
      } else {
        const { color, series_name, color_code, color_name, id, image, image_file, option_type } = option;
        this.originRaceList.forEach((data) => {
          data.color = color;
          data.series_name = series_name;
          data.color_code = color_code;
          data.color_name = color_name;
          data.id = id;
          data.image = image;
          data.image_file = image_file;
          data.option_type = option_type;
        });
        this.setState({
          selectGroupNumBack: this.state.selectGroupNumBack ? 0 : 1,
          currentGroupNumBack: this.state.currentGroupNumBack ? 0 : 1,
          targetPosRace: 0,
        });
      }
    }
  }

  private handlerClickColor(color: ColorInfo): void {
    const { targetPos } = this.state;
    const dummyList: { value: string, colorCode: string }[] = [];
    const colorList: { value: string, colorCode: string }[] = [];
    if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN') {
      if (CanvasConfService.ins.blindData.isStandardBlind) {
        if (color.image_file) {
          const image = new Image();
          image.onload = () => {
            CanvasConfService.ins.blindData.imgSizeWidth = image.width;
            CanvasConfService.ins.blindData.imgSizeHeight = image.height;
          };
          image.src = RequestBase.HOST_URL + color.image_file;
          DrawManager.getFileFromUrl(RequestBase.HOST_URL + color.image_file)
            .then((res: string) => {
              CanvasConfService.ins.blindData.displayImgPath = res;
            })
            .catch((err) => console.log(err));
        }
        if (color.sub_color_code) {
          ConnectionService.ins.connect(new GetColorsByCode(color.sub_color_code))
            .then((response: AxiosResponse<ColorInfo[]>) => {
              this.selectedGroupNum = this.state.currentGroupNum;
              if (targetPos === -1) {
                this.createColorList(
                  color.color,
                  color.texture_color,
                  response.data[0].color,
                  response.data[0].texture_color,
                ).forEach((data) => {
                  colorList.push({
                    value: data,
                    colorCode: color.color_code,
                  });
                });
                this.getImageSize(color.image_file, response.data[0].image_file)
                  .then((res) => {
                    this.setState({
                      slatColorList: colorList,
                      baseColorInfo: color,
                      underColorInfo: response.data[0],
                      topImage: res[0],
                      bottomImage: res[1],
                    });
                  });
              } else {
                this.createColorList(
                  response.data[0].color,
                  response.data[0].texture_color,
                  color.color,
                  color.texture_color,
                ).forEach((data) => {
                  colorList.push({
                    value: data,
                    colorCode: color.color_code,
                  });
                });
                this.getImageSize(response.data[0].image_file, color.image_file)
                  .then((res) => {
                    this.setState({
                      slatColorList: colorList,
                      baseColorInfo: response.data[0],
                      underColorInfo: color,
                      topImage: res[0],
                      bottomImage: res[1],
                    });
                  });
              }
            })
            .catch((err) => console.log(err));
        } else if (color.image_file) {
          this.selectedGroupNum = this.state.currentGroupNum;
          if (targetPos === -1) {
            this.createColorList().forEach((data) => {
              colorList.push({
                value: data,
                colorCode: color.color_code,
              });
            });
            this.getImageSize(color.image_file, color.image_file)
              .then((res) => {
                this.setState({
                  slatColorList: colorList,
                  baseColorInfo: color,
                  underColorInfo: color,
                  topImage: res[0],
                  bottomImage: res[1],
                });
              });
          } else {
            this.createColorList().forEach((data) => {
              colorList.push({
                value: data,
                colorCode: color.color_code,
              });
            });
            this.getImageSize(color.image_file, color.image_file)
              .then((res) => {
                this.setState({
                  slatColorList: colorList,
                  baseColorInfo: color,
                  underColorInfo: color,
                  topImage: res[0],
                  bottomImage: res[1],
                });
              });
          }
        }
      } else {
        if (color.image_file) {
          const image = new Image();
          image.onload = () => {
            CanvasConfService.ins.blindData.imgSizeWidth = image.width;
            CanvasConfService.ins.blindData.imgSizeHeight = image.height;
          };
          image.src = RequestBase.HOST_URL + color.image_file;
          DrawManager.getFileFromUrl(RequestBase.HOST_URL + color.image_file)
            .then((res: string) => {
              if (targetPos === -1) {
                CanvasConfService.ins.blindData.displayImgPath = res;
              } else {
                CanvasConfService.ins.blindData.displayImgPathUnder = res;
              }
            })
            .catch((err) => console.log(err));
        }
        this.selectedGroupNum = this.state.currentGroupNum;
        if (targetPos === -1) {
          this.createColorList(
            color.color,
            color.texture_color,
            this.state.underColorInfo.color,
            this.state.underColorInfo.texture_color,
          ).forEach((data) => {
            colorList.push({
              value: data,
              colorCode: color.color_code,
            });
          });
          this.getImageSize(color.image_file, this.state.underColorInfo.image_file)
            .then((res) => {
              this.setState({
                slatColorList: colorList,
                baseColorInfo: color,
                topImage: res[0],
              });
            });
        } else {
          this.createColorList(
            this.state.baseColorInfo.color,
            this.state.baseColorInfo.texture_color,
            color.color,
            color.texture_color,
          ).forEach((data) => {
            colorList.push({
              value: data,
              colorCode: color.color_code,
            });
          });
          this.getImageSize(this.state.baseColorInfo.image_file, color.image_file)
            .then((res) => {
              this.setState({
                slatColorList: colorList,
                underColorInfo: color,
                bottomImage: res[1],
              });
            });
        }
      }
    } else {
      /**
       *
       * スラット単位で色を変える場合、ここでbase64エンコードまで行う
       *
       */
      this.dummyNameList[targetPos - 1] = color.color_code;
      const groupNum = this.state.currentGroupNum;
      const selectGroupNum = this.selectedGroupNum;
      const targetNum = this.state.targetPos;
      DrawManager.getSlatElementFromUrl(
        CanvasConfService.ins.slatImg.indexOf('va') === -1 ? this.testSlatYoko : this.testSlat,
        color.color.replace('0x', '#'),
        color.texture_color.replace('0x', '#'),
      ).then((svgRes) => {
        const svgData = new XMLSerializer().serializeToString(svgRes);
        const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
        DrawManager.getFileFromUrl(data)
          .then((res: string) => {
            if (selectGroupNum === groupNum || CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'VMIX') {
              this.slatDataList[targetNum] = res;
            } else {
              for (let i: number = 0; i < this.state.slatColorList.length; i++) {
                this.slatDataList[i] = res;
              }
            }
          });
      });
      DrawManager.getSlatElementFromUrl(
        this.testBottom,
        color.color.replace('0x', '#'),
        color.texture_color.replace('0x', '#'),
      ).then((svgRes) => {
        const svgData = new XMLSerializer().serializeToString(svgRes);
        const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
        DrawManager.getFileFromUrl(data)
          .then((res: string) => {
            if (selectGroupNum === groupNum || CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'VMIX') {
              this.bottomDataList[targetNum] = res;
            } else {
              for (let i: number = 0; i < this.state.slatColorList.length; i++) {
                this.bottomDataList[i] = res;
              }
            }
          });
      });
      if (this.selectedGroupNum === this.state.currentGroupNum || CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'VMIX') {
        for (let i: number = 0; i < this.state.slatColorList.length; i++) {
          dummyList.push(i === this.state.targetPos ? {
            value: color.color.replace('0x', '#'),
            colorCode: color.color_code,
          } : this.state.slatColorList[i]);
        }
        this.setState({
          slatColorList: dummyList,
          targetPos: ((this.state.targetPos < dummyList.length - 1) ? this.state.targetPos + 1 : 0),
        });
      } else {
        for (let i: number = 0; i < this.state.slatColorList.length; i++) {
          dummyList.push({
            value: color.color.replace('0x', '#'),
            colorCode: color.color_code,
          });
        }
        this.selectedGroupNum = this.state.currentGroupNum;
        this.setState({
          slatColorList: dummyList,
          targetPos: 0,
        });
      }
    }
  }

  private handlerClickDecide(): void {
    const dummyColorList: Colors[] = [];
    if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN') {
      CanvasConfService.ins.responseDataColorInfo = this.state.baseColorInfo;
      CanvasConfService.ins.underSlatData = this.state.underColorInfo;
      CanvasConfService.ins.updateBlindSpec();
    } else {
      CanvasConfService.ins.centerRaceColorArr = [...this.originRaceList];
      if (this.state.currentGroupNum === this.selectedGroupNum) {
        CanvasConfService.ins.responseDataColorInfo = SlatModalService.ins.colorList[this.state.currentGroupNum].colors[0];
      }
      for (let i: number = 0; i < this.state.slatColorList.length; i++) {
        dummyColorList.push({
          name: this.dummyNameList[i],
          color: this.state.slatColorList[i].value,
          color_code: this.state.slatColorList[i].colorCode,
        });
      }
      SlatModalService.ins.slatColor = dummyColorList;
      SlatModalService.ins.slatSvgList = this.slatDataList;
      SlatModalService.ins.bottomSvgList = this.bottomDataList;
    }
    CanvasConfService.ins.updateBlindColor(dummyColorList);
    this.setState({
      isDisplaySlatModal: false,
    });
  }

  private createColorList(
    baseColor: string = CanvasConfService.ins.responseDataColorInfo.color,
    baseTexture: string = CanvasConfService.ins.responseDataColorInfo.texture_color,
    underColor: string = CanvasConfService.ins.underSlatData.color,
    underTexture: string = CanvasConfService.ins.underSlatData.texture_color,
  ): string[] {
    const value: string[] = [];
    for (let i: number = 0; i < 5; i++) {
      value.push(baseColor.replace('0x', '#'));
      value.push(baseTexture.replace('0x', '#'));
    }
    for (let i: number = 0; i < 5; i++) {
      value.push(underColor.replace('0x', '#'));
      value.push(underTexture.replace('0x', '#'));
    }
    return value;
  }

  private handlerClickColorGroup(i: number): void {
    if (this.state.isSelectRace) {
      this.setState({
        currentGroupNumRace: i,
      });
      return;
    }
    if (this.state.isSelectBack) {
      this.setState({
        currentGroupNumBack: i,
      });
      return;
    }
    if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN') {
      this.setState({
        currentGroupNum: i,
      });
    } else {
      const colorList: string[] = [];
      this.state.slatColorList.forEach(() => colorList.push(SlatModalService.ins.colorList[i].colors[0].color.replace('0x', '#')));
      this.setState({
        // slatColorList: colorList,
        currentGroupNum: i,
      });
    }
  }
}

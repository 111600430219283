import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';

/**
 * ブラインドタイプ一覧取得
 */
export class GetSubBlindList extends RequestBase {

  param: {
    sub_id: number;
  };

  constructor(
    sub_id: number,
  ) {
    super(HTTPMethod.GET, '/api/subblind/sub_blind_list');
    this.param = {
      sub_id: sub_id,
    };
  }

}

export class SubBlindDetail {
  id: number = -1;
  sub_id: number = -1;
  type: string = '';
  parent_id: number = -1;
  slat_img: string = '';
  top_img: string = '';
  bottom_img: string = '';
  screen_img: string = '';
  middlebar_img: string = '';
  rope_img: string = '';
  balance_img: string = '';
  detail_blind: detail_blind[] = [];
}

export class detail_blind {
  id: string = '';
  parent_id: string = '';
  unique_id: string = '';
  type_code: string = '';
  color_table: string = '';
  width: string = '';
  height: string = '';
  square: string = '';
  calc: string = '';
  calc_type: string = '';
  irregular: string = '';
  headbox_table: string = '';
  headbox: string = '';
  bottom_table: string = '';
  bottom: string = '';
  wide_table: string = '';
  wide: string = '';
  wood_table: string = '';
  wood: string = '';
  center_table: string = '';
  color: string = '';
  open: string = '';
  cover: string = '';
}
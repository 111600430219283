import * as React from 'react';
import { MenuBackgroundComponent } from './menu-content/background/menu-background-component';
import { MenuBlindComponent } from './menu-content/blind/menu-blind-component';
import { MenuColorComponent } from './menu-content/color/menu-color-component';
import { MenuOptionComponent } from './menu-content/option/menu-option-component';
import { MenuSizeComponent } from './menu-content/size/menu-size-component';
import { ReactElement } from 'react';
import { ColorGenerator } from '../../../../util/color-generator';
import './menu-component.scss';
import iconMenu1 from '../../../../assets/img/simulator/icon_menu_1.png';
import iconMenu1Ret from '../../../../assets/img/simulator/icon_menu_1@2x.png';
import iconMenu1Active from '../../../../assets/img/simulator/icon_menu_1_active.png';
import iconMenu1ActiveRet from '../../../../assets/img/simulator/icon_menu_1_active@2x.png';
import iconMenu2 from '../../../../assets/img/simulator/icon_menu_2.png';
import iconMenu2Ret from '../../../../assets/img/simulator/icon_menu_2@2x.png';
import iconMenu2Active from '../../../../assets/img/simulator/icon_menu_2_active.png';
import iconMenu2ActiveRet from '../../../../assets/img/simulator/icon_menu_2_active@2x.png';
import iconMenu3 from '../../../../assets/img/simulator/icon_menu_3.png';
import iconMenu3Ret from '../../../../assets/img/simulator/icon_menu_3@2x.png';
import iconMenu3Active from '../../../../assets/img/simulator/icon_menu_3_active.png';
import iconMenu3ActiveRet from '../../../../assets/img/simulator/icon_menu_3_active@2x.png';
import iconMenu4 from '../../../../assets/img/simulator/icon_menu_4.png';
import iconMenu4Ret from '../../../../assets/img/simulator/icon_menu_4@2x.png';
import iconMenu4Active from '../../../../assets/img/simulator/icon_menu_4_active.png';
import iconMenu4ActiveRet from '../../../../assets/img/simulator/icon_menu_4_active@2x.png';
import iconMenu5 from '../../../../assets/img/simulator/icon_menu_5.png';
import iconMenu5Ret from '../../../../assets/img/simulator/icon_menu_5@2x.png';
import iconMenu5Active from '../../../../assets/img/simulator/icon_menu_5_active.png';
import iconMenu5ActiveRet from '../../../../assets/img/simulator/icon_menu_5_active@2x.png';
import { SlatModalService } from '../../../../service/slat-modal-service';
import { ConnectionService } from '../../../../service/connection-service';
import { ColorInfo, GetColorsById } from '../../../../model/api/color/get-colors-by-id';
import { AxiosResponse } from 'axios';
import { ColorList, GetColorGroup } from '../../../../model/api/color/get-color-group';
import { CanvasConfService } from '../../../../service/canvas-conf-service';
import { DrawManager } from '../../../../manager/draw-manager';

interface MenuComponentProps {
}

interface MenuComponentState {
  currentContent: CurrentContent;
  rightMenuHeight: number;
}

export class MenuComponent extends React.Component<MenuComponentProps, MenuComponentState> {
  private isDefault: boolean = false;

  constructor(props: MenuComponentProps) {
    super(props);
    this.state = {
      currentContent: CurrentContent.background,
      rightMenuHeight: 0,
    };
  }

  render() {
    const { currentContent } = this.state;
    const contentComponent: ReactElement =
      currentContent === CurrentContent.background ? <MenuBackgroundComponent sampleBackgroundComponent={null} /> :
        currentContent === CurrentContent.blind ? <MenuBlindComponent default={this.isDefault} /> :
          currentContent === CurrentContent.color ? <MenuColorComponent /> :
            currentContent === CurrentContent.option ? <MenuOptionComponent
                default={this.isDefault}
                list={[]}
              /> :
              <MenuSizeComponent />;
    this.isDefault = false;
    return (
      <div className={'right_content_wrap'}>

        {contentComponent}

        <div className={'content_menu_wrap flex_box flex_space_between'}>
          <div
            id={'menuButton'}
            className={'btn_wrap'}
          >
            <button
              onClick={() => this.handlerClickChangeContent(CurrentContent.background)}
              className={'reset' + (currentContent === CurrentContent.background ? ' active' : '')}
            >
              <img
                src={iconMenu1}
                srcSet={`${iconMenu1} 1x, ${iconMenu1Ret} 2x`}
                alt={'背景'}
                className={'def_img'}
                onLoad={
                  () => {
                    if (document.getElementById('isSP') != null) {
                      /**
                       * スマホの時限定
                       * シミュレーション画面の右側の高さを確定させてます
                       */
                      this.setState({
                        rightMenuHeight: (document.getElementById('menuButton') as HTMLElement).clientHeight as number,
                      });
                      setTimeout(
                        () => (document.getElementById('simulationRightArea') as HTMLElement).style.setProperty('height', `calc(100% - ${this.state.rightMenuHeight + 10}px)`),
                      );
                    }
                  }
                }
              />
              <img
                src={iconMenu1Active}
                srcSet={`${iconMenu1Active} 1x, ${iconMenu1ActiveRet} 2x`}
                alt={'背景'}
                className={'active_img'}
              />
            </button>
          </div>
          <div className={'btn_wrap'}>
            <button
              onClick={() => this.handlerClickChangeContent(CurrentContent.size)}
              className={'reset' + (currentContent === CurrentContent.size ? ' active' : '')}
            >
              <img
                src={iconMenu5}
                srcSet={`${iconMenu5} 1x, ${iconMenu5Ret} 2x`}
                alt={'商品サイズ'}
                className={'def_img'}
              />
              <img
                src={iconMenu5Active}
                srcSet={`${iconMenu5Active} 1x, ${iconMenu5ActiveRet} 2x`}
                alt={'商品サイズ'}
                className={'active_img'}
              />
            </button>
          </div>
          <div className={'btn_wrap'}>
            <button
              onClick={() => this.handlerClickChangeContent(CurrentContent.blind)}
              className={'reset' + (currentContent === CurrentContent.blind ? ' active' : '')}
            >
              <img
                src={iconMenu2}
                srcSet={`${iconMenu2} 1x, ${iconMenu2Ret} 2x`}
                alt={'ブラインド'}
                className={'def_img'}
              />
              <img
                src={iconMenu2Active}
                srcSet={`${iconMenu2Active} 1x, ${iconMenu2ActiveRet} 2x`}
                alt={'ブラインド'}
                className={'active_img'}
              />
            </button>
          </div>
          <div className={'btn_wrap'}>
            <button
              onClick={() => this.handlerClickChangeContent(CurrentContent.color)}
              className={'reset' + (currentContent === CurrentContent.color ? ' active' : '')}
              disabled={!DrawManager.blindSelected}
            >
              <img
                src={iconMenu3}
                srcSet={`${iconMenu3} 1x, ${iconMenu3Ret} 2x`}
                alt={'色柄'}
                className={'def_img'}
              />
              <img
                src={iconMenu3Active}
                srcSet={`${iconMenu3Active} 1x, ${iconMenu3ActiveRet} 2x`}
                alt={'色柄'}
                className={'active_img'}
              />
            </button>
          </div>
          <div className={'btn_wrap'}>
            <button
              onClick={() => this.handlerClickChangeContent(CurrentContent.option)}
              className={'reset' + (currentContent === CurrentContent.option ? ' active' : '')}
              disabled={!DrawManager.blindSelected}
            >
              <img
                src={iconMenu4}
                srcSet={`${iconMenu4} 1x, ${iconMenu4Ret} 2x`}
                alt={'オプション'}
                className={'def_img'}
              />
              <img
                src={iconMenu4Active}
                srcSet={`${iconMenu4Active} 1x, ${iconMenu4ActiveRet} 2x`}
                alt={'オプション'}
                className={'active_img'}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   * @param content
   *
   * ボタンを押してコンテンツを選択
   */
  private handlerClickChangeContent(currentContent: CurrentContent): void {
    /**
     * 取得パラメータでモーダル表示を判断
     */
    if (currentContent === CurrentContent.color) {
      if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'TWIN') {
        ConnectionService.ins.connect(new GetColorGroup())
          .then((response: AxiosResponse<ColorInfo[]>) => {
            const arr: ColorList[] = [];
            const res = response.data;
            res.forEach((resData) => {
              const index = arr.findIndex((data) => data.group_name === resData.group_name);
              if (index !== -1) {
                arr[index].colors.push(resData);
              } else {
                arr.push({
                  group_name: resData.group_name,
                  colors: [resData],
                });
              }
            });
            SlatModalService.ins.colorList = arr;
            SlatModalService.ins.displayModalFunc(true);
          })
          .catch((err) => console.log(err));
        return;
      } else if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'MIX') {
        ConnectionService.ins.connect(new GetColorGroup())
          .then((response: AxiosResponse<ColorInfo[]>) => {
            const arr: ColorList[] = [];
            const res = response.data;
            res.forEach((resData) => {
              const index = arr.findIndex((data) => data.group_name === resData.group_name);
              if (index !== -1) {
                arr[index].colors.push(resData);
              } else {
                arr.push({
                  group_name: resData.group_name,
                  colors: [resData],
                });
              }
            });
            SlatModalService.ins.colorList = arr;
            SlatModalService.ins.displayModalFunc(true);
          })
          .catch((err) => console.log(err));
        return;
      } else if (CanvasConfService.ins.responseDataBlindDetail.color_change_type === 'VMIX') {
        ConnectionService.ins.connect(new GetColorGroup())
          .then((response: AxiosResponse<ColorInfo[]>) => {
            const arr: ColorList[] = [];
            response.data.forEach((resData) => {
              const index = arr.findIndex((data) => data.group_name === resData.group_name);
              if (index !== -1) {
                arr[index].colors.push(resData);
              } else {
                arr.push({
                  group_name: resData.group_name,
                  colors: [resData],
                });
              }
            });
            SlatModalService.ins.colorList = arr;
            SlatModalService.ins.displayModalFunc(true);
          })
          .catch((err) => console.log(err));
        return;
      }
    }
    if (currentContent === CurrentContent.blind || currentContent === CurrentContent.option) {
      this.isDefault = true;
    }
    this.setState({
      currentContent: currentContent,
    });
  }

}

enum CurrentContent {
  background,
  blind,
  color,
  option,
  size,
}

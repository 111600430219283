import * as React from 'react';
import {RoutingPass} from '../../../constant/routing-pass';
import {RouteComponentProps, withRouter} from 'react-router';
import './page-top-component.scss';
import imgLogoNichibei from '../../../assets/img/common/logo_nichibei.png';
import imgLogoNichibeiRet from '../../../assets/img/common/logo_nichibei@2x.png';
import imgLogoWindowSimulator from '../../../assets/img/common/logo_windowsimulator.png';
import imgLogoWindowSimulatorRet from '../../../assets/img/common/logo_windowsimulator@2x.png';
import imgLogoWindowSimulatorMin from '../../../assets/img/common/logo_windowsimulator_min.png';
import imgLogoWindowSimulatorMinRet from '../../../assets/img/common/logo_windowsimulator_min@2x.png';
import imgCatch from '../../../assets/img/top/top_catch.png';
import imgCatchRet from '../../../assets/img/top/top_catch@2x.png';
import imgBtnStart from '../../../assets/img/top/top_start.png';
import imgBtnStartRet from '../../../assets/img/top/top_start@2x.png';
import imgBtnNotice from '../../../assets/img/top/menu_btn_2.png';
import imgBtnNoticeRet from '../../../assets/img/top/menu_btn_2@2x.png';
import imgBtnHowto from '../../../assets/img/top/menu_btn_3.png';
import imgBtnHowtoRet from '../../../assets/img/top/menu_btn_3@2x.png';
import imgBtnFaq from '../../../assets/img/top/menu_btn_5.png';
import imgBtnFaqRet from '../../../assets/img/top/menu_btn_5@2x.png';
import imgBtnInfo from '../../../assets/img/top/menu_btn_4.png';
import imgBtnInfoRet from '../../../assets/img/top/menu_btn_4@2x.png';
import App from '../../../App';

interface PageTopComponentProps extends RouteComponentProps {
}

interface PageTopComponentState {
}

export class PageTopComponent extends React.Component<PageTopComponentProps, PageTopComponentState> {

  constructor(props: PageTopComponentProps) {
    super(props);
  }

  render() {
    return (
      <div className={'body_wrap'}>
        <div className={'body_top'}>

          <h1>
            <img
              src={imgLogoNichibei}
              srcSet={`${imgLogoNichibei} 1x, ${imgLogoNichibeiRet} 2x`}
              alt={'ニチベイ'}
              className={'imgLogoNichibei'}
            />
          </h1>

          <div className={'divide50 sp_20 spMin_10'} />

          <h2>
            <img
              src={imgLogoWindowSimulator}
              srcSet={`${imgLogoWindowSimulator} 1x, ${imgLogoWindowSimulatorRet} 2x`}
              alt={'window simulator'}
              className={'marg_auto imgLogoWindowSimulator'}
            />
          </h2>

          <div className={'divide20 spMin_10'} />

          <img
            src={imgLogoWindowSimulatorMin}
            srcSet={`${imgLogoWindowSimulatorMin} 1x, ${imgLogoWindowSimulatorMinRet} 2x`}
            alt={'ウィンドウシミュレーター'}
            className={'marg_auto imgLogoWindowSimulatorMin'}
          />

          <div className={'divide10'} />

          <div className={'catch_wrap'}>
            <img
              src={imgCatch}
              srcSet={`${imgCatch} 1x, ${imgCatchRet} 2x`}
              alt={'window simulator'}
              className={'marg_auto imgCatch'}
            />
          </div>

          <div className={'divide40 sp_30 spMin_20'} />

          <div className={'align_c'}>
            <div
              onClick={() => this.handlerClickGotoSimulatorPage(RoutingPass.SIMULATOR)}
              className={'cursor_p display_inb'}
            >
              <h3>
                <img
                  src={imgBtnStart}
                  srcSet={`${imgBtnStart} 1x, ${imgBtnStartRet} 2x`}
                  alt={'シミュレーションSTART'}
                />
              </h3>
            </div>
          </div>

          {/*<div className={'divide60 sp_30 spMin_10'} />*/}

          <div className={'annotation_wrap align_c'}>
            <div className={'annotationInner_wrap align_l'}>
              動作環境<br />
              HTML5対応のモダンブラウザPC（safari,Edge,Chrome,Firefox）、iOS/iPadOS（Safari）、Android（Chrome）<br />
              <div className={'flex_box'}>
                <div>※</div>
                <div>上記環境を満たしていてもすべての動作を保証するものではありません。</div>
              </div>
              <div className={'flex_box'}>
                <div>※</div>
                <div>Internet Ezplorerでは動作いたしません。</div>
              </div>
              <div className={'flex_box'}>
                <div>※</div>
                <div>ある程度の画面サイズが必要となりますので、タブレットサイズ以上のデバイスでのご利用をお勧めします。</div>
              </div>
            </div>
          </div>{/* //.annotation_wrap */}

          <div className={'btn_menu_wrap flex_box flex_align_center flex_content_center'}>
            {/*<div*/}
            {/*  onClick={() => this.handlerClickGotoAnotherPage(RoutingPass.NOTICE)}*/}
            {/*  className={'cursor_p btn_menu'}*/}
            {/*>*/}
            {/*  <h4>*/}
            {/*    <img*/}
            {/*      src={imgBtnNotice}*/}
            {/*      srcSet={`${imgBtnNotice} 1x, ${imgBtnNoticeRet} 2x`}*/}
            {/*      alt={'ニチベイ'}*/}
            {/*    />*/}
            {/*  </h4>*/}
            {/*</div>*/}

            <div
              onClick={() => this.handlerClickGotoAnotherPage('onlinemanual')}
              className={'cursor_p btn_menu'}
            >
              <h4>
                <img
                  src={imgBtnHowto}
                  srcSet={`${imgBtnHowto} 1x, ${imgBtnHowtoRet} 2x`}
                  alt={'ニチベイ'}
                />
              </h4>
            </div>

            <div
              onClick={() => this.handlerClickGotoAnotherPage('faq')}
              className={'cursor_p btn_menu'}
            >
              <h4>
                <img
                  src={imgBtnFaq}
                  srcSet={`${imgBtnFaq} 1x, ${imgBtnFaqRet} 2x`}
                  alt={'ニチベイ'}
                />
              </h4>
            </div>

            {/*<div*/}
            {/*  onClick={() => this.handlerClickGotoAnotherPage(RoutingPass.INFO)}*/}
            {/*  className={'cursor_p btn_menu'}*/}
            {/*>*/}
            {/*  <h4>*/}
            {/*    <img*/}
            {/*      src={imgBtnInfo}*/}
            {/*      srcSet={`${imgBtnInfo} 1x, ${imgBtnInfoRet} 2x`}*/}
            {/*      alt={'ニチベイ'}*/}
            {/*    />*/}
            {/*  </h4>*/}
            {/*</div>*/}
          </div>

        </div>
      </div>
    );
  }

  private handlerClickGotoSimulatorPage(pageName: RoutingPass): void {
    this.props.history.push(pageName);
  }
  private handlerClickGotoAnotherPage(linkTo: string): void {
    // this.props.history.push(pageName);
    const anchorEle: HTMLAnchorElement = document.createElement('a');
    // anchorEle.download = 'export.pdf';
    anchorEle.href = `https://www.nichi-bei.co.jp/jsp/nws/explanation/${linkTo}.php?v=${App.VERSION}`;
    anchorEle.target = 'blank';
    anchorEle.click();
  }

}

import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {RoutingPass} from '../../../constant/routing-pass';
import './page-info-component.scss'
import imgLogoBack from '../../../assets/img/common/logo_back.png';
import imgLogoBackRet from '../../../assets/img/common/logo_back@2x.png';
import imgLogoWindowSimulator from '../../../assets/img/common/header_logo_window_simulator.png';
import imgLogoWindowSimulatorRet from '../../../assets/img/common/header_logo_window_simulator@2x.png';
import imgTitleheadInfo from '../../../assets/img/common/icon_titlehead_info.png';
import imgTitleheadInfoRet from '../../../assets/img/common/icon_titlehead_info@2x.png';

interface PageInfoComponentProps extends RouteComponentProps {
}

interface PageInfoComponentState {
}

export class PageInfoComponent extends React.Component<PageInfoComponentProps, PageInfoComponentState> {

  constructor(props: PageInfoComponentProps) {
    super(props);
  }

  render() {
    return (
        <div className={'body_wrap'}>
          <div className={'body_info'}>
            <div className={'header_common flex_box flex_align_center'}>
              <div>
                <h1>
                  <button
                    onClick={() => this.props.history.push(RoutingPass.TOP)}
                    className={'reset'}
                  >
                    <img
                      src={imgLogoBack}
                      srcSet={`${imgLogoBack} 1x, ${imgLogoBackRet} 2x`}
                      alt={'トップへ'}
                    />
                  </button>
                </h1>
              </div>
              <div>
                <h2>
                  <img
                    src={imgLogoWindowSimulator}
                    srcSet={`${imgLogoWindowSimulator} 1x, ${imgLogoWindowSimulatorRet} 2x`}
                    alt={'Window Simulator'}
                  />
                </h2>
              </div>
            </div>
            {/* //.header_common end */}

            <div className={'common_body'}>
              <div className={'common_title flex_box flex_align_center'}>
                <div>
                  <img
                    src={imgTitleheadInfo}
                    srcSet={`${imgTitleheadInfo} 1x, ${imgTitleheadInfoRet} 2x`}
                    alt={'免責事項'}
                  />
                </div>
                <div><h3>免責事項</h3></div>
              </div>

              <div className={'common_contentArea'}>

                <div className={'common_cont_wrap'}>
                  <div className={'common_cont_title'}>
                    <div className={'common_cont_titleInner'}>
                      <h4>はじめに</h4>
                    </div>
                  </div>
                  <div className={'common_cont_body'}>
                    本コンテンツ内で生成されるブラインド画像は実際の商品を簡易的に表現したものです。<br/>
                    色柄や質感についての正確性が必要な場合は、お近くの販売店頭にお問い合わせいただき、実物のサンプルをご確認ください。
                  </div>
                  <hr className={'commonContBorder'} />
                </div>

                <div className={'common_cont_wrap'}>
                  <div className={'common_cont_title'}>
                    <div className={'common_cont_titleInner'}>
                      <h4>操作ログの取得と個人情報の取り扱いについて</h4>
                    </div>
                  </div>
                  <div className={'common_cont_body'}>
                    本コンテンツでは、お客様の本コンテンツ内での操作内容を収集・送信します。<br/>
                    尚、取得する情報には個人を特定できる情報は含みません。<br/>
                    収集した情報は当社にて収集・分析を行い、以下の目的のために必要な範囲で利用させていただきます。<br/><br/>
                    １.当社の既存商品の改良やサービス向上に役立てるため。<br/>
                    ２.お客様にご満足いただけるような新商品の開発に役立てるため。<br/>
                    ３.利用状況など統計資料として利用するため。<br/><br/>
                    株式会社ニチベイ
                  </div>
                  <hr className={'commonContBorder'} />
                </div>

              </div>
              {/* //.common_contentArea */}
            </div>
            {/* //.common_body */}

          </div>
        </div>
    );
  }

}

export default withRouter<PageInfoComponentProps>(PageInfoComponent);

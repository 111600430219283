
export class Pos {

  constructor(
    public x: number = 0,
    public y: number = 0
  ) {
  }

}

import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';

/**
 * ブラインド一覧取得
 */
export class GetBlindList extends RequestBase {

  param: {
    order_type: 'standard' | 'original',
  };

  constructor(
    order_type: 'standard' | 'original',
  ) {
    super(HTTPMethod.GET, '/api/blind/blind_list');
    this.param = {
      order_type: order_type,
    };
  }

}

import Axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {HTTPMethod} from '../enum/http-method';
import {APIType} from '../enum/api-type';
import {RequestBase} from '../model/api/request-base';
Axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
export class ConnectionService {

  private static _ins: ConnectionService;
  static get ins(): ConnectionService {
    if (!this._ins) {
      this._ins = new ConnectionService();
    }
    return this._ins;
  }

  private constructor() {}

  /*getFileFromUrl(url: string): Promise<File> {
    const fileName: string = url.split('/')[url.split('/').length - 1];
    return new Promise((resolve, reject) => {
      Axios.get(url, {responseType: 'blob'})
        .then((v: AxiosResponse) => {
          // resolve(new File([v.data], fileName));
          const date = new Date();
          const blob = new Blob([v.data], {type: v.data.type});
          const blobAssigned = Object.assign(blob, {
            name: fileName,
            lastModified: date.getTime(),
            lastModifiedDate: date
          });
          resolve(blobAssigned as File);
        })
        .catch((e: AxiosError) => {
          reject(null);
        });
    });
  }*/

  connect(request: RequestBase): Promise<AxiosResponse> {
    const method: HTTPMethod = request.getMethod();
    // Axios.defaults.withCredentials = true;
    Axios.defaults.headers = {
      'content-type': 'multipart/form-data'
    };
    return new Promise<AxiosResponse>((resolve, reject) => {
      switch (method) {
        case HTTPMethod.GET:
          this.get(request, resolve, reject);
          break;
        case HTTPMethod.POST:
          this.post(request, resolve, reject);
          break;
        case HTTPMethod.DELETE:
          this.delete(request, resolve, reject);
          break;
        case HTTPMethod.PATCH:
          this.patch(request, resolve, reject);
          break;
        default:
          break;
      }
    });
  }

  private get(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    const config: AxiosRequestConfig = request.hasOwnProperty('responseType') ? (
      {'responseType': (<any> request)['responseType']}
    ) : (
      {}
    );
    Axios.get(request.getUrl(), config)
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }

  private post(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    const config: any = request.hasOwnProperty('responseType') ? (
      {'responseType': (<any> request)['responseType']}
    ) : (
      {}
    );
    Axios.post(request.getUrl(), request.getParam(), config)
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }

  private delete(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    Axios.delete(request.getUrl())
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }

  private patch(request: RequestBase, resolve: (v: any) => void, reject: (v: any) => void): void {
    const config: any = request.hasOwnProperty('responseType') ? (
      {'responseType': (<any> request)['responseType']}
    ) : (
      {}
    );
    Axios.patch(request.getUrl(), request.getParam(), config)
      .then((response: AxiosResponse) => {
        this.displayDebugLog(true, request, response);
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        this.displayDebugLog(false, request, error);
        reject(error.response);
      });
  }


  private displayDebugLog(isSuccess: boolean, request: RequestBase, response: AxiosResponse | AxiosError): void {
    const logStyle = {
      green: 'color: #008000',
      red: 'color: #ff0000',
      magenta: 'color: #ff00ff',
      none: '',
    };
    if (isSuccess) {
      console.groupCollapsed(`[%c${(<any> response)['status']}%c] (${this.convertHTTPMethod2String(request.getMethod())}) ${request.getEndPoint()}`, logStyle.green, logStyle.none);
    } else {
      if (!response.hasOwnProperty('response')) return;
      if ((<any>response)['response']) {
        if (!((<any> response)['response']).hasOwnProperty('status')) return;
        console.groupCollapsed(`[%c${(<any> response)['response']['status']}%c] (${this.convertHTTPMethod2String(request.getMethod())}) ${request.getEndPoint()}`, logStyle.red, logStyle.none);
      } else {
        console.groupCollapsed(`[%cNetwork Error%c] (${this.convertHTTPMethod2String(request.getMethod())}) ${request.getEndPoint()}`, logStyle.magenta, logStyle.none);
      }
    }
    console.log('type     : ', this.convertAPIType2String(RequestBase.API_TYPE));
    console.log('method   : ', this.convertHTTPMethod2String(request.getMethod()));
    console.log('host     : ', request.getHost());
    console.log('endpoint : ', request.getEndPoint());
    console.log('url      : ', request.getUrl());
    console.log('param    : ', request.param);
    if (isSuccess) {
      console.group('[Success]');
      console.log('response : ', response);
      console.groupEnd();
    } else {
      console.group('[Error]');
      console.log('response : ', (<any> response)['response']);
      console.groupEnd();
    }
    console.groupEnd();
  }

  private convertAPIType2String(type: APIType): string {
    switch (type) {
      case APIType.develop:
        return 'develop';
      case APIType.distribution:
        return 'distribution';
      default:
        return 'undefined';
    }
  }

  private convertHTTPMethod2String(method: HTTPMethod): string {
    switch (method) {
      case HTTPMethod.GET:
        return 'GET';
      case HTTPMethod.POST:
        return 'POST';
      case HTTPMethod.DELETE:
        return 'DELETE';
      case HTTPMethod.PATCH:
        return 'PATCH';
      default:
        return 'undefined';
    }
  }

}

import * as React from 'react';
import './modal-component.scss';
import {ReactElement} from 'react';
import './modal-component.scss';
import {CanvasConfService} from '../../service/canvas-conf-service';

interface ModalComponentProps {
}

interface ModalComponentState {
  bodyList: ReactElement[],
}

export class ModalComponent extends React.Component<ModalComponentProps, ModalComponentState> {

  static ins: ModalComponent;

  constructor(props: ModalComponentProps) {
    super(props);
    this.state = {
      bodyList: [],
    };
    ModalComponent.ins = this;
  }

  componentDidMount(): void {
  }

  /**
   * Modal を表示する (多重対応)
   *
   * 3重モーダルの場合
   * <div>
   *   <div key="0" style="z-index: 1000">
   *     <div style="z-index: 1002">ボディ</div>
   *   </div>
   *   <div key="1" style="z-index: 1003">
   *     <div style="z-index: 1004">ボディ</div>
   *   </div>
   *   <div key="2" style="z-index: 1006">
   *     <div style="z-index: 1007">背景</div>
   *     <div style="z-index: 1008">ボディ</div>
   *   </div>
   * </div>
   */
  render() {
    const {
      bodyList,
    } = this.state;
    return (
      bodyList.length ? (
        <>
          {bodyList.map((body: ReactElement, index: number) => (
            <div
              key={index} style={{zIndex: 1000 + index * 3}}
              className={'dialog_wrap'}
            >
              {/* 背景は最前面のモーダルだけに表示 */}
              {index === bodyList.length - 1 ? (
                <div
                  className={'dialog_bg'}
                  style={{zIndex: 1001 + index * 3}}
                >
                </div>
              ) : (
                <></>
              )}
              <div
                className={'dialog_body'}
                style={{zIndex: 1002 + index * 3}}
              >
                {body}
              </div>
            </div>
          ))}
        </>
      ) : (
        <></>
      )
    );
  }

  /**
   * モーダルを表示 (追加表示)
   * @param {React.ReactElement} body
   */
  static open(body: ReactElement): void {
    const bodyList: ReactElement[] = this.ins.state.bodyList;
    bodyList.push(body);
    this.ins.setState({
      bodyList: bodyList,
    });
  }

  /**
   * 最前面のモーダルを閉じる
   */
  static close(): void {
    const bodyList: ReactElement[] = this.ins.state.bodyList;
    bodyList.pop();
    this.ins.setState({
      bodyList: bodyList,
    });
  }

  /**
   * すべてのモーダルを閉じる
   */
  static closeAll(): void {
    CanvasConfService.ins.isVertical = false;
    this.ins.setState({
      bodyList: [],
    });
  }

}

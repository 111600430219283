import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {RoutingPass} from '../../../constant/routing-pass';
import './page-notice-component.scss';
import imgLogoNichibei from '../../../assets/img/common/logo_back.png';
import imgLogoNichibeiRet from '../../../assets/img/common/logo_back@2x.png';
import imgLogoWindowSimulator from '../../../assets/img/common/header_logo_window_simulator.png';
import imgLogoWindowSimulatorRet from '../../../assets/img/common/header_logo_window_simulator@2x.png';
import imgTitleheadNotice from '../../../assets/img/common/icon_titlehead_notice.png';
import imgTitleheadNoticeRet from '../../../assets/img/common/icon_titlehead_notice@2x.png';
import {ConnectionService} from '../../../service/connection-service';
import {GetNewsList, NewsListData} from '../../../model/api/news/get-news-list';
import {AxiosResponse} from 'axios';

interface PageNoticeComponentProps extends RouteComponentProps {
}

interface PageNoticeComponentState {
  noticeList: NewsListData[];
}

export class PageNoticeComponent extends React.Component<PageNoticeComponentProps, PageNoticeComponentState> {

  constructor(props: PageNoticeComponentProps) {
    super(props);
    this.state = {
      noticeList: [],
    };
  }

  componentDidMount(): void {
    const dummyList: NewsListData[] = [];
    ConnectionService.ins.connect(new GetNewsList())
      .then((resolve: AxiosResponse<NewsListData[]>) => {
        for (let i: number = 0; i < resolve.data.length; i++) {
          const div = document.createElement('div');
          div.style.display = 'none';
          div.innerHTML = resolve.data[i].content as string;
          dummyList.push({
            id: resolve.data[i].id,
            title: resolve.data[i].title,
            content: div,
            created_at: resolve.data[i].created_at,
            updated_ad: resolve.data[i].updated_ad,
          })
        }
        this.setState({
          noticeList: dummyList,
        });
        setTimeout(() => {
          this.state.noticeList.map((data, i) => {
            const dom = document.getElementById('list_' + i);
            if (dom) {
              const res = data.content as HTMLElement;
              res.setAttributeNS('', 'style', 'display: block');
              dom.appendChild(data.content as HTMLElement);
            }
          });
        }, 1000);
      })
      .catch((err) => console.log(err));
  }

  render() {
    const {noticeList} = this.state;
    return (
      <div className={'body_wrap'}>
        <div className={'body_notice'}>

          <div className={'header_common flex_box flex_align_center'}>
            <div>
              <h1>
                <button
                  onClick={() => this.props.history.push(RoutingPass.TOP)}
                  className={'reset'}
                >
                  <img
                    src={imgLogoNichibei}
                    srcSet={`${imgLogoNichibei} 1x, ${imgLogoNichibeiRet} 2x`}
                    alt={'トップへ'}
                  />
                </button>
              </h1>
            </div>
            <div>
              <h2>
                <img
                  src={imgLogoWindowSimulator}
                  srcSet={`${imgLogoWindowSimulator} 1x, ${imgLogoWindowSimulatorRet} 2x`}
                  alt={'Window Simulator'}
                />
              </h2>
            </div>
          </div>
          {/* //.header_common end */}

          <div className={'common_body'}>
            <div className={'common_title flex_box flex_align_center'}>
              <div>
                <img
                  src={imgTitleheadNotice}
                  srcSet={`${imgTitleheadNotice} 1x, ${imgTitleheadNoticeRet} 2x`}
                  alt={'お知らせ'}
                />
              </div>
              <div><h3>お知らせ</h3></div>
            </div>

            <div className={'common_contentArea'}>
              {noticeList.map((data, i) => (
                <div
                  key={data.title + '_' + i}
                  className={'noticeList_wrap flex_box'}
                >
                  <div className={'listDate'}>
                    {(data.updated_ad || data.created_at).split(/[\x20]/)[0].replace(/-/g, ':')}
                  </div>
                  <div className={'noticeList_inner_wrap'}>
                    <div className={'listTitle'}>
                      <h4>{data.title}</h4>
                    </div>
                    <div
                      id={'list_' + i}
                      className={'listSentence'}
                    >
                      {/*{data.content}*/}
                      {/*<ElementComponent dom={data.content as HTMLElement}/>*/}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* //.common_contentArea */}
          </div>
          {/* //.common_body */}

        </div>
      </div>
    );
  }

}

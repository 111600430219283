/**
 * スラット変更モーダル用サービス
 */
import {ColorInfo} from '../model/api/color/get-colors-by-id';
import {ColorList} from '../model/api/color/get-color-group';
import {Colors} from './canvas-conf-service';

export class SlatModalService {
  private static _ins: SlatModalService;
  private constructor(){}
  public static get ins(): SlatModalService {
    if(!this._ins){
      this._ins = new SlatModalService();
    }
    return this._ins;
  }

  /**
   * モーダル表示切り替え処理をセット
   */
  private _displayModalFunc: any;
  get displayModalFunc(): any {
    return this._displayModalFunc;
  }
  set displayModalFunc(value: any) {
    this._displayModalFunc = value;
  }

  /**
   * 色一覧をセット
   */
  private _colorList: ColorList[] = [];
  get colorList(): ColorList[] {
    return this._colorList;
  }
  set colorList(value: ColorList[]) {
    this._colorList = value;
  }

  /**
   * スラットごとの色をセット
   */
  private _slatColor: Colors[] = [];
  get slatColor(): Colors[] {
    return this._slatColor;
  }
  set slatColor(value: Colors[]) {
    this._slatColor = value;
  }

  private _slatSvgList: string[] = [];
  get slatSvgList(): string[] {
    return this._slatSvgList;
  }
  set slatSvgList(value: string[]) {
    this._slatSvgList = value;
  }
  private _bottomSvgList: string[] = [];
  get bottomSvgList(): string[] {
    return this._bottomSvgList;
  }
  set bottomSvgList(value: string[]) {
    this._bottomSvgList = value;
  }

  private _isVertical: boolean = true;
  get isVertical(): boolean {
    return this._isVertical;
  }
  set isVertical(value: boolean) {
    this._isVertical = value;
  }

  public isOpen: boolean = false;
  private _isOpenType: boolean = false;
  set isOpenType(value: boolean) {
    this._isOpenType = value;
  }
  get isOpenType(): boolean {
    return this._isOpenType;
  }

  public accentDialog: (list: ColorInfo[]) => void = () => {};

}

import {Buhin, CanvasConfService} from '../service/canvas-conf-service';
import {SlatModalService} from '../service/slat-modal-service';

export class LogManager {
  static createParam(): string {
    const {
      platform,
      uniqueId,
      slatColor,
      blindSize,
      optionData,
    } = this;
    return `p=${platform()}-${uniqueId()}-${slatColor()}-${blindSize()}-${optionData()}`;
  }

  private static platform(): string {
    const plf = navigator.platform;
    const ua = navigator.userAgent;
    if (ua.toLowerCase().indexOf('windows nt') !== -1){
      return 'w';
    }
    if (ua.toLowerCase().indexOf('android') !== -1){
      return 'a';
    }
    if(ua.toLowerCase().indexOf('mac os x') !== -1){
      return 'm';
    }
    if(plf.toLowerCase() === 'ipad'){
      return 'p';
    }
    if(plf.toLowerCase() === 'iphone' || plf.toLowerCase() === 'ipod touch'){
      return 'i';
    }
    return 'x';
  }

   static uniqueId(): string {
    const {blindName, blindType, blindOpenType, isStandardBlind} = CanvasConfService.ins.blindData;
    const name = blindName.replace(/ /g, '').replace(/\n/g, '');
    const type = blindType.replace(/ /g, '').replace('　', '').replace(/\n/g, '').replace('ポポラ2', 'ポポラ２').replace('(', '（').replace(')', '）').replace('25', '２５').replace('45', '４５').replace('+', '＋') + blindOpenType.replace(/ /g, '').replace(/\n/g, '');
    switch (name) {
      case 'よこ型ブラインド':
        switch (type) {
          case 'セレーノフィット15':
            return '1';
          case 'セレーノフィット25':
            return '2';
          case 'セレーノオアシス15':
            return '198';
          case 'セレーノオアシス25':
            return '199';
          case 'ユニーク15':
            return '3';
          case 'ユニーク25':
            return '4';
          case 'セレーノグランツ25':
            return '170';
          case 'セレーノグランツ35':
            return '171';
          case 'クレール50Fバランス付き':
            return '147';
          case 'クレール50Fバランスなし':
            return '164';
          case 'クレール50バランス付き':
            return '148';
          case 'クレール50バランスなし':
            return '165';
          case 'クレール35F':
            return '149';
          case 'クレール35':
            return '150';
          case 'クレールグランツ50バランス付き':
            return '151';
          case 'クレールグランツ50バランスなし':
            return '166';
          case 'クレール50防炎・耐水タイプ':
            return '152';
          case 'クレールグランツ50防炎・耐水タイプ':
            return '153';
          case 'クオラ50F':
            return '172';
          case 'クオラ50':
            return '173';
          case 'クオラ35F':
            return '174';
          case 'クオラ35':
            return '175';
          case 'クオラグランツ':
            return '176';
          case 'ポポラ２アルミブラインド':
            return '59';
          case 'ポポラ２バンブーブラインド':
            return '24';
        }
        break;
      case 'よこ型ブラインドデザインタイプ':
        switch (type) {
          case 'クオラ50デザインタイプ':
            return '177';
          case 'セレーノオアシス25デザインタイプ':
            return '202';
        }
        break;
      case 'たて型ブラインド':
        switch (type) {
          case 'アルペジオシングルスタイル75ミリループコード式片開き':
            return '25';
          case 'アルペジオシングルスタイル75ミリループコード式両開き':
            return '34';
          case 'アルペジオシングルスタイル75ミリループコード式両側開閉':
            return '85';
          case 'アルペジオシングルスタイル75ミリバトン式片開き':
            return '35';
          case 'アルペジオシングルスタイル75ミリバトン式両側操作':
            return '36';
          case 'アルペジオシングルスタイル100ミリループコード式片開き':
            return '26';
          case 'アルペジオシングルスタイル100ミリループコード式両開き':
            return '37';
          case 'アルペジオシングルスタイル100ミリループコード式両側開閉':
            return '86';
          case 'アルペジオシングルスタイル100ミリバトン式片開き':
            return '38';
          case 'アルペジオシングルスタイル100ミリバトン式両側操作':
            return '39';
          case 'アルペジオシングルスタイル100ミリバトン式両開き':
            return '228';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式片開き':
            return '229';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式両開き':
            return '230';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式両側開閉':
            return '231';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトバトン式片開き':
            return '232';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトバトン式両側操作':
            return '233';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトバトン式両開き':
            return '234';
          case 'アルペジオモアラップスタイルループコード式片開き':
            return '27';
          case 'アルペジオモアラップスタイルループコード式両開き':
            return '40';
          case 'アルペジオモアラップスタイルループコード式両側開閉':
            return '87';
          case 'アルペジオモアラップスタイルバトン式片開き':
            return '41';
          case 'アルペジオモアラップスタイルバトン式両側操作':
            return '42';
          case 'アルペジオセンターレーススタイルツーループコード式片開き':
            return '213';
          case 'アルペジオセンターレーススタイルツーループコード式両開き':
            return '214';
          case 'アルペジオセンターレーススタイルループコード式片開き':
            return '28';
          case 'アルペジオセンターレーススタイルループコード式両開き':
            return '43';
          case 'アルペジオセンターレーススタイルループコード式両側開閉':
            return '88';
          case 'アルペジオセンターレーススタイルバトン式片開き':
            return '44';
          case 'アルペジオセンターレーススタイルバトン式両側操作':
            return '45';
          case 'アルペジオバックレーススタイルループコード式片開き':
            return '215';
          case 'アルペジオバックレーススタイルループコード式両開き':
            return '216';
          case 'アルペジオバックレーススタイルループコード式両側開閉':
            return '217';
          case 'アルペジオバックレーススタイルバトン式片開き':
            return '218';
          case 'アルペジオバックレーススタイルバトン式両側操作':
            return '219';
          case 'ソーラーV-Nシングルスタイル50ミリ片開き':
            return '185';
          case 'ソーラーV-Nシングルスタイル50ミリ両開き':
            return '186';
          case 'ソーラーV標準Nシングルスタイル75ミリ片開き':
            return '187';
          case 'ソーラーV標準Nシングルスタイル75ミリ両開き':
            return '188';
          case 'ソーラーV標準Nシングルスタイル100ミリ片開き':
            return '189';
          case 'ソーラーV標準Nシングルスタイル100ミリ両開き':
            return '190';
          case 'ソーラーV-Nセンターレーススタイル片開き':
            return '191';
          case 'ソーラーV-Nセンターレーススタイル両開き':
            return '192';
          case 'ソーラーV標準NUシングルスタイル75ミリ片開き':
            return '193';
          case 'ソーラーV標準NUシングルスタイル75ミリ両開き':
            return '194';
          case 'ソーラーV標準NUシングルスタイル100ミリ片開き':
            return '195';
          case 'ソーラーV標準NUシングルスタイル100ミリ両開き':
            return '196';
          case 'ポポラ２バーチカルブラインドシングルスタイルループコード式片開き':
            return '60';
          case 'ポポラ２バーチカルブラインドシングルスタイルループコード式両開き':
            return '61';
          case 'ポポラ２バーチカルブラインドシングルスタイルループコード式両側開閉':
            return '89';
          case 'ポポラ２バーチカルブラインドシングルスタイルバトン式片開き':
            return '62';
          case 'ポポラ２バーチカルブラインドシングルスタイルバトン式両側操作':
            return '63';
          case 'ポポラ２バーチカルブラインドモアラップスタイルループコード式片開き':
            return '64';
          case 'ポポラ２バーチカルブラインドモアラップスタイルループコード式両開き':
            return '65';
          case 'ポポラ２バーチカルブラインドモアラップスタイルループコード式両側開閉':
            return '90';
          case 'ポポラ２バーチカルブラインドモアラップスタイルバトン式片開き':
            return '66';
          case 'ポポラ２バーチカルブラインドモアラップスタイルバトン式両側操作':
            return '67';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルループコード式片開き':
            return '68';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルループコード式両開き':
            return '69';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルループコード式両側開閉':
            return '91';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルバトン式片開き':
            return '70';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルバトン式両側操作':
            return '71';
        }
        break;
      case 'ロールスクリーン':
        switch (type) {
          case 'ソフィー標準タイプ':
            return '29';
          case 'ソフィー標準タイプ（ウォッシャブル）':
            return '30';
          case 'ソフィーカバータイプ':
            return '183';
          case 'ソフィーカバータイプ（ウォッシャブル）':
            return '220';
          case 'ソフィーバスタイプ':
            return '31';
          case 'ポポラ２ロールスクリーン標準タイプ標準仕様':
            return '72';
          case 'ポポラ２ロールスクリーン標準タイプウォッシャブル仕様':
            return '73';
        }
        break;
      case 'プリーツスクリーンシングルスタイル':
        switch (type) {
          case 'もなみシングルスタイル':
            return '32';
          case 'もなみシングルスタイルコードレス式':
            return '103';
          case 'ポポラ２プリーツスクリーンシングルスタイル':
            return '74';
        }
        break;
      case '調光ロールスクリーン':
        switch (type) {
          case 'ha・na・ri（ハナリ）標準タイプ':
            return '161';
          case 'ha・na・ri（ハナリ）カバーレスタイプ':
            return '162';
          case 'レユール標準タイプ':
            return '108';
        }
        break;
      case 'ハニカムスクリーンシングルスタイル':
        switch (type) {
          case 'レフィーナ25シングルスタイル':
            return '109';
          case 'レフィーナ45シングルスタイル':
            return '110';
        }
        break;
      case '外付けロールスクリーン':
        switch (type) {
          case 'ソヨカ標準タイプ':
            return '197';
        }
        break;
      case 'ミックスルーバー':
        switch (type) {
          case 'アルペジオシングルスタイル75ミリループコード式片開き':
            return '46';
          case 'アルペジオシングルスタイル75ミリループコード式両開き':
            return '47';
          case 'アルペジオシングルスタイル75ミリループコード式両側開閉':
            return '92';
          case 'アルペジオシングルスタイル75ミリループコード式レールジョイント':
            return '205';
          case 'アルペジオシングルスタイル75ミリバトン式片開き':
            return '48';
          case 'アルペジオシングルスタイル75ミリバトン式両側操作':
            return '49';
          case 'アルペジオシングルスタイル75ミリバトン式レールジョイント':
            return '206';
          case 'アルペジオシングルスタイル100ミリループコード式片開き':
            return '50';
          case 'アルペジオシングルスタイル100ミリループコード式両開き':
            return '51';
          case 'アルペジオシングルスタイル100ミリループコード式両側開閉':
            return '93';
          case 'アルペジオシングルスタイル100ミリループコード式レールジョイント':
            return '207';
          case 'アルペジオシングルスタイル100ミリバトン式片開き':
            return '52';
          case 'アルペジオシングルスタイル100ミリバトン式両側操作':
            return '53';
          case 'アルペジオシングルスタイル100ミリバトン式両開き':
            return '235';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式片開き':
            return '236';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式両開き':
            return '237';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式両側開閉':
            return '238';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトループコード式レールジョイント':
            return '239';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトバトン式片開き':
            return '240';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトバトン式両側操作':
            return '241';
          case 'アルペジオシングルスタイル100ミリミニマルウエイトバトン式両開き':
            return '242';
          case 'アルペジオシングルスタイル100ミリバトン式レールジョイント':
            return '208';
          case 'アルペジオモアラップスタイルループコード式片開き':
            return '54';
          case 'アルペジオモアラップスタイルループコード式両開き':
            return '55';
          case 'アルペジオモアラップスタイルループコード式両側開閉':
            return '94';
          case 'アルペジオモアラップスタイルループコード式レールジョイント':
            return '209';
          case 'アルペジオモアラップスタイルバトン式片開き':
            return '56';
          case 'アルペジオモアラップスタイルバトン式両側操作':
            return '57';
          case 'アルペジオモアラップスタイルバトン式レールジョイント':
            return '210';
          case 'ポポラ２バーチカルブラインドシングルスタイルループコード式片開き':
            return '76';
          case 'ポポラ２バーチカルブラインドシングルスタイルループコード式両開き':
            return '77';
          case 'ポポラ２バーチカルブラインドシングルスタイルループコード式両側開閉':
            return '95';
          case 'ポポラ２バーチカルブラインドシングルスタイルバトン式片開き':
            return '78';
          case 'ポポラ２バーチカルブラインドシングルスタイルバトン式両側操作':
            return '79';
          case 'ポポラ２バーチカルブラインドモアラップスタイルループコード式片開き':
            return '80';
          case 'ポポラ２バーチカルブラインドモアラップスタイルループコード式両開き':
            return '81';
          case 'ポポラ２バーチカルブラインドモアラップスタイルループコード式両側開閉':
            return '96';
          case 'ポポラ２バーチカルブラインドモアラップスタイルバトン式片開き':
            return '82';
          case 'ポポラ２バーチカルブラインドモアラップスタイルバトン式両側操作':
            return '83';
          case 'アルペジオセンターレーススタイルツーループコード式片開き':
            return '221';
          case 'アルペジオセンターレーススタイルツーループコード式両開き':
            return '222';
          case 'アルペジオセンターレーススタイルループコード式片開き':
            return '113';
          case 'アルペジオセンターレーススタイルループコード式両開き':
            return '114';
          case 'アルペジオセンターレーススタイルループコード式両側開閉':
            return '115';
          case 'アルペジオセンターレーススタイルループコード式レールジョイント':
            return '211';
          case 'アルペジオセンターレーススタイルバトン式片開き':
            return '116';
          case 'アルペジオセンターレーススタイルバトン式両側操作':
            return '117';
          case 'アルペジオセンターレーススタイルバトン式レールジョイント':
            return '212';
          case 'アルペジオバックレーススタイルループコード式片開き':
            return '223';
          case 'アルペジオバックレーススタイルループコード式両開き':
            return '224';
          case 'アルペジオバックレーススタイルループコード式両側開閉':
            return '225';
          case 'アルペジオバックレーススタイルバトン式片開き':
            return '226';
          case 'アルペジオバックレーススタイルバトン式両側操作':
            return '227';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルループド式片開き':
            return '97';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルループコード式両開き':
            return '98';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルループコード式両側開閉':
            return '99';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルバトン式片開き':
            return '100';
          case 'ポポラ２バーチカルブラインドセンターレーススタイルバトン式両側操作':
            return '101';
        }
        break;
      case 'カラーアラカルト':
        switch (type) {
          case 'セレーノフィット15':
            return '118';
          case 'セレーノフィット25':
            return '119';
          case 'セレーノオアシス15':
            return '200';
          case 'セレーノオアシス25':
            return '201';
          case 'ユニーク15':
            return '120';
          case 'ユニーク25':
            return '121';
          case 'セレーノグランツ25':
            return '203';
          case 'セレーノグランツ35':
            return '204';
          case 'クオラ50F':
            return '243';
          case 'クオラ50':
            return '244';
        }
        break;
      case 'プリーツスクリーンツインスタイル':
        switch (type) {
          case 'もなみツインスタイル':
            return isStandardBlind ? '33' : '58';
          case 'もなみツインスタイルコードレス式':
            return isStandardBlind ? '105' : '107';
          case 'ポポラ２プリーツスクリーンツインスタイル':
            return isStandardBlind ? '75' : '84';
        }
        break;
      // case 'プリーツスクリーンツインスタイル':
      //   switch (type) {
      //     case '':
      //       return '';
      //   }
      //   break;
      case 'ハニカムスクリーンツインスタイル':
        switch (type) {
          // case 'レフィーナ２５ツインスタイル（ハニカム＋ハニカム）':
          //   return isStandardBlind ? '111' : '141';
          // case 'レフィーナ４５ツインスタイル（ハニカム＋ハニカム）':
          //   return isStandardBlind ? '112' : '142';
          // case 'レフィーナ２５ツインスタイル（ハニカム＋プリーツ）':
          //   return isStandardBlind ? '143' : '145';
          // case 'レフィーナ４５ツインスタイル（ハニカム＋プリーツ）':
          case 'レフィーナ２５（ハニカム＋ハニカム）':
            return isStandardBlind ? '111' : '141';
          case 'レフィーナ４５（ハニカム＋ハニカム）':
            return isStandardBlind ? '112' : '142';
          case 'レフィーナ２５（ハニカム＋プリーツ）':
            return isStandardBlind ? '143' : '145';
          case 'レフィーナ４５（ハニカム＋プリーツ）':
          return isStandardBlind ? '144' : '146';
        }
        break;
      // case 'ハニカムスクリーンツインスタイル':
      //   switch (type) {
      //     case '':
      //       return '';
      //   }
      //   break;
    }
    return String(CanvasConfService.ins.detail_blind.unique_id);
  }

  private static slatColor(): string{
    return CanvasConfService.ins.blindData.blindName.indexOf('ツインスタイル') !== -1 ? 'MULTI' :
      CanvasConfService.ins.blindData.isStandardBlind ? CanvasConfService.ins.blindData.color :
        SlatModalService.ins.slatColor[0].color_code;
        // CanvasConfService.ins.responseDataColorInfo.color_code;
  }

  private static blindSize(): string {
    return `${CanvasConfService.ins.blindData.windowSizeWidth}-${CanvasConfService.ins.blindData.windowSizeHeight}`;
  }

  private static optionData(): string {
    const {
      head_box,
      head_box_value1,
      bottom_rail,
      bottom_rail_value1,
      wide_ladder_tape,
      wide_ladder_tape_value1,
      wood_balance,
      wood_balance_value1,
    } = CanvasConfService.ins.isSetOption;
    let option: string = '';
    if (head_box) {
      option += `${option ? '-' : ''}HB${head_box_value1 || 9999}`;
    }
    if (wood_balance) {
      option += `${option ? '-' : ''}BA${wood_balance_value1 || 9999}`;
    }
    if (wide_ladder_tape) {
      option += `${option ? '-' : ''}WT${wide_ladder_tape_value1 || 9999}`
    }
    if (CanvasConfService.ins.centerRace.id !== -1) {
      // if (CanvasConfService.ins)
      option += `${option ? '-' : ''}CB${CanvasConfService.ins.centerRace.color_code || 9999}`;
    }
    if (bottom_rail) {
      option += `${option ? '-' : ''}BR${bottom_rail_value1 || 9999}`;
    }
    // CanvasConfService.ins.accentColor.acctentType TODO acccent
    switch (CanvasConfService.ins.accentColor.acctentType) {
      case 'both':
        option += `${option ? '-' : ''}AW${CanvasConfService.ins.accentColor.color.color_code || 9999}`;
        break;
      case 'left':
        option += `${option ? '-' : ''}AL${CanvasConfService.ins.accentColor.color.color_code || 9999}`;
        break;
      case 'right':
        option += `${option ? '-' : ''}AR${CanvasConfService.ins.accentColor.color.color_code || 9999}`;
    }
    return option;
  }

}

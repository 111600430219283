import {APIType} from '../../enum/api-type';
import {HTTPMethod} from '../../enum/http-method';

export class RequestBase {

  // 接続先は API_TYPE を切り替える
  // static API_TYPE: APIType = APIType.distribution;
  static API_TYPE: APIType = APIType.develop;
  // static HOST_DEV: string = 'https://nichibei.marietta.co.jp';
  static HOST_DEV: string = 'https://nichibei.marietta.co.jp/dev';
  // static HOST_DIS: string = '.';
  static HOST_DIS: string = 'https://nws2.bitpark.co.jp';
  static get HOST_URL(): string {
    const {API_TYPE, HOST_DEV, HOST_DIS} = RequestBase;
    return API_TYPE === APIType.develop ? HOST_DEV : HOST_DIS;
  }

  private host: string = '';
  method: HTTPMethod;
  endPoint: string = '';
  param: any = {};

  protected constructor(method: HTTPMethod, endpoint: string) {
    this.host = this.selectHost(RequestBase.API_TYPE);
    this.method = method;
    this.endPoint = endpoint;
  }

  getHost(): string {
    return this.host;
  }

  getEndPoint(): string {
    return this.endPoint;
  }

  getMethod(): HTTPMethod {
    return this.method;
  }

  getUrl(): string {
    switch (this.method) {
      case HTTPMethod.GET:
        return this.getUrlWithQueryParam();
      case HTTPMethod.POST:
        return this.getUrlNoQueryParam();
      case HTTPMethod.DELETE:
        return this.getUrlWithQueryParam();
      case HTTPMethod.PATCH:
        return this.getUrlNoQueryParam();
      default:
        console.error(`getUrl() is no support this method !!`);
        return '';
    }
  };

  /**
   * URL作成メソッド
   * クエリーパラメータ付き
   */
  private getUrlWithQueryParam(): string {
    return `${this.getHost()}${this.getEndPoint()}${this.getParam()}`;
  }

  /**
   * URL作成メソッド
   * クエリーパラメータ無し
   */
  private getUrlNoQueryParam(): string {
    return `${this.getHost()}${this.getEndPoint()}`;
  }

  getParam(): FormData | string {
    switch (this.method) {
      case HTTPMethod.GET:
        return this.getParamQueryParam();
      case HTTPMethod.POST:
        return this.getParamFormData();
      case HTTPMethod.DELETE:
        return this.getParamQueryParam();
      case HTTPMethod.PATCH:
        return this.getParamFormData();
      default:
        console.error(`getParam() is no support this method !!`);
        return '';
    }
  };

  /**
   * パラメータ作成メソッド
   * クエリーパラメータ
   */
  private getParamQueryParam(): string {
    let query: string = '?';
    Object.keys(this.param).forEach((key: string, i: number) => {
      query += `${i ? '&' : ''}${key}=${this.param[key]}`;
    });
    return query;
  }

  /**
   * パラメータ作成メソッド
   * フォームデータ
   */
  private getParamFormData(): FormData {
    const formData: FormData = new FormData();
    Object.keys(this.param).forEach((key: string) => {
      formData.append(key, this.param[key]);
    });
    return formData;
  }

  selectHost(type: APIType): string {
    switch (type) {
      case APIType.develop:
        return RequestBase.HOST_DEV;
      case APIType.distribution:
        return RequestBase.HOST_DIS;
    }
  }

}

export class HtmlCreator {

  static create(png: string, svgEle: HTMLElement | SVGElement, scale: number): string {
    return  `
<html>
<body style="margin: 0;">
<div style="position: relative;">
<img src="${png}" />
${svgEle.outerHTML.replace(/xlink:/g, '')}
</div>
</body>
</html>
`
  }
}

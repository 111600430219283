import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {Buhin, CanvasConfService, CenterColor, Colors} from "../../../service/canvas-conf-service";
import {string} from "prop-types";

export class PostMakePdf extends RequestBase {

  param: {
    filedata: string,
    shohin_name: string,
    shohin: string[],
    buhin: Buhin[],
    pdf_kind: 1 | 2 | 3,
    type: 0 | 1 | 2,
    color: Colors[],
    center_color: CenterColor[],
    order_sheet: 0 | 1,
    blind_id: string,
    sub_blind_id: string,
    detail_id: string,
  } | any;

  constructor(
    filedata: string,
    shohin: string[],
    buhin: Buhin[],
    pdf_kind: number,
    type: 0 | 1 | 2 | 3 | 4,
    color: {name: string, color: string}[],
    center_color: CenterColor[],
    order_sheet: 0 | 1,
    blind_id: string,
    sub_blind_id: string,
    detail_id: string,
  ) {
    super(HTTPMethod.POST, '/api/subblind/make_pdf');
    this.param = {
      filedata: filedata,
      shohin_name: CanvasConfService.ins.blindData.blindType.replace('\n', '\t'),
      shohin: shohin,
      buhin: buhin,
      pdf_kind: pdf_kind,
      type: type,
      color: color,
      center_color: center_color,
      order_sheet: order_sheet,
      blind_id: blind_id,
      sub_blind_id: sub_blind_id,
      detail_id: detail_id,
    };
  }

  getParam(): FormData {
    const formData: FormData = new FormData();
    Object.keys(this.param).forEach((key: string) => {
      if (key === 'shohin') {
        const list = this.param[key];
        let param: string = '';
        list.forEach((v: any, i: any) => {
          if(param){
            param += ',';
          }else {
            param += '[';
          }
          param += '"' + v + '"';
        });
        param += ']';
        formData.append(key, param);
      }else if(key === 'buhin' || key === 'color' || key === 'center_color'){
        if(this.param[key].length > 0){
          const list = this.param[key];
          let param: string = '';
          list.forEach((v: any, i: any) => {
            if(param){
              param += ',';
            }else{
              param += '[';
            }
            let param2: string = '';
            Object.keys(v).forEach((v2: any, j: any) => {
              if(param2){
                param2 += ',';
              }else{
                param2 += '{';
              }
              param2 += v2 + ':' + v[v2];
            });
            param += param2 + '}';
          });
          param += ']';
          formData.append(key, param);
        }
      } else {
        formData.append(key, this.param[key]);
      }
    });
    return formData;
  }

}

import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';

export class GetNewsList extends RequestBase {

  param: {};

  constructor(){
    super(HTTPMethod.GET, '/api/news/news_list');
    this.param = {};
  }

}

export class NewsListData {
  id: number = -1;
  title: string = '';
  content: string | HTMLElement = '';
  created_at: string = '';
  updated_ad: string = '';
}

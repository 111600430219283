import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {CanvasConfService} from "../../../service/canvas-conf-service";

/**
 * オプション一覧取得
 */
export class GetOptionColors extends RequestBase {

  param: {
    option_type: string;
  };

  constructor(
    option_type: string,
  ) {
    super(HTTPMethod.GET, '/api/subblind/option_colors');
    this.param = {
      option_type: option_type,
    };
  }

}

export class OptionDetail {
  id: number = -1;
  option_type: string = '';
  color_code: string = '';
  color_name: string = '';
  color: string = '';
  image: string = '';
  image_file: string = '';
  series_name: string = '';
}

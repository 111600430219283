import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {CanvasConfService} from "../../../service/canvas-conf-service";

/**
 * 色情報取得
 *
 * color_codeを指定しないとidに紐づく一覧取得
 */
export class GetColorsByCode extends RequestBase {

  param:  {
    color_code: string,
  };

  constructor(
    color_code: string = '',
  ) {
    super(HTTPMethod.GET, '/api/colordata/colors_by_code');
    this.param = {
      color_code: color_code,
    };
  }

}

export class ColorInfo {
  id: number = 0;
  color_type: string = '';
  color_code: string = '';
  sub_color_code: string = '';
  series_name: string = '';
  group_name: string = '';
  color_name: string = '';
  image: string = '';
  image_file: string = '';
  color: string = '';
  texture_color: string = '';
  base_texture: number = 0;
  alpha: string = '';
  accent_side: number = 0;
  accent_bottom: number = 0;
  head_box_texture: string = '';
  bottom_rail_texture: string = '';
  wide_ladder_tape_texture: string = '';
  delete_at: string = '';
}
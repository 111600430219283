import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';
import {CanvasConfService} from "../../../service/canvas-conf-service";

/**
 * ブラインド幅・高さ取得
 */
export class GetWidthHeight extends RequestBase {

  param: {
    id: number;
  };

  constructor(
    id: number = CanvasConfService.ins.blindId,
  ) {
    super(HTTPMethod.GET, '/api/subblind/width_height');
    this.param = {
      id: id,
    };
  }

}

export class BlindWidthHeight {
  width: number = 0;
  height: number = 0;
}

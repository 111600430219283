import {RequestBase} from '../request-base';
import {HTTPMethod} from '../../../enum/http-method';

/**
 * サンプル背景一覧取得
 */
export class GetSampleBackgroundList extends RequestBase {

  param: {};

  constructor() {
    super(HTTPMethod.GET, '/api/sample/sample_list');
    this.param = {};
  }

  getUrl(): string {
    return `${this.getHost()}${this.getEndPoint()}${this.getParam()}`;
  }

  getParam(): string {
    return '';
  }

}

import * as React from 'react';
import './modal-dialog-component.scss';
import {CanvasConfService} from '../../../service/canvas-conf-service';

interface ModalDialogComponentProps {
  title: string;
  value: string[];
  displayCloseButton: boolean;
  displayOkButton?: boolean;
  callbackClose?: () => void;
  fontColor?: string;
}

interface ModalDialogComponentState {
}

export class ModalDialogComponent extends React.Component<ModalDialogComponentProps, ModalDialogComponentState> {

  constructor(props: ModalDialogComponentProps) {
    super(props);
  }

  render() {
    const {title, value, displayCloseButton, callbackClose, displayOkButton, fontColor} = this.props;
    return (
      <div className={'dialog_bodyInner' + (CanvasConfService.ins.isVertical ? ' dialog_changeLandscape' : '')}>
        <div className={'dialog_title flex_box flex_align_center flex_space_between'}>
          <div className={'dialog_titleInner'}>{title}</div>
          <div className={'right_btn_wrap'}>
            {displayCloseButton ? (
              <button
                type={'button'}
                className={'reset'}
                onClick={() => {return callbackClose ? callbackClose() : null;}}
              >&#10005;</button>
            ) : (<></>)}
          </div>
        </div>
        <div className={'dialog_cont'}>
          <div className={'dialog_message'} style={fontColor ? {color: fontColor} : {}}>{value.map((data, i) => (
            <a key={'dialog-message_' + i}>
              {data}<br />
            </a>
          ))}</div>
            {/*
            グレーのボタンにしたいなら"bg_gray"、グリーンのボタンにしたいなら"bg_green"
            をbuttonタグにクラスとして付与してください
            */}
            {displayOkButton ? (
              <>
                <div className={'divide30'}/>
                <hr/>
                <div className={'divide30'}/>
                <div className={'dialog_btn flex_box flex_align_center flex_content_center'}>
                  <div>
                    <button
                      type={'button'}
                      className={'reset bg_gray'}
                      onClick={callbackClose ? (() => callbackClose()) : (() => {return;})}
                    >OK
                    </button>
                  </div>
                </div>
              </>
            ) : (<></>)}
        </div>
        {/* //.dialog_cont */}
      </div>
    );
  }

}


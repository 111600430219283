import * as React from 'react';
import {CanvasConfService} from '../../../../../../../service/canvas-conf-service';
import './sample-background-component.scss';
import {GetSampleBackgroundList} from "../../../../../../../model/api/background/get-sample-background-list";
import {ConnectionService} from "../../../../../../../service/connection-service";
import {
  GetSampleBackgroundDetail,
  SampleBackgroundDetail,
} from "../../../../../../../model/api/background/get-sample-background-detail";
import {AxiosResponse} from "axios";
import {DrawManager} from '../../../../../../../manager/draw-manager';

interface SampleBackgroundComponentProps {
}

interface SampleBackgroundComponentState {
  sampleList: SampleBackgroundDetail[];
}

export class SampleBackgroundComponent extends React.Component<SampleBackgroundComponentProps, SampleBackgroundComponentState> {
  private hostUrl: string = '';

  constructor(props: SampleBackgroundComponentProps) {
    super(props);
    this.state = {
      sampleList: [],
    };
  }

  componentDidMount(): void {
    const request: GetSampleBackgroundList = new GetSampleBackgroundList();
    this.hostUrl = request.getHost();
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<SampleBackgroundDetail[]>) => {
        this.setState({
          sampleList: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {sampleList} = this.state;
    return (
      <div className={'content_area_wrap cont_backGroundSampleArea_inner'}>
        <div className={'scrollArea'}>
          <div className={'imgList_col4_wrap'}>

            <div className={'flex_box flex_container_wrap'}>
              {sampleList.map((data, i) => (
                <div
                  key={data + '_' + i}
                  onClick={() => this.handlerClickSampleImage(+data.id)}
                  className={'img_wrap imgAreaAdjust flex_box flex_align_center flex_content_center'}
                >
                  <img src={this.hostUrl + data.image_file}/>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
    );
  }

  private handlerClickSampleImage(id: number): void {
    const request: GetSampleBackgroundDetail = new GetSampleBackgroundDetail(id);
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<SampleBackgroundDetail>) => {
        // CanvasConfService.loading(true);
        DrawManager.currentPos = {x: 0, y: 0};
        CanvasConfService.ins.backgroundImg = this.hostUrl + response.data.image_file;
        DrawManager.rotation = 0;
        CanvasConfService.ins.update();
      })
      .catch((err) => {
        console.log(err);
      });
  }

}

import * as React from 'react';
import './menu-color-component.scss';
import { ColorList, GetColorGroup } from '../../../../../../model/api/color/get-color-group';
import { ConnectionService } from '../../../../../../service/connection-service';
import { AxiosResponse } from 'axios';
import { CanvasConfService } from '../../../../../../service/canvas-conf-service';
import { DrawManager } from '../../../../../../manager/draw-manager';
import { RequestBase } from '../../../../../../model/api/request-base';
import { GetOptionColors } from '../../../../../../model/api/option/get-option-colors';
import { detail_blind } from '../../../../../../model/api/blind/get-sub-blind-list';
import { OptionDetail } from '../../../../../../model/api/option/get-option-colors';
import { ColorInfo } from '../../../../../../model/api/color/get-colors-by-id';
import { ColorExceptionManager } from '../../../../../../manager/color-exception-manager';
import { ModalComponent } from '../../../../../modal/modal-component';
import { ModalDialogComponent } from '../../../../../modal/dialog/modal-dialog-component';
import { SlatModalService } from '../../../../../../service/slat-modal-service';
import imgOpenCloseBtnActive from '../../../../../../assets/img/simulator/icon_btn_openClose_active.png';
import imgOpenCloseBtnActiveRet from '../../../../../../assets/img/simulator/icon_btn_openClose_active@2x.png';
import imgOpenCloseBtn from '../../../../../../assets/img/simulator/icon_btn_openClose.png';
import imgOpenCloseBtnRet from '../../../../../../assets/img/simulator/icon_btn_openClose@2x.png';


interface MenuColorComponentProps {
}

interface MenuColorComponentState {
  colorList: ColorList[];
}

export class MenuColorComponent extends React.Component<MenuColorComponentProps, MenuColorComponentState> {
  private hostUrl: string = '';

  constructor(props: MenuColorComponentProps) {
    super(props);
    this.state = {
      colorList: [],
    };
  }

  render() {
    const { colorList } = this.state;
    return (
      <>
        <div className={'content_area_wrap cont_colorSelectArea_inner'}>
          <div className={'scrollArea'}>
            {colorList.map((colorType, type_i) => (

              <div
                key={colorType.group_name + '_' + type_i}
                className={''}
              >
                <div className={'colorTypeName'}><h4>{colorType.group_name}</h4></div>
                <div className={'flex_box flex_container_wrap'}>
                  {colorType.colors.map((colorData, data_i) => (
                    <div
                      key={colorData.color_name + '_' + type_i + '_' + data_i}
                      // className={'img_wrap' + (ColorExceptionManager.exception(colorData.color_code) ? ' isDisabled' : '')}
                      className={'img_wrap' + (ColorExceptionManager.exception(colorData.color_code) ? ' ' : '')}
                    >
                      <div
                        className={'imgInner_wrap'}
                      >
                        {colorData.image ? (
                          <svg
                            xmlns={'http://www.w3.org/2000/svg'}
                            x={0}
                            y={0}
                            width={50}
                            height={50}
                            viewBox={'0 0 50 50'}
                            onClick={() => {
                              CanvasConfService.ins.responseDataColorInfo = colorData;
                              this.handlerClickColorImg(
                                colorData.color.replace('0x', '#'),
                                colorData.color_code,
                                this.hostUrl + colorData.image_file,
                                colorData.head_box_texture,
                                colorData.bottom_rail_texture,
                              );
                            }}
                          >
                            {colorData.color ? (
                              <rect
                                x={0}
                                y={0}
                                width={48}
                                height={48}
                                fill={colorData.color.replace('0x', '#')}
                              />
                            ) : (<></>)}
                            <image
                              xlinkHref={RequestBase.HOST_URL + colorData.image_file}
                              x={0}
                              y={0}
                              width={48}
                              height={48}
                              preserveAspectRatio={'none'}
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x={0}
                            y={0}
                            width={50}
                            height={50}
                            viewBox="0 0 50 50"
                            onClick={() => {
                              CanvasConfService.ins.responseDataColorInfo = colorData;
                              if (CanvasConfService.ins.accentColor.color.group_name !== colorData.group_name) {
                                CanvasConfService.ins.accentColor.color = colorData;
                              }
                              this.handlerClickColorData(
                                colorData.color.replace('0x', '#'),
                                colorData.color_code,
                                colorData.head_box_texture,
                                colorData.bottom_rail_texture,
                              );
                            }}
                          >
                            <rect
                              x={0}
                              y={0}
                              width={48}
                              height={48}
                              fill={colorData.color.replace('0x', '#')}
                            />
                          </svg>)}
                      </div>
                      <div className={'align_c colorCode'}>{colorData.color_code}<br />{colorData.color_name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  componentDidMount(): void {
    const request: GetColorGroup = new GetColorGroup();
    this.hostUrl = request.getHost();
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<ColorInfo[]>) => {
        const arr: ColorList[] = [];
        const res = response.data;
        // res.sort((a, b) => {return a.id > b.id ? 1 : -1});
        // response.data.forEach((resData) => {
        res.forEach((resData) => {
          const index = arr.findIndex((data) => data.group_name === resData.group_name);
          if (index !== -1) {
            arr[index].colors.push(resData);
          } else {
            arr.push({
              group_name: resData.group_name,
              colors: [resData],
            });
          }
        });
        // 色柄追加でカラーコード順に見れなくなったので個別に制御
        const indexNonGlare = arr.findIndex((v) => v.group_name === 'ノングレア遮熱');
        const indexMetallic = arr.findIndex((v) => v.group_name === '遮熱メタリック');
        if (indexNonGlare !== -1 && indexMetallic !== -1) {
          const nonGlare = { ...arr[indexNonGlare] };
          arr.splice(indexNonGlare, 1);
          arr.splice(indexMetallic + 1, 0, nonGlare);
        }
        this.setState({
          colorList: arr,
        });
      });
  }

  private handlerClickColorData(value: string, code: string, headbox_code: string, bottom_code: string) {
    const {
      headbox,
      headbox_table,
      bottom,
      bottom_table,
      center_table,
      wood,
      wood_table,
      parent_id,
    } = CanvasConfService.ins.detail_blind;
    // const func1 = headbox_table ? ConnectionService.ins.connect(new GetOptionColors(headbox_table)) : new Promise((resolve) => resolve());
    // const func2 = bottom_table ? ConnectionService.ins.connect(new GetOptionColors(bottom_table)) : new Promise((resolve) => resolve());
    const func1 = headbox_table ? ConnectionService.ins.connect(new GetOptionColors(headbox_table)) : new Promise((resolve) => resolve());
    const func2 = bottom_table ? ConnectionService.ins.connect(new GetOptionColors(bottom_table)) : new Promise((resolve) => resolve());
    const func3 = center_table ? ConnectionService.ins.connect(new GetOptionColors(center_table)) : new Promise((resolve) => resolve());
    const func4 = wood_table ? ConnectionService.ins.connect(new GetOptionColors(wood_table)) : new Promise((resolve) => resolve());
    Promise.all([
      func1, func2, func3, func4,
    ])
      .then((res: any) => {
        CanvasConfService.loading(true);
        const arr = [8, 9, 134, 135, 115, 116, 10, 11, 108, 109, 24, 25, 110, 27, 28];
        if (arr.find((v) => v === +parent_id)) {
          CanvasConfService.ins.isSetOption.head_box = value.replace('0x', '#');
          CanvasConfService.ins.isSetOption.middle = value.replace('0x', '#');
          CanvasConfService.ins.isSetOption.head_box_value1 = code;
          CanvasConfService.ins.isSetOption.bottom_rail_value1 = code;
        } else if (headbox && headbox_table) {
          const dummy = res[0].data.find((detail: any) => {
            if (detail.color_code === headbox_code) {
              return detail;
            }
          });
          const color = dummy ? dummy.color : value;
          if (+headbox === 10) {
            CanvasConfService.ins.isSetOption.head_box = color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle = color.replace('0x', '#');
          }
        } else if (+headbox !== 0 && !headbox_code) {
          CanvasConfService.ins.isSetOption.head_box = value.replace('0x', '#');
          CanvasConfService.ins.isSetOption.middle = value.replace('0x', '#');
        } else if (+headbox === 10 && !headbox_table) {
          CanvasConfService.ins.isSetOption.head_box = value.replace('0x', '#');
          CanvasConfService.ins.isSetOption.middle = value.replace('0x', '#');
        }
        if (bottom && bottom_table) {
          const dummy = res[1].data.find((detail: any) => {
            if (detail.color_code === bottom_code) {
              return detail;
            }
          });
          const color = dummy ? dummy.color : value;
          if (+bottom === 10) {
            CanvasConfService.ins.isSetOption.bottom_rail = color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle = color.replace('0x', '#');
          }
        } else if (+bottom !== 0 && !bottom_code) {
          CanvasConfService.ins.isSetOption.bottom_rail = value.replace('0x', '#');
          CanvasConfService.ins.isSetOption.middle = value.replace('0x', '#');
        } else if (+bottom === 10 && !bottom_table) {
          CanvasConfService.ins.isSetOption.bottom_rail = value.replace('0x', '#');
          CanvasConfService.ins.isSetOption.middle = value.replace('0x', '#');
        }
        if (center_table) {
          CanvasConfService.ins.centerRaceArr = res[2].data;
          const index = res[2].data.findIndex((data: any) => data.color_code === CanvasConfService.ins.responseDataColorInfo.sub_color_code);
          // const index = res[2].data.findIndex((data: any) => data.color_code === 'PA356');
          if (index !== -1) {
            const centerData = CanvasConfService.ins.centerRaceArr[index];
            CanvasConfService.ins.centerRaceBase = centerData;
            const { option_type, color_code, color_name, color, series_name } = centerData;
            const centerRace = CanvasConfService.ins.centerRaceArr.find((data) => data.option_type === option_type && data.color_code !== color_code && data.color_name === color_name && data.series_name === series_name);
            if (centerRace) {
              CanvasConfService.ins.centerRace = centerRace;
            } else {
              CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
            }
            // CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
          } else {
            // CanvasConfService.ins.centerRace = new OptionDetail();
            CanvasConfService.ins.centerRace = (res[2].data && res[2].data[0]) ? res[2].data[0] : {
              id: 467,
              option_type: 'patAC',
              color_code: 'PA356',
              color_name: 'ホワイト',
              color: '0xF7F6F2',
              image: '',
              image_file: '',
              series_name: 'センターレース',
            };
          }
        }
        if (wood_table) {
          // console.log('wood: ', res[3]);
        } else if (!wood_table && wood) {
          if (CanvasConfService.ins.isSetOption.head_box) {
            CanvasConfService.ins.isSetOption.wood_balance = CanvasConfService.ins.isSetOption.head_box;
          } else {
            CanvasConfService.ins.isSetOption.wood_balance = value.replace('0x', '#');
          }
        }
        CanvasConfService.ins.blindData.color = code;
        CanvasConfService.ins.blindData.blindColorCode = value;
        CanvasConfService.ins.blindData.displayImgPath = null;
        CanvasConfService.ins.updateBlindSpec();
        CanvasConfService.ins.updateBlindColor(value);
      })
      .catch((err) => console.log(err));
  }

  private handlerClickColorImg(value: string, code: string, img: string, headbox_code: string, bottom_code: string) {
    // DrawManager.clearSVG();
    CanvasConfService.loading(true);
    CanvasConfService.ins.blindData.color = code;
    CanvasConfService.ins.blindData.blindColorCode = value;
    const image = new Image();
    image.onload = () => {
      const { headbox, bottom, parent_id } = CanvasConfService.ins.detail_blind;
      const func1 = (+headbox === 10 && headbox_code) ?
        ConnectionService.ins.connect(new GetOptionColors(CanvasConfService.ins.detail_blind.headbox_table)) : new Promise((resolve) => resolve());
      const func2 = (+bottom === 10 && bottom_code) ?
        ConnectionService.ins.connect(new GetOptionColors(CanvasConfService.ins.detail_blind.bottom_table)) : new Promise((resolve) => resolve());
      Promise.all([
        func1, func2,
      ])
        .then((res: any) => {
          const arr = [8, 9, 134, 135, 115, 116, 10, 11, 108, 109, 24, 25, 110, 27, 28];
          if (arr.find((v) => v === +parent_id)) {
            CanvasConfService.ins.isSetOption.head_box = value.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle = value.replace('0x', '#');
            CanvasConfService.ins.isSetOption.head_box_value1 = code;
            CanvasConfService.ins.isSetOption.bottom_rail_value1 = code;
          } else if ((+headbox === 10 && headbox_code)) {
            const color = res[0].data.find((detail: any) => {
              if (detail.color_code === headbox_code) {
                return detail;
              }
            }).color || value;
            CanvasConfService.ins.isSetOption.head_box = color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle = color.replace('0x', '#');
          }
          if ((+bottom === 10 && bottom_code)) {
            const color = res[1].data.find((detail: any) => {
              if (detail.color_code === bottom_code) {
                return detail;
              }
            }).color || value;
            CanvasConfService.ins.isSetOption.bottom_rail = color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle = color.replace('0x', '#');
          }
          CanvasConfService.ins.blindData.imgSizeWidth = image.width;
          CanvasConfService.ins.blindData.imgSizeHeight = image.height;
          DrawManager.getFileFromUrl(img)
            .then((res: string) => {
              CanvasConfService.ins.blindData.displayImgPath = res;
              CanvasConfService.ins.updateBlindSpec();
              CanvasConfService.ins.updateBlindColor(value);
            })
            .catch((err) => {
              CanvasConfService.loading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          CanvasConfService.loading(false);
          console.log(err);
        });
    };
    image.src = img;
  }
}

export class RoutingPass {

  /** トップ */
  private static _TOP: string = '/top';
  static get TOP(): string {
    return this._TOP;
  }

  /** お知らせ */
  private static _NOTICE: string = '/notice';
  static get NOTICE(): string {
    return this._NOTICE;
  }

  /** 使い方 */
  private static _HOWTO: string = '/howto';
  static get HOWTO(): string {
    return this._HOWTO;
  }

  /** インフォメーション */
  private static _INFO: string = '/info';
  static get INFO(): string {
    return this._INFO;
  }

  /** シミュレータ */
  private static _SIMULATOR: string = '/simulator';
  static get SIMULATOR(): string {
    return this._SIMULATOR;
  }

}

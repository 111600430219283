import * as React from 'react';
import './menu-blind-component.scss';
import blindSelectHead1 from '../../../../../../assets/img/simulator/blindSelectHead_blind_1.png';
import blindSelectHead2 from '../../../../../../assets/img/simulator/blindSelectHead_blind_2.png';
import { CanvasConfService, IsSetOption, OptionImg } from '../../../../../../service/canvas-conf-service';
import { GetBlindList } from '../../../../../../model/api/blind/get-blind-list';
import { ConnectionService } from '../../../../../../service/connection-service';
import { BlindDetail, GetBlindDetail } from '../../../../../../model/api/blind/get-blind-detail';
import { GetSubBlindList, SubBlindDetail } from '../../../../../../model/api/blind/get-sub-blind-list';
import { GetOpenTypeList, OpenTypeDetail } from '../../../../../../model/api/blind/get-open-type-list';
import { AxiosResponse } from 'axios';
import { BlindWidthHeight, GetWidthHeight } from '../../../../../../model/api/blind/get-width-height';
import { DrawManager } from '../../../../../../manager/draw-manager';
import { ColorList, GetColorGroup } from '../../../../../../model/api/color/get-color-group';
import { ColorInfo, GetColorsById } from '../../../../../../model/api/color/get-colors-by-id';
import { SlatModalService } from '../../../../../../service/slat-modal-service';
import { GetColorsByCode } from '../../../../../../model/api/color/get-colors-by-code';
import { RequestBase } from '../../../../../../model/api/request-base';
import { GetOptionColors, OptionDetail } from '../../../../../../model/api/option/get-option-colors';
import { ModalComponent } from '../../../../../modal/modal-component';
import { ModalDialogComponent } from '../../../../../modal/dialog/modal-dialog-component';
import imgOpenCloseBtn from '../../../../../../assets/img/simulator/icon_btn_openClose.png';
import imgOpenCloseBtnRet from '../../../../../../assets/img/simulator/icon_btn_openClose@2x.png';
import imgOpenCloseBtnActive from '../../../../../../assets/img/simulator/icon_btn_openClose_active.png';
import imgOpenCloseBtnActiveRet from '../../../../../../assets/img/simulator/icon_btn_openClose_active@2x.png';

interface MenuBlindComponentProps {
  default: boolean;
}

interface MenuBlindComponentState {
  currentBlind: 'standard' | 'original';
  blind: BlindDetail[];
  screenType: ScreenType;
  subBlind: SubBlindDetail[],
  openType: OpenTypeDetail[],
  isComplete: boolean,
}

export class MenuBlindComponent extends React.Component<MenuBlindComponentProps, MenuBlindComponentState> {
  private slatVertical: string = 'vertical.svg';
  private slatSide: string = 'side.svg';
  private bottom: string = 'bottom_vertical.svg';
  private hostUrl: string = '';
  private optionList: SubBlindDetail[] = [];

  constructor(props: MenuBlindComponentProps) {
    super(props);
    this.state = {
      currentBlind: 'standard',
      blind: [],
      screenType: ScreenType.Blind,
      subBlind: [],
      openType: [],
      isComplete: false,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<MenuBlindComponentProps>, nextContext: any): void {
    if (nextProps.default) {
      this.setState({
        screenType: ScreenType.Blind,
      });
    }
  }

  render() {
    const { currentBlind, blind, screenType, subBlind, openType, isComplete } = this.state;
    return (
      <>
        <div className={'content_area_wrap cont_blindSelectArea_inner'}>

          <div className={'tab_btn_wrap flex_box'}>
            <div className={currentBlind === 'standard' ? 'active' : ''}>
              <button
                onClick={() => this.handlerClickStandardButton()}
                className={'reset flex_box'}
              >
                <span>
                  <img
                    src={blindSelectHead1}
                    alt={'スタンダードブラインド'}
                  />
                </span>
                <span>
                  <span><h4>スタンダードブラインド</h4></span>
                  <span>
                    豊富な商品の中から選べます。<br className={'sp_none'} />
                    それぞれタイプやカラーが選択可能です。
                  </span>
                </span>
              </button>
            </div>

            <div className={currentBlind === 'original' ? 'active' : ''}>
              <button
                onClick={() => this.handlerClickOriginalButton()}
                className={'reset flex_box'}
              >
                <span>
                  <img
                    src={blindSelectHead2}
                    alt={'オリジナルブラインド'}
                  />
                </span>
                <span>
                  <span>
                    <h4>オリジナルブラインド</h4>
                  </span>
                  <span>
                    カラーを組み合わせて自分だけの<br className={'sp_none'} />
                    オリジナルブラインドを。<br className={'sp_none'} />
                    一部商品のみ選択可能です。
                  </span>
                </span>
              </button>
            </div>

          </div>
          {/* //.tab_btn_wrap */}

          <div className={'blindList_wrap'}>
            {
              isComplete ? (
                <>
                  <div className={'isComplete_wrap flex_box flex_align_center flex_content_center'}>
                    <div className={'areaBg'} />
                    <div className={'isComplete_icon flex_box flex_align_center flex_content_center'}>&#10003;</div>
                  </div>
                </>
              ) : (<></>)
            }
            <div className={'scrollArea'}>
              <div className={'imgList_col4_wrap'}>
                <div className={'flex_box flex_container_wrap'}>
                  {screenType === ScreenType.Blind ? (
                    <>
                      {blind.map((data, i) => (
                        <div
                          key={data + '_' + i}
                          onClick={() => this.handlerClickBlind(+data.id, data.type, data.sub_id)}
                          className={'img_wrap imgAreaAdjust screenType1'}
                        >
                          <img src={this.hostUrl + data.type_image_file} />
                          <div>{data.type}</div>
                        </div>
                      ))}
                    </>
                  ) : screenType === ScreenType.SubBlind ? (
                    <>
                      {subBlind.map((data, i) => (
                        <div
                          key={data + '_' + i}
                          className={'img_wrap imgAreaAdjust screenType2 flex_box flex_align_center flex_content_center'}
                          onClick={() => {
                            CanvasConfService.ins.detail_blindArr = data.detail_blind;
                            if (data.detail_blind.length > 0) {
                              CanvasConfService.ins.detail_blind = data.detail_blind[0];
                            }
                            CanvasConfService.ins.blindData.sub_id = String(data.sub_id);
                            this.handlerClickSubBlind(data, +i);
                          }}
                        >
                          {data.type}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {openType.map((data, i) => (
                        <div
                          key={data + '_' + i}
                          onClick={() => {
                            CanvasConfService.ins.detail_blind = CanvasConfService.ins.detail_blindArr[+i];
                            if (!CanvasConfService.ins.detail_blind) {
                              CanvasConfService.ins.detail_blind = CanvasConfService.ins.detail_blindArr[CanvasConfService.ins.detail_blindArr.length - 1];
                            }
                            console.group(CanvasConfService.ins.detail_blind);
                            console.log(CanvasConfService.ins.detail_blindArr);
                            console.groupEnd();
                            CanvasConfService.ins.blindData.blindOpenType = data.type_name;
                            this.handlerClickOpenType();
                          }}
                          className={'img_wrap imgAreaAdjust screenType3'}
                        >
                          <div>
                            <img src={this.hostUrl + data.type_img} />
                            <div>{data.type_name}</div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* //.blindList_wrap */}

        </div>
      </>
    );
  }

  componentDidMount(): void {
    const request: GetBlindList = new GetBlindList('standard');
    this.hostUrl = request.getHost();
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<BlindDetail[]>) => {
        this.setState({
          currentBlind: 'standard',
          blind: response.data,
          screenType: ScreenType.Blind,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private handlerClickStandardButton(): void {
    const request: GetBlindList = new GetBlindList('standard');
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<BlindDetail[]>) => {
        this.setState({
          currentBlind: 'standard',
          blind: response.data,
          screenType: ScreenType.Blind,
          isComplete: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private handlerClickOriginalButton(): void {
    const request: GetBlindList = new GetBlindList('original');
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<BlindDetail[]>) => {
        this.setState({
          currentBlind: 'original',
          blind: response.data,
          screenType: ScreenType.Blind,
          isComplete: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private handlerClickBlind(id: number, blindName: string, subId: number): void {
    DrawManager.blindSelected = true;
    CanvasConfService.loading(true);
    SlatModalService.ins.isOpenType = false;
    CanvasConfService.ins.centerRaceColorArr = [];
    // setTimeout(() => CanvasConfService.loading(false), 500);
    CanvasConfService.ins.blindData.id = String(id);
    CanvasConfService.ins.blindData.sub_id = String(subId);
    CanvasConfService.ins.blindData.blindName = blindName;
    CanvasConfService.ins.blindData.isStandardBlind = this.state.currentBlind === 'standard';
    const request: GetBlindDetail = new GetBlindDetail(id);
    ConnectionService.ins.connect(request)
      .then((responseBlindDetail: AxiosResponse<BlindDetail>) => {
        CanvasConfService.ins.responseDataBlindDetail = responseBlindDetail.data;
        if (CanvasConfService.ins.responseDataBlindDetail.type === '調光ロールスクリーン') {
          SlatModalService.ins.isOpen = true;
        } else {
          SlatModalService.ins.isOpen = false;
        }
        ConnectionService.ins.connect(new GetWidthHeight())
          .then((responseSize: AxiosResponse<BlindWidthHeight>) => {
            CanvasConfService.ins.blindData.blindWidth = responseSize.data.width;
            CanvasConfService.ins.blindData.blindHeight = responseSize.data.height;
          })
          .catch((err) => console.log(err));
        const typeRequest: GetSubBlindList = new GetSubBlindList(+responseBlindDetail.data.sub_id);
        ConnectionService.ins.connect(typeRequest)
          .then((responseSubDetail: AxiosResponse<SubBlindDetail[]>) => {
            CanvasConfService.ins.detail_blindArr = responseSubDetail.data[0].detail_blind;
            if (responseSubDetail.data[0].detail_blind.length > 0) {
              CanvasConfService.ins.detail_blind = responseSubDetail.data[0].detail_blind[0];
            }
            ConnectionService.ins.connect(new GetColorsById(responseSubDetail.data[0].id))
              .then((responseColor: AxiosResponse<ColorInfo[]>) => {
                if (responseColor.data.length > 0) {
                  const image = new Image();
                  image.onload = () => {
                    CanvasConfService.ins.blindData.imgSizeWidth = image.width;
                    CanvasConfService.ins.blindData.imgSizeHeight = image.height;
                  };
                  image.src = responseColor.data[0].image_file;
                  CanvasConfService.ins.blindData.blindColorCode = responseColor.data[0].color.replace('0x', '#');
                  SlatModalService.ins.slatColor = [{
                    name: responseColor.data[0].color_code,
                    color: responseColor.data[0].color.replace('0x', '#'),
                    color_code: responseColor.data[0].color_code,
                  }];
                  CanvasConfService.ins.responseDataColorInfo = responseColor.data[0];
                  (new Promise((resolve: () => void) => {
                    if (responseColor.data[0].image_file) {
                      DrawManager.getFileFromUrl(this.hostUrl + responseColor.data[0].image_file)
                        .then((res: string) => {
                          CanvasConfService.ins.blindData.displayImgPath = res;
                          resolve();
                        });
                    } else {
                      CanvasConfService.ins.blindData.displayImgPath = null;
                      resolve();
                    }
                  })).then(() => {
                    if (responseSubDetail.data.length > 0) {
                      CanvasConfService.ins.blindId = responseSubDetail.data[0].id;
                      if (responseSubDetail.data[0].slat_img) {
                        CanvasConfService.ins.slatImg = request.getHost() + responseSubDetail.data[0].slat_img;
                      } else {
                        CanvasConfService.ins.slatImg = '';
                      }
                      const {
                        top_img,
                        bottom_img,
                        rope_img,
                        balance_img,
                        middlebar_img,
                        screen_img,
                      } = responseSubDetail.data[0];
                      if (top_img) {
                        DrawManager.getFileFromUrl(request.getHost() + top_img)
                          .then((res: string) => {
                            CanvasConfService.ins.optionImg.head_box = res;
                          });
                      } else {
                        CanvasConfService.ins.optionImg.head_box = '';
                      }
                      if (bottom_img) {
                        DrawManager.getFileFromUrl(request.getHost() + bottom_img)
                          .then((res: string) => {
                            CanvasConfService.ins.optionImg.bottom_rail = res;
                          });
                      } else {
                        CanvasConfService.ins.optionImg.bottom_rail = '';
                      }
                      if (rope_img) {
                        DrawManager.getFileFromUrl(request.getHost() + rope_img)
                          .then((res: string) => {
                            CanvasConfService.ins.optionImg.wide_ladder_tape = res;
                          });
                      } else {
                        CanvasConfService.ins.optionImg.wide_ladder_tape = '';
                      }
                      if (balance_img) {
                        DrawManager.getFileFromUrl(request.getHost() + balance_img)
                          .then((res: string) => {
                            CanvasConfService.ins.optionImg.wood_balance = res;
                          });
                      } else {
                        CanvasConfService.ins.optionImg.wood_balance = '';
                      }
                      if (middlebar_img) {
                        DrawManager.getFileFromUrl(request.getHost() + middlebar_img)
                          .then((res: string) => {
                            CanvasConfService.ins.optionImg.accent = res;
                          });
                      } else {
                        CanvasConfService.ins.optionImg.accent = '';
                      }
                      if (screen_img) {
                        DrawManager.getFileFromUrl(request.getHost() + screen_img)
                          .then((res: string) => {
                            CanvasConfService.ins.optionImg.cover = res;
                          });
                      } else {
                        CanvasConfService.ins.optionImg.cover = '';
                      }
                      this.optionList = responseSubDetail.data;
                      CanvasConfService.ins.isSetOption = new IsSetOption();
                      CanvasConfService.ins.blindData.blindType = responseSubDetail.data[0].type;
                      CanvasConfService.ins.responseDataBlindDetail = responseBlindDetail.data;
                      // SlatModalService.ins.slatSvgList = [responseColor.data[0].texture_color.replace('0x', '#')];

                      DrawManager.getSlatElementFromUrl(
                        this.bottom,
                        responseColor.data[0].color.replace('0x', '#'),
                        responseColor.data[0].texture_color.replace('0x', '#'),
                      ).then((svgRes) => {
                        const svgData = new XMLSerializer().serializeToString(svgRes);
                        const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
                        DrawManager.getFileFromUrl(data)
                          .then((res: string) => {
                            SlatModalService.ins.bottomSvgList = [res];
                          });
                      });
                      DrawManager.getSlatElementFromUrl(
                        CanvasConfService.ins.slatImg.indexOf('va') === -1 ? this.slatSide : this.slatVertical,
                        responseColor.data[0].color.replace('0x', '#'),
                        responseColor.data[0].texture_color.replace('0x', '#'),
                      ).then((svgRes) => {
                        const svgData = new XMLSerializer().serializeToString(svgRes);
                        const data = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
                        DrawManager.getFileFromUrl(data)
                          .then((res: string) => {
                            SlatModalService.ins.slatSvgList = [res];
                            const {
                              headbox,
                              bottom,
                              wide,
                              wood,
                              headbox_table,
                              bottom_table,
                              wide_table,
                              wood_table,
                              center_table,
                            } = CanvasConfService.ins.detail_blind;
                            const func1 = ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4 || +headbox === 10)) ?
                              ConnectionService.ins.connect(headbox_table ? new GetOptionColors(headbox_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func2 = ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4 || +bottom === 10)) ?
                              ConnectionService.ins.connect(bottom_table ? new GetOptionColors(bottom_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func3 = ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4 || +wide === 10)) ?
                              ConnectionService.ins.connect(wide_table ? new GetOptionColors(wide_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func4 = ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4 || +wood === 10)) ?
                              ConnectionService.ins.connect(wood_table ? new GetOptionColors(wood_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func5 = center_table ? ConnectionService.ins.connect(new GetOptionColors(center_table)) : new Promise((resolve) => resolve());
                            Promise.all([
                              func1, func2, func3, func4, func5,
                            ])
                              .then((res: any) => {
                                if ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4 || +headbox === 10)) {
                                  CanvasConfService.ins.isSetOption.head_box = res[0].data[0].color.replace('0x', '#');
                                  if (res[0].data[0].hasOwnProperty('color_code') && res[0].data[0].hasOwnProperty('color_name')) {
                                    CanvasConfService.ins.isSetOption.head_box_value1 = res[0].data[0].color_code;
                                    CanvasConfService.ins.isSetOption.head_box_value2 = res[0].data[0].color_name;
                                    ``;
                                  }
                                  CanvasConfService.ins.isSetOption.middle = res[0].data[0].color.replace('0x', '#');
                                  if (res[0].data[0].hasOwnProperty('color_code') && res[0].data[0].hasOwnProperty('color_name')) {
                                    CanvasConfService.ins.isSetOption.middle_value1 = res[0].data[0].color_code;
                                    CanvasConfService.ins.isSetOption.middle_value2 = res[0].data[0].color_name;
                                  }
                                }
                                if ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4 || +bottom === 10)) {
                                  CanvasConfService.ins.isSetOption.bottom_rail = res[1].data[0].color.replace('0x', '#');
                                  if (res[1].data[0].hasOwnProperty('color_code') && res[1].data[0].hasOwnProperty('color_name')) {
                                    CanvasConfService.ins.isSetOption.bottom_rail_value1 = res[1].data[0].color_code;
                                    CanvasConfService.ins.isSetOption.bottom_rail_value2 = res[1].data[0].color_name;
                                  }
                                  CanvasConfService.ins.isSetOption.middle = res[1].data[0].color.replace('0x', '#');
                                  if (res[1].data[0].hasOwnProperty('color_code') && res[1].data[0].hasOwnProperty('color_name')) {
                                    CanvasConfService.ins.isSetOption.middle_value1 = res[1].data[0].color_code;
                                    CanvasConfService.ins.isSetOption.middle_value2 = res[1].data[0].color_name;
                                  }
                                }
                                if ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4 || +wide === 10)) {
                                  CanvasConfService.ins.isSetOption.wide_ladder_tape = res[2].data[0].color.replace('0x', '#');
                                  if (res[2].data[0].hasOwnProperty('color_code') && res[2].data[0].hasOwnProperty('color_name')) {
                                    CanvasConfService.ins.isSetOption.wide_ladder_tape_value1 = res[2].data[0].color_code;
                                    CanvasConfService.ins.isSetOption.wide_ladder_tape_value2 = res[2].data[0].color_name;
                                  }
                                }
                                if ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4 || +wood === 10)) {
                                  CanvasConfService.ins.isSetOption.wood_balance = res[3].data[0].color.replace('0x', '#');
                                  if (res[3].data[0].hasOwnProperty('color_code') && res[0].data[0].hasOwnProperty('color_name')) {
                                    CanvasConfService.ins.isSetOption.wood_balance_value1 = res[3].data[0].color_code;
                                    CanvasConfService.ins.isSetOption.wood_balance_value2 = res[3].data[0].color_name;
                                  }
                                }
                                if (center_table) {
                                  CanvasConfService.ins.centerRaceArr = res[4].data;
                                }
                                if (responseColor.data[0].sub_color_code) {
                                  const index = CanvasConfService.ins.centerRaceArr.findIndex((data) => data.color_code === responseColor.data[0].sub_color_code);
                                  if (index !== -1) {
                                    const centerData = CanvasConfService.ins.centerRaceArr[index];
                                    CanvasConfService.ins.centerRaceBase = centerData;
                                    const { option_type, color_code, color_name, color, series_name } = centerData;
                                    const centerRace = CanvasConfService.ins.centerRaceArr.find((data) => data.option_type === option_type && data.color_code !== color_code && data.color_name === color_name && data.series_name === series_name);
                                    if (centerRace) {
                                      CanvasConfService.ins.centerRace = centerRace;
                                    } else {
                                      CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
                                    }
                                    // CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
                                  } else {
                                    CanvasConfService.ins.centerRaceColorArr = [];
                                    const centerRace = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'センターレース');
                                    if (centerRace) {
                                      const {
                                        id,
                                        option_type,
                                        color_code,
                                        color_name,
                                        color,
                                        image_file,
                                        image,
                                        series_name,
                                      } = centerRace;
                                      CanvasConfService.ins.centerRace = {
                                        id: id,
                                        option_type: option_type,
                                        color_code: color_code,
                                        color_name: color_name,
                                        color: color,
                                        image: image,
                                        image_file: image_file,
                                        series_name: series_name,
                                      };
                                    } else {
                                      const centerRaceArr = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'ミラーセンターレース');
                                      if (centerRaceArr) {
                                        const {
                                          id,
                                          option_type,
                                          color_code,
                                          color_name,
                                          color,
                                          image_file,
                                          image,
                                          series_name,
                                        } = centerRaceArr;
                                        CanvasConfService.ins.centerRace = {
                                          id: id,
                                          option_type: option_type,
                                          color_code: color_code,
                                          color_name: color_name,
                                          color: color,
                                          image: image,
                                          image_file: image_file,
                                          series_name: series_name,
                                        };
                                      }
                                    }
                                    // CanvasConfService.ins.centerRace = {
                                    //   id: 467,
                                    //   option_type: 'patAC',
                                    //   color_code: 'PA356',
                                    //   color_name: 'ホワイト',
                                    //   color: '0xF7F6F2',
                                    //   image: '',
                                    //   image_file: '',
                                    //   series_name: 'センターレース',
                                    // };
                                  }
                                  // if(index){
                                  //   CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
                                  // }else{
                                  //   CanvasConfService.ins.centerRace = new OptionDetail();
                                  // }
                                  ConnectionService.ins.connect(new GetColorsByCode(responseColor.data[0].sub_color_code))
                                    .then((responseUnderSlatData: AxiosResponse<ColorInfo[]>) => {
                                      if (responseUnderSlatData.data.length > 0) {
                                        CanvasConfService.ins.underSlatData = responseUnderSlatData.data[0];
                                      }
                                      setTimeout(() => {
                                        CanvasConfService.ins.blindData.color = responseColor.data[0].color_code;
                                        // responseColor.data[0].group_name;
                                        CanvasConfService.ins.updateBlindSpec();
                                        CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                                        // DrawManager.drawSVG();
                                      }, 400);
                                      const request: GetOpenTypeList = new GetOpenTypeList();
                                      ConnectionService.ins.connect(request)
                                        .then((openRes) => {
                                          CanvasConfService.ins.blindData.blindOpenType = openRes.data[0] ? openRes.data[0].type_name : '';
                                          this.setState({
                                            screenType: ScreenType.SubBlind,
                                            subBlind: responseSubDetail.data,
                                          });
                                        });
                                    })
                                    .catch((err) => console.log(err));
                                } else {
                                  setTimeout(() => {
                                    CanvasConfService.ins.blindData.color = responseColor.data[0].color_code;
                                    CanvasConfService.ins.updateBlindSpec();
                                    CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                                    // DrawManager.drawSVG();
                                  }, 400);
                                  const request: GetOpenTypeList = new GetOpenTypeList();
                                  ConnectionService.ins.connect(request)
                                    .then((openRes) => {
                                      CanvasConfService.ins.blindData.blindOpenType = openRes.data[0] ? openRes.data[0].type_name : '';
                                      this.setState({
                                        screenType: ScreenType.SubBlind,
                                        subBlind: responseSubDetail.data,
                                      });
                                    });
                                }
                              })
                              .catch((err) => console.log(err));
                          });
                      });
                    }
                  })
                    .catch((err) => console.log(err));
                }
              });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private async handlerClickSubBlind(subBlind: SubBlindDetail, i: number): Promise<void> {
    const { type, id, slat_img, detail_blind } = subBlind;
    CanvasConfService.ins.centerRaceColorArr = [];
    CanvasConfService.loading(true);
    SlatModalService.ins.isOpenType = false;
    CanvasConfService.ins.blindData.blindType = type;
    CanvasConfService.ins.blindId = id;
    CanvasConfService.ins.detail_blind = CanvasConfService.ins.detail_blindArr[i];
    const request: GetOpenTypeList = new GetOpenTypeList();
    const {
      top_img,
      bottom_img,
      rope_img,
      balance_img,
      middlebar_img,
      screen_img,
    } = this.optionList[i];
    CanvasConfService.ins.detail_blind = detail_blind[0];
    if (top_img) {
      DrawManager.getFileFromUrl(request.getHost() + top_img)
        .then((res: string) => {
          CanvasConfService.ins.optionImg.head_box = res;
        });
    } else {
      CanvasConfService.ins.optionImg.head_box = '';
    }
    if (bottom_img) {
      DrawManager.getFileFromUrl(request.getHost() + bottom_img)
        .then((res: string) => {
          CanvasConfService.ins.optionImg.bottom_rail = res;
        });
    } else {
      CanvasConfService.ins.optionImg.bottom_rail = '';
    }
    if (balance_img) {
      DrawManager.getFileFromUrl(request.getHost() + balance_img)
        .then((res: string) => {
          CanvasConfService.ins.optionImg.wood_balance = res;
        });
    } else {
      CanvasConfService.ins.optionImg.wood_balance = '';
    }
    if (middlebar_img) {
      DrawManager.getFileFromUrl(request.getHost() + middlebar_img)
        .then((res: string) => {
          CanvasConfService.ins.optionImg.accent = res;
        });
    } else {
      CanvasConfService.ins.optionImg.accent = '';
    }
    if (screen_img) {
      DrawManager.getFileFromUrl(request.getHost() + screen_img)
        .then((res: string) => {
          CanvasConfService.ins.optionImg.cover = screen_img;
        });
    } else {
      CanvasConfService.ins.optionImg.cover = '';
    }
    CanvasConfService.ins.isSetOption = new IsSetOption();
    if (rope_img) {
      DrawManager.getFileFromUrl(request.getHost() + rope_img)
        .then((res: string) => {
          CanvasConfService.ins.optionImg.wide_ladder_tape = res;
        });
    } else {
      CanvasConfService.ins.optionImg.wide_ladder_tape = '';
    }
    await new Promise((resolve) => {
      const {
        headbox,
        bottom,
        wide,
        wood,
        headbox_table,
        bottom_table,
        wide_table,
        wood_table,
        center_table,
      } = detail_blind[0];
      const func1 = ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4 || +headbox === 10)) ?
        ConnectionService.ins.connect(headbox_table ? new GetOptionColors(headbox_table) : new GetColorsById()) : new Promise((resolve) => resolve());
      const func2 = ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4 || +bottom === 10)) ?
        ConnectionService.ins.connect(bottom_table ? new GetOptionColors(bottom_table) : new GetColorsById()) : new Promise((resolve) => resolve());
      let func3 = ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4 || +wide === 10)) ?
        ConnectionService.ins.connect(wide_table ? new GetOptionColors(wide_table) : new GetColorsById()) : new Promise((resolve) => resolve());
      const func4 = ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4 || +wood === 10)) ?
        ConnectionService.ins.connect(wood_table ? new GetOptionColors(wood_table) : new GetColorsById()) : new Promise((resolve) => resolve());
      const func5 = center_table ? ConnectionService.ins.connect(new GetOptionColors(center_table)) : new Promise((resolve) => resolve());
      Promise.all([
        func1, func2, func3, func4, func5,
      ])
        .then((res: any) => {
          if ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4 || +headbox === 10)) {
            CanvasConfService.ins.isSetOption.head_box = res[0].data[0].color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.head_box_value1 = res[0].data[0].color_code;
            CanvasConfService.ins.isSetOption.head_box_value2 = res[0].data[0].color_name;
            CanvasConfService.ins.isSetOption.middle = res[0].data[0].color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle_value1 = res[0].data[0].color_code;
            CanvasConfService.ins.isSetOption.middle_value2 = res[0].data[0].color_name;
          }
          if ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4 || +bottom === 10)) {
            CanvasConfService.ins.isSetOption.bottom_rail = res[1].data[0].color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.bottom_rail_value1 = res[1].data[0].color_code;
            CanvasConfService.ins.isSetOption.bottom_rail_value2 = res[1].data[0].color_name;
            CanvasConfService.ins.isSetOption.middle = res[1].data[0].color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.middle_value1 = res[1].data[0].color_code;
            CanvasConfService.ins.isSetOption.middle_value2 = res[1].data[0].color_name;
          }
          if ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4 || +wide === 10)) {
            CanvasConfService.ins.isSetOption.wide_ladder_tape = res[2].data[0].color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.wide_ladder_tape_value1 = res[2].data[0].color_code;
            CanvasConfService.ins.isSetOption.wide_ladder_tape_value2 = res[2].data[0].color_name;
          }
          if ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4 || +wood === 10)) {
            CanvasConfService.ins.isSetOption.wood_balance = res[3].data[0].color.replace('0x', '#');
            CanvasConfService.ins.isSetOption.wood_balance_value1 = res[3].data[0].color_code;
            CanvasConfService.ins.isSetOption.wood_balance_value2 = res[3].data[0].color_name;
          }
          if (center_table) {
            CanvasConfService.ins.centerRaceArr = res[4].data;
          }
          if (detail_blind[0].wide_table) {
            const request = new GetOptionColors(detail_blind[0].wide_table);
            ConnectionService.ins.connect(request)
              .then((responce: AxiosResponse<OptionDetail[]>) => {
                CanvasConfService.ins.isSetOption.wide_ladder_tape = this.hostUrl + responce.data[0].image_file;
                CanvasConfService.ins.isSetOption.wide_ladder_tape_value1 = responce.data[0].color_code;
                CanvasConfService.ins.isSetOption.wide_ladder_tape_value2 = responce.data[0].color_name;
                CanvasConfService.ins.optionImg.wide_ladder_tape = this.hostUrl + responce.data[0].image_file;
                resolve();
              });
          } else {
            resolve();
          }
        })
        .catch((err) => console.log(err));
    });
    await new Promise((resolve: () => void) => {
      ConnectionService.ins.connect(new GetColorsById())
        .then((colorRes: AxiosResponse<ColorInfo[]>) => {
          let arr = colorRes.data;
          CanvasConfService.ins.responseDataColorInfo = arr[0];
          SlatModalService.ins.slatColor = [{
            name: colorRes.data[0].color_code,
            color: colorRes.data[0].color.replace('0x', '#'),
            color_code: colorRes.data[0].color_code,
          }];

          ConnectionService.ins.connect(request)
            .then((response: AxiosResponse<OpenTypeDetail[]>) => {
              CanvasConfService.ins.blindData.color = colorRes.data[0].color_code;
              CanvasConfService.ins.updateBlindSpec();
              if (response.data.length > 0) {
                if (slat_img && CanvasConfService.ins.slatImg !== slat_img) {
                  CanvasConfService.ins.slatImg = request.getHost() + slat_img;
                  CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                  // setTimeout(() => DrawManager.drawSVG());
                } else {
                  CanvasConfService.ins.slatImg = '';
                }
                CanvasConfService.ins.blindData.blindOpenType = response.data[0] ? response.data[0].type_name : '';
                this.setState({
                  screenType: ScreenType.OpenType,
                  openType: response.data,
                });
              } else {
                if (slat_img && CanvasConfService.ins.slatImg !== slat_img) {
                  CanvasConfService.ins.slatImg = request.getHost() + slat_img;
                  CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                  // setTimeout(() => DrawManager.drawSVG());
                } else {
                  CanvasConfService.ins.slatImg = '';
                }
                this.setState({
                  isComplete: true,
                });
                setTimeout(() => {
                  if (this.state.currentBlind === 'standard') {
                    this.handlerClickStandardButton();
                  } else {
                    this.handlerClickOriginalButton();
                  }
                }, 500);
              }
              resolve();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    });

    ConnectionService.ins.connect(new GetColorsById(id))
      .then((responseColor: AxiosResponse<ColorInfo[]>) => {
        if (responseColor.data.length > 0) {
          CanvasConfService.ins.blindData.blindColorCode = responseColor.data[0].color.replace('0x', '#');
          CanvasConfService.ins.responseDataColorInfo = responseColor.data[0];
          if (CanvasConfService.ins.accentColor.color.group_name !== responseColor.data[0].group_name) {
            CanvasConfService.ins.accentColor.color = responseColor.data[0];
          }
          if (responseColor.data[0].image_file) {
            const image = new Image();
            image.onload = () => {
              CanvasConfService.ins.blindData.imgSizeWidth = image.width;
              CanvasConfService.ins.blindData.imgSizeHeight = image.height;
            };
            image.src = this.hostUrl + responseColor.data[0].image_file;
            DrawManager.getFileFromUrl(this.hostUrl + responseColor.data[0].image_file)
              .then((res: string) => {
                CanvasConfService.ins.blindData.displayImgPath = res;
                setTimeout(() => {
                  CanvasConfService.ins.blindData.color = responseColor.data[0].color_code;
                  CanvasConfService.ins.updateBlindSpec();
                  CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                  // DrawManager.drawSVG();
                });
              });
          } else {
            CanvasConfService.ins.blindData.displayImgPath = null;
            setTimeout(() => {
              CanvasConfService.ins.blindData.color = responseColor.data[0].color_code;
              CanvasConfService.ins.updateBlindSpec();
              CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
              // DrawManager.drawSVG();
            });
          }
          if (responseColor.data[0].sub_color_code) {
            const index = CanvasConfService.ins.centerRaceArr.findIndex((data) => data.color_code === responseColor.data[0].sub_color_code);
            // if(index){
            //   CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
            // }else{
            //   CanvasConfService.ins.centerRace = new OptionDetail();
            // }
            if (index !== -1) {
              const centerData = CanvasConfService.ins.centerRaceArr[index];
              CanvasConfService.ins.centerRaceBase = centerData;
              const { option_type, color_code, color_name, color, series_name } = centerData;
              const centerRace = CanvasConfService.ins.centerRaceArr.find((data) => data.option_type === option_type && data.color_code !== color_code && data.color_name === color_name && data.series_name === series_name);
              if (centerRace) {
                CanvasConfService.ins.centerRace = centerRace;
              } else {
                CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
              }
              // CanvasConfService.ins.centerRace = CanvasConfService.ins.centerRaceArr[index];
            } else {
              // CanvasConfService.ins.centerRace = new OptionDetail();
              CanvasConfService.ins.centerRaceColorArr = [];
              const centerRace = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'センターレース');
              if (centerRace) {
                const { id, option_type, color_code, color_name, color, image_file, image, series_name } = centerRace;
                CanvasConfService.ins.centerRace = {
                  id: id,
                  option_type: option_type,
                  color_code: color_code,
                  color_name: color_name,
                  color: color,
                  image: image,
                  image_file: image_file,
                  series_name: series_name,
                };
              } else {
                const centerRaceArr = CanvasConfService.ins.centerRaceArr.find((data) => data.series_name === 'ミラーセンターレース');
                if (centerRaceArr) {
                  const {
                    id,
                    option_type,
                    color_code,
                    color_name,
                    color,
                    image_file,
                    image,
                    series_name,
                  } = centerRaceArr;
                  CanvasConfService.ins.centerRace = {
                    id: id,
                    option_type: option_type,
                    color_code: color_code,
                    color_name: color_name,
                    color: color,
                    image: image,
                    image_file: image_file,
                    series_name: series_name,
                  };
                }
              }
            }
            ConnectionService.ins.connect(new GetColorsByCode(responseColor.data[0].sub_color_code))
              .then((responseUnderSlatData: AxiosResponse<ColorInfo[]>) => {
                if (responseUnderSlatData.data.length > 0) {
                  const a = CanvasConfService.ins.responseDataColorInfo;
                  if (+subBlind.sub_id === 62 || +subBlind.sub_id === 107) {
                    CanvasConfService.ins.responseDataColorInfo = responseUnderSlatData.data[0];
                    CanvasConfService.ins.underSlatData = a;
                  } else {
                    CanvasConfService.ins.underSlatData = responseUnderSlatData.data[0];
                  }
                  CanvasConfService.ins.updateBlindSpec();
                  CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                }
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => console.log(err));
  }

  private handlerClickOpenType(): void {
    const request: GetBlindList = new GetBlindList(this.state.currentBlind);
    this.hostUrl = request.getHost();
    ConnectionService.ins.connect(request)
      .then((response: AxiosResponse<BlindDetail[]>) => {
        this.setState({
          isComplete: true,
        });
        setTimeout(() => {
          this.setState({
            blind: response.data,
            screenType: ScreenType.Blind,
            isComplete: false,
          });
        }, 500);
        CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

enum ScreenType {
  Blind,
  SubBlind,
  OpenType,
}

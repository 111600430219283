import * as React from 'react';
import './color-change-confirm-component.scss';
// import btnAddSheet from '../../../assets/img/modal/btn_saveImage_addSheet.png';
// import btnAddSheetRet from '../../../assets/img/modal/btn_saveImage_addSheet@2x.png';
// import btnCancel from '../../../assets/img/modal/btn_saveImage_cancel.png';
// import btnCancelRet from '../../../assets/img/modal/btn_saveImage_cancel@2x.png';
import {CanvasConfService} from '../../../service/canvas-conf-service';
import btnYes from '../../../assets/img/modal/btn_yes.png';
import btnYesRet from '../../../assets/img/modal/btn_yes@2x.png';
import btnNo from '../../../assets/img/modal/btn_no.png';
import btnNoRet from '../../../assets/img/modal/btn_no@2x.png';

interface ColorChangeConfirmComponentProps {
  callback: () => void,
}

interface ColorChangeConfirmComponentState {
}

export class ColorChangeConfirmComponent extends React.Component<ColorChangeConfirmComponentProps, ColorChangeConfirmComponentState> {

  constructor(props: ColorChangeConfirmComponentProps) {
    super(props);
    this.state = {};
  }

  componentWillMount(): void {
  }

  render() {
    return (
      <div className={'dialog_bodyInner'}>
        <div className={'dialog_title flex_box flex_align_center flex_space_between'}>
          <div className={'dialog_titleInner'}>スラットカラー変更</div>
          <div className={'right_btn_wrap'}>
            <button
              type={'button'}
              className={'reset'}
              onClick={() => this.handlerClickNo()}
            >&#10005;</button>
          </div>
        </div>

          <div className={'dialog_cont'}>
            <div className={'dialog_contInner'}>

              <div className={'dialog_message flex_box flex_align_center flex_content_center'}>
                <div>選択した色柄で塗り潰します。<br />よろしいですか？</div>
              </div>
              <div className={'divide30'}/>

              <div className={'dialog_btn flex_box flex_align_center flex_content_center'}>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickNo()}
                  >
                    <img
                      src={btnNo}
                      srcSet={`${btnNo} 1x, ${btnNoRet} 2x`}
                      alt={'キャンセル'}
                    />
                  </button>
                </div>
                <div>
                  <button
                    type={'button'}
                    className={'reset'}
                    onClick={() => this.handlerClickYes()}
                  >
                    <img
                      src={btnYes}
                      srcSet={`${btnYes} 1x, ${btnYesRet} 2x`}
                      alt={'イメージシート作成'}
                    />
                  </button>
                </div>
              </div>
              {/* //.dialog_btn */}

            </div>
          </div>

      </div>
    );
  }

  private handlerClickYes(): void {
    this.props.callback();
    CanvasConfService.isColorChangeConfirm(false, () => {});
  }

  private handlerClickNo(): void {
    CanvasConfService.isColorChangeConfirm(false, () => {});
  }

}

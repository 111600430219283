

export class NumericSolveManager {

  public static solve(A: number[][], b: number[], isFast: boolean): number[] {
    return this.LUsolve(this.LU(A, isFast), b);
  }

  private static LUsolve(LUP: any, b: number[]): any {
    const LU: number[][] = LUP.LU;
    const n: number = LU.length;
    const x: number[] = this.clone(b);
    const P: number[] = LUP.P;
    let Pi: number;
    let tmp: number;
    let LUi: number[];
    for (let i: number = n - 1; i !== -1; --i) {
      x[i] = b[i];
    }
    for (let i: number = 0; i < n; ++i) {
      Pi = P[i];
      if (P[i] !== i) {
        tmp = x[i];
        x[i] = x[Pi];
        x[Pi] = tmp;
      }
      LUi = LU[i];
      for (let j: number = 0; j < i; ++j) {
        x[i] -= x[j] * LUi[j];
      }
    }
    for (let i: number = n - 1; i >= 0; --i) {
      LUi = LU[i];
      for (let j: number = i + 1; j < n; ++j) {
        x[i] -= x[j] * LUi[j];
      }
      x[i] /= LUi[i];
    }
    return x;
  }

  private static LU(A: number[][], isFast: boolean): {LU: number[][], P: number[]} {
    isFast = isFast || false;
    const n: number = A.length;
    const n1: number = n - 1;
    const P: number[]= new Array(n);
    let _A: number[][] = isFast ? A : this.clone(A);
    let Pk: number;
    let Ak: number[];
    let max: number;
    let absAjk: number;
    let Akk: number;
    let Ai: number[];
    let j: number;
    for (let k: number = 0; k < n; ++k){
      Pk = k;
      Ak = _A[k];
      max = Math.abs(Ak[k]);
      for(j = k + 1; j < n; ++j){
        absAjk = Math.abs(_A[j][k]);
        if(max < absAjk){
          max = absAjk;
          Pk = j;
        }
      }
      P[k] = Pk;
      if(Pk != k) {
        _A[k] = _A[Pk];
        _A[Pk] = Ak;
        Ak = _A[k];
      }
      Akk = Ak[k];
      for(let i: number = k + 1; i < n; ++i){
        _A[i][k] /= Akk;
      }
      for(let i :number = k + 1; i < n; ++i){
        Ai = _A[i];
        for(j = k + 1; j < n1; ++j){
          Ai[j] -= Ai[k] * Ak[j];
          ++j;
          Ai[j] -= Ai[k] * Ak[j];
        }
        if(j === n1){
          Ai[j] -= Ai[k] * Ak[j];
        }
      }
    }
    return {LU: _A, P: P};
  }

  private static clone(x: any): any {
    return typeof x !== 'object' ? x : this._foreach2(x, this.dim(x), 0, (arr: any) => this.cloneV(arr));
  }

  private static cloneV(x: any): number[] {
    const n: number = x.length;
    let ret: number[] = Array(n);
    for(let i: number = n - 1; i !== -1; --i){
      ret[i] = x[i];
    }
    return ret;

  }

  private static dim(x: any): any {
    if(typeof x !== 'object'){
      return[];
    }
    const y = x[0];
    if(typeof y !== 'object'){
      return [x.length];
    }
    const z = y[0];
    if(typeof z !== 'object'){
      return [x.length, y.length];
    }
    return this._dim(x);
  }

  private static _dim(x: any): number[] {
    const ret: number[] = [];
    let _x = x;
    while (typeof _x === 'object'){
      ret.push(_x.length);
      _x = _x[0];
    }
    return ret;
  }

  private static _foreach2(x: any, s: any, k: any, f: any): any {
    if(k === s.length - 1){
      return f(x);
    }
    const n: number = s[k];
    let ret: number[] = Array(n);
    for(let i: number = n - 1; i >= 0; --i){
      ret[i] = this._foreach2(x[i], s, k + 1, f);
    }
    return ret;
  }
}
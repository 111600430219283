import * as React from 'react';
import './draw-container-component.scss';
import {CanvasConfService} from '../../../../service/canvas-conf-service';
import {BlindDetail, GetBlindDetail} from '../../../../model/api/blind/get-blind-detail';
import {ConnectionService} from '../../../../service/connection-service';
import {AxiosResponse} from 'axios';
import {DrawManager} from '../../../../manager/draw-manager';
import {GetSampleBackgroundList} from '../../../../model/api/background/get-sample-background-list';
import {SampleBackgroundDetail} from '../../../../model/api/background/get-sample-background-detail';
import {GetSubBlindList, SubBlindDetail} from '../../../../model/api/blind/get-sub-blind-list';
import {ColorList, GetColorGroup} from '../../../../model/api/color/get-color-group';
import {BlindWidthHeight, GetWidthHeight} from '../../../../model/api/blind/get-width-height';
import {GetOpenTypeList, OpenTypeDetail} from '../../../../model/api/blind/get-open-type-list';
import {GetOptionColors} from '../../../../model/api/option/get-option-colors';
import {func} from 'prop-types';
import {ColorInfo, GetColorsById} from '../../../../model/api/color/get-colors-by-id';
import {Pos} from '../../../../model/pos';

interface DrawContainerComponentProps {
}

interface DrawContainerComponentState {
  reload: boolean;
}

export class DrawContainerComponent extends React.Component<DrawContainerComponentProps, DrawContainerComponentState> {
  private isMobile: boolean;

  constructor(props: DrawContainerComponentProps) {
    super(props);
    DrawManager.controlHandlerObj = null;
    DrawManager.targetPoints = [
      new Pos(-350, -350),
      new Pos(400 - 350, -350),
      new Pos(400 - 350, 400 - 350),
      new Pos(-350, 400 - 350),
    ];
    CanvasConfService.reset();
    this.state = {
      reload: true,
    };
    CanvasConfService.ins.updateDrawContainer = () => DrawManager.drawSVG();
    const ua = navigator.userAgent;
    this.isMobile = ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 || ua.indexOf('Mobile') > 0;
  }

  render() {
    return (
      <div
        className={'drawAreaInner_wrap'}
        style={{'background': '#FFFFFF'}}
      >

        <canvas
          id={CanvasConfService.ins.canvasId}
          width={700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)}
          height={700 - (this.isMobile ? (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1) ? 450 : (navigator.userAgent.indexOf('iPhone') !== -1) ? 400 : 250 : 0)}
          style={{'background': '#FFFFFF'}}
        />
        <div id={CanvasConfService.ins.svgAreaId}/>
        <div id={'control-area'}/>

      </div>
    );
  }

  componentDidMount(): void {
    // 操作するCanvas要素のidを渡す
    const requestBlind: GetBlindDetail = new GetBlindDetail(CanvasConfService.ins.blindId);
    ConnectionService.ins.connect(requestBlind)
      .then((responseBlind: AxiosResponse<BlindDetail>) => {
        const requestBackgournd: GetSampleBackgroundList = new GetSampleBackgroundList();
        ConnectionService.ins.connect(requestBackgournd)
          .then((responseBackground: AxiosResponse<SampleBackgroundDetail[]>) => {
            const img = new Image();
            img.src = requestBackgournd.getHost() + responseBackground.data[5].image_file;
            CanvasConfService.ins.backgroundImg = img.src;
            /**
             * ブラインドスペック初期値設定
             * */
            const requestSubBlind: GetSubBlindList = new GetSubBlindList(responseBlind.data.sub_id);
            ConnectionService.ins.connect(requestSubBlind)
              .then((responseSubBlind: AxiosResponse<SubBlindDetail[]>) => {
                CanvasConfService.ins.detail_blindArr = responseSubBlind.data[0].detail_blind;
                CanvasConfService.ins.detail_blind = responseSubBlind.data[0].detail_blind[0];
                const requestColor: GetColorGroup = new GetColorGroup(responseBlind.data.id);
                ConnectionService.ins.connect(requestColor)
                  .then((responseColor: AxiosResponse<ColorInfo[]>) => {
                    const requestSize: GetWidthHeight = new GetWidthHeight(responseBlind.data.id);
                    ConnectionService.ins.connect(requestSize)
                      .then((responseSize: AxiosResponse<BlindWidthHeight>) => {
                        const requestOpenType: GetOpenTypeList = new GetOpenTypeList(responseBlind.data.id);
                        ConnectionService.ins.connect(requestOpenType)
                          .then((responseOpenType: AxiosResponse<OpenTypeDetail[]>) => {
                            CanvasConfService.ins.blindData.blindName = responseBlind.data.type;
                            CanvasConfService.ins.blindData.isStandardBlind = true;
                            CanvasConfService.ins.blindData.blindType = responseSubBlind.data[0].type;
                            const {
                              top_img,
                              bottom_img,
                              rope_img,
                              balance_img,
                              middlebar_img,
                              screen_img,
                            } = responseSubBlind.data[0];
                            if (top_img) {
                              DrawManager.getFileFromUrl(requestBlind.getHost() + top_img)
                                .then((res: string) => {
                                  CanvasConfService.ins.optionImg.head_box = res;
                                });
                            }
                            if (bottom_img) {
                              DrawManager.getFileFromUrl(requestBlind.getHost() + bottom_img)
                                .then((res: string) => {
                                  CanvasConfService.ins.optionImg.bottom_rail = res;
                                });
                            }
                            if (rope_img) {
                              DrawManager.getFileFromUrl(requestBlind.getHost() + rope_img)
                                .then((res: string) => {
                                  CanvasConfService.ins.optionImg.wide_ladder_tape = res;
                                });
                            }
                            if (balance_img) {
                              DrawManager.getFileFromUrl(requestBlind.getHost() + balance_img)
                                .then((res: string) => {
                                  CanvasConfService.ins.optionImg.wood_balance = res;
                                });
                            }
                            if (middlebar_img) {
                              DrawManager.getFileFromUrl(requestBlind.getHost() + middlebar_img)
                                .then((res: string) => {
                                  CanvasConfService.ins.optionImg.accent = res;
                                });
                            }
                            if (screen_img) {
                              DrawManager.getFileFromUrl(requestBlind.getHost() + screen_img)
                                .then((res: string) => {
                                  CanvasConfService.ins.optionImg.cover = res;
                                });
                            }
                            CanvasConfService.ins.blindData.id = String(responseBlind.data.id);
                            CanvasConfService.ins.blindData.sub_id = String(responseBlind.data.sub_id);
                            const arr: ColorList[] = [];
                            responseColor.data.forEach((resData) => {
                              const index = arr.findIndex((data) => data.group_name === resData.group_name);
                              if (index !== -1) {
                                arr[index].colors.push(resData);
                              } else {
                                arr.push({
                                  group_name: resData.group_name,
                                  colors: [resData],
                                });
                              }
                            });
                            CanvasConfService.ins.blindData.color = arr[0].colors[0].color_code;
                            CanvasConfService.ins.blindData.blindColorCode = arr[0].colors[0].color.replace('0x', '#');
                            CanvasConfService.ins.responseDataColorInfo = arr[0].colors[0];
                            CanvasConfService.ins.blindData.blindWidth = responseSize.data.width;
                            CanvasConfService.ins.blindData.blindHeight = responseSize.data.height;
                            CanvasConfService.ins.blindData.blindOpenType = responseOpenType.data[0].type_name;
                            CanvasConfService.ins.slatImg = requestBlind.getHost() + responseSubBlind.data[0].slat_img;
                            CanvasConfService.ins.updateBlindSpec();
                            CanvasConfService.ins.responseDataBlindDetail = responseBlind.data;
                            const {headbox, bottom, wide, wood, headbox_table, bottom_table, wide_table, wood_table, center_table} = CanvasConfService.ins.detail_blind;
                            // const func1 = ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4)) ?
                            //   ConnectionService.ins.connect(headbox_table ? new GetOptionColors(headbox_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            // const func2 = ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4)) ?
                            //   ConnectionService.ins.connect(bottom_table ? new GetOptionColors(bottom_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            // let func3 = ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4)) ?
                            //   ConnectionService.ins.connect(wide_table ? new GetOptionColors(wide_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            // const func4 = ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4)) ?
                            //   ConnectionService.ins.connect(wood_table ? new GetOptionColors(wood_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func1 = ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4 || +headbox === 10)) ?
                              ConnectionService.ins.connect(headbox_table ? new GetOptionColors(headbox_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func2 = ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4 || +bottom === 10)) ?
                              ConnectionService.ins.connect(bottom_table ? new GetOptionColors(bottom_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            let func3 = ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4 || +wide === 10)) ?
                              ConnectionService.ins.connect(wide_table ? new GetOptionColors(wide_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func4 = ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4 || +wood === 10)) ?
                              ConnectionService.ins.connect(wood_table ? new GetOptionColors(wood_table) : new GetColorsById()) : new Promise((resolve) => resolve());
                            const func5 = center_table ? ConnectionService.ins.connect(new GetOptionColors(center_table)) : new Promise((resolve) => resolve());
                            Promise.all([
                              func1, func2, func3, func4, func5,
                            ])
                              .then((res: any) => {
                                // if (headbox_table && (+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4)) {
                                //   CanvasConfService.ins.isSetOption.head_box = res[0].data[0].color.replace('0x', '#');
                                // }
                                // if (bottom_table && (+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4)) {
                                //   CanvasConfService.ins.isSetOption.bottom_rail = res[1].data[0].color.replace('0x', '#');
                                // }
                                // if (wide_table && (+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4)) {
                                //   CanvasConfService.ins.isSetOption.wide_ladder_tape = res[2].data[0].color.replace('0x', '#');
                                // }
                                // if (wood_table && (+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4)) {
                                //   CanvasConfService.ins.isSetOption.wood_balance = res[3].data[0].color.replace('0x', '#');
                                // }
                                if ((+headbox === 1 || +headbox === 2 || +headbox === 3 || +headbox === 4 || +headbox === 10)) {
                                  CanvasConfService.ins.isSetOption.head_box = res[0].data[0].color.replace('0x', '#');
                                  CanvasConfService.ins.isSetOption.head_box_value1 = res[0].data[0].color_code;
                                  CanvasConfService.ins.isSetOption.head_box_value2 = res[0].data[0].color_name;
                                  CanvasConfService.ins.isSetOption.middle = res[0].data[0].color.replace('0x', '#');
                                  CanvasConfService.ins.isSetOption.middle_value1 = res[0].data[0].color_code;
                                  CanvasConfService.ins.isSetOption.middle_value2 = res[0].data[0].color_name;
                                }
                                if ((+bottom === 1 || +bottom === 2 || +bottom === 3 || +bottom === 4 || +bottom === 10)) {
                                  CanvasConfService.ins.isSetOption.bottom_rail = res[1].data[0].color.replace('0x', '#');
                                  CanvasConfService.ins.isSetOption.bottom_rail_value1 = res[1].data[0].color_code;
                                  CanvasConfService.ins.isSetOption.bottom_rail_value2 = res[1].data[0].color_name;
                                  CanvasConfService.ins.isSetOption.middle = res[1].data[0].color.replace('0x', '#');
                                  CanvasConfService.ins.isSetOption.middle_value1 = res[1].data[0].color_code;
                                  CanvasConfService.ins.isSetOption.middle_value2 = res[1].data[0].color_name;
                                }
                                if ((+wide === 1 || +wide === 2 || +wide === 3 || +wide === 4 || +wide === 10)) {
                                  CanvasConfService.ins.isSetOption.wide_ladder_tape = res[2].data[0].color.replace('0x', '#');
                                  CanvasConfService.ins.isSetOption.wide_ladder_tape_value1 = res[2].data[0].color_code;
                                  CanvasConfService.ins.isSetOption.wide_ladder_tape_value2 = res[2].data[0].color_name;
                                }
                                if ((+wood === 1 || +wood === 2 || +wood === 3 || +wood === 4 || +wood === 10)) {
                                  CanvasConfService.ins.isSetOption.wood_balance = res[3].data[0].color.replace('0x', '#');
                                  CanvasConfService.ins.isSetOption.wood_balance_value1 = res[3].data[0].color_code;
                                  CanvasConfService.ins.isSetOption.wood_balance_value2 = res[3].data[0].color_name;
                                }
                                if(center_table) {
                                  CanvasConfService.ins.centerRaceArr = res[4].data;
                                }
                                if (CanvasConfService.ins.isVertical) {
                                  CanvasConfService.ins.rotateListener = () => {
                                    setTimeout(() => {
                                      const draw = new DrawManager();
                                      draw.init();
                                      CanvasConfService.ins.updateBlindColor = (value: string) => draw.setSlatImg(value);
                                      CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                                      CanvasConfService.ins.outputPdf = (orderSheet: 0 | 1) => draw.outputPdf(orderSheet);
                                      CanvasConfService.ins.saveImg = () => draw.saveImg();
                                      CanvasConfService.ins.rotateListener = null;
                                    }, 300);
                                  };
                                } else {
                                  const draw = new DrawManager();
                                  draw.init();
                                  CanvasConfService.ins.updateBlindColor = (value: string) => draw.setSlatImg(value);
                                  CanvasConfService.ins.updateBlindColor(CanvasConfService.ins.blindData.blindColorCode);
                                  CanvasConfService.ins.outputPdf = (orderSheet: 0 | 1) => draw.outputPdf(orderSheet);
                                  CanvasConfService.ins.saveImg = () => draw.saveImg();
                                  // DrawManager.blindSelected = true;
                                }
                              })
                              .catch((err) => console.log(err));
                          })
                          .catch((err) => console.log(err));
                      })
                      .catch((err) => console.log(err));
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount(): void {
    CanvasConfService.ins.rotateListener = null;
  }

}
